import { useState, useCallback, useEffect } from "react";
import { api, header } from "../lib/queries/axiosInstance";
import { trimObj } from "../lib/utils/utilityHandler";
import { da } from "date-fns/locale";
import Papa from "papaparse";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const useRaAdoption = ({ adoptionStatus, gender, searchValues }) => {
  const [limit, setLimit] = useState(100);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(start + limit);
  const [pageCount, setpageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [inputs, setInputs] = useState([]);
  const [statusOptions, setStatusOptions] = useState("");
  const [advancedSearchOptions, setAdvancedSearchOptions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [searchResult, setSearchResult] = useState("");
  const [dateFilter, setDateFilter] = useState(`*${new Date().getFullYear()}*`);
  const [hasFarmSize, setHasFarmSize] = useState("");
  const [filterOptions, setFilterOptions] = useState([]);

  const fetchInputDistributions = useCallback(async () => {
    // let params;
    // if (advancedSearchOptions.length > 0) {
    //   let advance = "";
    //   advancedSearchOptions.forEach((item, index) => {
    //     // Append the parameter to the URL
    //     advance += `${item.column}=${item.value}`;

    //     // If it's not the last item, add an ampersand (&) as a separator
    //     if (index < advancedSearchOptions.length - 1) {
    //       advance += "&";
    //     }
    //   });
    //   console.log(advancedSearchOptions);
    //   console.log(advance);
    // } else {
    // }
    try {
      setLoading(true);
      // const res = await api.get(`rga`, {
      //   headers: header(),
      // });

      // const res = await api.get(`rga/search?${params}`, {
      //   headers: header(),
      // });
      const res = await api.get(`rga/view`, {
        headers: header(),
      });

      const data = await res.data;
      console.log(data.code);
      if (data.code === 200) {
        const total = data.data.total;
        setTotal(total);
        setpageCount(Math.ceil(total / limit));
        setInputs(data.data.content);
      } else {
        setInputs([]);
        setTotal(0);
        setpageCount(0);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setInputs([]);
      setTotal(0);
      setpageCount(0);
      console.log(err);
    }
  }, [limit, start, hasFarmSize]);

  const searchRaAdoptions = useCallback(
    async (advancedSearchOptions) => {
      // let params = `${lastName && "&Last_Name=" + lastName}${firstName ? "&First_Name=" + firstName : ""}${middleName ? "&Middle_Name=" + middleName : ""}${middleName ? "&Middle_Name=" + middleName : ""}&start=${start}&limit=${limit}`;
      let transformedData;
      let params;


      if (advancedSearchOptions.length) {
        setFilterOptions(advancedSearchOptions);
        transformedData = advancedSearchOptions.reduce((acc, item) => {
          switch (item.column) {
            case "First_Name":
              acc.firstName = item.value;
              break;
            case "Middle_Name":
              acc.middleName = item.value;
              break;
            case "Last_Name":
              acc.lastName = item.value;
              break;
            case "LGA":
              acc.lga = item.value;
              break;
            default:
              break;
          }
          return acc;
        }, {});
        console.log("transformedData", transformedData);
        params = `${
          transformedData.lastName && "Last_Name=" + transformedData.lastName
        }${
          transformedData.firstName
            ? "&First_Name=" + transformedData.firstName
            : ""
        }${
          transformedData.middleName
            ? "&Middle_Name=" + transformedData.middleName
            : ""
        }${
          transformedData.lga ? "&LGA=" + transformedData.lga : ""
        }&start=${start}&limit=${limit}`;

        try {
          setLoading(true);
          // const res = await api.get(`rga`, {
          //   headers: header(),
          // });
          console.log(params);
          const res = await api.get(`rga/search?${params}`, {
            headers: header(),
          });

          const data = await res.data;
          console.log(data.code);
          if (data.code === 200) {
            const total = data.data.total;
            setSearchResult(data);
            setTotal(total);
            // setpageCount(Math.ceil(total / limit));
            setInputs(data.data.content);
          } else {
            setInputs([]);
            setTotal(0);
            setpageCount(0);
          }

          setLoading(false);
        } catch (err) {
          setLoading(false);
          setInputs([]);
          setTotal(0);
          setpageCount(0);
          console.log(err);
        }
      }

      // let params = `${lastName && "&Last_Name=" + lastName}${firstName ? "&First_Name=" + firstName : ""}${middleName ? "&Middle_Name=" + middleName : ""}${middleName ? "&Middle_Name=" + middleName : ""}&start=${start}&limit=${limit}`;

      // try {
      //   setLoading(true);
      //   // const res = await api.get(`rga`, {
      //   //   headers: header(),
      //   // });
      //   console.log(params);
      //   const res = await api.get(`rga/search?${params}`, {
      //     headers: header(),
      //   });

      //   const data = await res.data;
      //   console.log(data.code);
      //   if (data.code === 200) {
      //     const total = data.data.total;
      //     setSearchResult(data);
      //     setTotal(total);
      //     // setpageCount(Math.ceil(total / limit));
      //     setInputs(data.data.content);
      //   } else {
      //     setInputs([]);
      //     setTotal(0);
      //     setpageCount(0);
      //   }

      //   setLoading(false);
      // } catch (err) {
      //   setLoading(false);
      //   setInputs([]);
      //   setTotal(0);
      //   setpageCount(0);
      //   console.log(err);
      // }
    },
    [limit, start]
  );

  const searchByAdoptionsStatus = useCallback(async () => {
    console.log("adoptionStatus", adoptionStatus);
    try {
      setLoading(true);
      // const res = await api.get(`rga`, {
      //   headers: header(),
      // });

      const res = await api.get(`rga/search?AdoptionStatus=${adoptionStatus}`, {
        headers: header(),
      });

      const data = await res.data;
      console.log(data.code);
      if (data.code === 200) {
        const total = data.data.total;
        setSearchResult(data);
        setTotal(total);
        // setpageCount(Math.ceil(total / limit));
        setInputs(data.data.content);
      } else {
        setInputs([]);
        setTotal(0);
        setpageCount(0);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setInputs([]);
      setTotal(0);
      setpageCount(0);
      console.log(err);
    }
  }, [adoptionStatus]);

  const searchByAdoptionsByGender = useCallback(async () => {
    console.log("gender running RA", gender);
    try {
      setLoading(true);
      // const res = await api.get(`rga`, {
      //   headers: header(),
      // });

      const res = await api.get(`rga/search?HH_Gender=${gender}`, {
        headers: header(),
      });

      const data = await res.data;
      console.log(data.code);
      if (data.code === 200) {
        const total = data.data.total;
        setSearchResult(data);
        setTotal(total);
        // setpageCount(Math.ceil(total / limit));
        setInputs(data.data.content);
      } else {
        setInputs([]);
        setTotal(0);
        setpageCount(0);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setInputs([]);
      setTotal(0);
      setpageCount(0);
      console.log(err);
    }
  }, [gender]);

  async function onExport() {
    const tempLimit = 3000; // Number of items to fetch per request

    console.log("filterOptions>>>>>>>>>", filterOptions);

    let transformedData;
    let params;

    if (filterOptions.length) {
      console.log("Running here");
      transformedData = filterOptions.reduce((acc, item) => {
        switch (item.column) {
          case "First_Name":
            acc.firstName = item.value;
            break;
          case "Middle_Name":
            acc.middleName = item.value;
            break;
          case "Last_Name":
            acc.lastName = item.value;
            break;
          case "LGA":
            acc.lga = item.value;
            break;
          default:
            break;
        }
        return acc;
      }, {});
    }

    const pageCount = Math.ceil(total / tempLimit);
    const zip = new JSZip();
    try {
      for (let page = 0; page < pageCount; page++) {
        const tempStart = page * tempLimit;

        if(filterOptions.length){
          params = `${
            transformedData.lastName && "Last_Name=" + transformedData.lastName
          }${
            transformedData.firstName
              ? "&First_Name=" + transformedData.firstName
              : ""
          }${
            transformedData.middleName
              ? "&Middle_Name=" + transformedData.middleName
              : ""
          }${
            transformedData.lga ? "&LGA=" + transformedData.lga : ""
          }&start=${tempStart}&limit=${tempLimit}`;
        }else if(gender) {
          params = `HH_Gender=${gender}`;
        }else if(adoptionStatus) {
          params = `AdoptionStatus=${adoptionStatus}`;
        }else {
          params = ``;
        }

        setLoading(true);
        const res = await api.get(`rga/search?${params}`, {
          headers: header(),
        });
        const data = await res.data; // Retrieved data from the backend
        const csvContent = Papa.unparse(data.data.content);
        zip.file(`RaAdoptions_${page}.csv`, csvContent);
        // const total = data.data.total;
        // setTotal(total);
        // setpageCount(Math.ceil(total / limit));
        // setEnumerations(data.data.content);
        // // Generate CSV file
        // const csv = Papa.unparse(data.data.content);
        // const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        // const csvURL = URL.createObjectURL(csvData);
        // const tempLink = document.createElement("a");
        // tempLink.href = csvURL;
        // tempLink.setAttribute("download", `data_${page}.csv`);
        // tempLink.click();
      }
      const currentTimestamp = new Date().toISOString().replace(/:/g, "-");
      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, `RaAdoptions_${currentTimestamp}.zip`); // Download the ZIP file with timestamp
      setLoading(false); // Set loading state to false after finishing the export process
    } catch (err) {
      setLoading(false);
      // setEnumerations([]);
    }
  }

  useEffect(() => {
    setLoading(true);
    fetchInputDistributions();
  }, [limit, start]);
  const handleOnClose = () => setOpenModal(false);

  return {
    setOpenModal,
    openModal,
    handleOnClose,
    setLimit,
    inputs,
    setSearchResult,
    searchResult,
    setInputs,
    fetchInputDistributions,
    limit,
    loading,
    setLoading,
    start,
    setStart,
    end,
    setEnd,
    pageCount,
    setpageCount,
    total,
    setTotal,
    statusOptions,
    setStatusOptions,
    dateFilter,
    setDateFilter,
    hasFarmSize,
    setHasFarmSize,
    onExport,
    advancedSearchOptions,
    setAdvancedSearchOptions,
    searchRaAdoptions,
    searchByAdoptionsStatus,
    searchByAdoptionsByGender,
  };
};

export default useRaAdoption;
