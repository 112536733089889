import React from "react";

const ProgressBar = ({ total, completed }) => {
  const percentage = (completed / total) * 100;

  const progressBarStyle = {
    width: `${percentage}%`,
  };

  const remainingBarStyle = {
    width: `${100 - percentage}%`,
  };

  return (
    <div className="w-full bg-white p-4 rounded-md">
      <p className="mb-2">Progression</p>
      <div className="w-full h-10 rounded-md" style={{ background: "#EBEDF1" }}>
        <div
          className="bg-primary h-full text-right flex justify-end items-center rounded-md text-white pr-1 font-bold text-xs"
          style={progressBarStyle}
        >
          {/* {percentage.toFixed(2)}% */}
        </div>
        <p className="text-center justify-center items-center font-semibold text-primary">
          {percentage.toFixed(2)}%
        </p>
      </div>
      {/* Bottom */}
      <div className="w-full flex justify-between items-center">
        <p className=" text-slate-600 text-sm mt-2">
          Enumeration Reached:{" "}
          <span className="font-bold text-black">
            {completed.toLocaleString()}
          </span>
        </p>

        <p className=" text-slate-600 text-sm mt-2">
          Enumeration Target:{" "}
          <span className="font-bold text-black">{total.toLocaleString()}</span>
        </p>
      </div>
    </div>
  );
};

export default ProgressBar;
