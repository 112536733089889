import { useState, useCallback, useEffect } from "react";
import { api, header } from "../../lib/queries/axiosInstance";
import { trimObj } from "../../lib/utils/utilityHandler";

const useEnumerationsQuery = ({
  status,
  NIN,
  gender,
  dateSelected,
  state,
  lga,
  farmSize,
}) => {
  const [limit, setLimit] = useState(100);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(start + limit);
  const [pageCount, setpageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [enumerations, setEnumerations] = useState([]);
  const [statusOptions, setStatusOptions] = useState("");
  const [advancedSearchOptions, setAdvancedSearchOptions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [objParams, setObjParams] = useState({});
  const [searchResult, setSearchResult] = useState("");

  const fetchEnumerations = useCallback(async () => {
    let params = {
      Status: status,
      A7_National_ID_Status: NIN,
      A6_Household_Gender: gender,
      search: searchResult,
      UpdatedAT: dateSelected,
      B1_What_is_your_state_of_residence: state,
      B2_What_is_your_LGA_of_residence: lga,
      E7_Farm_size:
        farmSize === "unvalidated" ? " " : farmSize === "*.*" ? farmSize : null,
      start,
      limit,
    };

    try {
      setLoading(true);
      const _params = buildParams();
      const __params = {
        ..._params,
        ...params,
      };

      const np = trimObj(__params);
      const res = await api.get(`Enumeration/filter`, {
        headers: header(),
        params: np,
      });
      const data = await res.data;
      console.log(res);
      const total = data.data.total;
      setTotal(total);
      setpageCount(Math.ceil(total / limit));
      setEnumerations(data.data.content);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setEnumerations([]);
    }
  }, [
    limit,
    start,
    status,
    NIN,
    objParams,
    gender,
    dateSelected,
    searchResult,
    state,
    lga,
    farmSize,
  ]);

  useEffect(() => {
    let ObjectParams = {
      Status: status,
      A7_National_ID_Status: NIN,
      A6_Household_Gender: gender,
      search: searchResult,
      UpdatedAT: dateSelected,
      B1_What_is_your_state_of_residence: state,
      B2_What_is_your_LGA_of_residence: lga,
      E7_Farm_size:
        farmSize === "unvalidated" ? " " : farmSize === "*.*" ? farmSize : null,
      start,
      limit,
    };

    const resetPage = () => {
      setStart(0);
      setLimit(100);
      return ObjectParams;
    };
    resetPage();
  }, [status, NIN, gender, searchResult, dateSelected, state, farmSize, lga]);

  const buildParams = () => {
    const params = {};
    for (let option of advancedSearchOptions) {
      params[option.column] = option.value;
    }

    setObjParams(params);
    return params;
  };

  useEffect(() => {
    buildParams();
  }, [advancedSearchOptions]);

  // const searchEnumerations = useCallback(async () => {
  //   try {
  //     setLoading(true);
  //     const res = await api.get(`Enumeration/search/${searchResult}`, {
  //       headers: header(),
  //     });
  //     const data = await res.data;
  //     const total = data.data.total;
  //     setTotal(total);
  //     setpageCount(Math.ceil(total / limit));
  //     setEnumerations(data.data);
  //     setLoading(false);
  //   } catch (err) {
  //     setLoading(false);
  //     setEnumerations([]);
  //   }
  // }, [searchResult]);

  const advanceSearchEnumerations = async () => {
    handleOnClose();
    try {
      const _params = buildParams();
      console.log({ _params });
      setLoading(true);
      const res = await api.get(`Enumeration/filter`, {
        headers: header(),
        params: _params,
      });
      console.log(res.data);
      const data = await res.data;
      const total = data.data.total;
      setTotal(total);
      setpageCount(Math.ceil(total / limit));
      setEnumerations(data.data.content);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setEnumerations([]);
    }
  };

  useEffect(() => {
    setLoading(true);

    fetchEnumerations();
  }, [start, limit, status, NIN, gender, dateSelected, state, farmSize, lga]);

  const handleOnClose = () => setOpenModal(false);

  return {
    setOpenModal,
    openModal,
    handleOnClose,
    setLimit,
    enumerations,
    setSearchResult,
    searchResult,
    setEnumerations,
    fetchEnumerations,
    // searchEnumerations,
    limit,
    loading,
    setLoading,
    start,
    setStart,
    end,
    setEnd,
    pageCount,
    setpageCount,
    total,
    setTotal,
    statusOptions,
    setStatusOptions,
    setAdvancedSearchOptions,
    advancedSearchOptions,
    advanceSearchEnumerations,
  };
};

export default useEnumerationsQuery;
