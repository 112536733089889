import React from "react";
import { useAuthContext } from "../../../src/Context/AuthContext";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";
import "./style.css";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import ProgressBar from "../../components/Generic/ProgressBar";
import { AiOutlineLeft } from "react-icons/ai";

const IndirectDashboard = () => {
  const { authDashboard } = useAuthContext();
  const contClass = "h-full bg-white pt-10 px-8 shadow-sm rounded-md";
  const roundedCircle = "h-[15px] w-[15px] rounded-full my-1 mr-2";
  let tempTotal = [];
  let tempState = [];
  for (const item of authDashboard?.YiWstate || []) {
    if (item?.State !== undefined && item?.Total !== undefined) {
      tempState.push(item.State);
      tempTotal.push(item.Total);
    }
  }
  let tempJobNumber = [];
  let tempJobs = [];
  for (const item of authDashboard?.YiWWorkType || []) {
    if (item?.WorkType !== undefined && item?.Total !== undefined) {
      tempJobs.push(item.WorkType);
      tempJobNumber.push(item.Total);
    }
  }

  let tempEduNumbers = [];
  let tempEdu = [];
  for (const item of authDashboard?.YiWEducation || []) {
    if (item?.Education !== undefined && item?.Total !== undefined) {
      tempEdu.push(item.Education);
      tempEduNumbers.push(item.Total);
    }
  }
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide the legend color
      },
      tooltip: {
        enabled: false, // Hide the data labels
      },
    },
  };
  const genderData = {
    labels: ["Male", "Female"],
    datasets: [
      {
        data: [
          authDashboard?.YiWGender[1]?.Total,
          authDashboard?.YiWGender[0]?.Total,
        ],
        backgroundColor: ["#46A578", "#F4BE37"],
        hoverBackgroundColor: ["#46A578", "#F4BE37"],
      },
    ],
  };
  const disabilityData = {
    labels: ["Disabled", "Non-Disabled"],
    datasets: [
      {
        data: [
          authDashboard?.YiWDisablityStatus[1]?.Total,
          authDashboard?.YiWDisablityStatus[0]?.Total,
        ],
        backgroundColor: ["rgba(99, 255, 181, 1)", "rgba(7, 156, 4, 1)"],
        hoverBackgroundColor: ["rgba(99, 255, 181, 1)", "rgba(7, 156, 4, 1)"],
      },
    ],
  };
  const displacementData = {
    labels: ["IDP", "Non-IDP"],
    datasets: [
      {
        data: [
          authDashboard?.YiWDisplacementStatus[0]?.Total,
          authDashboard?.YiWDisplacementStatus[1]?.Total,
        ],
        backgroundColor: ["rgba(94, 102, 0, 1)", "rgba(201, 248, 12, 1)"],
        hoverBackgroundColor: ["rgba(94, 102, 0, 1)", "rgba(201, 248, 12, 1)"],
      },
    ],
  };
  const sexCategoryData = {
    labels: [
      authDashboard?.YiWDemography[0]?.GenderAgeCategory,
      authDashboard?.YiWDemography[1]?.GenderAgeCategory,
      authDashboard?.YiWDemography[2]?.GenderAgeCategory,
      authDashboard?.YiWDemography[3]?.GenderAgeCategory,
    ],
    datasets: [
      {
        data: [
          authDashboard?.YiWDemography[0]?.Total,
          authDashboard?.YiWDemography[1]?.Total,
          authDashboard?.YiWDemography[2]?.Total,
          authDashboard?.YiWDemography[3]?.Total,
        ],
        backgroundColor: ["#466CCC", "#6AC1FF", "#E6BE65", "#E8A462"],
        hoverBackgroundColor: ["#466CCC", "#6AC1FF", "#E6BE65", "#E8A462"],
      },
    ],
  };
  console.log(tempState);
  const enumerationsChartData = {
    series: [
      {
        name: "Series 1",
        data: tempTotal,
      },
    ],
    options: {
      chart: {
        stacked: false,
      },
      xaxis: {
        categories: tempState,
        labels: {
          rotate: 0, // Set rotate to 0 to keep labels straight
        },
        style: {
          fontWeight: "700", // Set font weight to bold
        },
      },
      colors: ["#46A578"],
      legend: {
        position: "top",
      },
    },
  };
  const jobsData = {
    labels: tempJobs,
    datasets: [
      {
        data: tempJobNumber,
        backgroundColor: [
          "rgba(70, 108, 204, 1)",
          "rgba(68, 210, 241, 1)",
          "rgba(106, 193, 255, 1)",
          "rgba(232, 164, 98, 1)",
          "rgba(107, 109, 7, 1)",
          "rgba(52, 210, 210, 1)",
          "rgba(230, 190, 101, 1)",
          "rgba(136, 90, 248, 1)",
          "rgba(70, 108, 204, 1)",
          "rgba(243, 67, 67, 1)",
          "rgba(153, 153, 153, 1)",
          "rgba(211, 227, 25, 1)",
        ],
        hoverBackgroundColor: [
          "rgba(70, 108, 204, 1)",
          "rgba(68, 210, 241, 1)",
          "rgba(106, 193, 255, 1)",
          "rgba(232, 164, 98, 1)",
          "rgba(107, 109, 7, 1)",
          "rgba(52, 210, 210, 1)",
          "rgba(230, 190, 101, 1)",
          "rgba(136, 90, 248, 1)",
          "rgba(70, 108, 204, 1)",
          "rgba(243, 67, 67, 1)",
          "rgba(153, 153, 153, 1)",
          "rgba(211, 227, 25, 1)",
        ],
      },
    ],
  };
  const eduData = {
    labels: tempJobs,
    datasets: [
      {
        data: tempJobNumber,
        backgroundColor: [
          "rgba(70, 108, 204, 1)",
          "rgba(136, 90, 248, 1)",
          "rgba(70, 108, 204, 1)",
          "rgba(232, 164, 98, 1)",
          "rgba(107, 109, 7, 1)",
          "rgba(52, 210, 210, 1)",
        ],
        hoverBackgroundColor: [
          "rgba(70, 108, 204, 1)",
          "rgba(136, 90, 248, 1)",
          "rgba(70, 108, 204, 1)",
          "rgba(232, 164, 98, 1)",
          "rgba(107, 109, 7, 1)",
          "rgba(52, 210, 210, 1)",
        ],
      },
    ],
  };
  const ageData = {
    labels: ["Adult", "Youth"],
    datasets: [
      {
        data: [
          authDashboard?.YiWAgeCategory[0]?.Total,
          authDashboard?.YiWAgeCategory[1]?.Total,
        ],
        backgroundColor: ["#E6BE65", "#E8A462"],
        hoverBackgroundColor: ["#E6BE65", "#E8A462"],
      },
    ],
  };
  return (
    <div className="w-full">
      <div className="w-full flex justify-between items-center">
        <div className="flex items-center">
          <Link className="flex items-center cursor-pointer mr-4" to={"/"}>
            <AiOutlineLeft color={"black"} size={20} />
            <p className="text-primary">Back</p>
          </Link>
          <h1 className="text-md font-medium ">
            Indirect Outreach(Job Creation)
          </h1>
        </div>
        <Link
          className=" block text-sm bg-primary w-[140px] my-5 mx-4 text-white py-3 px-5 rounded-md text-center"
          to={`/indirect-outreaches`}
        >
          {"Records in Grid"}
        </Link>
      </div>

      {/* CHarts */}
      {authDashboard && authDashboard.EnumerationStatus && (
        <>
          <div className="w-full">
            <ProgressBar
              total={parseInt(57600)}
              completed={parseInt(authDashboard?.YiWStatistics[0]?.Reached)}
            />
          </div>
          <div>
            {/* First Row */}
            <div className="w-full h-[300px] grid lg:grid-cols-2 space-x-4  my-6 mb-24 ">
              <div className={contClass}>
                <div className="w-full flex flex-row justify-between items-center mb-4">
                  <h3 className="font-semibold">State</h3>
                  <div className="flex flex-row items-center">
                    <div className="w-[10px] h-[10px] bg-primary rounded-full" />
                    <p className="font-light text-sm">Enumerations</p>
                  </div>
                </div>
                <Chart
                  options={enumerationsChartData.options}
                  series={enumerationsChartData.series}
                  type="bar"
                  width={"100%"}
                />
              </div>
              {/* second Row */}
              <div className="flex justify-between">
                {/* h-full bg-white py-10 px-10 shadow-sm rounded-md */}
                <div
                  className={
                    "w-[47%] h-full bg-white py-10 px-4 shadow-sm rounded-md"
                  }
                >
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="font-semibold px-5 pt-2">Gender</h2>
                  </div>
                  <div className="">
                    <Pie data={genderData} options={options} />
                  </div>
                  {/* Validated Gender */}
                  <div className="my-5 ml-10">
                    <div className="flex items-center">
                      <div
                        className={roundedCircle}
                        style={{
                          backgroundColor:
                            genderData.datasets[0].backgroundColor[0],
                        }}
                      />
                      <p className="text-xs">
                        Male{" "}
                        <span className="font-bold">
                          ({genderData.datasets[0].data[0].toLocaleString()})
                        </span>
                      </p>
                    </div>
                    <div className="flex items-center my-2">
                      <div
                        className={roundedCircle}
                        style={{
                          backgroundColor:
                            genderData.datasets[0].backgroundColor[1],
                        }}
                      />
                      <p className="text-xs">
                        Female{" "}
                        <span className="font-bold">
                          ({genderData.datasets[0].data[1].toLocaleString()})
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    "w-[47%] h-full bg-white py-10 px-4 shadow-sm rounded-md"
                  }
                >
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="font-semibold px-5 pt-2">Demography</h2>
                  </div>
                  <div className="">
                    <Pie data={sexCategoryData} options={options} />
                  </div>
                  {/* Sex Category */}
                  <div className="my-5">
                    <div className="flex items-center">
                      <div
                        className={roundedCircle}
                        style={{
                          backgroundColor:
                            sexCategoryData.datasets[0].backgroundColor[0],
                        }}
                      />
                      <p className="text-xs">
                        {authDashboard?.YiWDemography[0]?.GenderAgeCategory}{" "}
                        <span className="font-bold">
                          (
                          {sexCategoryData.datasets[0].data[0].toLocaleString()}
                          )
                        </span>
                      </p>
                    </div>
                    <div className="flex items-center my-2">
                      <div
                        className={roundedCircle}
                        style={{
                          backgroundColor:
                            sexCategoryData.datasets[0].backgroundColor[1],
                        }}
                      />
                      <p className="text-xs">
                        {authDashboard?.YiWDemography[1]?.GenderAgeCategory}
                        <span className="font-bold">
                          (
                          {sexCategoryData.datasets[0].data[1].toLocaleString()}
                          )
                        </span>
                      </p>
                    </div>
                    <div className="flex items-center my-2">
                      <div
                        className={roundedCircle}
                        style={{
                          backgroundColor:
                            sexCategoryData.datasets[0].backgroundColor[2],
                        }}
                      />
                      <p className="text-xs">
                        {authDashboard?.YiWDemography[2]?.GenderAgeCategory}{" "}
                        <span className="font-bold">
                          (
                          {sexCategoryData.datasets[0].data[2].toLocaleString()}
                          )
                        </span>
                      </p>
                    </div>
                    <div className="flex items-center my-2">
                      <div
                        className={roundedCircle}
                        style={{
                          backgroundColor:
                            sexCategoryData.datasets[0].backgroundColor[3],
                        }}
                      />
                      <p className="text-xs">
                        {authDashboard?.YiWDemography[3]?.GenderAgeCategory}{" "}
                        <span className="font-bold">
                          (
                          {sexCategoryData.datasets[0].data[3].toLocaleString()}
                          )
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            {/* Row Two */}
            <div className="w-full h-[300px] grid lg:grid-cols-2 space-x-4  my-6 mb-24 ">
              <div className={contClass}>
                <div className="w-full flex flex-row justify-between items-center mb-4">
                  <h3 className="font-semibold">Jobs</h3>
                </div>
                <div className="flex">
                  <div className="w-1/2">
                    <Pie data={jobsData} options={options} />
                  </div>
                  <div className="w-1/2 flex justify-between items-center ml-2">
                    {/* COlors */}
                    <div className="">
                      {jobsData?.datasets[0]?.backgroundColor?.map(
                        (item, index) => {
                          console.log("items", item);
                          return (
                            <div
                              key={index}
                              className={roundedCircle}
                              style={{
                                backgroundColor: item,
                              }}
                            />
                          );
                        }
                      )}
                    </div>
                    {/* Description */}
                    <div className="my-1">
                      {tempJobs.map((item, index) => (
                        <p key={index} className="text-xs h-[15px] my-1">
                          {item === "Farm Labour (e.g. Weeding, planting etc.)"
                            ? item.slice(0, 12)
                            : item === "Data collection (Enumeration)"
                            ? item?.slice(0, 15)
                            : item}
                        </p>
                      ))}
                    </div>
                    {/* Numbers */}
                    <div className="">
                      {tempJobNumber.map((item, index) => (
                        <p key={index} className="text-xs font-bold my-1">
                          {`(${item.toLocaleString()})`}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {/* second Row */}
              <div className="flex justify-between">
                {/* h-full bg-white py-10 px-10 shadow-sm rounded-md */}
                <div
                  className={
                    "w-[47%] h-full bg-white py-10 px-4 shadow-sm rounded-md"
                  }
                >
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="font-semibold px-5 pt-2">
                      Disability Status
                    </h2>
                  </div>
                  <div className="">
                    <Pie data={disabilityData} options={options} />
                  </div>
                  <div className="my-5 ml-10">
                    <div className="flex items-center">
                      <div
                        className={roundedCircle}
                        style={{
                          backgroundColor:
                            disabilityData.datasets[0].backgroundColor[0],
                        }}
                      />
                      <p className="text-xs">
                        Disabled{" "}
                        <span className="font-bold">
                          ({disabilityData.datasets[0].data[0].toLocaleString()}
                          )
                        </span>
                      </p>
                    </div>
                    <div className="flex items-center my-2">
                      <div
                        className={roundedCircle}
                        style={{
                          backgroundColor:
                            disabilityData.datasets[0].backgroundColor[1],
                        }}
                      />
                      <p className="text-xs">
                        Non-Disabled{" "}
                        <span className="font-bold">
                          ({disabilityData.datasets[0].data[1].toLocaleString()}
                          )
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    "w-[47%] h-full bg-white py-10 px-4 shadow-sm rounded-md"
                  }
                >
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="font-semibold px-4 pt-2">
                      Displacement Status
                    </h2>
                  </div>
                  <div className="">
                    <Pie data={displacementData} options={options} />
                  </div>
                  <div className="my-5 ml-10">
                    <div className="flex items-center">
                      <div
                        className={roundedCircle}
                        style={{
                          backgroundColor:
                            displacementData.datasets[0].backgroundColor[0],
                        }}
                      />
                      <p className="text-xs">
                        IDP{" "}
                        <span className="font-bold">
                          (
                          {displacementData.datasets[0].data[0].toLocaleString()}
                          )
                        </span>
                      </p>
                    </div>
                    <div className="flex items-center my-2">
                      <div
                        className={roundedCircle}
                        style={{
                          backgroundColor:
                            displacementData.datasets[0].backgroundColor[1],
                        }}
                      />
                      <p className="text-xs">
                        Non-IDP{" "}
                        <span className="font-bold">
                          (
                          {displacementData.datasets[0].data[1].toLocaleString()}
                          )
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Row Three */}
            <div className="w-full h-[300px] grid lg:grid-cols-2 space-x-4  my-6 mb-24 ">
              {/* col One */}
              <div className={contClass}>
                <div className="w-full flex flex-row justify-between items-center">
                  <h3 className="font-semibold">Education Level</h3>
                </div>
                <div className="flex">
                  <div className="w-1/2">
                    <Pie data={eduData} options={options} />
                  </div>
                  <div className="w-1/2 flex justify-between items-center ml-2">
                    <div className="ml-4">
                      {tempEdu?.map((item, index) => (
                        <div
                          key={index}
                          className="flex justify-between items-start my-1"
                        >
                          <div className="w-[10%]">
                            <div
                              className={roundedCircle}
                              style={{
                                backgroundColor:
                                  eduData?.datasets[0]?.backgroundColor[index],
                              }}
                            />
                          </div>
                          <p className="text-xs my-1 w-[60%]">{item}</p>
                          <p
                            key={index}
                            className="text-xs font-bold my-1 w-[20%]"
                          >
                            {`${tempEduNumbers[index].toLocaleString()}`}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {/* Col Two */}
              <div className="flex justify-between">
                {/* Sub COl 1 */}
                <div
                  className={
                    "w-[47%] h-full bg-white py-10 px-4 shadow-sm rounded-md"
                  }
                >
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="font-semibold px-5 pt-2">Age Category</h2>
                  </div>
                  <div className="">
                    <Pie data={ageData} options={options} />
                  </div>
                  {/* Validated Gender */}
                  <div className="my-5 ml-10">
                    <div className="flex items-center">
                      <div
                        className={roundedCircle}
                        style={{
                          backgroundColor:
                            ageData.datasets[0].backgroundColor[0],
                        }}
                      />
                      <p className="text-xs">
                        Adult{" "}
                        <span className="font-bold">
                          ({ageData.datasets[0].data[0].toLocaleString()})
                        </span>
                      </p>
                    </div>
                    <div className="flex items-center my-2">
                      <div
                        className={roundedCircle}
                        style={{
                          backgroundColor:
                            ageData.datasets[0].backgroundColor[1],
                        }}
                      />
                      <p className="text-xs">
                        Youth{" "}
                        <span className="font-bold">
                          ({ageData.datasets[0].data[1].toLocaleString()})
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
          </div>
        </>
      )}
    </div>
  );
};

export default IndirectDashboard;
