import React, { useState, useEffect } from "react";
import { BsCheckCircleFill, BsArrowLeft } from "react-icons/bs";
import { newStates, states } from "../../lib/constants/states";
import { wardsList } from "../../constants/wards";
import { FaUserAlt } from "react-icons/fa";
import axios from "axios";
import { baseURL } from "../../constants/URL";
import { useAuthContext } from "../../Context/AuthContext";
import { CustomMessage } from "../../components/Generic";
import { Link } from "react-router-dom";

export default function AddNewStaff() {
  const [image, setImage] = useState(null);
  const [filteredLga, setFilteredLga] = useState([""]);
  const [filteredWard, setFilteredWard] = useState([]);
  const [selectedOffice, setSelectedOffice] = useState({});

  const [formData, setFormData] = useState({
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Email: "",
    PhoneNumber: "",
    StaffPhoto: "",
    Role: "",
    Gender: "",
    StateOfResidence: "",
    LGA: "",
    Ward: "",
    Designation: "",
    OtherDesignation: "",
    StaffIDCardFront: "",
    StaffIDCardBack: "",
    Signature: "",
  });

  const [error, setError] = useState("");
  const [resErr, setResErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { setIsMessage, isMessage, setMessageContent, setMessageType } =
    useAuthContext();

  const onChange = (e) => {
    const { id, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };
  const onOfficeChange = (e) => {
    const { id, value } = e.target;

    console.log(value);
  };
  const {
    FirstName,
    MiddleName,
    LastName,
    Email,
    PhoneNumber,
    Role,
    Gender,
    StateOfResidence,
    LGA,
    Ward,
    Designation,
    OtherDesignation,
    StaffIDCardFront,
    StaffIDCardBack,
    StaffPhoto,
    Signature,
  } = formData;
  const yesorno = ["Yes", "No"];
  const statesdata = ["Abuja", "Adamawa", "Kaduna", "Nasrawa"];
  const office = [
    { title: "SCL Corporate", content: [] },
    { title: "SCL Juriya", content: [] },
    {
      title: "Embedded Partner",
      content: [
        "Ikore",
        "BlueBrain",
        "Gender Dynamics",
        "Tyrdnt Security Ltd",
        "Justevia LLC",
        "Goldshield & Springs Solicitors",
        "Lera Communications",
      ],
    },
    { title: "Consultant", content: ["IT/Data", "AgriFinance", "CIITTAA"] },
    { title: "KoboWeb ", content: [] },
  ];
  const options = [
    "Normal Staff",
    "Admin Officer",
    "Analyst",
    "MEL HQ Financial Officer",
    "CEO",
  ];
  const designation = [
    "Field Agent",
    "State MEL Officer",
    "State Coordinator",
    "State Admin Officer",
    "Driver",
    "Office Assistant",
    "Finance & Operations Manager",
    "Procurement and Logistics Specialist",
    "MEL Specialist",
    "Lead Delivery Manager",
    "CEO/PM",
    "Steering Committee Member",
    "Others",
  ];

  const inputClass =
    "text-sm bg-gray-50 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline";
  const labelClass = "block text-gray-700 mb-2 text-sm";

  useEffect(() => {
    setError(false);
    setResErr(false);
  }, [formData]);
  const handleChangeState = (itemValue) => {
    const filteredCity = states.find((item) => item.state === itemValue);
    const newFilteredCity = filteredCity === undefined ? "" : filteredCity.lgas;
    setFormData((prev) => ({
      ...prev,
      StateOfResidence: itemValue,
    }));
    setFilteredLga(newFilteredCity);
    return newFilteredCity;
  };
  const handleChangeLGA = (itemValue) => {
    const tempFilter = wardsList.find(
      (item) => item.lga === itemValue.toUpperCase()
    );
    const newFiltered = tempFilter === undefined ? [] : tempFilter.wards;
    setFilteredWard(newFiltered);
    setFormData((prev) => ({
      ...prev,
      LGA: itemValue,
    }));
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const name = e.target.name;
    setFormData((prev) => ({
      ...prev,
      [name]: file,
    }));
  };
  const handleSubmit = async () => {
    const key = localStorage.getItem("token");
    if (
      FirstName === "" ||
      LastName === "" ||
      Email === "" ||
      PhoneNumber === "" ||
      Role === "" ||
      Gender === "" ||
      StateOfResidence === "" ||
      LGA === "" ||
      Ward === "" ||
      Designation === "" ||
      StaffIDCardFront === "" ||
      StaffIDCardBack === "" ||
      StaffPhoto === "" ||
      Signature === ""
    ) {
      setMessageContent("All Fields Fill");
      setIsMessage(true);
      setMessageType("error");
    } else {
      let formdata = new FormData();
      formdata.append("FirstName", FirstName);
      formdata.append("MiddleName", MiddleName);
      formdata.append("LastName", LastName);
      formdata.append("Email", Email);
      formdata.append("PhoneNumber", PhoneNumber);
      formdata.append("StaffPhoto", StaffPhoto);
      formdata.append("Gender", Gender);
      formdata.append("Role", Role);
      formdata.append("StateOfResidence", StateOfResidence);
      formdata.append("LGA", LGA);
      formdata.append("Ward", Ward);
      formdata.append("Designation", Designation);
      formdata.append("OtherDesignation", OtherDesignation);
      formdata.append("StaffIDCardFront", StaffIDCardFront);
      formdata.append("StaffIDCardBack", StaffIDCardBack);
      formdata.append("Signature", Signature);
      try {
        setLoading(true);
        const headers = {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${key}`,
        };
        const response = await axios.post(`${baseURL}/stafftable`, formdata, {
          headers,
        });
        if (response.data.code === 200) {
          setFormData({
            ...formData,
            FirstName: "",
            MiddleName: "",
            LastName: "",
            Email: "",
            PhoneNumber: "",
            StaffPhoto: "",
            Role: "",
            Gender: "",
            StateOfResidence: "",
            LGA: "",
            Ward: "",
            Designation: "",
            OtherDesignation: "",
            StaffIDCardFront: "",
            StaffIDCardBack: "",
            Signature: "",
          });
          setMessageContent(response.data.message);
          setIsMessage(true);
          setMessageType("success");
          setLoading(false);
          setImage(null);
        } else {
          setMessageContent(response.data.message);
          setIsMessage(true);
          setMessageType("error");
        }
      } catch (error) {
        setMessageContent(error.message);
        setIsMessage(true);
        setMessageType("error");
      }
    }
  };
  if (isMessage) {
    return <CustomMessage />;
  }
  return (
    <>
      <div className="grid mt-8 grid-cols-12 gap-4">
        <div className="col-start-3 col-span-8 border rounded bg-white">
          <Link className="flex items-center" to={"/staff"}>
            <BsArrowLeft className={"text-primary"} size={20} />
            <p className="text-primary text-xs font-bold">Back</p>
          </Link>
          <div className="text-xl font-medium text-center my-8">
            Add New Staff
          </div>
          <form>
            {/* Upload Image */}
            <div>
              <center>
                {/* Icon or Image */}
                <div className="flex items-center justify-center bg-customGray w-[120px] h-[120px]  rounded-full">
                  {image ? (
                    <img
                      src={image}
                      className="h-[100%] w-[100%] rounded-full"
                      alt="Passport"
                    />
                  ) : (
                    <FaUserAlt size={50} className="text-zinc-500 mx-auto " />
                  )}
                </div>
                <div className="">
                  <input
                    type="file"
                    accept=".jpg,.png,.jpeg"
                    onChange={(e) => {
                      if (e.target.files.length === 0) {
                        setImage(null);
                      } else {
                        setImage(URL?.createObjectURL(e.target.files[0]));
                        setFormData((prev) => ({
                          ...prev,
                          StaffPhoto: e.target.files[0],
                        }));
                        // setUploadImg(e.target.files[0]);
                      }
                    }}
                    className="text-[xs]"
                  />
                </div>
              </center>
            </div>
            <div className="grid grid-cols-3 px-6 py-2">
              <div className="p-2">
                <label className="block text-gray-700 mb-2 text-sm">
                  FirstName
                </label>
                <input
                  onChange={onChange}
                  value={FirstName}
                  type="text"
                  id="FirstName"
                  className="text-sm bg-gray-50 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="p-2">
                <label className="block text-gray-700 mb-2 text-sm">
                  Middle Name
                </label>
                <input
                  onChange={onChange}
                  value={MiddleName}
                  type="text"
                  id="MiddleName"
                  className="text-sm bg-gray-50 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="p-2">
                <label className="block text-gray-700 mb-2 text-sm">
                  Last Name
                </label>
                <input
                  onChange={onChange}
                  value={LastName}
                  type="text"
                  id="LastName"
                  className="text-sm bg-gray-50 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="p-2">
                <label className={labelClass}>Phone Number</label>
                <input
                  onChange={onChange}
                  value={PhoneNumber}
                  type="text"
                  id="PhoneNumber"
                  className="text-sm bg-gray-50 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="p-2">
                <label className="block text-gray-700 mb-2 text-sm">
                  Email
                </label>
                <input
                  onChange={onChange}
                  value={Email}
                  type="Email"
                  id="Email"
                  className="text-sm bg-gray-50 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              {/* Gender */}
              <div className="p-2">
                <label className="block text-gray-700 mb-2 text-sm">
                  Gender
                </label>
                <select
                  value={Gender}
                  id="Gender"
                  onChange={onChange}
                  className="text-sm bg-gray-50 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value={""}>-- Gender --</option>
                  <option value={"Male"}>Male</option>
                  <option value={"Female"}>Female</option>
                </select>
              </div>
              {/* PWD */}
              <div className="p-2">
                <label className="block text-gray-700 mb-2 text-sm">
                  Disabled?
                </label>
                <select
                  value={Role}
                  id="PwD"
                  onChange={onChange}
                  className="text-sm bg-gray-50 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value={""}> -- </option>
                  {yesorno.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              {/* IDP */}
              <div className="p-2">
                <label className="block text-gray-700 mb-2 text-sm">IDP?</label>
                <select
                  value={Role}
                  id="IDP"
                  onChange={onChange}
                  className="text-sm bg-gray-50 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value={""}> -- </option>
                  {yesorno.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              {/* Role */}
              {/* <div className="p-2">
                <label className="block text-gray-700 mb-2 text-sm">Role</label>
                <select
                  value={Role}
                  id="Role"
                  onChange={onChange}
                  className="text-sm bg-gray-50 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value={""}>-- Choose Role --</option>
                  {options.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div> */}
              {/* State */}
              <div className="p-2">
                <div>
                  <label className={labelClass}>State of residence/work</label>

                  <select
                    onChange={(e) => handleChangeState(e.target.value)}
                    value={StateOfResidence}
                    className={inputClass}
                  >
                    <option selected value="">
                      --
                    </option>
                    {statesdata.map((opts) => (
                      <option value={opts}>{opts}</option>
                    ))}
                  </select>
                </div>
              </div>
              {/* LGA */}
              {/* <div className="p-2">
                <label className={labelClass}>LGA</label>

                <select
                  onChange={(e) => handleChangeLGA(e.target.value)}
                  value={LGA}
                  className={inputClass}
                >
                  <option selected value="">
                    LGA
                  </option>
                  {filteredLga.length &&
                    filteredLga.map((opts) => (
                      <option value={opts}>{opts}</option>
                    ))}
                </select>
              </div> */}
              {/* Ward */}
              {/* <div className="p-2">
                <label className={labelClass}>Ward</label>

                <select
                  onChange={onChange}
                  value={Ward}
                  id="Ward"
                  className={inputClass}
                >
                  <option selected value="">
                    -- Choose Ward --
                  </option>
                  {filteredWard.length &&
                    filteredWard.map((opts) => (
                      <option value={opts}>{opts}</option>
                    ))}
                </select>
              </div> */}
              {/* Designation */}
              {/* Office/Organization */}
              <div className="p-2">
                <label className="block text-gray-700 mb-2 text-sm">
                  Office/Organization
                </label>
                <select
                  value={Designation}
                  id="Designation"
                  onChange={onChange}
                  className="text-sm bg-gray-50 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value={""}>-- --</option>
                  {office.map((option, index) => (
                    <option key={index} value={option}>
                      {option.title}
                    </option>
                  ))}
                </select>
              </div>
              {/* Role */}
              <div className="p-2">
                <label className="block text-gray-700 mb-2 text-sm">
                  Designation
                </label>
                <select
                  value={Designation}
                  id="Designation"
                  onChange={onChange}
                  className="text-sm bg-gray-50 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                  <option value={""}>-- Choose Designation --</option>
                  {designation.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              {/* Other Designation */}
              <div className="p-2">
                <label className={labelClass}>Other Designation</label>
                <input
                  onChange={onChange}
                  value={OtherDesignation}
                  type="text"
                  id="OtherDesignation"
                  className={inputClass}
                />
              </div>
              {/* Front ID */}
              <div className="p-2 mt-4">
                <label className={labelClass} htmlFor="StaffIDCardFront">
                  Staff ID Card Front:
                </label>
                <input
                  type="file"
                  name="StaffIDCardFront"
                  id="StaffIDCardFront"
                  onChange={handleFileChange}
                  className={inputClass}
                />
                {/* <label htmlFor="StaffIDCardFront">
                  Choose Staff ID Card Front
                </label> */}
              </div>
              {/* Back ID */}
              <div className="p-2 mt-4">
                <label className={labelClass} htmlFor="StaffIDCardBack">
                  Staff ID Card Back:
                </label>
                <input
                  type="file"
                  name="StaffIDCardBack"
                  id="StaffIDCardBack"
                  onChange={handleFileChange}
                  className={inputClass}
                />
                {/* <label htmlFor="StaffIDCardBack">
                  Choose Staff ID Card Back
                </label> */}
              </div>
              {/* Signature */}
              <div className="p-2 mt-4">
                <label className={labelClass} htmlFor="Signature">
                  Signature:
                </label>
                <input
                  type="file"
                  name="Signature"
                  id="Signature"
                  onChange={handleFileChange}
                  className={inputClass}
                />
                {/* <label htmlFor="Signature">Choose Signature</label> */}
              </div>
            </div>
            <div className="p-6">
              <button
                className="bg-primary w-full  text-white py-3 px-10 rounded-lg focus:outline-none focus:shadow-outline"
                type="button"
                onClick={handleSubmit}
              >
                {loading ? "Loading..." : "Add Staff Details"}
              </button>
            </div>
          </form>
        </div>
      </div>
      {showModal && <Modal onClose={() => setShowModal(!showModal)} />}
    </>
  );
}

const Modal = ({ onClose }) => {
  return (
    <>
      <div
        id="popup-modal"
        tabIndex="-1"
        className="fixed rounded-lg top-0 border-raidius left-0 right-0 z-50 bg-gray-600 bg-opacity-20 backdrop-blur-xs flex justify-center items-center p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full"
      >
        <div className="relative w-full h-full max-w-sm md:h-auto">
          <div className="relative bg-white rounded-lg  frounded-lg shadow dark:bg-gray-700">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              data-modal-hide="popup-modal"
              onClick={onClose}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>

            <div className="p-6 py-8 justify-items-center text-center">
              <div className="">
                <div className="flex justify-center mb-6 mt-4 items-center">
                  <BsCheckCircleFill fontSize={68} color="green" />
                </div>
                <div>
                  <h3 className="mb-5 text-2xl font-regular text-gray-700 dark:text-gray-400">
                    Agent Created Successfully.
                  </h3>
                </div>
                <button
                  data-modal-hide="popup-modal"
                  type="button"
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-green-300  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
