import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { baseURL } from "../constants/URL";

const FieldAgentContext = createContext({});

const URL = `${baseURL}/FieldAgents`;

const FieldAgentContextProvider = ({ children }) => {
  const [fieldAgents, setFieldAgents] = useState([]);
  const [fieldAgentDetail, setFieldAgentDetail] = useState([]);
  const [fieldAgentName, setFieldAgentName] = useState([]);

  useEffect(() => {
    const fetchFieldAgents = async () => {
      const result = await fetch(URL);
      result.json().then((json) => {
        setFieldAgents(json.data.content);
      });
    };
    fetchFieldAgents();
  }, []);

  // console.log(fieldAgent);

  return (
    <FieldAgentContext.Provider
      value={{
        fieldAgents,
        setFieldAgents,
        fieldAgentDetail,
        setFieldAgentDetail,
        fieldAgentName,
        setFieldAgentName,
      }}
    >
      {children}
    </FieldAgentContext.Provider>
  );
};

export default FieldAgentContextProvider;

// export const useFieldAgentContext = () => useContext(FieldAgentContext);

export function useFieldAgentProvider() {
  const context = useContext(FieldAgentContext);
  if (context === undefined) {
    throw new Error("useFieldAgentProvider must be within a provider");
  }
  return context;
}
