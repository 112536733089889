import React from "react";
import styles from "./demoplot.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { HiOutlineSearch } from "react-icons/hi";
import { api, header } from "../../lib/queries/axiosInstance";
import PulseLoader from "react-spinners/PulseLoader";
import ReactPaginate from "react-paginate";
import Papa from "papaparse";
import { TiExportOutline } from "react-icons/ti";
import { getDateFromCreated } from "../../lib/constants/functions";

const TableData = ({ children }) => (
  <td className="px-6 py-2 text-black">{children}</td>
);
export default function DemoPlotsInputDistributions() {
  const [limit, setLimit] = useState(100);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(start + limit);
  const [pageCount, setpageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [demoPlots, setDemoPlots] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const getDemoPlots = async () => {
      const res = await api.get(`dp_input_distribution`, {
        headers: header(),
      });
      // const getDemoPlots = async () => {
      //   const res = await api.get(`householdsurvey`, {
      //     headers: header(),
      //   });

      const data = await res.data;
      console.log(data);
      if (data?.data?.content?.length > 0) {
        const total = data.data.total;
        setTotal(total);
        setpageCount(Math.ceil(total / limit));
        setDemoPlots(data.data.content);
      }
      setLoading(false);
    };

    getDemoPlots();
  }, [limit]);

  const fetchDemoPlots = async (start) => {
    setLoading(true);
    const res = await api.get(`dp_input_distribution`, {
      headers: header(),
    });

    const data = await res.data;
    setLoading(false);
    const resData = data.data.content;
    // console.log(data.data);
    return resData;
  };

  const handlePageClick = async (data) => {
    let startCounter = data.selected + 1;
    let startr = startCounter * limit - limit;
    let endr = startr + limit;
    const survs = await fetchDemoPlots(startr);
    setEnd(endr);
    setStart(startr);
    setDemoPlots(survs);
  };

  const exportToCSV = () => {
    console.log("Export Clicked");
    const currentTimestamp = new Date().toISOString().replace(/:/g, "-");
    // const csv = Papa.unparse(demoPlots);
    // const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    // const csvURL = URL.createObjectURL(csvData);
    // const tempLink = document.createElement("a");
    // tempLink.href = csvURL;
    // tempLink.setAttribute(
    //   "download",
    //   `Generated-demoPlots_${currentTimestamp}.csv`
    // );
    // tempLink.click();
    // // Generate CSV file
    const csv = Papa.unparse(demoPlots);
    const modifiedCsv = csv.replace(/â€“/g, "-");
    const csvData = new Blob([modifiedCsv], {
      type: "text/csv;charset=utf-8;",
    });
    const csvURL = URL.createObjectURL(csvData);
    const tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute(
      "download",
      `Generated-demoPlots_${currentTimestamp}.csv`
    );
    tempLink.click();
  };

  return (
    <div>
      <div className="text-xl pt-1">Demoplots Input Distributions({total})</div>
      <div className="bg-white mt-4 p-6">
        <div className="flex items-start py-2  justify-between">
          <div></div>
          {/* Export */}
          <div>
            <button
              data-modal-target="popup-modal"
              data-modal-toggle="popup-modal"
              className="text-white w-full text-xs bg-primary py-[8px] px-2 flex justify-center items-center rounded-sm border border-primary focus:outline-none focus:shadow-outline"
              type="button"
              onClick={() => exportToCSV()}
            >
              Export to CSV
              <TiExportOutline className="text-white ml-2" />
            </button>
          </div>
        </div>
        <div
          className={` ${styles.tablewrp} relative scroll-div overflow-scroll mt-4 shadow-md  w-100% sm:rounded-lg`}
        >
          {loading ? (
            <div className=" m-12  justify-items-center justify-center">
              {" "}
              <PulseLoader
                className=" m-12 justify-center"
                color={"#46A576"}
                loading={loading}
                // cssOverride={override}
                size={14}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <table
              className={` w-full  text-sm text-left text-gray-500 dark:text-gray-400`}
            >
              <thead className="text-xs sticky top-0 right-0 left-0 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Plot_UID
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    FarmSize
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    AgentComment
                  </th>
                  {/* <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Signature
                  </th> */}
                  {/* <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    GeoCode
                  </th> */}
                  {/* <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    InputPhoto
                  </th> */}
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    DistributionDate
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    DistributionVenue
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Acacia_albida
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Acacia_senegal
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Acacia_seyal
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Prosopis_africana
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Scerocarya_bierrea
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Parkia_biglobosa
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Baobab
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Balanites_Aegyptiaca
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Ziziphus_mauritiana
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Teak
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Moringa_oleifera
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Moringa_stenopetala
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Elais_Guinensis
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Jetropa_curcas
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Mahogany
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Super_napia
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Marigold_stem
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Maize
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Sorghum
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Rice
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Yam
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Ginger
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Soybean
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Hibiscus
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Sesame
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Bambara_Nut
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Groundnut
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Sweet_Potato
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Watermelon
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Pumpkin
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Napia
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Cowpea
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Chilli
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Marigold_kg
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Compost
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Biofertilizer_Biopesticide
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Pruning_tools
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    CreatedAT
                  </th>
                </tr>
              </thead>
              <tbody className=" pt-8">
                {demoPlots.length &&
                  demoPlots.map((plot, index) => (
                    <tr
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 pt-8  hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer"
                      key={index}
                      onClick={() => navigate(`/demo-plot/${plot.Plot_UID}`)}
                    >
                      <TableData>{plot?.Plot_UID}</TableData>
                      <TableData>{plot?.FarmSize}</TableData>
                      <TableData>{plot?.AgentComment}</TableData>
                      {/* <TableData>{plot?.Signature}</TableData>
                      <TableData>{plot?.GeoCode}</TableData>
                      <TableData>{plot?.InputPhoto}</TableData> */}
                      <TableData>
                        {getDateFromCreated(plot?.DistributionDate)}
                      </TableData>
                      <TableData>{plot?.DistributionVenue}</TableData>
                      <TableData>{plot?.Acacia_albida}</TableData>
                      <TableData>{plot?.Acacia_senegal}</TableData>
                      <TableData>{plot?.Acacia_seyal}</TableData>
                      <TableData>{plot?.Prosopis_africana}</TableData>
                      <TableData>{plot?.Scerocarya_bierrea}</TableData>
                      <TableData>{plot?.Parkia_biglobosa}</TableData>
                      <TableData>{plot?.Baobab}</TableData>
                      <TableData>{plot?.Balanites_Aegyptiaca}</TableData>
                      <TableData>{plot?.Ziziphus_mauritiana}</TableData>
                      <TableData>{plot?.Teak}</TableData>
                      <TableData>{plot?.Moringa_oleifera}</TableData>
                      <TableData>{plot?.Moringa_stenopetala}</TableData>
                      <TableData>{plot?.Elais_Guinensis}</TableData>
                      <TableData>{plot?.Jetropa_curcas}</TableData>
                      <TableData>{plot?.Mahogany}</TableData>
                      <TableData>{plot?.Super_napia}</TableData>
                      <TableData>{plot?.Marigold_stem}</TableData>
                      <TableData>{plot?.Maize}</TableData>
                      <TableData>{plot?.Sorghum}</TableData>
                      <TableData>{plot?.Rice}</TableData>
                      <TableData>{plot?.Yam}</TableData>
                      <TableData>{plot?.Ginger}</TableData>
                      <TableData>{plot?.Soybean}</TableData>
                      <TableData>{plot?.Hibiscus}</TableData>
                      <TableData>{plot?.Sesame}</TableData>
                      <TableData>{plot?.Bambara_Nut}</TableData>
                      <TableData>{plot?.Groundnut}</TableData>
                      <TableData>{plot?.Sweet_Potato}</TableData>
                      <TableData>{plot?.Watermelon}</TableData>
                      <TableData>{plot?.Pumpkin}</TableData>
                      <TableData>{plot?.Napia}</TableData>
                      <TableData>{plot?.Cowpea}</TableData>
                      <TableData>{plot?.Chilli}</TableData>
                      <TableData>{plot?.Marigold_kg}</TableData>
                      <TableData>{plot?.Compost}</TableData>
                      <TableData>{plot?.Biofertilizer_Biopesticide}</TableData>
                      <TableData>{plot?.Pruning_tools}</TableData>
                      <TableData>
                        {getDateFromCreated(plot?.CreatedAT)}
                      </TableData>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
        {/* <nav
          className="flex items-center justify-between pt-4"
          aria-label="Table navigation"
        >
          <div className="flex items-center">
            <div className="mt-4 ">
              <form>
                {" "}
                <label
                  // for="countries"
                  className="block mb-2 text-sm font-regular text-gray-900 dark:text-white"
                >
                  No of Data Per Page
                </label>
                <select
                  id="countries"
                  className=" border border-gray-200 text-gray-500 text-sm rounded-md font-light focus:ring-green-500 focus:border-green-400 block w-full p-2.5 "
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <option selected>{limit}</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                </select>
              </form>
            </div>
          </div>

          <div className="flex items-center gap-4">
            <div className=" gap-4 ">
              <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                Showing{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {start}-{end}
                </span>{" "}
                of{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {total}
                </span>
              </span>
            </div>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"inline-flex items-center -space-x-px"}
              pageClassName={
                "px-3 py-2 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              pageLinkClassName={""}
              previousClassName={
                "block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              previousLinkClassName={""}
              nextClassName={
                "block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              nextLinkClassName={""}
              breakClassName={
                "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              breakLinkClassName={
                "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              activeClassName={
                "z-10 px-3 py-2 leading-tight text-white border border-blue-400 bg-primary hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
              }
            />
          </div>
        </nav> */}
      </div>
    </div>
  );
}
