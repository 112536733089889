import { useEffect, useState } from "react";
import { baseURL } from "../constants/URL";

const useFieldAgent = (id) => {
  //   const { id } = useParams();
  const [fieldAgentDetail, setFieldAgentDetail] = useState(null);
  const [pendingFieldAgents, setPendingFieldAgents] = useState(null);
  const [ResponseFormOne, setResponseFormOne] = useState(null);

  const SINGLE_URL = `${baseURL}/FieldAgents`;
  const PENDING_URL = `${baseURL}/FieldAgents/PendingFieldAgent`;

  const registeredby = "User";

  const ADD_FA_URL = `${baseURL}/FieldAgents`;

  const singleFieldAgent = async (id) => {
    const result = await fetch(`${SINGLE_URL}/${id}`);
    result.json().then((json) => {
      setFieldAgentDetail(json.data);
    });
  };

  useEffect(() => {
    id && singleFieldAgent(id);
  }, [id]);

  const getPendingFieldAgent = async () => {
    const result = await fetch(PENDING_URL);
    result.json().then((json) => {
      setPendingFieldAgents(json.data.content);
    });
  };

  useEffect(() => {
    getPendingFieldAgent();
  }, []);

  const createFieldAgent = async (registeredby) => {
    const result = await fetch(`${ADD_FA_URL}/${registeredby}`);

    result.json().then((json) => {
      setResponseFormOne(json.data.content);
    });
  };

  useEffect(() => {
    createFieldAgent(registeredby);
  }, [registeredby]);

  if (ResponseFormOne) {
    console.log(ResponseFormOne);
  }

  return { fieldAgentDetail, pendingFieldAgents };
};

export default useFieldAgent;
