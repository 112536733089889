import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getStatus } from "../../../src/lib/helpers";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
// import { useFieldAgentProvider } from "../../../Context/FieldAgentContext";
import { api, header } from "../../lib/queries/axiosInstance";
import { CustomLink } from "../../components/Generic";

export default function CoordinatorFieldAgent() {
  // const { fieldAgents } = useFieldAgentProvider();
  // const { FieldAgentID, status } = fieldAgents
  // const { fieldAgents } = useFieldAgentProvider();

  const [fieldAgents, setFieldAgents] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFieldAgents = () => {
      setLoading(true);
      api
        .get("/FieldAgents", {
          headers: header(),
        })
        .then((res) => {
          //   console.log(res.data.data.content)
          setFieldAgents(res.data.data.content);
        })
        .catch((err) => {
          console.log(err);
          setError(err.response);
        })
        .finally(() => setLoading(false));
    };

    fetchFieldAgents();
  }, []);

  console.log(fieldAgents);

  return (
    <div>
      <div className="w-full flex justify-between items-center">
        <div className="text-xl pt-1">All Field Agents</div>
        <div>
          <CustomLink
            path={"/add-agents"}
            text={"Add Field Agent"}
            width={"w-[150px]"}
          />
        </div>
      </div>
      <div className="mt-6 bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1">
        <div className="flex items-end py-2 justify-between">
          {/* <div>
            <label
              // for="countries"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Sort by Status
            </label>
            <select
              id="countries"
              className=" border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            >
              <option selected>All Status </option>
              <option value="US">Pending Request</option>
              <option value="CA">Approved Agents</option>
              <option value="FR">Rejected Agents</option>
            </select>
          </div> */}
          {/* <strong className="text-gray-700 font-medium">All Field Agents</strong> */}
          {/* <div className="relative">
            <HiOutlineSearch
              fontSize={20}
              className="text-gray-400 absolute top-1/2 left-3 -translate-y-1/2"
            />
            <input
              type="text"
              placeholder="Search..."
              className="text-sm focus:outline-none active:outline-none border border-gray-300 w-[24rem] h-10 pl-11 pr-4 rounded-sm"
            />
          </div> */}
        </div>
        <div className="border-x border-gray-200 rounded-sm mt-3">
          <table className="w-full text-gray-700">
            <thead>
              <tr>
                <th>S/N</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Agent ID</th>
                <th>Phone Number</th>
                <th>Gender</th>
                <th>Email</th>
                <th>State</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {fieldAgents &&
                fieldAgents.map((agent) => (
                  <tr key={agent.FieldAgentID}>
                    <td>{agent.id}</td>
                    <td>
                      <div className="">
                        {/* <div
                          className="h-6 w-6 rounded-full bg-sky-500 bg-cover bg-no-repeat bg-center"
                          style={{
                            backgroundImage:
                              'url("https://source.unsplash.com/80x80?face")',
                          }}
                        >
                       
                        </div> */}
                        {agent.FirstName}
                      </div>
                    </td>
                    <td>{agent.LastName}</td>
                    <td>{agent.FieldAgentID}</td>
                    <td>{agent.Phone}</td>
                    <td>{agent.Gender}</td>
                    <td>{agent.Email}</td>
                    <td>{agent.StateAddress}</td>
                    <td>{getStatus(agent.Status)}</td>
                    <td>
                      <span className="flex items-center gap-2">
                        <Link
                          to={`/field-agents/details/${agent.FieldAgentID}`}
                        >
                          <GrView />
                        </Link>
                        {/* <FiEdit /> <MdDeleteOutline fontSize={20} /> */}
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
