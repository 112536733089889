import React from "react";

const NumberInputField = ({
  title,
  placeholder,
  type,
  nameID,
  value,
  onHandleChange,
  maxLength,
}) => {
  return (
    <div>
      <label className="block text-xs my-2">{title}</label>
      <input
        // type="number"
        // inputMode="numeric"
        id={nameID}
        name={nameID}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onHandleChange(nameID, e.target.value)}
        className=" block text-xs py-3 px-4 rounded-sm w-full shadow-xs border border-gray-300 "
        // maxLength={20}
        pattern="[0-9]*" // Only accept numbers
        title="Numbers Only" // Show tooltip for invalid input
        // style={{ WebkitAppearance: "none", appearance: "none" }}
      />
    </div>
  );
};

export default NumberInputField;
