import React, { useEffect, useState } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import { api, header } from "../../lib/queries/axiosInstance";
import { Spinner } from "../../components/Generic";
import DemoPlotMap from "../../components/Layouts/DemoPlotMap";
import InputDistribution from "./InputDistributionComponent";
import { SlCalender, SlHome } from "react-icons/sl";

const DemoPlotDetails = () => {
  const [plotDetails, setPlotDetails] = useState(null);
  const [DPIDetails, setDPIDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const listTitle = "text-xs font-medium my-2 tracking-wider block";
  const listItem = "text-xs font-light block";
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    const getPlotDetails = async () => {
      const res = await api.get(`demoplot_new/${id}`, {
        headers: header(),
      });
      const data = await res.data;
      if (data.code === 200) {
        setPlotDetails(data.data);
      }
      setLoading(false);
    };

    getPlotDetails();
  }, []);

  // if(plotDetails){
  //   console.log(plotDetails.Plot_UID)

  //   getDPInputDistributions()

  // }

  useEffect(() => {
    if (plotDetails) {
      const getDPInputDistributions = async () => {
        const res = await api.get(
          `dp_input_distribution/byDemoPlot/${plotDetails.Plot_UID}`,
          { headers: header() }
        );
        const data = await res.data;
        setDPIDetails(data.data.content);

        console.log(data.data.content);
        // console.log(data.data.content);
      };
      getDPInputDistributions();
    }
  }, [plotDetails]);

  // if (plotDetails.Plot_UID){

  //   const getDPInputDistributions = async () => {
  //     const res = await api.get(
  //       `dp_input_distribution/byDemoPlot/${plotDetails.Plot_UID}`,
  //       { headers: header() }
  //     );

  //     const data = await res.data;
  //     console.log(data);
  //   };
  //   getDPInputDistributions()

  // }

  // console.log(plotDetails);
  if (loading) {
    return <Spinner />;
  }

  console.log(plotDetails);

  return (
    <div style={{ width: "100%" }} className="p-4">
      <div className="mt-4 ">
        <div className="flex items-center mb-5">
          <Link
            className="flex items-center cursor-pointer mr-4"
            to={`/demo-plots`}
          >
            <AiOutlineLeft color={"black"} size={25} />
            <p className="text-primary">Back</p>
          </Link>
          <h1 className="text-lg font-semibold ">Demo Plot Details</h1>
        </div>
      </div>

      {plotDetails !== null && (
        <div className="bg-white rounded-sm shadow-lg w-full px-10 pt-10 pb-4 flex items-start justify-between">
          <div className="w-[25%]">
            <li className={listTitle}>
              Agent_Email_Address
              <span className={listItem}>
                {plotDetails?.Agent_Email_Address}
              </span>
            </li>

            <li className={listTitle}>
              Agent_First_Name
              <span className={listItem}>{plotDetails?.Agent_First_Name}</span>
            </li>

            <li className={listTitle}>
              Agent_ID
              <span className={listItem}>{plotDetails?.Agent_ID}</span>
            </li>

            <li className={listTitle}>
              Agent_Phone_Number
              <span className={listItem}>
                {plotDetails?.Agent_Phone_Number}
              </span>
            </li>

            <li className={listTitle}>
              Agent_Second_Name
              <span className={listItem}>{plotDetails?.Agent_Second_Name}</span>
            </li>
            <li className={listTitle}>
              First_Name
              <span className={listItem}>{plotDetails?.First_Name}</span>
            </li>
            <li className={listTitle}>
              Middle_Name
              <span className={listItem}>{plotDetails?.Middle_Name}</span>
            </li>
            <li className={listTitle}>
              Last_Name
              <span className={listItem}>{plotDetails?.Last_Name}</span>
            </li>
            <li className={listTitle}>
              Phone_Number
              <span className={listItem}>{plotDetails?.Phone_Number}</span>
            </li>
            {/* <li className={listTitle}>
              Farm_Photo
              <span className={listItem}>{plotDetails?.Farm_Photo}</span>
            </li> */}

            <li className={listTitle}>
              Farm_size
              <span className={listItem}>{plotDetails?.Farm_size}</span>
            </li>

            {/* <li className={listTitle}>
              Farming_mode_sub_items
              <span className={`${listItem} flex flex-wrap w-full`}>
                {plotDetails?.Farming_mode_sub_items}
              </span>
            </li> */}

            <li className={listTitle}>
              Field_agents_comments
              <span className={listItem}>
                {plotDetails?.Field_agents_comments}
              </span>
            </li>
          </div>
          <div className="w-[25%]">
            <li className={listTitle}>
              Nearest_Landmark
              <span className={listItem}>{plotDetails?.Nearest_Landmark}</span>
            </li>
            <li className={listTitle}>
              Community
              <span className={listItem}>{plotDetails?.Community}</span>
            </li>
            <li className={listTitle}>
              Ward
              <span className={listItem}>{plotDetails?.Ward}</span>
            </li>
            <li className={listTitle}>
              LGA
              <span className={listItem}>{plotDetails?.LGA}</span>
            </li>

            <li className={listTitle}>
              State
              <span className={listItem}>{plotDetails?.State}</span>
            </li>

            <li className={listTitle}>
              Plot_UID
              <span className={listItem}>{plotDetails?.Plot_UID}</span>
            </li>

            <li className={listTitle}>
              Source_of_Farm_Labour
              <span className={listItem}>
                {plotDetails?.Source_of_Farm_Labour}
              </span>
            </li>

            <li className={listTitle}>
              Source_of_Water
              <span className={listItem}>{plotDetails?.Source_of_Water}</span>
            </li>

            <li className={listTitle}>
              Type_of_Farming_engage_in
              <span className={listItem}>
                {plotDetails?.Type_of_Farming_engage_in}
              </span>
            </li>

            <li className={listTitle}>
              CreatedAT
              <span className={listItem}>{plotDetails?.CreatedAT}</span>
            </li>
          </div>
          <div className="w-[50%]">
            <DemoPlotMap
              GeoMapping={JSON.parse(plotDetails.GeoMapping)}
              YOUR_LATITUDE={parseFloat(plotDetails.Farm_Cordinat_lat)}
              YOUR_LONGITUDE={parseFloat(plotDetails.Farm_Cordinat_lng)}
            />
          </div>
        </div>
      )}

      <div className="my-8 bg-green">
        <div className="block p-6 bg-white border  rounded-lg  hover:bg-gray-10">
          <div className="mb-12">
            <h5 className="mb-2 text-xl font-medium tracking-tight text-gray-900 dark:text-white">
              Input Distribution
            </h5>
            <p className="font-normal text-gray-400 ">
              View all input distribution history for this demo plot{" "}
            </p>
          </div>
          <div className=" grid md:grid-cols-2 gap-8 ">
            {DPIDetails &&
              DPIDetails.map((input, index) => (
                <div key={index}>
                  {/* <div className="h-[400px] bg-green-400"><p>{input.FarmSize}</p></div> */}
                  {/* <InputDistribution /> */}

                  <div className="bg-green-50 p-6  block rounded-lg ">
                    <h5 className="mb-2 text-xl font-medium tracking-tight text-gray-900 dark:text-white">
                      {input.Plot_UID}
                    </h5>
                    <div className="flex">
                      <div className="flex p-4 gap-2 items-center">
                        <SlCalender />
                        <p>{input.DistributionDate}</p>
                      </div>

                      <div className="flex p-4 gap-2 items-center">
                        <SlHome />
                        <p>{input.DistributionVenue}</p>
                      </div>
                    </div>
                    <div>
                      {/* <Link to={`/field-agents/details/${agent.FieldAgentID}`}> */}
                      <Link to={`/demo-plot/inputdistribution`}>
                        <button
                          data-modal-target="popup-modal"
                          data-modal-toggle="popup-modal"
                          className="bg-primary w-[24] mt-4 text-white py-3 px-10 rounded-lg focus:outline-none focus:shadow-outline"
                          type="button"
                          //   onClick={() => setOpenModal(true)}
                        >
                          View Input Distribution
                        </button>
                      </Link>
                    </div>

                    {/* <div className="bg-green-50 p-6">Hello World</div> */}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemoPlotDetails;
