import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { HiOutlineSearch } from "react-icons/hi";
import { api, header } from "../../lib/queries/axiosInstance";
import PulseLoader from "react-spinners/PulseLoader";
import ReactPaginate from "react-paginate";
import Papa from "papaparse";
import { TiExportOutline } from "react-icons/ti";

const TableData = ({ children }) => (
  <td className="px-6 py-2 text-black">{children}</td>
);

const Outreach = () => {
  const [limit, setLimit] = useState(100);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(start + limit);
  const [pageCount, setpageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [outreach, setOutreach] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const getOutreach = async () => {
      setLoading(true);
      const res = await api.get("/outreach", {
        headers: header(),
      });

      const data = await res.data;
      setOutreach(data.data.content);
      setTotal(data.data.total)
      setLoading(false);
      console.log(data);
    };

    getOutreach();
  }, []);

  return (
    <div>
      {" "}
      <div>
        <div className="text-xl pt-1">Outreach</div>
        <div className="bg-white mt-4 p-6">
          <div className="flex items-start py-2  justify-between">
            <div></div>
          </div>
          <div
            className={`relative scroll-div overflow-scroll mt-4 shadow-md  w-100% sm:rounded-lg`}
          >
            {loading ? (
              <div className=" m-12  justify-items-center justify-center">
                {" "}
                <PulseLoader
                  className=" m-12 justify-center"
                  color={"#46A576"}
                  loading={loading}
                  // cssOverride={override}
                  size={14}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <table
                className={` w-full  text-sm text-left text-gray-500 dark:text-gray-400`}
              >
                <thead className="text-xs sticky top-0 right-0 left-0 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      OID
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      AgentID
                    </th>

                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      First_Name
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Middle_Name
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Last_Name
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Phone_Number
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      State
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      LGA
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      ElectoralWard
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Gender
                    </th>

                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Address
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      DateOfBirth
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      SexAgeCategory
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      DisabilityCategory
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    DisplacemmentCategory
                    </th>
                  
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      ID_Type
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      CreatedAT
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      UpdatedAT
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Action Buttion
                    </th>

                   
                  </tr>
                </thead>
                <tbody className=" pt-8">
                  {outreach.length &&
                    outreach.map((singleOutreach, index) => (
                      <tr
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 pt-8  hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer"
                        key={index}
                        onClick={() => navigate(`/outreach-details/${singleOutreach.id}`)}
                      >
                        <TableData>{singleOutreach.OID}</TableData>
                        <TableData>{singleOutreach.AgentID}</TableData>
                        <TableData>{singleOutreach.FirstName}</TableData>
                        <TableData>{singleOutreach.MiddleName}</TableData>
                        <TableData>{singleOutreach.LastName}</TableData>
                        <TableData>{singleOutreach.PhoneNumber}</TableData>
                        <TableData>{singleOutreach.State}</TableData>
                        <TableData>{singleOutreach.Lga}</TableData>
                        <TableData>{singleOutreach.ElectoralWard}</TableData>
                        <TableData>{singleOutreach.Gender}</TableData>
                        <TableData>{singleOutreach.Address}</TableData>
                        <TableData>{singleOutreach.DateOfBirth}</TableData>
                        <TableData>{singleOutreach.SexAgeCategory}</TableData>
                        <TableData>
                          {singleOutreach.DisabilityCategory}
                        </TableData>
                        <TableData>
                          {singleOutreach.DisplacemmentCategory}
                        </TableData>
                        <TableData>{singleOutreach.ID_Type}</TableData>
                        <TableData>{singleOutreach.CreatedAT}</TableData>
                        <TableData>{singleOutreach.UpdatedAT}</TableData>
                        <TableData>  <span className="flex items-center justify-between">
                        <Link
                          to={`/outreach-details/${singleOutreach.OID}`}
                        >
                          <GrView />
                        </Link>
                        {/* <FiEdit /> <MdDeleteOutline fontSize={20} /> */}
                      </span>
                      
                      </TableData>


                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
          <nav
            className="flex items-center justify-between pt-4"
            aria-label="Table navigation"
          >
            <div className="flex items-center">
              <div className="mt-4 ">
                <form>
                  {" "}
                  <label
                    // for="countries"
                    className="block mb-2 text-sm font-regular text-gray-900 dark:text-white"
                  >
                    No of Data Per Page
                  </label>
                  <select
                    id="countries"
                    className=" border border-gray-200 text-gray-500 text-sm rounded-md font-light focus:ring-green-500 focus:border-green-400 block w-full p-2.5 "
                    onChange={(e) => setLimit(e.target.value)}
                  >
                    <option selected>{limit}</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                  </select>
                </form>
              </div>
            </div>

            <div className="flex items-center gap-4">
              <div className=" gap-4 ">
                <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                  Showing{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {start}-{total} {"  "}
                  </span>
                  of{"  "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {total}
                  </span>
                </span>
              </div>
              {/* <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                // onPageChange={handlePageClick}
                containerClassName={"inline-flex items-center -space-x-px"}
                pageClassName={
                  "px-3 py-2 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                }
                pageLinkClassName={""}
                previousClassName={
                  "block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                }
                previousLinkClassName={""}
                nextClassName={
                  "block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                }
                nextLinkClassName={""}
                breakClassName={
                  "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                }
                breakLinkClassName={
                  "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                }
                activeClassName={
                  "z-10 px-3 py-2 leading-tight text-white border border-blue-400 bg-primary hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                }
              /> */}
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Outreach;
