import React, { useEffect, useState } from "react";
import { useEventContext } from "../../Context/EventContext";
import { states } from "../../constants/states";
import { wardsList } from "../../constants/wards";
import { newStates } from "../../lib/constants/states";
import assignedBtn from "../../assets/images/assignedBtn.png";
import { useAuthContext } from "../../Context/AuthContext";
import { CustomMessage } from "../Generic";

const EventLocDateTime = () => {
  const [filteredLga, setFilteredLga] = useState([""]);
  const [filteredWard, setFilteredWard] = useState([]);

  const {
    eventForm,
    setEventForm,
    onChange,
    onActiveAssignUser,
    onAssignUser,
  } = useEventContext();
  const { setIsMessage, isMessage, setMessageContent, setMessageType } =
    useAuthContext();
  const { Author, EventDate, EventTime, EventCommunity, State, LGA, Ward } =
    eventForm;
  const inputClass =
    "text-xs bg-white drop-shadow-lg border-none rounded w-full py-3 px-3 text-gray-700 leading-tight";
  const labelClass = "block text-gray-700 mb-2 text-xs";

  const handleChangeState = (itemValue) => {
    const filteredCity = states.find((item) => item.state === itemValue);
    const newFilteredCity = filteredCity === undefined ? "" : filteredCity.lgas;
    setEventForm((prev) => ({
      ...prev,
      State: itemValue,
    }));
    setFilteredLga(newFilteredCity);
    return newFilteredCity;
  };
  const handleChangeLGA = (itemValue) => {
    const tempFilter = wardsList.find(
      (item) => item.lga === itemValue.toUpperCase()
    );
    const newFiltered = tempFilter === undefined ? [] : tempFilter.wards;
    setFilteredWard(newFiltered);
    setEventForm((prev) => ({
      ...prev,
      LGA: itemValue,
      EventCommunity: itemValue,
    }));
  };
  function onNext() {
    if (
      EventDate === "" ||
      EventTime === "" ||
      State === "" ||
      LGA === "" ||
      Ward === ""
    ) {
      setMessageContent("All Fields Fill");
      setIsMessage(true);
      setMessageType("error");
    } else {
      onActiveAssignUser();
    }
  }
  if (isMessage) {
    return <CustomMessage />;
  }
  return (
    <div>
      {/* State */}
      <div className="p-2">
        <div>
          <label className={labelClass}>State</label>

          <select
            onChange={(e) => handleChangeState(e.target.value)}
            value={State}
            className={inputClass}
          >
            <option selected value="">
              --
            </option>
            {newStates.map((opts) => (
              <option value={opts}>{opts}</option>
            ))}
          </select>
        </div>
      </div>
      {/* LGA and Ward */}
      <div className="flex w-full justify-between items-start">
        {/* LGA */}
        <div className="p-2 w-[48%]">
          <label className={labelClass}>LGA</label>

          <select
            onChange={(e) => handleChangeLGA(e.target.value)}
            value={LGA}
            className={inputClass}
          >
            <option selected value="">
              LGA
            </option>
            {filteredLga.length &&
              filteredLga.map((opts) => <option value={opts}>{opts}</option>)}
          </select>
        </div>
        {/* Ward */}
        <div className="p-2 w-[48%]">
          <label className={labelClass}>Ward</label>

          <select
            onChange={onChange}
            value={Ward}
            id="Ward"
            className={inputClass}
          >
            <option selected value="">
              -- Choose Ward --
            </option>
            {filteredWard.length &&
              filteredWard.map((opts) => <option value={opts}>{opts}</option>)}
          </select>
        </div>
      </div>
      {/* Date and Time */}
      <div className="flex w-full justify-between items-start">
        {/* Date */}
        <div className="p-2 w-[48%]">
          <label htmlFor="EventDate" className={labelClass}>
            Choose date:
          </label>
          <input
            className={inputClass}
            type="date"
            id="EventDate"
            name="EventDate"
            value={EventDate}
            onChange={onChange}
          />
        </div>
        {/* Time */}
        <div className="p-2 w-[48%]">
          <label htmlFor="EventTime" className={labelClass}>
            Select Time:
          </label>
          <input
            className={inputClass}
            type="time"
            id="EventTime"
            name="EventTime"
            value={EventTime}
            onChange={onChange}
          />
        </div>
      </div>
      {/* Assigned Btn */}
      <div className="">
        <button onClick={onNext}>
          <img src={assignedBtn} className="w-[150px] my-3 cursor-pointer" />
        </button>
      </div>
    </div>
  );
};

export default EventLocDateTime;
