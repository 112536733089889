function getDateFromCreated(createdAt) {
  const currentDate = new Date();
  const createdDate = new Date(createdAt);

  //   if (createdDate.toDateString() === currentDate.toDateString()) {
  //     return "Today";
  //   } else if (
  //     createdDate.toDateString() ===
  //     new Date(currentDate - 24 * 60 * 60 * 1000).toDateString()
  //   ) {
  //     return "Yesterday";
  //   } else {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateArray = createdAt.split("T")[0].split("-");
  const newdateDateFormat = `${monthNames[dateArray[1] - 1]} ${dateArray[2]}, ${
    dateArray[0]
  }`;
  return newdateDateFormat;
  //   }
}

export { getDateFromCreated };
