import { createContext, useContext, useEffect, useState } from "react";
const EventContext = createContext({});

const EventContextProvider = ({ children }) => {
  const [events, setEvents] = useState([]);
  const [onEventType, setOnEventType] = useState(true);
  const [onEventCat, setOnEventCat] = useState(false);
  const [onEventLocDateTime, setOnEventLocDateTime] = useState(false);
  const [onAssignUser, setOnAssignUser] = useState(false);
  const [eventForm, setEventForm] = useState({
    EventType: "",
    AssignedStaff: "",
    EventCategory: "",
    EventSubCategory: "",
    Author: "",
    EventDate: "",
    EventTime: "",
    EventCommunity: "",
    State: "",
    LGA: "",
    Ward: "",
  });
  function onResetForm() {
    setEventForm({
      ...eventForm,
      EventType: "",
      AssignedStaff: "",
      EventCategory: "",
      EventSubCategory: "",
      Author: "",
      EventDate: "",
      EventTime: "",
      EventCommunity: "",
      State: "",
      LGA: "",
      Ward: "",
    });
  }

  function onActiveEventType() {
    setOnEventType(true);
    setOnEventCat(false);
    setOnEventLocDateTime(false);
    setOnAssignUser(false);
  }
  function onActiveEventCat() {
    setOnEventType(false);
    setOnEventCat(true);
    setOnEventLocDateTime(false);
    setOnAssignUser(false);
  }
  function onActiveEventLocDateTime() {
    setOnEventType(false);
    setOnEventCat(false);
    setOnEventLocDateTime(true);
    setOnAssignUser(false);
  }
  function onActiveAssignUser() {
    setOnEventType(false);
    setOnEventCat(false);
    setOnEventLocDateTime(false);
    setOnAssignUser(true);
  }
  const onChange = (e) => {
    const { id, value } = e.target;
    setEventForm((prev) => ({
      ...prev,
      [id]: value,
    }));
  };
  const getAllEvents = async () => {};
  useEffect(() => {}, []);

  return (
    <EventContext.Provider
      value={{
        events,
        setEvents,
        getAllEvents,
        eventForm,
        setEventForm,
        onChange,
        onResetForm,
        onEventType,
        onEventCat,
        onEventLocDateTime,
        onAssignUser,
        onActiveEventType,
        onActiveEventCat,
        onActiveEventLocDateTime,
        onActiveAssignUser,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export default EventContextProvider;

export const useEventContext = () => useContext(EventContext);
