import React from "react";
import { Link } from "react-router-dom";
// import { getStatus } from "../../../lib/helpers";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { HiOutlineSearch } from "react-icons/hi";
import { format } from "date-fns";
import useEngagement from "../../../hooks/useEngagement";

export default function Engagements() {
  const { engagements } = useEngagement();
  console.log(engagements);

  return (
    <div>
      <div className="text-2xl  pt-1 font-medium">All Engagement</div>
      <div className="mt-6 bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1">
        <div className="border-x border-gray-200 rounded-sm mt-3">
          <table className="w-full text-gray-700">
            <thead>
              <tr>
                <th>S/N</th>
                <th>Engagement Type</th>
                <th>State</th>
                <th>LGA</th>
                <th>City</th>
                <th>Ward</th>
                <th>Date</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {engagements &&
                engagements.map((engagement) => (
                  <tr key={engagement.id}>
                    <td>
                      <Link to="#">#{engagement.EngagementID}</Link>
                    </td>
                    <td>
                      <Link to="#">{engagement.EngagementType}</Link>
                    </td>
                    <td>
                      <Link to="#">{engagement.State}</Link>
                    </td>
                    <td>{engagement.LGA}</td>
                    <td>{engagement.City}</td>
                    <td>{engagement.Ward}</td>
                    <td>{engagement.Date}</td>
                    <td>{engagement.Status}</td>
                    {/* <td>
                    {format(new Date(engagement.creation_date), "dd MMM yyyy")}
                  </td>
                  <td>{getStatus(agent.status)}</td> */}
                    <td>
                      <span className="flex items-center gap-2">
                        <Link to={`/engagement/details/${engagement.EngagementID}`}>
                          <GrView />
                        </Link>
                        
                        <FiEdit /> <MdDeleteOutline fontSize={20} />
                      </span>
                    </td>
                    
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
