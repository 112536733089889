import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { GrView } from "react-icons/gr";
import { FiEdit, FiSettings } from "react-icons/fi";
import { TbFilterOff } from "react-icons/tb";
import { HiOutlineSearch } from "react-icons/hi";
import { TiExportOutline } from "react-icons/ti";
import PulseLoader from "react-spinners/PulseLoader";
// import { useEnumerationProvider } from "../../../Context/EnumerationContext";
import ReactPaginate from "react-paginate";
import { useAuthContext } from "../../../src/Context/AuthContext";
import { getStatus } from "../../lib/helpers";
import { BsTrash } from "react-icons/bs";
import { getColumnData } from "../../lib/services/advancedSearchSelect";
import { states, tempState } from "../../lib/constants/states";
import Papa from "papaparse";
import { api, header } from "../../lib/queries/axiosInstance";
import { trimObj } from "../../lib/utils/utilityHandler";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { FaRegTimesCircle } from "react-icons/fa";
import useHouseholdInputsQuery from "../../hooks/useHouseholdInputDistribution";

const TableData = ({ children }) => <td className="px-6 py-2">{children}</td>;

export default function InputDistribution() {
  const navigate = useNavigate();
  const { authUser, authDashboard } = useAuthContext();
  console.log(authDashboard);
  const [enumerationStatus, setEnumerationStatus] = useState(null);
  const [NINStatus, setNINStatus] = useState(null);
  const [householdGender, setHouseholdGender] = useState(null);
  const [state, setState] = useState("");
  const [filteredLga, setFilteredLga] = useState([""]);
  const [selectedLga, setSelectedLga] = useState();
  const [selectedState, setselectedState] = useState();
  const [isReadyToPrint, setIsReadyToPrint] = useState(false);
  const [objParams, setObjParams] = useState({});
  const [onShowSearch, setOnShowSearch] = useState(false);

  // const [searchResult, setSearchResult] = useState("");

  const [selectedDate, setSelectedDate] = useState("");
  const [updatedDate, setUpdatedDate] = useState("");

  const formatDate = (dateStr) => {
    if (dateStr.startsWith("0")) {
      return dateStr.split("")[1];
    }
    return dateStr;
  };
  function handleDateChange(event) {
    // setLimit(100);
    // setStart(0);
    setSelectedDate(event.target.value);
    // console.log(event.type);

    // if (event.target.value) {
    let dateArray = event.target.value?.split("-");
    let newdateDateFormat = `*${dateArray[0]}-*${dateArray[1]}-*${dateArray[3]}*`;
    console.log("Daet", newdateDateFormat);
    //   setDateFilter(`*${newdateDateFormat}`);
    // } else if (event.type === "change") {
    //   setDateFilter("");
    // }
  }

  const {
    // setDateSearch,
    // dateSearch,
    openModal,
    setOpenModal,
    handleOnClose,
    inputs,
    setLimit,
    setEnumerations,
    limit,
    loading,
    setLoading,
    start,
    setStart,
    end,
    setEnd,
    pageCount,
    setpageCount,
    total,
    setTotal,
    searchResult,
    setSearchResult,
    statusOptions,
    setStatusOptions,
    searchEnumerations,
    fetchEnumerations,
    advancedSearchOptions,
    setAdvancedSearchOptions,
    dateFilter,
    setDateFilter,
  } = useHouseholdInputsQuery({
    dateSelected: updatedDate,
    state: selectedState,
    lga: selectedLga,
  });

  const handleSearch = () => {
    fetchEnumerations();
  };

  const handlePageClick = async (data) => {
    let startCounter = data.selected + 1;
    let startr = startCounter * limit - limit;
    let endr = startr + limit;
    setEnd(endr);
    setStart(startr);
  };

  const handleChangeState = (itemValue) => {
    const filteredCity = states.find((item) => item.state === itemValue);
    const newFilteredCity = filteredCity === undefined ? "" : filteredCity.lgas;

    setFilteredLga(newFilteredCity);
    return newFilteredCity;
  };

  useEffect(() => {
    handleChangeState(selectedState);
  }, [selectedState]);

  const handleEnumerationStatus = (e) => {
    setLimit(100);
    setStart(0);
    setEnumerationStatus(e.target.value);
  };

  const handleGender = (e) => {
    setLimit(100);
    setStart(0);
    setHouseholdGender(e.target.value);
  };

  const handleNINStatus = (e) => {
    setLimit(100);
    setStart(0);
    setNINStatus(e.target.value);
  };

  const handleState = (e) => {
    setLimit(100);
    setStart(0);
    setselectedState(e.target.value);
  };

  function getDateFromCreated(createdAt) {
    const currentDate = new Date();
    const createdDate = new Date(createdAt);

    if (createdDate.toDateString() === currentDate.toDateString()) {
      return "Today";
    } else if (
      createdDate.toDateString() ===
      new Date(currentDate - 24 * 60 * 60 * 1000).toDateString()
    ) {
      return "Yesterday";
    } else {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];
      const dateArray = createdAt.split("T")[0].split("-");
      const newdateDateFormat = `${monthNames[dateArray[1] - 1]} ${
        dateArray[2]
      }, ${dateArray[0]}`;
      return newdateDateFormat;
    }
  }
  const buildParams = () => {
    const params = {};
    for (let option of advancedSearchOptions) {
      params[option.column] = option.value;
    }

    setObjParams(params);
    return params;
  };

  return (
    <div>
      <div className="flex justify-between items-start text-xl pt-1">
        Household Input Distributions{authUser && authUser.TargetRole}{" "}
        {`(${total})`}
      </div>

      <div className="bg-white mt-4 p-6">
        <div className="flex items-start py-2 justify-between">
          <div className={" flex gap-3 items-center"}>
            <div>
              <label
                htmlFor="date"
                className="block mb-2 text-xs font-regular text-gray-900 dark:text-white "
              >
                Date:
              </label>{" "}
              <input
                className="border border-gray-200 text-xs py-3 rounded-md text-gray-600  "
                type="date"
                id="date"
                name="date"
                value={selectedDate}
                onChange={handleDateChange}
              />{" "}
            </div>

            {authUser && authUser.Role === "Analyst" && (
              <div>
                <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                  State
                </label>
                <select
                  onChange={handleState}
                  value={selectedState}
                  className="border border-gray-200 text-[10px] font-medium px-1 py-2 text-gray-500 rounded-md  focus:ring-green-500 focus:border-green-500 block w-full "
                >
                  <option selected value="">
                    --
                  </option>
                  {tempState.map((opts) => (
                    <option value={opts}>{opts}</option>
                  ))}
                </select>
              </div>
            )}

            {authUser && authUser.Role === "Analyst" && (
              <div>
                <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                  LGA
                </label>
                <select
                  onChange={(e) => setSelectedLga(e.target.value)}
                  value={selectedLga}
                  className=" border border-gray-200 text-[10px] font-medium px-1 py-2 text-gray-500 rounded-md  focus:ring-green-500 focus:border-green-500 block w-full "
                >
                  <option selected value="">
                    LGA
                  </option>
                  {filteredLga.length &&
                    filteredLga.map((opts) => (
                      <option value={opts}>{opts}</option>
                    ))}
                </select>
              </div>
            )}
          </div>
        </div>

        <div
          className={`max-h-[70vh] relative scroll-div overflow-scroll mt-4 shadow-md  w-100% sm:rounded-lg`}
        >
          {loading ? (
            <div className=" m-12  justify-items-center justify-center">
              {" "}
              <PulseLoader
                className=" m-12 justify-center"
                color={"#46A576"}
                loading={loading}
                // cssOverride={override}
                size={14}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <div>
              <table
                className={` w-full  text-sm text-left text-gray-500 dark:text-gray-400`}
              >
                <thead className="text-xs sticky top-0 right-0 left-0 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      UID
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Agent ID
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      First Name
                    </th>
                    {/* <th scope="col" className="px-6 whitespace-nowrap  py-3">
                      Middle Name
                    </th> */}
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Last Name
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Gender
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      State
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      LGA
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody className=" pt-8">
                  {inputs?.length &&
                    inputs?.map((farmer) => (
                      <tr
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 pt-8  hover:bg-gray-50 dark:hover:bg-gray-600"
                        // key={farmer.UID}
                        // onClick={() =>
                        //   navigate(`/householddetail/${farmer.UID}`)
                        // }
                      >
                        <TableData>{farmer.UID}</TableData>
                        <TableData>{farmer.AgentID}</TableData>
                        <TableData>{farmer.First_Name}</TableData>
                        {/* <TableData>{farmer.Middle_Name}</TableData> */}
                        <TableData>{farmer.Last_Name}</TableData>
                        <TableData>{farmer.Household_Gender}</TableData>
                        <TableData>{farmer.State}</TableData>
                        <TableData>{farmer.LGA}</TableData>
                        <TableData>
                          {getDateFromCreated(farmer?.CreatedAT)}
                        </TableData>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <nav
          className="flex items-center justify-between pt-4"
          aria-label="Table navigation"
        >
          <div className="flex items-center">
            <div className="mt-4 ">
              <form>
                {" "}
                <label
                  // for="countries"
                  className="block mb-2 text-sm font-regular text-gray-900 dark:text-white"
                >
                  No of Data Per Page
                </label>
                <select
                  id="countries"
                  className=" border border-gray-200 text-gray-500 text-sm rounded-md font-light focus:ring-green-500 focus:border-green-400 block w-full p-2.5 "
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <option selected>{limit}</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                </select>
              </form>
            </div>
          </div>

          <div className="flex items-center gap-4">
            <div className=" gap-4 ">
              {/* <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                Showing{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {start}-{end}
                </span>{" "}
                of{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {total}
                </span>
              </span> */}
            </div>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"inline-flex items-center -space-x-px"}
              pageClassName={
                "px-3 py-2 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              pageLinkClassName={""}
              previousClassName={
                "block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              previousLinkClassName={""}
              nextClassName={
                "block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              nextLinkClassName={""}
              breakClassName={
                "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              breakLinkClassName={
                "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              activeClassName={
                "z-10 px-3 py-2 leading-tight text-white border border-blue-400 bg-primary hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
              }
            />
          </div>
        </nav>
      </div>
    </div>
  );
}

const Modal = ({
  onClose,
  setAdvancedSearchOptions,
  advancedSearchOptions,
  fetchEnumerations,
}) => {
  // const [val, setVal] = useState([""]);

  const [searchColumnOptions, setSearchColumnOptions] = useState([]);
  const handleNewInput = () => {
    setAdvancedSearchOptions([
      ...advancedSearchOptions,
      {
        column: "",
        value: "",
      },
    ]);
  };

  // useEffect(() => {
  //   if(!advancedSearchOptions.length){
  //   handleNewInput();
  // }
  // }, []);

  // const handleChange = (onChangeValue, i) => {
  //   const inputData = [...val];
  //   inputData[i] = onChangeValue.target.value;
  //   setVal(inputData);
  // };

  const handleDelete = (i) => {
    const deleteVal = [...advancedSearchOptions];
    deleteVal.splice(i, 1);
    setAdvancedSearchOptions(deleteVal);
  };

  const handleSelectFormChange = (e, index) => {
    const newValue = [...advancedSearchOptions];
    advancedSearchOptions[index].column = e.target.value;
    setAdvancedSearchOptions(newValue);
  };

  const handleInputFormChange = (e, index) => {
    const newValue = [...advancedSearchOptions];
    advancedSearchOptions[index].value = e.target.value;
    setAdvancedSearchOptions(newValue);
  };

  useEffect(() => {
    setSearchColumnOptions(getColumnData());

    // console.log(searchOptions)
  }, []);

  const advSearch = () => {
    onClose();
    fetchEnumerations();
  };

  console.log(advancedSearchOptions);

  return (
    <div
      id="popup-modal"
      tabIndex="-1"
      className="fixed rounded-lg  z-50 bg-gray-600 bg-opacity-20 backdrop-blur-xs right-5 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full w-full"
    >
      <div className=" w-full flex flex-col flex-1  min-h-screen bg-white max-w-sm absolute top-0 right-0 ">
        <div className="relative flex flex-col flex-1 bg-white shadow dark:bg-gray-700">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-900 bg-transparent hover:bg-green-900 hover:text-gray-100  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-hide="popup-modal"
            onClick={onClose}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>

          <div className="p-6 ">
            <div className="flex justify-between items-center text-xl pt-1">
              Advanced Search
            </div>
            <p className="font-light text-sm">Select column to search from</p>
          </div>

          <div className="p-6">
            {advancedSearchOptions.map((data, i) => {
              return (
                <div className="mt-6 border p-2 rounded-md">
                  <div className=" mt-2">
                    <div className=" ">
                      <button
                        className="top-0 text-red text-sm right-0 p-2"
                        onClick={() => handleDelete(i)}
                      >
                        {" "}
                        <span className="flex items-center">
                          <BsTrash fontSize={14} color="red">
                            {" "}
                          </BsTrash>{" "}
                          Delete
                        </span>{" "}
                      </button>
                    </div>
                    {/* <label className="block text-gray-700 mb-2 text-sm">
                Select Engagement Type
              </label> */}
                    <select
                      value={data.column}
                      onChange={(e) => {
                        handleSelectFormChange(e, i);
                      }}
                      className="text-sm bg-gray-50 appearance-none border-gray-300 rounded w-full py-3 px-3  text-gray-500  leading-tight focus:outline-none focus:shadow-outline"
                    >
                      {searchColumnOptions.map((opts, i) => (
                        <option>{opts}</option>
                      ))}

                      {/* <option disabled selected>
                        --Select Search Field--
                      </option>
                      <option value="Lagos">Farmers_One</option>
                      <option value="Lagos">Farmers_Two</option>
                      <option value="Lagos">Farmers_Three</option> */}
                    </select>
                  </div>

                  <div className="mt-2">
                    <input
                      value={data.value}
                      onChange={(e) => handleInputFormChange(e, i)}
                      className="text-sm bg-gray-50 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                </div>
              );
            })}

            <button
              onClick={() => handleNewInput()}
              data-modal-target="popup-modal"
              data-modal-toggle="popup-modal"
              className="bg-green-50 w-full text-base text-primary border py-3 px-10 border-primary rounded-md border-dashed mt-4 focus:outline-none focus:shadow-outline"
              type="button"
            >
              Add Search Column
            </button>
          </div>
        </div>
        <div className="flex bg-white flex-col m-6">
          <button
            data-modal-target="popup-modal"
            onClick={advSearch}
            data-modal-toggle="popup-modal"
            className="bg-primary  w-full text-base text-white py-3 px-10 rounded-lg focus:outline-none focus:shadow-outline"
            type="button"
          >
            Submit Search
          </button>
        </div>
      </div>
    </div>
  );
};
