import React from "react";

export const PrimaryBtn = ({ text, OnBtnClick, outlined }) => {
  return (
    <button
      className={`block text-xs ${
        outlined ? "text-primary bg-white" : "bg-primary text-white"
      } w-full my-5 mx-4 py-3 px-5 rounded-md text-center border border-primary`}
      onClick={OnBtnClick}
    >
      {text}
    </button>
  );
};
