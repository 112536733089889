import React from 'react'
import { Link, useParams } from "react-router-dom";
import { api, header } from "../../lib/queries/axiosInstance";
import { Spinner } from "../../components/Generic";
import { useEffect, useState } from "react";


const  OutreachDetails = () => {

  const { id } = useParams();
//   const { engagementDetails } = useEngagementDetails(id);
const [loading, setLoading] = useState(false);
  const [outreachDetails, setOutreachDetails] = useState(null);


  useEffect(() => {
    const getOutreach = async () => {
      setLoading(true);
      const res = await api.get(`outreach/${id}`, {
        headers: header(),
      });

      const data = await res.data.data;
      setOutreachDetails(data);
      setLoading(false);
      console.log(data);
    };

    getOutreach();
  }, []);


  return (
    <div className="mt-4">
    <div className="text-xl pt-1">Outreach Details</div>
    {outreachDetails ? (
      <div className="grid lg:grid-cols-2 lg:grid-rows-1 ">
        <div className="bg-grey-100 rounded-lg py-6 ">
          <div className="bg-white rounded-lg p-6">
          <div
                    className=""
                  >
                    <img  className="h-28 w-28 rounded-full bg-cover bg-no-repeat bg-center" src={outreachDetails.Photo} alt="Field Agent Avatar"></img>

                  </div>
     {/* <img src={outreachDetails.Photo}  alt=''></img> */}
            <h2 className="text-2xl mt-2 font-regular">
              {outreachDetails.FirstName} {outreachDetails.MiddleName} {outreachDetails.LastName}
            </h2>
            <div className="gap-y-4 column-gap">
              <div className="mt-6">
                <span className="flex gap-2 mt-2 items-center">
                  <p> Outreach ID:</p>{" "}
                  <p className="font-extralight">{outreachDetails.OID}</p>
                </span>
              </div>
              <div>
                <span className="flex gap-2 mt-2 items-center">
                  <p>Agent ID:</p>{" "}
                  <p className="font-extralight">{outreachDetails.AgentID}</p>
                </span>
              </div>

              <div>
                <span className="flex gap-2 mt-2 items-center">
                  <p>PhoneNumber:</p>{" "}
                  <p className="font-extralight">
                    {outreachDetails.PhoneNumber}
                  </p>
                </span>
              </div>

              <div>
                <span className="flex gap-2 mt-2 items-center">
                  <p>Gender:</p>{" "}
                  <p className="font-extralight">{outreachDetails.Gender}</p>
                </span>
              </div>
              <div>
                <div>
                  <span className="flex gap-2 mt-2 items-center">
                    <p>State: </p>{" "}
                    <p className="font-extralight">
                      {outreachDetails.State}
                    </p>
                  </span>
                </div>

                <span className="flex gap-2 mt-2 items-center">
                  <p>Lga:</p>
                  <p className="font-extralight">
                    {outreachDetails.Lga}
                  </p>
                </span>
              </div>

              <div>
                <span className="flex gap-2 mt-2 items-center">
                  <p>ElectoralWard: </p>{" "}
                  <p className="font-extralight">
                    {outreachDetails.ElectoralWard}
                  </p>
                </span>
              </div>

              <div>
                <span className="flex gap-2 mt-2 items-center">
                  <p>Address:</p>{" "}
                  <p className="font-extralight">
                    {outreachDetails.Address}
                  </p>
                </span>
              </div>

              <div>
                <span className="flex gap-2 mt-2 items-center">
                  <p>DateOfBirth:</p>{" "}
                  <p className="font-extralight">
                    {outreachDetails.DateOfBirth}
                  </p>
                </span>
              </div>

              <div>
                <span className="flex gap-2 mt-2 items-center">
                  <p>SexAgeCategory:</p>{" "}
                  <p className="font-extralight">
                    {outreachDetails.SexAgeCategory}
                  </p>
                </span>
              </div>
            </div>

            <div>
              <span className="flex gap-2 mt-2 items-center">
                <p>DisabilityCategory:</p>{" "}
                <p className="font-extralight">
                  {outreachDetails.DisabilityCategory}
                </p>
              </span>
            </div>

            <div>
              <span className="flex gap-2 mt-2 items-center">
                <p>DisplacemmentCategory:</p>{" "}
                <p className="font-extralight">
                  {outreachDetails.DisplacemmentCategory}
                </p>
              </span>
            </div>

            <div>
              <span className="flex gap-2 mt-2 items-center">
                <p>CreatedAT:</p>{" "}
                <p className="font-extralight">
                  {outreachDetails.CreatedAT}
                </p>
              </span>
            </div>

            <div>
              <span className="flex gap-2 mt-2 items-center">
                <p>UpdatedAT:</p>{" "}
                <p className="font-extralight">
                  {outreachDetails.UpdatedAT}
                </p>
              </span>
            </div>

            <div>
              <span className="flex gap-2 mt-2 items-center">
                <p>ID_Type:</p>{" "}
                <p className="font-extralight">
                  {outreachDetails.ID_Type}
                </p>
              </span>
            </div>


          </div>
        </div>
      </div>
   ) : (
      <p>loading</p>
    )} 
  </div>
  )
}

export default OutreachDetails