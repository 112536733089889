import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import { api, header } from "../../lib/queries/axiosInstance";
import { RiDeleteBinFill } from "react-icons/ri";
import { BiEdit } from "react-icons/bi";
import CustomLink from "../../components/Generic/CustomLink";

const TableData = ({ children }) => (
  <td className="px-6 py-2 text-black">{children}</td>
);

export default function EventDetails() {
  const [event, setEvent] = useState(null);
  const [attendance, setAttendance] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    setLoading(true);
    const getEvent = async () => {
      const res = await api.get(`eventtable/${id}`, {
        headers: header(),
      });
      const data = await res.data;
      if (data.code === 200) {
        setEvent(data.data);
      }
      setLoading(false);
    };
    const getEventAtendance = async () => {
      const res = await api.get(`attendancetable/events/${id}`, {
        headers: header(),
      });
      const data = await res.data;
      if (data.data?.content?.length > 0) {
        setAttendance(data?.data?.content);
      }
      setLoading(false);
    };
    getEvent();
    getEventAtendance();
  }, []);
  return (
    <div style={{ width: "100%" }} className="p-4">
      <div className="mt-4">
        <div className="flex justify-between items-center">
          {/* Right Panel */}
          <div className="flex items-center mb-5">
            <Link
              className="flex items-center cursor-pointer mr-4"
              to={`/all-events`}
            >
              <AiOutlineLeft color={"black"} size={25} />
              <p className="text-primary">Back</p>
            </Link>
            <h1 className="text-lg font-semibold ">Training Event</h1>
          </div>
          <div className="flex justify-center items-center">
            {/* Delete */}
            {attendance.length > 0 && (
              <Link
                className={`w-[100px] h-[42px] flex items-center justify-center  text-red-600  border-red-600 py-2 px-4 rounded-md border text-center text-sm`}
                to={"#"}
              >
                <RiDeleteBinFill size={25} className="mr-2" />
                {"Delete"}
              </Link>
            )}
            {/* Edit */}
            <CustomLink
              text={"Edit"}
              Icon={BiEdit}
              path={`/edit-event/${id}`}
              width={"w-[100px]"}
            />
          </div>
        </div>
        {event && (
          <div className="w-full">
            {/* First Row */}
            <div className="w-full flex justify-between items-start">
              {/* Col1  */}
              <div className="w-[65%] bg-white rounded-sm shadow-lg p-8 ">
                <div>
                  <p className="text-gray-500 text-xs">Title</p>
                  <h3 className="text-sm font-medium">{event.EventCategory}</h3>
                </div>
                <div className="my-4">
                  <p className="text-gray-500 text-xs">Sub Title</p>
                  <h3 className="text-[12px] font-normal">
                    {event.EventSubCategory ? event.EventSubCategory : "-"}
                  </h3>
                </div>
                {/* Field Agent and Status */}
                <div className="flex items-start">
                  <div className="mr-6">
                    <p className="text-gray-500 text-xs">Field Agent Name</p>
                    <h3 className="text-[12px] font-normal">
                      {"Field Agent Name"}
                    </h3>
                  </div>
                  <div>
                    <p className="text-gray-500 text-xs">Status</p>
                    <p
                      className={` text-[10px] py-1 px-3 rounded-md text-center${
                        event?.Status === "Completed"
                          ? " bg-green-200 text-primary"
                          : " bg-red-200 text-red-600"
                      }`}
                    >
                      {event?.Status}
                    </p>
                  </div>
                </div>
              </div>
              {/* COl 2 */}
              <div className="w-[30%] bg-white rounded-sm shadow-lg p-8">
                <div className="flex items-start justify-between w-full my-2">
                  <p className="text-gray-500 text-xs">Event Date: </p>
                  <span className="text-[14px] text-black font-medium">
                    {event.EventDate}
                  </span>
                </div>

                <div className="flex items-start justify-between w-full my-2">
                  <p className="text-gray-500 text-xs">Event Time:</p>
                  <span className="text-[14px] text-black font-medium">
                    {event.EventTime}
                  </span>
                </div>
                <div className="flex items-start justify-between w-full my-2">
                  <p className="text-gray-500 text-xs">State:</p>
                  <span className="text-[14px] text-black font-medium">
                    {event.State}
                  </span>
                </div>
                <div className="flex items-start justify-between w-full my-2">
                  <p className="text-gray-500 text-xs">LGA:</p>
                  <span className="text-[14px] text-black font-medium">
                    {event.LGA}
                  </span>
                </div>
                <div className="flex items-start justify-between w-full my-2">
                  <p className="text-gray-500 text-xs">Ward:</p>
                  <span className="text-[14px] text-black font-medium">
                    {event.Ward}
                  </span>
                </div>
              </div>
            </div>
            {/* Attendance */}
            <div
              className={`max-h-[65vh] relative scroll-div overflow-scroll mt-4 shadow-md  w-100% sm:rounded-lg`}
            >
              <h1 className="my-2 text-xl">Attendance List</h1>
              <table
                className={` w-full  text-[10px] text-left text-black dark:text-gray-400 py-10`}
              >
                <thead className="text-[10px] sticky top-0 right-0 left-0 text-black uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      S/N
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      FirstName
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      MiddleName
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      LastName
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      PhoneNumber
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Gender
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      UserType
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      did training meet expectation
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      is the training high quality
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Did the training enhance your capacity
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      What could be done in future
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Assigned Agent
                    </th>
                  </tr>
                </thead>
                <tbody className=" pt-8">
                  {attendance.length > 0 &&
                    attendance.map((item, index) => (
                      <tr
                        key={index}
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 pt-8  hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer"
                        //   onClick={() =>
                        //     navigate(`/event-details/${event.EventUID}`)
                        //   }
                      >
                        <TableData>{index + 1}</TableData>
                        <TableData>{item.FirstName}</TableData>
                        <TableData>{item.MiddleName}</TableData>
                        <TableData>{item.LastName}</TableData>
                        <TableData>{item.PhoneNumber}</TableData>
                        <TableData>{item.Gender}</TableData>
                        <TableData>{item.UserType}</TableData>
                        <TableData>
                          {item.TrainingReceivedMeetsExpectations}
                        </TableData>
                        <TableData>
                          {item.TrainingReceivedHighQuality}
                        </TableData>
                        <TableData>
                          {item.TrainingReceivedEnhanceCapacity}
                        </TableData>
                        <TableData>{item.WhatCouldBeDoneInFunture}</TableData>
                        <TableData>{"Agent Name"}</TableData>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
