import React from "react";
import { SlCalender, SlHome } from "react-icons/sl";

function DPInputDistributionDetails() {
  return (
    <div>
      <h1 className="text-lg font-semibold ">
        Demo Plot Input Distribution Details
      </h1>
      <div className="flex">
        <div className="flex p-4 gap-2 items-center">
          <SlCalender />
          <p>12/04/2023</p>
        </div>

        <div className="flex p-4 gap-2 items-center">
          <SlHome />
          <p>Venue</p>
        </div>
      </div>
    </div>
  );
}

export default DPInputDistributionDetails;
