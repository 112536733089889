import { createContext, useState, useEffect, useContext } from "react";
// import setAuthToken from "../lib/utils/setAuthToken";
import { api, header } from "../../src/lib/queries/axiosInstance";

const AuthContext = createContext({});

const AuthContextProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(null);
  const [authUser, setAuthUser] = useState(null);
  const [authUserRole, setAuthUserRole] = useState(null);
  const [authDashboard, setAuthDashboard] = useState(null);
  const [isMessage, setIsMessage] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [messageContent, setMessageContent] = useState("");
  const [params, setParams] = useState("");
  // const [dashboardData, setDashboardData] = useState(null)

  const store = localStorage?.getItem("token") || undefined;
  // const dashboard = localStorage?.getItem("dashboard Data") || undefined;
  //

  const fetchUserDetails = async () => {
    api
      .get("/Admin/GetAccountDetails", {
        headers: header(),
      })
      .then((res) => {
        // console.log(res.data)
        // console.log(res.data.data)
        // console.log(res.data.dashboard)
        setAuthUser(res.data.data);
        setAuthUserRole(res.data.data.Role);
        setAuthDashboard(res.data.dashboard);
        // console.log(authUser)
      })
      .catch((err) => console.log(err, "error"));
  };

  useEffect(() => {
    fetchUserDetails();
  }, [store]);

  const isLoggedIn = async () => {};

  useEffect(() => {
    isLoggedIn();
  }, []);
  return (
    <AuthContext.Provider
      value={{
        authToken,
        setAuthToken,
        authUser,
        setAuthUser,
        fetchUserDetails,
        setAuthDashboard,
        authDashboard,
        authUserRole,
        isMessage,
        setIsMessage,
        messageType,
        setMessageType,
        messageContent,
        setMessageContent,
        params,
        setParams,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

// export const useAuthContext = () => useContext(AuthContext);

export function useAuthContext() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("AuthContext must be within a provider");
  }
  return context;
}
