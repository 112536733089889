export const bankName = [
  'First Multiple Microfinance Bank',
  'Access Bank Plc',
  'Fidelity Bank Plc',
  'First City Monument Bank Limited',
  'First Bank of Nigeria Limited',
  'Guaranty Trust Holding Company Plc',
  'Union Bank of Nigeria Plc',
  'United Bank for Africa Plc',
  'Zenith Bank Plc',
  'Citibank Nigeria Limited',
  'Ecobank Nigeria',
  'Heritage Bank Plc',
  'Keystone Bank Limited',
  'Polaris Bank Limited',
  'Stanbic IBTC Bank Plc',
  'Standard Chartered',
  'Sterling Bank Plc',
  'Titan Trust bank',
  'Unity Bank Plc',
  'Wema Bank Plc',
  'Globus Bank Limited',
  'Parallex Bank Limited',
  'PremiumTrust Bank Limited',
  'Providus Bank Limited',
  'SunTrust Bank Nigeria Limited',
  'Jaiz Bank Plc',
  'LOTUS BANK',
  'TAJBank Limited',
  'Mutual Trust Microfinance Bank',
  'Rephidim Microfinance Bank',
  'Shepherd Trust Microfinance Bank',
  'Empire Trust Microfinance Bank',
  'Finca Microfinance Bank Limited',
  'Fina Trust Microfinance Bank',
  'Accion Microfinance Bank',
  'Peace Microfinance Bank',
  'Infinity Microfinance Bank',
  'Pearl Microfinance Bank Limited',
  'Covenant Microfinance Bank Ltd',
  'Advans La Fayette Microfinance Bank',
  'Sparkle Bank',
  'Kuda Bank',
  'Moniepoint Microfinance Bank',
  'Opay',
  'Palmpay',
  'Rubies Bank',
  'VFD Microfinance Bank',
  'Mint Finex MFB',
  'Mkobo MFB',
  'Coronation Merchant Bank',
  'FBNQuest Merchant Bank',
  'FSDH Merchant Bank',
  'Rand Merchant Bank',
  'Nova Merchant Bank',
];
