import React, { useState, useEffect } from "react";
import { BsArrowLeft, BsCheckCircleFill } from "react-icons/bs";
// import { useNavigate } from "react-router-dom";
import { api } from "../../../lib/queries/axiosInstance";
import { baseURL } from "../../../constants/URL";
import { Link } from "react-router-dom";
// import useFieldAgent from "../../../hooks/useFieldAgents";

export default function AddFieldAgents() {
  // function openView(newview, oldview) {
  //   document.getElementById(newview).style.display = "block";
  //   document.getElementById(oldview).style.display = "none";
  // }

  const [formData, setFormData] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    password: "",
    password2: "",
  });

  const [ResponseFormOne, setResponseFormOne] = useState(null);
  const [error, setError] = useState("");
  const [resErr, setResErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  console.log(showModal);

  const onChange = (e) => {
    const { id, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const { fname, lname, email, phone, password, password2 } = formData;

  const registeredby = "User";

  const URL = `${baseURL}/FieldAgents/${registeredby}`;

  // const navigate = useNavigate();

  useEffect(() => {
    setError(false);
    setResErr(false);
  }, [formData]);

  const handleSubmit = async () => {
    if (password !== password2) {
      setError("passwords do not match");
      return;
    }

    setLoading(true);
    api
      .post(`/FieldAgents/${registeredby}`, {
        FirstName: fname,
        LastName: lname,
        Email: email,
        Password: password,
        Phone: phone,
      })
      .then((res) => {
        setResponseFormOne(res.data.data);
        // navigate("/");
        console.log("successs");
        setShowModal(true);
        console.log(res);

        // fetchUserDetails();
        return res;
      })
      .catch((err) => {
        console.log(err);
        setResErr(err.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className="grid mt-8 grid-cols-12 gap-4">
        <div className="col-start-4 col-span-6 border rounded bg-white">
          <Link className="flex items-center" to={"/fieldagents"}>
            <BsArrowLeft className={"text-primary"} size={20} />
            <p className="text-primary text-xs font-bold">Back</p>
          </Link>
          <div className="text-xl font-medium text-center my-8">
            Add a New Field Agent
          </div>

          {error && (
            <p
              style={{
                color: "red",
                padding: 16,
                margin: 10,
                backgroundColor: "pink",
                borderRadius: 4,
              }}
            >
              {error}
            </p>
          )}
          {resErr && (
            <p
              style={{
                color: "red",
                padding: 16,
                margin: 10,
                backgroundColor: "pink",
                borderRadius: 4,
              }}
            >
              {resErr}
            </p>
          )}
          <form>
            <div className="grid grid-cols-2 px-6 py-2">
              <div className="p-2">
                <label className="block text-gray-700 mb-2 text-sm">
                  FirstName
                </label>
                <input
                  onChange={onChange}
                  value={FormData.fname}
                  type="text"
                  id="fname"
                  className="text-sm bg-gray-50 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="p-2">
                <label className="block text-gray-700 mb-2 text-sm">
                  Last Name
                </label>
                <input
                  onChange={onChange}
                  value={FormData.lname}
                  type="text"
                  id="lname"
                  className="text-sm bg-gray-50 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="p-2">
                <label className="block text-gray-700 mb-2 text-sm">
                  Phone Number
                </label>
                <input
                  onChange={onChange}
                  value={FormData.phone}
                  type="text"
                  id="phone"
                  className="text-sm bg-gray-50 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="p-2">
                <label className="block text-gray-700 mb-2 text-sm">
                  Email
                </label>
                <input
                  onChange={onChange}
                  value={FormData.email}
                  type="email"
                  id="email"
                  className="text-sm bg-gray-50 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="p-2">
                <label className="block text-gray-700 mb-2 text-sm">
                  Password
                </label>
                <input
                  onChange={onChange}
                  value={FormData.password}
                  type="password"
                  id="password"
                  className="text-sm bg-gray-50 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>

              <div className="p-2">
                <label className="block text-gray-700 mb-2 text-sm">
                  Confirm Password
                </label>
                <input
                  onChange={onChange}
                  value={FormData.password2}
                  type="password"
                  id="password2"
                  className="text-sm bg-gray-50 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            </div>
            <div className="p-6">
              <button
                className="bg-primary w-full  text-white py-3 px-10 rounded-lg focus:outline-none focus:shadow-outline"
                type="button"
                onClick={handleSubmit}
              >
                {loading ? "Loading..." : "Create Agent"}
              </button>
            </div>
          </form>
        </div>
      </div>
      {showModal && <Modal onClose={() => setShowModal(!showModal)} />}
    </>
  );
}

const Modal = ({ onClose }) => {
  return (
    <>
      <div
        id="popup-modal"
        tabIndex="-1"
        className="fixed rounded-lg top-0 border-raidius left-0 right-0 z-50 bg-gray-600 bg-opacity-20 backdrop-blur-xs flex justify-center items-center p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full"
      >
        <div className="relative w-full h-full max-w-sm md:h-auto">
          <div className="relative bg-white rounded-lg  frounded-lg shadow dark:bg-gray-700">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              data-modal-hide="popup-modal"
              onClick={onClose}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>

            <div className="p-6 py-8 justify-items-center text-center">
              <div className="">
                <div className="flex justify-center mb-6 mt-4 items-center">
                  <BsCheckCircleFill fontSize={68} color="green" />
                </div>
                <div>
                  <h3 className="mb-5 text-2xl font-regular text-gray-700 dark:text-gray-400">
                    Agent Created Successfully.
                  </h3>
                </div>
                <button
                  data-modal-hide="popup-modal"
                  type="button"
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-green-300  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
