import { useEffect, useState } from "react";
import { api, header } from "../../src/lib/queries/axiosInstance";


const useEngagementDetails = (id) => {


  const [engagementDetails, setEngagementDetails] = useState(null);
  const [loading, setLoading] = useState(false)

  const singleEngagement = async (id) => {
    console.log(id)
  

    try {
        setLoading(true)
        const res = await api.get(`Engagements/${id}`, {
            headers: header(),
          })
          
        const data  = await res.data;
        console.log(data)
        setEngagementDetails(data.data);
        setLoading(false);
    } catch (err) {
        setLoading(false)
    }

  };



  useEffect(() => {
    id && singleEngagement(id);
  }, [id]);

  return { engagementDetails };
};

export default useEngagementDetails;
