import React, { useEffect, useState } from "react";
import { useAuthContext } from "../../../src/Context/AuthContext";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";
import "./style.css";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import { FiBarChart2 } from "react-icons/fi";

const Dashboard = () => {
  const { authUser, authDashboard, setParams } = useAuthContext();
  const [enumData, setEnumData] = useState([]);
  const [enumLabel, setEnumLabel] = useState([]);
  const [engagementData, SetEngagementData] = useState([]);
  const [engagementLabel, SetEngagementLabel] = useState([]);
  const contClass = "h-full bg-white py-10 px-10 shadow-sm rounded-md";
  const roundedCircle = "h-[15px] w-[15px] rounded-full mr-2";
  useEffect(() => {
    if (authDashboard !== null) {
      setEnumData(authDashboard?.EnumerationChart?.series);
      setEnumLabel(authDashboard?.EnumerationChart?.labels);
      SetEngagementData(authDashboard?.EngagementAttendeeChart?.series);
      SetEngagementLabel(authDashboard?.EngagementAttendeeChart?.labels);
    }
  }, [authDashboard]);
  console.log(authDashboard);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide the legend color
      },
      tooltip: {
        enabled: false, // Hide the data labels
      },
    },
  };
  const displacementData = {
    labels: ["IDP", "Non-IDP"],
    datasets: [
      {
        data: [
          authDashboard?.DisplacemmentIDP,
          authDashboard?.DisplacemmentNonIDP,
        ],
        backgroundColor: ["#8BBFF4", "#526FC0"],
        hoverBackgroundColor: ["#8BBFF4", "#526FC0"],
      },
    ],
  };
  const disabilityData = {
    labels: ["Disabled", "Non-Disabled"],
    datasets: [
      {
        data: [
          authDashboard?.DisabilityStatusDisabled,
          authDashboard?.DisabilityStatusNotDisabled,
        ],
        backgroundColor: ["#8BBFF4", "#E8A462"],
        hoverBackgroundColor: ["#8BBFF4", "#E8A462"],
      },
    ],
  };
  const genderData = {
    labels: ["Male", "Female"],
    datasets: [
      {
        data: [
          authDashboard?.ValidatedMaleHouseHold,
          authDashboard?.ValidatedFemaleHouseHold,
        ],
        backgroundColor: ["#46A578", "#F4BE37"],
        hoverBackgroundColor: ["#46A578", "#F4BE37"],
      },
    ],
  };
  const householdData = {
    labels: [
      "Spouse to household head",
      "Child to household head",
      "Household Head",
    ],
    datasets: [
      {
        data: [
          authDashboard?.HouseholdStatus[0]?.Total,
          authDashboard?.HouseholdStatus[1]?.Total,
          authDashboard?.HouseholdStatus[2]?.Total,
        ],
        backgroundColor: ["#E8A462", "#46A578", "#F4BE37"],
        hoverBackgroundColor: ["#E8A462", "#46A578", "#F4BE37"],
      },
    ],
  };

  const sexCategoryData = {
    labels: ["Male  Youth", "Female  Youth ", "Male Adult ", "Female Adult "],
    datasets: [
      {
        data: authDashboard?.SexAgeCategory?.series,
        backgroundColor: ["#466CCC", "#6AC1FF", "#E6BE65", "#E8A462"],
        hoverBackgroundColor: ["#466CCC", "#6AC1FF", "#E6BE65", "#E8A462"],
      },
    ],
  };
  const eduData = {
    labels: [
      authDashboard?.Educational_level[0]?.Educational_level,
      authDashboard?.Educational_level[1]?.Educational_level,
      authDashboard?.Educational_level[2]?.Educational_level,
      authDashboard?.Educational_level[3]?.Educational_level,
    ],
    datasets: [
      {
        data: [
          authDashboard?.Educational_level[0]?.Total,
          authDashboard?.Educational_level[1]?.Total,
          authDashboard?.Educational_level[2]?.Total,
          authDashboard?.Educational_level[3]?.Total,
        ],
        backgroundColor: ["#466CCC", "#6AC1FF", "#E6BE65", "#000"],
        hoverBackgroundColor: ["#466CCC", "#6AC1FF", "#E6BE65", "#000"],
      },
    ],
  };

  const farmingData = [
    authDashboard?.Farming_Type[0]?.Total,
    authDashboard?.Farming_Type[1]?.Total,
    authDashboard?.Farming_Type[2]?.Total,
    authDashboard?.Farming_Type[3]?.Total,
  ];
  const farmingLabel = [
    authDashboard?.Farming_Type[0]?.FarmingType,
    authDashboard?.Farming_Type[1]?.FarmingType,
    authDashboard?.Farming_Type[2]?.FarmingType,
    authDashboard?.Farming_Type[3]?.FarmingType,
  ];
  const chartData = {
    series: [
      {
        name: "Enumerations",
        data: enumData,
      },
    ],
    options: {
      chart: {
        // background: "#F4BE37",
      },
      xaxis: {
        categories: enumLabel,
      },
      colors: ["#46A578"],
      legend: {
        position: "top",
      },
      stroke: {
        width: 2,
      },
      fill: {
        opacity: 0.5,
      },
    },
  };
  const engagementChartData = {
    series: [
      {
        name: "Series 1",
        data: farmingData,
      },
    ],
    options: {
      chart: {
        // background: '#f7f7f7',
        stacked: false,
      },
      xaxis: {
        categories: farmingLabel,
        labels: {
          rotate: 0, // Set rotate to 0 to keep labels straight
        },
        style: {
          fontWeight: "700", // Set font weight to bold
        },
      },
      colors: ["#46A578"],
      legend: {
        position: "top",
      },
    },
  };
  const tempstate = [
    { state: "Adamawa", numbers: authDashboard?.AdamawaEnumerations },
    { state: "Kaduna", numbers: authDashboard?.KadunaEnumerations },
    { state: "Nasarawa", numbers: authDashboard?.NasarawaEnumerations },
  ];
  // Gesi Charts
  const gesiFinData = {
    labels: ["Banked", "Un-Banked"],
    datasets: [
      {
        data: [
          authDashboard?.Gesi?.Banked[0].Total,
          authDashboard?.Gesi?.Banked[1].Total,
        ],
        backgroundColor: ["#8BBFF4", "#E8A462"],
        hoverBackgroundColor: ["#8BBFF4", "#E8A462"],
      },
    ],
  };
  const gesiGenderData = {
    labels: ["Male", "Female"],
    datasets: [
      {
        data: [
          authDashboard?.Gesi?.Gender[1]?.Total,
          authDashboard?.Gesi?.Gender[0]?.Total,
        ],
        backgroundColor: ["#46A578", "#F4BE37"],
        hoverBackgroundColor: ["#46A578", "#F4BE37"],
      },
    ],
  };
  const gesiAgeRangeData = {
    labels: [
      authDashboard?.Gesi?.AgeRange?.map((item) => {
        return item.Age;
      }),
    ],
    datasets: [
      {
        data: authDashboard?.Gesi?.AgeRange?.map((item) => {
          return item.Total;
        }),
        backgroundColor: ["#466CCC", "#6AC1FF", "#E6BE65", "#E8A462"],
        hoverBackgroundColor: ["#466CCC", "#6AC1FF", "#E6BE65", "#E8A462"],
      },
    ],
  };
  console.log();
  return (
    <div className="">
      <p>
        <div className="text-base">
          <span className="text-2xl mr-1">👋 </span>Hi,{" "}
          {authUser && (
            <span>{`${authUser.FirstName} ${authUser.LastName}`}</span>
          )}
        </div>
        <div className="text-xl pt-1">Welcome to your dashboard</div>
      </p>
      {/* Stats */}
      <div className="mt-8">
        <div className="grid lg:grid-cols-4 lg:grid-rows-1  xs:grid-cols-2 xs:grid-rows-2   gap-4">
          <Link to={`/household`}>
            <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
              <div>
                <p className="mb-2 text-sm font-regular text-gray-600 dark:text-gray-400">
                  Validated Households
                </p>
                <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                  {authDashboard?.ValidatedHouseHold?.toLocaleString()}
                </p>
              </div>
            </div>
          </Link>
          <Link to={`/fieldagents`}>
            <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
              <div>
                <p className="mb-2 text-sm font-regular text-gray-600 dark:text-gray-400">
                  Field Agents
                </p>
                <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                  {authDashboard?.AllFieldAgents?.toLocaleString()}
                </p>
              </div>
            </div>
          </Link>

          <Link to={`/demo-plots`}>
            <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
              <div>
                <p className="mb-2 text-sm font-regular text-gray-600 dark:text-gray-400">
                  Demoplots
                </p>
                <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                  {authDashboard?.TotalDemoPlots?.toLocaleString()}
                </p>
              </div>
            </div>
          </Link>
          {authUser && authUser.Role === "Analyst" && (
            <Link to={`/household-survey`}>
              <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
                <div>
                  <p className="mb-2 text-sm font-regular text-gray-600 dark:text-gray-400">
                    Gesi Survey
                  </p>
                  <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                    {authDashboard?.Gesi.Total?.toLocaleString()}
                  </p>
                </div>
              </div>
            </Link>
          )}
          <Link
            to={`/household`}
            onClick={() => {
              setParams("validated");
            }}
          >
            <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
              <div>
                <p className="mb-2 text-sm font-regular text-gray-600 dark:text-gray-400">
                  Validated Farm Size
                </p>
                <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                  {authDashboard?.ValidatedFarmSize?.toLocaleString()}
                </p>
              </div>
            </div>
          </Link>
          <Link
            to={`/household`}
            onClick={() => {
              setParams("unvalidated");
            }}
          >
            <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
              <div>
                <p className="mb-2 text-sm font-regular text-gray-600 dark:text-gray-400">
                  Unvalidated Farm Size
                </p>
                <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                  {authDashboard?.UnValidatedFarmSize?.toLocaleString()}
                </p>
              </div>
            </div>
          </Link>
          {/* <Link to={`/ra-adoption`}>
            <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
              <div>
                <p className="mb-2 text-sm font-regular text-gray-600 dark:text-gray-400">
                  RA Adoption
                </p>
                <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                  {"12,989"}
                </p>
              </div>
            </div>
          </Link> */}
          {/* enumerators/dashboard */}
          <Link to={`/`}>
            <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
              <div>
                <p className="mb-2 text-sm font-regular text-gray-600 dark:text-gray-400">
                  YiW Enumerators
                </p>
                <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                  {authDashboard?.YiWEnumerators[0]?.Total?.toLocaleString()}
                </p>
              </div>
            </div>
          </Link>
          <Link to={`/indirect-outreaches/dashboard`}>
            <div className="flex items-center p-4 bg-white rounded-lg shadow-xs dark:bg-gray-800">
              <div>
                <p className="mb-2 text-sm font-regular text-gray-600 dark:text-gray-400">
                  {/* Indirect Outreach */}Job Creation (Yiw)
                </p>
                <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                  {authDashboard?.YiWStatistics[0]?.Reached?.toLocaleString()}
                </p>
              </div>
            </div>
          </Link>
        </div>
      </div>
      {/* CHarts */}
      {authDashboard && authDashboard.EnumerationStatus && (
        <div>
          {/* First Row */}
          <div className="w-full h-[300px] grid lg:grid-cols-2 space-x-4  my-6 mb-24 ">
            <div className={contClass}>
              <div className="w-full flex flex-row justify-between items-center mb-4">
                <h3 className="font-semibold">States</h3>
                <h3 className="font-semibold">Households</h3>
              </div>
              {tempstate.map((item, index) => (
                <div
                  className="py-4 my-2 border-b border-b-gray-200 flex flex-row justify-between items-center"
                  key={index}
                >
                  <p className="text-sm font-medium">{item.state}</p>
                  <p className="font-semibold text-md">
                    {item.numbers.toLocaleString()}
                  </p>
                </div>
              ))}
            </div>
            <div className={contClass}>
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-bold px-5 pt-2">Validated Gender</h2>
                <h2 className="text-bold px-5 pt-2">Status in Households</h2>
              </div>
              <div className="flex">
                <div className="w-1/2">
                  <Pie data={genderData} options={options} />
                </div>
                <div className="w-1/2">
                  <Pie data={householdData} options={options} />
                </div>
              </div>
              <div className="flex justify-between items-center ">
                {/* Validated Gender */}
                <div className="my-5 ml-10">
                  <div className="flex items-center">
                    <div
                      className={roundedCircle}
                      style={{
                        backgroundColor:
                          genderData.datasets[0].backgroundColor[0],
                      }}
                    />
                    <p className="text-xs">
                      Male{" "}
                      <span className="font-bold">
                        ({genderData.datasets[0].data[0].toLocaleString()})
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center my-2">
                    <div
                      className={roundedCircle}
                      style={{
                        backgroundColor:
                          genderData.datasets[0].backgroundColor[1],
                      }}
                    />
                    <p className="text-xs">
                      Female{" "}
                      <span className="font-bold">
                        ({genderData.datasets[0].data[1].toLocaleString()})
                      </span>
                    </p>
                  </div>
                </div>
                {/* Household Status */}

                <div className="my-5">
                  <div className="flex items-center">
                    <div
                      className={roundedCircle}
                      style={{
                        backgroundColor:
                          householdData.datasets[0].backgroundColor[0],
                      }}
                    />
                    <p className="text-xs">
                      Spouse to household head
                      <span className="font-bold ml-1">
                        ({householdData.datasets[0].data[0].toLocaleString()})
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center my-2">
                    <div
                      className={roundedCircle}
                      style={{
                        backgroundColor:
                          householdData.datasets[0].backgroundColor[1],
                      }}
                    />
                    <p className="text-xs">
                      Child to household head
                      <span className="font-bold ml-1">
                        ({householdData.datasets[0].data[1].toLocaleString()})
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center my-2">
                    <div
                      className={roundedCircle}
                      style={{
                        backgroundColor:
                          householdData.datasets[0].backgroundColor[2],
                      }}
                    />
                    <p className="text-xs">
                      Household Head
                      <span className="font-bold ml-1">
                        ({householdData.datasets[0].data[2].toLocaleString()})
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="h-[px]" /> */}
          {/* Second Row */}
          <div className="w-full grid grid-cols-2 space-x-4  my-6 ">
            <div className={contClass}>
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-bold px-5 pt-2">Disability Status</h2>
                <h2 className="text-bold px-5 pt-2">Displacement Status</h2>
              </div>
              <div className="flex">
                <div className="w-1/2">
                  <Pie data={disabilityData} options={options} />
                </div>
                <div className="w-1/2">
                  {/* <h2 className="text-bold px-5 pt-2">Displacement Status</h2> */}
                  <Pie data={displacementData} options={options} />
                </div>
              </div>
              <div className="flex justify-between items-center ">
                {/* DIsablilty Status */}
                <div className="my-5">
                  <div className="flex items-center">
                    <div
                      className={roundedCircle}
                      style={{ backgroundColor: "#8BBFF4" }}
                    />
                    <p className="text-xs">
                      Disabled{" "}
                      <span className="font-bold">
                        ({disabilityData.datasets[0].data[0].toLocaleString()})
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center my-2">
                    <div
                      className={roundedCircle}
                      style={{
                        backgroundColor:
                          disabilityData.datasets[0].backgroundColor[1],
                      }}
                    />
                    <p className="text-xs">
                      Non Disabled{" "}
                      <span className="font-bold">
                        ({disabilityData.datasets[0].data[1].toLocaleString()})
                      </span>
                    </p>
                  </div>
                </div>
                {/* Displacement Status */}
                <div className="my-5">
                  <div className="flex items-center">
                    <div
                      className={roundedCircle}
                      style={{
                        backgroundColor:
                          displacementData.datasets[0].backgroundColor[0],
                      }}
                    />
                    <p className="text-xs">
                      IDP{" "}
                      <span className="font-bold">
                        ({displacementData.datasets[0].data[0].toLocaleString()}
                        )
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center my-2">
                    <div
                      className={roundedCircle}
                      style={{
                        backgroundColor:
                          displacementData.datasets[0].backgroundColor[1],
                      }}
                    />
                    <p className="text-xs">
                      Non IDP{" "}
                      <span className="font-bold">
                        ({displacementData.datasets[0].data[1].toLocaleString()}
                        )
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={`h-full bg-white py-10 px-5 shadow-sm rounded-md`}>
              <h2 className="text-bold px-5 pt-2 flex flex-row items-end">
                Validation Period{" "}
                <FiBarChart2 size={30} className="text-primary" />
              </h2>
              <Chart
                options={chartData.options}
                series={chartData.series}
                type="area"
                width={"100%"}
              />
            </div>
          </div>
          {/* Third Row */}
          <div className="w-full grid grid-cols-2 space-x-4 my-6">
            <div className={contClass}>
              <div className="w-full flex flex-row justify-between items-center mb-4">
                <h3 className="font-semibold">Farming Type</h3>
                <div className="flex flex-row items-center">
                  <div className="w-[10px] h-[10px] bg-primary rounded-full" />
                  <p className="font-light text-xs underline ml-2">
                    Households by Farming Type
                  </p>
                </div>
              </div>
              <Chart
                options={engagementChartData.options}
                series={engagementChartData.series}
                type="bar"
                width={"100%"}
              />
            </div>
            <div className={contClass}>
              <div className="flex justify-around items-center mb-4">
                <h4 className="text-sm font-bold px-5 pt-2">Gender Category</h4>
                <h4 className="text-sm font-bold px-5 pt-2">
                  Educational Level
                </h4>
              </div>
              <div className="flex">
                <div className="w-1/2">
                  <Pie data={sexCategoryData} options={options} />
                </div>
                <div className="w-1/2">
                  {/* <h2 className="text-bold px-5 pt-2">Displacement Status</h2> */}
                  <Pie data={eduData} options={options} />
                </div>
              </div>
              <div className="flex justify-around items-center ">
                {/* Sex Category */}
                <div className="my-5">
                  <div className="flex items-center">
                    <div
                      className={roundedCircle}
                      style={{
                        backgroundColor:
                          sexCategoryData.datasets[0].backgroundColor[0],
                      }}
                    />
                    <p className="text-xs">
                      Male Youth{" "}
                      <span className="font-bold">
                        ({sexCategoryData.datasets[0].data[0].toLocaleString()})
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center my-2">
                    <div
                      className={roundedCircle}
                      style={{
                        backgroundColor:
                          sexCategoryData.datasets[0].backgroundColor[1],
                      }}
                    />
                    <p className="text-xs">
                      Female Youth{" "}
                      <span className="font-bold">
                        ({sexCategoryData.datasets[0].data[1].toLocaleString()})
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center my-2">
                    <div
                      className={roundedCircle}
                      style={{
                        backgroundColor:
                          sexCategoryData.datasets[0].backgroundColor[2],
                      }}
                    />
                    <p className="text-xs">
                      Male Adult{" "}
                      <span className="font-bold">
                        ({sexCategoryData.datasets[0].data[2].toLocaleString()})
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center my-2">
                    <div
                      className={roundedCircle}
                      style={{
                        backgroundColor:
                          sexCategoryData.datasets[0].backgroundColor[3],
                      }}
                    />
                    <p className="text-xs">
                      Female Adult{" "}
                      <span className="font-bold">
                        ({sexCategoryData.datasets[0].data[3].toLocaleString()})
                      </span>
                    </p>
                  </div>
                </div>
                {/* Educational Level */}
                <div className="my-5">
                  <div className="flex items-center">
                    <div
                      className={roundedCircle}
                      style={{
                        backgroundColor: eduData.datasets[0].backgroundColor[0],
                      }}
                    />
                    <p className="text-xs">
                      Tetiary{" "}
                      <span className="font-bold">
                        ({eduData.datasets[0].data[0].toLocaleString()})
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center my-2">
                    <div
                      className={roundedCircle}
                      style={{
                        backgroundColor: eduData.datasets[0].backgroundColor[1],
                      }}
                    />
                    <p className="text-xs">
                      Secondary{" "}
                      <span className="font-bold">
                        ({eduData.datasets[0].data[1].toLocaleString()})
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center my-2">
                    <div
                      className={roundedCircle}
                      style={{
                        backgroundColor: eduData.datasets[0].backgroundColor[2],
                      }}
                    />
                    <p className="text-xs">
                      Primary{" "}
                      <span className="font-bold">
                        ({eduData.datasets[0].data[2].toLocaleString()})
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center my-2">
                    <div
                      className={roundedCircle}
                      style={{
                        backgroundColor: eduData.datasets[0].backgroundColor[3],
                      }}
                    />
                    <p className="text-xs">
                      None{" "}
                      <span className="font-bold">
                        ({eduData.datasets[0].data[3].toLocaleString()})
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* GESI */}
          {authUser && authUser.Role === "Analyst" && (
            <p className="text-2xl font-bold text-black">GESI Survey</p>
          )}
          {/* First Row */}
          {authUser && authUser.Role === "Analyst" && (
            <div className="w-full h-[300px] grid lg:grid-cols-2 space-x-4  my-6 mb-24 ">
              <div className={contClass}>
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-bold px-5 pt-2">Gender</h2>
                  <h2 className="text-bold px-5 pt-2">Financial Status</h2>
                </div>
                <div className="flex">
                  <div className="w-1/2">
                    <Pie data={gesiGenderData} options={options} />
                  </div>
                  <div className="w-1/2">
                    <Pie data={gesiFinData} options={options} />
                  </div>
                </div>
                <div className="flex justify-between items-center ">
                  {/* Gesi Gender */}
                  <div className="my-5 ml-10">
                    <div className="flex items-center">
                      <div
                        className={roundedCircle}
                        style={{
                          backgroundColor:
                            gesiGenderData.datasets[0].backgroundColor[0],
                        }}
                      />
                      <p className="text-xs">
                        Male{" "}
                        <span className="font-bold">
                          ({gesiGenderData.datasets[0].data[0].toLocaleString()}
                          )
                        </span>
                      </p>
                    </div>
                    <div className="flex items-center my-2">
                      <div
                        className={roundedCircle}
                        style={{
                          backgroundColor:
                            gesiGenderData.datasets[0].backgroundColor[1],
                        }}
                      />
                      <p className="text-xs">
                        Female{" "}
                        <span className="font-bold">
                          ({gesiGenderData.datasets[0].data[1].toLocaleString()}
                          )
                        </span>
                      </p>
                    </div>
                  </div>
                  {/* Gesi Fin */}
                  <div className="my-5 ml-10">
                    <div className="flex items-center">
                      <div
                        className={roundedCircle}
                        style={{
                          backgroundColor:
                            gesiFinData.datasets[0].backgroundColor[0],
                        }}
                      />
                      <p className="text-xs">
                        Banked{" "}
                        <span className="font-bold">
                          ({gesiFinData.datasets[0].data[0].toLocaleString()})
                        </span>
                      </p>
                    </div>
                    <div className="flex items-center my-2">
                      <div
                        className={roundedCircle}
                        style={{
                          backgroundColor:
                            gesiFinData.datasets[0].backgroundColor[1],
                        }}
                      />
                      <p className="text-xs">
                        Un-Banked{" "}
                        <span className="font-bold">
                          ({gesiFinData.datasets[0].data[1].toLocaleString()})
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={contClass}>
                <div className="flex justify-center items-center mb-4">
                  <h2 className="text-bold px-5 pt-2">Age Range</h2>
                </div>
                <div className="flex">
                  <div className="flex">
                    <div className="w-full">
                      <Pie data={gesiAgeRangeData} options={options} />
                    </div>
                  </div>
                  {/* gesiAgeRange */}
                  <div className="my-5">
                    <div className="flex items-center">
                      <div
                        className={roundedCircle}
                        style={{
                          backgroundColor:
                            gesiAgeRangeData.datasets[0].backgroundColor[0],
                        }}
                      />
                      <p className="text-xs">
                        {"31 – 45"}{" "}
                        <span className="font-bold">
                          (
                          {gesiAgeRangeData.datasets[0].data[0].toLocaleString()}
                          )
                        </span>
                      </p>
                    </div>
                    <div className="flex items-center my-2">
                      <div
                        className={roundedCircle}
                        style={{
                          backgroundColor:
                            gesiAgeRangeData.datasets[0].backgroundColor[1],
                        }}
                      />
                      <p className="text-xs">
                        {"15 – 30"}{" "}
                        <span className="font-bold">
                          (
                          {gesiAgeRangeData.datasets[0].data[1].toLocaleString()}
                          )
                        </span>
                      </p>
                    </div>
                    <div className="flex items-center my-2">
                      <div
                        className={roundedCircle}
                        style={{
                          backgroundColor:
                            gesiAgeRangeData.datasets[0].backgroundColor[2],
                        }}
                      />
                      <p className="text-xs">
                        {"46 -  60"}{" "}
                        <span className="font-bold">
                          (
                          {gesiAgeRangeData.datasets[0].data[2].toLocaleString()}
                          )
                        </span>
                      </p>
                    </div>
                    <div className="flex items-center my-2">
                      <div
                        className={roundedCircle}
                        style={{
                          backgroundColor:
                            gesiAgeRangeData.datasets[0].backgroundColor[3],
                        }}
                      />
                      <p className="text-xs">
                        {"More than 60 years"}{" "}
                        <span className="font-bold">
                          (
                          {gesiAgeRangeData.datasets[0].data[3].toLocaleString()}
                          )
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
