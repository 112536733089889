import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Login } from "../pages/Auth";
import {
  Dashboard,
  EnumeratorsDashboard,
  IndirectDashboard,
} from "../pages/Dashboard";
import Layout from "../components/Layouts";

import {
  FieldAgentDetails,
  AllFieldAgents,
  AddFieldAgents,
  PendingFieldAgents,
} from "../pages/FieldAgent";
import {
  EngagementHome,
  // EngagementDetails,
  CreateEngagement,
  PendingEngagement,
  AttendeeDetails,
  AllAttendees,
} from "../pages/Engagement";
import EngagementAttendees from "../pages/Engagement/EngagementDetails/EngagementAttendees";
import {
  FarmerDetails,
  EnumerationHome,
  AllFarmers,
  OngoingEnumeration,
} from "../pages/Enumeration";
import CoordinatorFieldAgent from "../pages/Cordinator/CoordinatorFieldAgent";
import Household from "../pages/Cordinator/Household";

import Cluster from "../pages/Cluster";
import CreateCluster from "../pages/Cluster/CreateCluster";
import Influencer from "../pages/Influencer";
import ProtectedRoute from "../components/Auth/ProtectedRoutes";
import AllCoordinatorAttendees from "../pages/Cordinator/Attendees";
import Engagements from "../pages/Cordinator/Engagements/Engagements";
import EngagementDetails from "../pages/Cordinator/Engagements/EngagementDetails";

// import HouseholdDetail from "../pages/Cordinator/HouseholdDetails";
import HouseholdDetails from "../pages/Cordinator/HouseholdDetails";
import HouseHoldViewMore from "../pages/Cordinator/HouseHoldViewMore";
import { EditHouseDetails } from "../pages/Household";
import { SurveyListings } from "../pages/survey";
import { AddNewStaff, StaffListings } from "../pages/Staff";
import { CreateEvent, EditEvent, EventListings } from "../pages/Events";
import EventDetails from "../pages/Events/EventDetails";
import {
  DemoPlotDetails,
  DemoPlotListings,
  DemoPlotsAttendance,
  DemoPlotsInputDistributions,
  DemoPlotsTrainings,
  DPInputDistributionDetails,
} from "../pages/demo-plot";
import { FarmLandsPage } from "../pages/Farmlands";
import { AdhocListings } from "../pages/Adhoc";
import Outreach from "../pages/Outreach/Outreach";
import OutreachDetails from "../pages/Outreach/OutreachDetails";
import {
  HouseHoldInput,
  HouseHoldInputDetails,
  RAAdoption,
  UpdateSingleHouseholdInput,
} from "../pages/Activities";
import EnumerationList from "../pages/Enumeration/EnumerationHome/EnumerationList";
import { EnumeratorsListings, IndirectListings } from "../pages/Users";

// import Login from "../pages/Auth";
// import FarmerList from "../pages/Enumeration/AllFarmers/FarmerList";

const AdminRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ProtectedRoute />}>
          <Route path="/" element={<Layout />}>
            {/* Dashboards */}
            <Route index element={<Dashboard />} />
            <Route
              path="/enumerators/dashboard"
              element={<EnumeratorsDashboard />}
            />
            <Route
              path="/indirect-outreaches/dashboard"
              element={<IndirectDashboard />}
            />
            {/* Users Route */}
            <Route path="/field-agents" element={<AllFieldAgents />} />
            <Route path="/adhoc-agents" element={<AdhocListings />} />
            <Route path="/fieldagents" element={<CoordinatorFieldAgent />} />
            <Route
              path="/field-agents/details/:id"
              element={<FieldAgentDetails />}
            />
            <Route path="/add-agents" element={<AddFieldAgents />} />
            <Route path="/pending-agents" element={<PendingFieldAgents />} />
            <Route path="/enumerators" element={<EnumeratorsListings />} />
            <Route path="/indirect-outreaches" element={<IndirectListings />} />
            <Route path="/engagements" element={<EngagementHome />} />
            <Route path="/allengagements" element={<Engagements />} />
            <Route
              path="/engagement/details/:id"
              element={<EngagementDetails />}
            />
            <Route
              path="/engagement/details/attendees"
              element={<EngagementAttendees />}
            />
            <Route path="/create-engagement" element={<CreateEngagement />} />
            <Route path="/pending-engagement" element={<PendingEngagement />} />
            <Route path="/enumeration" element={<EnumerationHome />} />
            <Route path="/all-attendees" element={<AllAttendees />} />
            <Route path="/allattendees" element={<AllCoordinatorAttendees />} />
            <Route path="/attendee-details" element={<AttendeeDetails />} />
            <Route
              path="/ongoing-enumeration"
              element={<OngoingEnumeration />}
            />
            <Route path="/all-farmers" element={<AllFarmers />} />
            <Route path="/household" element={<Household />} />
            <Route path="/farmer-details/:id" element={<FarmerDetails />} />
            <Route path="/householddetail/:id" element={<HouseholdDetails />} />
            <Route
              path="/household-view-more/:id"
              element={<HouseHoldViewMore />}
            />
            <Route
              path="/edit-household-details/:id"
              element={<EditHouseDetails />}
            />
            <Route path="/outreach" element={<Outreach />} />
            <Route path="/outreach-details/:id" element={<OutreachDetails />} />
            {/* <Route path="/farmerlist" element={<FarmerList />} /> */}
            <Route path="/cluster" element={<Cluster />} />
            <Route path="/cluster/create" element={<CreateCluster />} />
            <Route path="/influencer" element={<Influencer />} />
            {/* Survey Paths */}
            <Route path="/household-survey" element={<SurveyListings />} />

            {/* DemoPlots Routes */}
            <Route path="/demo-plots" element={<DemoPlotListings />} />
            <Route
              path="/demo-plots/input-distribution"
              element={<DemoPlotsInputDistributions />}
            />
            <Route
              path="/demoplots-attendance"
              element={<DemoPlotsAttendance />}
            />
            <Route
              path="/demoplots-training"
              element={<DemoPlotsTrainings />}
            />
            <Route path="/demo-plot/:id" element={<DemoPlotDetails />} />
            <Route
              path="/demo-plot/inputdistribution"
              element={<DPInputDistributionDetails />}
            />

            {/* Staff ROute */}
            <Route path="/add-new-staff" element={<AddNewStaff />} />
            <Route path="/staff" element={<StaffListings />} />

            {/* RA ADOTPION */}
            <Route path="/ra-adoption" element={<RAAdoption />} />

            {/* Events ROutes */}
            <Route path="/create-event" element={<CreateEvent />} />
            <Route path="/all-events" element={<EventListings />} />
            <Route path="/event-details/:id" element={<EventDetails />} />
            <Route path="/edit-event/:id" element={<EditEvent />} />

            {/* FarmLands Routes */}
            <Route path="/farmlands" element={<FarmLandsPage />} />
            {/* Activities Routes */}
            <Route path="/household-inputs" element={<HouseHoldInput />} />
            <Route
              path="/input-details/:id"
              element={<HouseHoldInputDetails />}
            />
            <Route
              path="/edit-input-details/:id"
              element={<UpdateSingleHouseholdInput />}
            />
          </Route>
        </Route>

        <Route path="/login" element={<Login />} />
      </Routes>
    </Router>
  );
};

export default AdminRoutes;
