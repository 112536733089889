import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { api, header } from "../../lib/queries/axiosInstance";
import PulseLoader from "react-spinners/PulseLoader";
import ReactPaginate from "react-paginate";
import Papa from "papaparse";
import { TiExportOutline } from "react-icons/ti";

const TableData = ({ children }) => (
  <td className="px-6 py-2 text-black">{children}</td>
);
export default function IndirectListings() {
  const [limit, setLimit] = useState(100);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(start + limit);
  const [pageCount, setpageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [surveys, setSurveys] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const getSurveys = async () => {
      const res = await api.get(`indirectoutreach/0/${limit}`, {
        headers: header(),
      });
      // const getSurveys = async () => {
      //   const res = await api.get(`householdsurvey`, {
      //     headers: header(),
      //   });

      const data = await res.data;
      console.log(data);
      if (data?.data?.content?.length > 0) {
        const total = data.data.total;
        setTotal(total);
        setpageCount(Math.ceil(total / limit));
        setSurveys(data.data.content);
      }
      setLoading(false);
    };

    getSurveys();
  }, [limit]);

  const fetchSurveys = async (start) => {
    setLoading(true);
    const res = await api.get(`householdsurvey/${start}/${limit}`, {
      headers: header(),
    });

    const data = await res.data;
    setLoading(false);
    const resData = data.data.content;
    // console.log(data.data);
    return resData;
  };

  const handlePageClick = async (data) => {
    let startCounter = data.selected + 1;
    let startr = startCounter * limit - limit;
    let endr = startr + limit;
    const survs = await fetchSurveys(startr);
    setEnd(endr);
    setStart(startr);
    setSurveys(survs);
  };

  return (
    <div>
      <div className="text-xl pt-1">YiW Enumerators({total})</div>
      <div className="bg-white mt-4 p-6">
        <div
          className={` max-h-[65vh] relative scroll-div overflow-scroll mt-4 shadow-md  w-100% sm:rounded-lg`}
        >
          {loading ? (
            <div className=" m-12  justify-items-center justify-center">
              {" "}
              <PulseLoader
                className=" m-12 justify-center"
                color={"#46A576"}
                loading={loading}
                // cssOverride={override}
                size={14}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <table
              className={` w-full  text-sm text-left text-gray-500 dark:text-gray-400`}
            >
              <thead className="text-xs sticky top-0 right-0 left-0 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    FirstName
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    MiddleName
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    LastName
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Gender
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    MaritalStatus
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    HouseholdStatus
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    PhoneNo
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    AltPhone
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Birthdate
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    State
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    LGA
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Ward
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Community
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Address
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    LocationType
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Education
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Student
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    CurrentEducationLevel
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    StudentType
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    EmploymentStatus
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    EmploymentType
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    EmploymentSector
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WorkType
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WorkTypeOthers
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    EngagementPeriodUnit
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    EngagementPeriod
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WorkStatus
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WorkSource
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WorkSourceDiscription
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    See
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Hear
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Walk
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Remember
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    SelfCare
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Communicate
                  </th>
                  {/* <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WhyLiveHere
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Picture
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Coordinate
                  </th> */}
                  {/* <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Comment
                  </th> */}
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    DisablilityStatus
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    DisplacementStatus
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    CreatedAT
                  </th>
                </tr>
              </thead>
              <tbody className=" pt-8">
                {surveys.length &&
                  surveys.map((farmer, index) => (
                    <tr
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 pt-8  hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer"
                      // onClick={() => navigate(`/householddetail/${farmer.UID}`)}
                      key={index}
                    >
                      <TableData>{farmer?.FirstName}</TableData>
                      <TableData>{farmer?.MiddleName}</TableData>
                      <TableData>{farmer?.LastName}</TableData>
                      <TableData>{farmer?.Gender}</TableData>
                      <TableData>{farmer?.MaritalStatus}</TableData>
                      <TableData>{farmer?.HouseholdStatus}</TableData>
                      <TableData>{farmer?.PhoneNo}</TableData>
                      <TableData>{farmer?.AltPhone}</TableData>
                      <TableData>{farmer?.Birthdate}</TableData>
                      <TableData>{farmer?.State}</TableData>
                      <TableData>{farmer?.LGA}</TableData>
                      <TableData>{farmer?.Ward}</TableData>
                      <TableData>{farmer?.Community}</TableData>
                      <TableData>{farmer?.Address}</TableData>
                      <TableData>{farmer?.LocationType}</TableData>
                      <TableData>{farmer?.Education}</TableData>
                      <TableData>{farmer?.Student}</TableData>
                      <TableData>{farmer?.CurrentEducationLevel}</TableData>
                      <TableData>{farmer?.StudentType}</TableData>
                      <TableData>{farmer?.EmploymentStatus}</TableData>
                      <TableData>{farmer?.EmploymentType}</TableData>
                      <TableData>{farmer?.EmploymentSector}</TableData>
                      <TableData>{farmer?.WorkType}</TableData>
                      <TableData>{farmer?.WorkTypeOthers}</TableData>
                      <TableData>{farmer?.EngagementPeriodUnit}</TableData>
                      <TableData>{farmer?.EngagementPeriod}</TableData>
                      <TableData>{farmer?.WorkStatus}</TableData>
                      <TableData>{farmer?.WorkSource}</TableData>
                      <TableData>{farmer?.WorkSourceDiscription}</TableData>
                      <TableData>{farmer?.See}</TableData>
                      <TableData>{farmer?.Hear}</TableData>
                      <TableData>{farmer?.Walk}</TableData>
                      <TableData>{farmer?.Remember}</TableData>
                      <TableData>{farmer?.SelfCare}</TableData>
                      <TableData>{farmer?.Communicate}</TableData>
                      {/* <TableData>{farmer?.WhyLiveHere}</TableData> */}
                      {/* <TableData>{farmer?.Picture}</TableData>
                      <TableData>{farmer?.Coordinate}</TableData> */}
                      {/* <TableData>{farmer?.Comment}</TableData> */}
                      <TableData>{farmer?.DisablilityStatus}</TableData>
                      <TableData>{farmer?.DisplacementStatus}</TableData>
                      <TableData>{farmer?.CreatedAT}</TableData>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
        <nav
          className="flex items-center justify-between pt-4"
          aria-label="Table navigation"
        >
          <div className="flex items-center">
            <div className="mt-4 ">
              <form>
                {" "}
                <label
                  // for="countries"
                  className="block mb-2 text-sm font-regular text-gray-900 dark:text-white"
                >
                  No of Data Per Page
                </label>
                <select
                  id="countries"
                  className=" border border-gray-200 text-gray-500 text-sm rounded-md font-light focus:ring-green-500 focus:border-green-400 block w-full p-2.5 "
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <option selected>{limit}</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                </select>
              </form>
            </div>
          </div>

          <div className="flex items-center gap-4">
            <div className=" gap-4 ">
              <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                Showing{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {start}-{end}
                </span>{" "}
                of{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {total}
                </span>
              </span>
            </div>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"inline-flex items-center -space-x-px"}
              pageClassName={
                "px-3 py-2 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              pageLinkClassName={""}
              previousClassName={
                "block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              previousLinkClassName={""}
              nextClassName={
                "block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              nextLinkClassName={""}
              breakClassName={
                "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              breakLinkClassName={
                "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              activeClassName={
                "z-10 px-3 py-2 leading-tight text-white border border-blue-400 bg-primary hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
              }
            />
          </div>
        </nav>
      </div>
    </div>
  );
}