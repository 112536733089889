import React, { useState } from "react";
import { useEventContext } from "../../Context/EventContext";
import { toast } from "react-toastify";
import { useEffect } from "react";

const EventCat = () => {
  const [selectItem, setSelectedItem] = useState({});
  const { eventForm, onChange, onActiveEventLocDateTime, setEventForm } =
    useEventContext();
  const { EventCategory, EventSubCategory } = eventForm;
  const data = [
    {
      id: 1,
      name: "RA theory, and best agronomic and production practices",
      content: [
        "Minimal No Tillage",
        "Intercropping (Polyculture)",
        "Crop Rotation",
        "Mulching",
        "Cover Cropping",
        "Integrated Pest Management (IPM)",
        "Water Management/Harvesting Methods",
        "Sustainable Animal Grazing (SPS)",
      ],
      eventType: "household",
    },
    {
      id: 2,
      name: "Financial Inclusion",
      content: [
        "Financial literacy",
        "MTG formation [Requirements for formation]",
        "Group Dynamics [Roles & Responsiblilities of members]",
        "Financial Services",
      ],
      eventType: "household",
    },
    {
      id: 3,
      name: "Seed & Seedling Bank Management",
      content: ["Seed development and storage", "Seedling management"],
      eventType: "household",
    },
    {
      id: 4,
      name: "Business Developement",
      content: [],
      eventType: "household",
    },
    { id: 5, name: "Marketing", content: [], eventType: "household" },
    { id: 6, name: "Waste Conversion", content: [], eventType: "household" },
    {
      id: 7,
      name: "Post Harvest Management",
      content: [],
      eventType: "household",
    },
    {
      id: 8,
      name: "RA theory, and best agronomic and production practices (Boot Camp)",
      content: [],
      eventType: "staff",
    },

    {
      id: 9,
      name: "Management Training",
      content: [
        "Governance & Operations",
        "Safegruarding",
        "Knowledge Management",
        "Performance & Compliance",
        "DEI",
      ],
      eventType: "staff",
    },
    {
      id: 10,
      name: "Gender Eqaulity & Social Inclusion",
      content: [
        "GESI concepts",
        "Training of field enumerators on GESI Analysis",
      ],
      eventType: "staff",
    },
    {
      id: 11,
      name: "Periodic Learning Sessions",
      content: [],
      eventType: "staff",
    },
    {
      id: 12,
      name: "MEL (Household Validation/Enumeration Training)",
      content: [],
      eventType: "staff",
    },
  ];

  useEffect(() => {
    if (EventCategory) {
      const temp = data?.find((item) => item.name === EventCategory);
      if (temp !== undefined) {
        setSelectedItem(temp);
      }
    }
  }, []);
  const onCatChange = (e) => {
    const { id, value } = e.target;
    setEventForm((prev) => ({
      ...prev,
      [id]: value,
    }));
    const temp = data?.find((item) => item.name === value);
    if (temp !== undefined) {
      setSelectedItem(temp);
    }
  };
  const household_data = [
    {
      id: 1,
      name: "RA theory, and best agronomic and production practices",
      content: [
        "Minimal No Tillage",
        "Intercropping (Polyculture)",
        "Crop Rotation",
        "Mulching",
        "Cover Cropping",
        "Integrated Pest Management (IPM)",
        "Water Management/Harvesting Methods",
        "Sustainable Animal Grazing (SPS)",
      ],
    },
    {
      id: 2,
      name: "Financial Inclusion",
      content: [
        "Financial literacy",
        "MTG formation [Requirements for formation]",
        "Group Dynamics [Roles & Responsiblilities of members]",
        "Financial Services",
      ],
    },
    {
      id: 3,
      name: "Seed & Seedling Bank Management",
      content: ["Seed development and storage", "Seedling management"],
    },
    {
      id: 4,
      name: "Business Developement",
      content: [],
      eventType: "household",
    },
    { id: 5, name: "Marketing", content: [], eventType: "household" },
    { id: 6, name: "Waste Conversion", content: [], eventType: "household" },
    {
      id: 7,
      name: "Post Harvest Management",
      content: [],
    },
  ];
  const staff_data = [
    {
      id: 8,
      name: "RA theory, and best agronomic and production practices (Boot Camp)",
      content: [],
    },

    {
      id: 9,
      name: "Management Training",
      content: [
        "Governance & Operations",
        "Safegruarding",
        "Knowledge Management",
        "Performance & Compliance",
        "DEI",
      ],
    },
    {
      id: 10,
      name: "Gender Eqaulity & Social Inclusion",
      content: [
        "GESI concepts",
        "Training of field enumerators on GESI Analysis",
      ],
    },
    {
      id: 11,
      name: "Periodic Learning Sessions",
      content: [],
    },
    {
      id: 12,
      name: "MEL (Household Validation/Enumeration Training)",
      content: [],
    },
  ];

  const onNext = () => {
    if (selectItem?.content?.length > 0) {
      if (EventCategory === "" || EventSubCategory === "") {
        toast.error("All Fields Must be Fill");
      } else {
        onActiveEventLocDateTime();
      }
    } else {
      if (EventCategory === "") {
        toast.error("All Fields Must be Fill");
      } else {
        onActiveEventLocDateTime();
      }
    }
  };
  return (
    <div>
      <div className="p-2">
        <label className="block text-gray-700 mb-2 text-xs">
          Select {eventForm.EventType} Training Category
        </label>
        <select
          value={EventCategory}
          id="EventCategory"
          onChange={onCatChange}
          className="text-xs bg-white drop-shadow-lg border-none rounded w-full py-3 px-3 text-gray-700 leading-tight "
        >
          <option value={""}>-- Select --</option>
          {eventForm.EventType === "staff" ? (
            <>
              {staff_data.map((opts) => (
                <option key={opts.id} value={opts.name}>
                  {opts.name}
                </option>
              ))}
            </>
          ) : (
            <>
              {household_data.map((opts) => (
                <option key={opts.id} value={opts.name}>
                  {opts.name}
                </option>
              ))}
            </>
          )}
        </select>
      </div>
      {selectItem?.content?.length > 0 && (
        <div className="p-2">
          <label className="block text-gray-700 mb-2 text-xs">
            Select Training Sub Category
          </label>
          <select
            value={EventSubCategory}
            id="EventSubCategory"
            onChange={onChange}
            className="text-xs bg-white drop-shadow-lg border-none rounded w-full py-3 px-3 text-gray-700 leading-tight "
          >
            <option value={""}>-- Select --</option>
            {selectItem?.content.map((opts) => (
              <option key={opts} value={opts}>
                {opts}
              </option>
            ))}
          </select>
        </div>
      )}
      <div className="flex w-full items-end justify-end">
        <div className="pt-6">
          <button
            className="bg-primary  text-white py-3 px-10 rounded-lg focus:outline-none focus:shadow-outline"
            type="button"
            onClick={onNext}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventCat;
