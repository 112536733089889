import React, { useState } from "react";
import { PrimaryBtn } from "../Form";
import { CustomMessage, Spinner } from "../Generic";
import { useEventContext } from "../../Context/EventContext";
import { useAuthContext } from "../../Context/AuthContext";
import axios from "axios";
import { baseURL } from "../../constants/URL";

const AssignUser = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const { setIsMessage, isMessage, setMessageContent, setMessageType } =
    useAuthContext();

  const { eventForm, onActiveEventType, onResetForm } = useEventContext();

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setCheckedItems([...checkedItems, id]);
    } else {
      setCheckedItems(checkedItems.filter((itemId) => itemId !== id));
    }
  };
  async function onSubmit() {
    if (checkedItems.length === 0) {
      setMessageContent("All Fields Fill");
      setIsMessage(true);
      setMessageType("error");
    } else {
      eventForm.Author = checkedItems.join(", ");
      const key = localStorage.getItem("token");
      try {
        setLoading(true);
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${key}`,
        };
        const response = await axios.post(`${baseURL}/eventtable`, eventForm, {
          headers,
        });
        if (response.data.code === 200) {
          setLoading(false);
          setMessageContent("Event Created Successfully");
          setIsMessage(true);
          setMessageType("success");
          setTimeout(() => {
            onActiveEventType();
            onResetForm();
          }, 2000);
        } else {
          setMessageContent(response.data.message);
          setIsMessage(true);
          setMessageType("error");
          setLoading(false);
        }
      } catch (error) {
        setMessageContent(error.message);
        setIsMessage(true);
        setMessageType("error");
        setLoading(false);
      }
    }
  }
  if (isMessage) {
    return <CustomMessage />;
  }
  if (loading) {
    return <Spinner />;
  }
  return (
    <div>
      {data?.length > 0 && (
        <ul>
          {data.map((agent, index) => (
            <label
              className=" block font-light text-sm border-b-[1.5px] border-b-gray-300 py-2 px-1 mx-5"
              key={index}
            >
              <input
                type="checkbox"
                checked={checkedItems.includes(agent.FieldAgentID)}
                onChange={(event) =>
                  handleCheckboxChange(event, agent.FieldAgentID)
                }
                className="mr-5 p-2"
              />
              {`${agent.FirstName} ${
                agent.MiddleName ? agent.MiddleName : ""
              } ${agent.LastName}`}
            </label>
          ))}
        </ul>
      )}
      <div className=" flex justify-between items-center w-full pr-8 my-2 ">
        <div className="w-[140px]">
          <PrimaryBtn
            text={"Clear Selections"}
            OnBtnClick={() => setCheckedItems([])}
            outlined
          />
        </div>
        <div className="w-[100px]">
          <PrimaryBtn text={"Submit"} OnBtnClick={onSubmit} />
        </div>
      </div>
    </div>
  );
};

export default AssignUser;
