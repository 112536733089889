import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useContext, useEffect } from "react";

const useAuth = () => {
  const token = localStorage.getItem("token");
  return token ? true : false;
};

const ProtectedRoute = () => {
  const location = useLocation();
    const auth = useAuth();
    
    

  return auth ? <Outlet /> : <Navigate to="/login" from={location} />;
};

export default ProtectedRoute;
