import React, { useEffect, useState } from "react";
import { useEventContext } from "../../Context/EventContext";
import {
  AssignUser,
  EventCat,
  EventLocDateTime,
  EventType,
} from "../../components/Event";
import { useAuthContext } from "../../Context/AuthContext";
import { api, header } from "../../lib/queries/axiosInstance";
import { Link } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";

const CreateEvent = () => {
  const [agents, setAgents] = useState([]);
  const {
    onEventType,
    onEventCat,
    onEventLocDateTime,
    onAssignUser,
    onActiveEventType,
    onActiveEventCat,
    onActiveEventLocDateTime,
    onActiveAssignUser,
    eventForm,
  } = useEventContext();

  const { State } = eventForm;
  const getAgents = async () => {
    const res = await api.get(`FieldAgents`, {
      headers: header(),
    });
    const data = await res.data;
    if (data.code === 200) {
      setAgents(data.data.content);
    }
  };
  useEffect(() => {
    getAgents();
  }, []);
  useEffect(() => {
    if (State) {
      const temp = agents?.filter((item) => item.StateAddress === State);
      if (temp !== undefined) {
        setAgents(temp);
      }
    }
  }, [State]);
  console.log(agents);
  return (
    <div>
      <Link className="flex items-center" to={"/all-events"}>
        <BsArrowLeft className={"text-primary"} size={20} />
        <p className="text-primary text-xs font-bold">Back</p>
      </Link>
      <div className="text-xl font-medium text-center">Create an Event</div>
      <div className="text-sm text-center mt-1 mb-5">
        Create an Engagement or Training Event
      </div>
      <div className="grid mt-8 grid-cols-12 gap-4">
        <div className="col-start-4 col-span-6 border-none rounded-md p-5 bg-white">
          {!onEventType && (
            <button
              className="text-primary  text-[10px] flex items-center font-bold"
              type="button"
              onClick={() => {
                if (onEventCat) {
                  onActiveEventType();
                } else if (onEventLocDateTime) {
                  onActiveEventCat();
                } else if (onAssignUser) {
                  onActiveEventLocDateTime();
                }
              }}
            >
              <BsArrowLeft className={"text-primary"} size={20} />
              Previous
            </button>
          )}

          {onEventType && <EventType />}
          {onEventCat && <EventCat />}
          {onEventLocDateTime && <EventLocDateTime />}
          {onAssignUser && <AssignUser data={agents} />}
        </div>
      </div>
    </div>
  );
};

export default CreateEvent;
