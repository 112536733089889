import { createContext, useContext, useEffect, useState } from "react";
import { baseURL } from "../constants/URL";
const EnumerationContext = createContext({});

const URL = `${baseURL}/Enumeration`;

const EnumerationContextProvider = ({ children }) => {
  const [enumerations, setEnumerations] = useState([]);
  const [enumerationDetail, setEnumerationDetail] = useState([]);

  useEffect(() => {
    const fetchEnumerations = async () => {
      const result = await fetch(URL);
      result.json().then((json) => {
        setEnumerations(json.data.content);
      });
    };
    fetchEnumerations();
  }, []);

  return (
    <EnumerationContext.Provider
      value={{
        enumerations,
        setEnumerations,
        enumerationDetail,
        setEnumerationDetail,
      }}
    >
      {children}
    </EnumerationContext.Provider>
  );
};

export default EnumerationContextProvider;

// export const useEnumerationContext = () => useContext(EnumerationContext);

export function useEnumerationProvider() {
  const context = useContext(EnumerationContext);
  if (context === undefined) {
    throw new Error("useEnumerateProvider must be within a provider");
  }
  return context;
}
