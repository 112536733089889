import React from "react";
import { GoogleMap, Marker, Polygon } from "@react-google-maps/api";
import { toast } from "react-toastify";

const FarmLandMap = ({ landData }) => {
  const containerStyle = {
    width: "100%",
    height: "600px",
  };

  const options = {
    streetViewControl: false,
    fullscreenControl: false,
  };

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{ lat: 10.3130618, lng: 9.8015682 }}
      zoom={7}
      options={options}
      onLoad={(map) => {
        // Do something when the map is loaded (if needed)
      }}
    >
      {landData.map(
        (
          {
            GeoMapping,
            Agent_First_Name,
            Agent_Second_Name,
            Community,
            LGA,
            State,
            Farm_size,
          },
          index
        ) => {
          const coordinates = JSON.parse(GeoMapping);
          const polygon = coordinates.map((coordinate) => ({
            lat: coordinate.latitude,
            lng: coordinate.longitude,
          }));

          return (
            <React.Fragment key={index}>
              <Polygon
                paths={polygon}
                options={{
                  strokeColor: "#019E59",
                  strokeOpacity: 1,
                  strokeWeight: 2,
                  fillColor: "#46A576",
                  fillOpacity: 0.5,
                  label: `${Agent_First_Name} ${Agent_Second_Name} `, // Set the label for the polygon
                }}
                onClick={() => {
                  toast.success(
                    `${Agent_First_Name} ${Agent_Second_Name} Demoplot - Location: ${Community}, ${LGA}, ${State}. - Farm Size: ${Farm_size} Acres`
                  );
                }}
              />
              {/* {coordinates.map((polygon, index) => (
                <Marker
                  key={index}
                  position={{
                    lat: polygon.latitude,
                    lng: polygon.longitude,
                  }}
                />
              ))} */}
              {coordinates.map((coordinate, coordIndex) => (
                <Marker
                  key={coordIndex}
                  position={{
                    lat: coordinate.latitude,
                    lng: coordinate.longitude,
                  }}
                  icon={{
                    url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
                    scaledSize: new window.google.maps.Size(32, 32),
                  }}
                />
              ))}
            </React.Fragment>
          );
        }
      )}
    </GoogleMap>
  );
};

export default FarmLandMap;
