import { useState, useCallback, useEffect } from "react";
import { api, header } from "../lib/queries/axiosInstance";
import { trimObj } from "../lib/utils/utilityHandler";
import { da } from "date-fns/locale";
import Papa from "papaparse";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const useHouseholdInputsQuery = ({ dateSelected, state, lga }) => {
  const [limit, setLimit] = useState(100);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(start + limit);
  const [pageCount, setpageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [inputs, setInputs] = useState([]);
  const [statusOptions, setStatusOptions] = useState("");
  const [advancedSearchOptions, setAdvancedSearchOptions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [searchResult, setSearchResult] = useState("");
  const [dateFilter, setDateFilter] = useState(`*${new Date().getFullYear()}*`);
  const [hasFarmSize, setHasFarmSize] = useState("");

  const fetchInputDistributions = useCallback(async () => {
    // let params;
    // if (advancedSearchOptions.length > 0) {
    //   let advance = "";
    //   advancedSearchOptions.forEach((item, index) => {
    //     // Append the parameter to the URL
    //     advance += `${item.column}=${item.value}`;

    //     // If it's not the last item, add an ampersand (&) as a separator
    //     if (index < advancedSearchOptions.length - 1) {
    //       advance += "&";
    //     }
    //   });
    //   console.log(advancedSearchOptions);
    //   console.log(advance);
    // } else {
    let params = `${dateFilter ? "CreatedAT=" + dateFilter : ""}${
      state ? "&State=" + state : ""
    }${lga ? "&LGA=" + lga : ""}${
      hasFarmSize === "No"
        ? "&FarmSize="
        : hasFarmSize === "Yes"
        ? "&FarmSize=*.*"
        : ""
    }&start=${start}&limit=${limit}`;
    // }
    try {
      setLoading(true);
      const res = await api.get(`hh_input_distribution/search?${params}`, {
        headers: header(),
      });
      const data = await res.data;
      console.log("res", res);
      if (data.code === 200) {
        const total = data.data.total;
        setTotal(total);
        setpageCount(Math.ceil(total / limit));
        setInputs(data.data.content);
      } else {
        setInputs([]);
        setTotal(0);
        setpageCount(0);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setInputs([]);
      setTotal(0);
      setpageCount(0);
      console.log(err);
    }
  }, [limit, start, hasFarmSize, dateFilter, state, lga]);
  
  async function onExport() {
    const tempLimit = 3000; // Number of items to fetch per request

    const pageCount = Math.ceil(total / tempLimit);
    const zip = new JSZip();
    try {
      for (let page = 0; page < pageCount; page++) {
        const tempStart = page * tempLimit;
        let params = `${dateFilter ? "CreatedAT=" + dateFilter : ""}${
          state ? "&State=" + state : ""
        }${lga ? "&LGA=" + lga : ""}${
          hasFarmSize === "No"
            ? "&FarmSize="
            : hasFarmSize === "Yes"
            ? "&FarmSize=*"
            : ""
        }&start=${tempStart}&limit=${tempLimit}`;
        setLoading(true);
        const res = await api.get(`hh_input_distribution/search?${params}`, {
          headers: header(),
        });
        const data = await res.data; // Retrieved data from the backend
        const csvContent = Papa.unparse(data.data.content);
        zip.file(`Inputs_${page}.csv`, csvContent);
        // const total = data.data.total;
        // setTotal(total);
        // setpageCount(Math.ceil(total / limit));
        // setEnumerations(data.data.content);
        // // Generate CSV file
        // const csv = Papa.unparse(data.data.content);
        // const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        // const csvURL = URL.createObjectURL(csvData);
        // const tempLink = document.createElement("a");
        // tempLink.href = csvURL;
        // tempLink.setAttribute("download", `data_${page}.csv`);
        // tempLink.click();
      }
      const currentTimestamp = new Date().toISOString().replace(/:/g, "-");
      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, `Inputs_${currentTimestamp}.zip`); // Download the ZIP file with timestamp
      setLoading(false); // Set loading state to false after finishing the export process
    } catch (err) {
      setLoading(false);
      // setEnumerations([]);
    }
  }
  useEffect(() => {
    setLoading(true);
    fetchInputDistributions();
  }, [limit, start, dateFilter, hasFarmSize, state, lga]);
  const handleOnClose = () => setOpenModal(false);

  return {
    setOpenModal,
    openModal,
    handleOnClose,
    setLimit,
    inputs,
    setSearchResult,
    searchResult,
    setInputs,
    fetchInputDistributions,
    limit,
    loading,
    setLoading,
    start,
    setStart,
    end,
    setEnd,
    pageCount,
    setpageCount,
    total,
    setTotal,
    statusOptions,
    setStatusOptions,
    dateFilter,
    setDateFilter,
    hasFarmSize,
    setHasFarmSize,
    onExport,
    advancedSearchOptions,
    setAdvancedSearchOptions,
  };
};

export default useHouseholdInputsQuery;
