import React from "react";
import { Link } from "react-router-dom";

const CustomLink = ({ width, text, path, outlined, Icon }) => {
  return (
    <div>
      <Link
        className={`flex items-center justify-center ${
          outlined ? "text-primary  bg-white" : "text-white  bg-primary"
        } ${
          width ? width : "w-full"
        } my-5 mx-4 py-3 px-5 rounded-md border border-primary text-center text-xs`}
        to={path}
      >
        {Icon && (
          <Icon
            size={20}
            className={`${outlined ? "text-primary  " : "text-white "} mr-2`}
          />
        )}
        {text}
      </Link>
    </div>
  );
};

export default CustomLink;
