import React from "react";
import styles from "./adhoc.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { HiOutlineSearch } from "react-icons/hi";
import { api, header } from "../../lib/queries/axiosInstance";
import PulseLoader from "react-spinners/PulseLoader";
import ReactPaginate from "react-paginate";
import Papa from "papaparse";
import { TiExportOutline } from "react-icons/ti";
import { baseURL } from "../../constants/URL";
import axios from "axios";
import { useAuthContext } from "../../Context/AuthContext";
import { CustomMessage, Spinner } from "../../components/Generic";

const TableData = ({ children }) => (
  <td className="px-6 py-2 text-black">{children}</td>
);
export default function AdhocListings() {
  const [limit, setLimit] = useState(100);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(start + limit);
  const [pageCount, setpageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [demoPlots, setDemoPlots] = useState([]);
  const [subLoading, setSubLoading] = useState(false);
  const navigate = useNavigate();
  const { setIsMessage, isMessage, setMessageContent, setMessageType } =
    useAuthContext();
  const getDemoPlots = async () => {
    setLoading(true);
    const res = await api.get(`assistfieldagent/0/${limit}`, {
      headers: header(),
    });
    const data = await res.data;
    console.log(data);
    if (data?.data?.content?.length > 0) {
      const total = data.data.total;
      setTotal(total);
      setpageCount(Math.ceil(total / limit));
      setDemoPlots(data.data.content);
    }
    setLoading(false);
  };

  useEffect(() => {
    getDemoPlots();
  }, [limit]);

  const fetchDemoPlots = async (start) => {
    setLoading(true);
    const res = await api.get(`assistfieldagent/${start}/${limit}`, {
      headers: header(),
    });

    const data = await res.data;
    setLoading(false);
    const resData = data.data.content;
    // console.log(data.data);
    return resData;
  };

  const handlePageClick = async (data) => {
    let startCounter = data.selected + 1;
    let startr = startCounter * limit - limit;
    let endr = startr + limit;
    const survs = await fetchDemoPlots(startr);
    setEnd(endr);
    setStart(startr);
    setDemoPlots(survs);
  };
  async function onApprove(AST_UID) {
    const key = localStorage.getItem("token");
    try {
      setSubLoading(true);
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      };
      const response = await axios.put(
        `${baseURL}/assistfieldagent/verify`,
        { AST_UID: AST_UID },
        {
          headers,
        }
      );
      if (response.data.code === 200) {
        setSubLoading(false);
        setMessageContent("Adhoc Agent Approve Successfully");
        setIsMessage(true);
        setMessageType("success");
        await getDemoPlots();
      } else {
        setMessageContent(response.data.message);
        setIsMessage(true);
        setMessageType("error");
        setSubLoading(false);
      }
    } catch (error) {
      setMessageContent(error.response.data.message);
      setIsMessage(true);
      setMessageType("error");
      setSubLoading(false);
    }
  }
  if (isMessage) {
    return <CustomMessage />;
  }
  if (subLoading) {
    return <Spinner />;
  }
  return (
    <div>
      <div className="text-xl pt-1">Adhoc Agents({total})</div>
      <div className="bg-white mt-4 p-6">
        <div className="flex items-start py-2  justify-between">
          <div></div>
        </div>
        <div
          className={` ${styles.tablewrp} relative scroll-div overflow-scroll mt-4 shadow-md  w-100% sm:rounded-lg`}
        >
          {loading ? (
            <div className=" m-12  justify-items-center justify-center">
              {" "}
              <PulseLoader
                className=" m-12 justify-center"
                color={"#46A576"}
                loading={loading}
                // cssOverride={override}
                size={14}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <table
              className={` w-full  text-sm text-left text-gray-500 dark:text-gray-400`}
            >
              <thead className="text-xs sticky top-0 right-0 left-0 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    AST_UID
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Agent_Name
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Agent_ID
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    FirstName
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    LastName
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Gender
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Email
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Phone
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Age
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Location
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Lga
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    State
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Image_Url
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    ID_Card_Front
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    ID_Card_Back
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    UpdatedAT
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    CreatedAT
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap"></th>
                </tr>
              </thead>
              <tbody className=" pt-8">
                {demoPlots.length &&
                  demoPlots.map((adhoc, index) => (
                    <tr
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 pt-8  hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer"
                      key={index}
                    >
                      <TableData>{adhoc?.AST_UID}</TableData>
                      <TableData>{adhoc?.Agent_Name}</TableData>
                      <TableData>{adhoc?.Agent_ID}</TableData>
                      <TableData>{adhoc?.FirstName}</TableData>
                      <TableData>{adhoc?.LastName}</TableData>
                      <TableData>{adhoc?.Gender}</TableData>
                      <TableData>{adhoc?.Email}</TableData>
                      <TableData>{adhoc?.Phone}</TableData>
                      <TableData>{adhoc?.Age}</TableData>
                      <TableData>{adhoc?.Location}</TableData>
                      <TableData>{adhoc?.Lga}</TableData>
                      <TableData>{adhoc?.State}</TableData>
                      <TableData>{adhoc?.Image_Url}</TableData>
                      <TableData>{adhoc?.ID_Card_Front}</TableData>
                      <TableData>{adhoc?.ID_Card_Back}</TableData>
                      <TableData>{adhoc?.UpdatedAT}</TableData>
                      <TableData>{adhoc?.CreatedAT}</TableData>
                      <div className="flex flex-row items-center justify-center px-6 py-2">
                        {adhoc.Status !== "Active" && (
                          <button
                            className="bg-primary text-white text-center text-xs flex py-2 px-3 rounded-lg focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={() => onApprove(adhoc?.AST_UID)}
                          >
                            Approve
                          </button>
                        )}
                      </div>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
        <nav
          className="flex items-center justify-between pt-4"
          aria-label="Table navigation"
        >
          <div className="flex items-center">
            <div className="mt-4 ">
              <form>
                {" "}
                <label
                  // for="countries"
                  className="block mb-2 text-sm font-regular text-gray-900 dark:text-white"
                >
                  No of Data Per Page
                </label>
                <select
                  id="countries"
                  className=" border border-gray-200 text-gray-500 text-sm rounded-md font-light focus:ring-green-500 focus:border-green-400 block w-full p-2.5 "
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <option selected>{limit}</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                </select>
              </form>
            </div>
          </div>

          <div className="flex items-center gap-4">
            <div className=" gap-4 ">
              <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                Showing{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {start}-{end}
                </span>{" "}
                of{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {total}
                </span>
              </span>
            </div>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"inline-flex items-center -space-x-px"}
              pageClassName={
                "px-3 py-2 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              pageLinkClassName={""}
              previousClassName={
                "block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              previousLinkClassName={""}
              nextClassName={
                "block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              nextLinkClassName={""}
              breakClassName={
                "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              breakLinkClassName={
                "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              activeClassName={
                "z-10 px-3 py-2 leading-tight text-white border border-blue-400 bg-primary hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
              }
            />
          </div>
        </nav>
      </div>
    </div>
  );
}
