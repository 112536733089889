import React from "react";
import styles from "./farmer.module.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { HiOutlineSearch } from "react-icons/hi";
import { api, header } from "../../../lib/queries/axiosInstance";
import PulseLoader from "react-spinners/PulseLoader";
// import { useEnumerationProvider } from "../../../Context/EnumerationContext";
import ReactPaginate from "react-paginate";
import { useAuthContext } from "../../../Context/AuthContext";

const TableData = ({ children }) => <td className="px-6 py-2">{children}</td>;
export default function AllFarmers() {
  // const { authUser } = useAuthContext()
  // console.log(authUser)

  // let authUserLocation = "kaduna";

  const [limit, setLimit] = useState(100);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(start + limit);
  const [pageCount, setpageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [enumerations, setEnumerations] = useState([]);
  const [searchResult, setSearchResult] = useState("");

  const fetchSearch = async () => {
    setLoading(true);

    const searchedData = await api.get(`Enumeration/search/${searchResult}`, {
      headers: header(),
    });
    setEnumerations(searchedData.data.data);
    setLoading(false);
  };

  const handleSearch = () => {
    fetchSearch();
  };

  useEffect(() => {
    setLoading(true);
    const getEnumerations = async () => {
      const res = await api.get(`Enumeration/1/${limit}`, {
        headers: header(),
      });

      const data = await res.data;
      console.log(data);

      const total = data.data.total;
      setTotal(total);
      setpageCount(Math.ceil(total / limit));
      setEnumerations(data.data.content);
      setLoading(false);
    };

    getEnumerations();
  }, [limit]);

  const fetchEnums = async (start) => {
    setLoading(true);
    const res = await api.get(`Enumeration/${start}/${limit}`, {
      headers: header(),
    });

    const data = await res.data;
    setLoading(false);
    const resData = data.data.content;
    // console.log(data.data);
    return resData;
  };

  // const fetchEnums = async (start) => {
  //   setLoading(true);
  //   const res = await fetch(
  //     `https://kbwprjs.com/staging/api/Enumeration/${start}/${limit}`
  //   );
  //   const data = await res.json();
  //   setLoading(false);
  //   const resData = data.data.content;
  //   // console.log(data.data);
  //   return resData;
  // };

  // const handleSelect = async (data) => {
  //   const selectedLimit = e.target.value
  //   setLimit(selectedLimit)
  // }

  const handlePageClick = async (data) => {
    // console.log(data.selected);

    let startCounter = data.selected + 1;
    let startr = startCounter * limit - limit;
    let endr = startr + limit;

    // let
    //   console.log(start);
    //   console.log(start + limit);
    const enums = await fetchEnums(startr);

    setEnd(endr);
    setStart(startr);
    setEnumerations(enums);
    // console.log(enums)
    // return startr;
    // scroll to the top
    //window.scrollTo(0, 0)
  };

  return (
    <div>
      <div className="text-xl pt-1">All Household</div>
      <div className="bg-white mt-4 p-6">
        <div className="flex items-start py-2  justify-between">
          <div className={"flex gap-2 items-center"}>
            <div>
              <label
                // for="countries"
                className="block mb-2 text-sm font-regular text-gray-900 dark:text-white "
              >
                NIN Status
              </label>

              <select
                id="countries"
                className=" border border-gray-200 text-gray-500 text-sm rounded-md font-light focus:ring-green-500 focus:border-green-500 block w-full p-2.5 "
              >
                <option selected>All NIN Status </option>
                <option value="US">Have NIN</option>
                <option value="CA">Don't have NIN</option>
              </select>
            </div>

            <div>
              <label
                // for="countries"
                className="block mb-2 text-sm font-regular text-gray-900 dark:text-white"
              >
                Gender
              </label>
              <select
                id="countries"
                className=" border border-gray-200 text-gray-500 text-sm rounded-md font-light focus:ring-green-500 focus:border-green-400 block w-full p-2.5 "
              >
                <option selected>All Gender </option>
                <option value="US">Male</option>
                <option value="CA">Female</option>
              </select>
            </div>

            <div>
              <label
                // for="countries"
                className="block mb-2 text-sm font-regular text-gray-900 dark:text-white"
              >
                State
              </label>
              <select
                id="countries"
                className=" border border-gray-200 text-gray-500 text-sm rounded-md font-light focus:ring-green-500 focus:border-green-400 block w-full p-2.5 "
              >
                <option selected>Abuja </option>
                <option value="US">Kaduna</option>
                <option value="CA">Lagos</option>
              </select>
            </div>

            <div>
              <label
                // for="countries"
                className="block mb-2 text-sm font-regular text-gray-900 dark:text-white"
              >
                Cluster
              </label>
              <select
                id="countries"
                className=" border border-gray-200 text-gray-500 text-sm rounded-md font-light focus:ring-green-500 focus:border-green-500 block w-full p-2.5 "
              >
                <option selected>All Clusters </option>
                <option value="US">Cluster 001</option>
                <option value="CA">Cluster 002</option>
              </select>
            </div>
          </div>

          {/* <div className="pb-4 bg-white dark:bg-gray-900">
        <label for="table-search" className="sr-only">Search</label>
        <div className="relative mt-1">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
            </div>
            <input type="text" id="table-search" className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items"/>
        </div>
          </div> */}

          <div className="flex items-center ">
            <div className="relative">
              <HiOutlineSearch
                fontSize={20}
                className="text-gray-400 absolute top-1/2 left-3 -translate-y-1/2"
              />
              <input
                type="text"
                name="searchResult"
                value={searchResult}
                onChange={(e) => setSearchResult(e.target.value)}
                placeholder="Search..."
                className="text-sm focus:ring-green-500 focus:border-green-400   active:outline-none border border-gray-300 w-[24rem] h-10 pl-11 pr-4 rounded-md"
              />
            </div>

            <div className="p-6">
              <button
                className="bg-primary w-full  text-white py-3 px-10 rounded-lg focus:outline-none focus:shadow-outline"
                type="button"
                onClick={handleSearch}
              >
                {loading ? "Loading..." : "Search"}
              </button>
            </div>
          </div>
        </div>

        <div
          className={` ${styles.tablewrp} relative scroll-div overflow-scroll mt-4 shadow-md  w-100% sm:rounded-lg`}
        >
          {loading ? (
            <div className=" m-12  justify-items-center justify-center">
              {" "}
              <PulseLoader
                className=" m-12 justify-center"
                color={"#46A576"}
                loading={loading}
                // cssOverride={override}
                size={14}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <table
              className={` w-full  text-sm text-left text-gray-500 dark:text-gray-400`}
            >
              <thead className="text-xs sticky top-0 right-0 left-0 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  {/* <th scope="col" className="p-4">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label for="checkbox-all-search" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </th> */}

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    A3 Farmers ID
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    A3 First Name
                  </th>
                  <th scope="col" className="px-6 whitespace-nowrap  py-3">
                    A4 Middle Name
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    A5 Last Name
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    A6 Gender
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    A7 Have NIN
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    A8 D.O.B
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    A9 Phone Number
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    A10 Marital Status
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    A11 Eductaion Level
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    A12 Household Size
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    A13 House Status
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    B1 State
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    B2 LGA
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    B3 Electoral Ward
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    B4 Community
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    B5 Residential Address
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    B6 Why Live in this community
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    B7 Farmer House cordinate
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    B8 Nearest Landmark
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    C1 Means of Identification
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    C2 ID Front View
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    C3 ID Front View
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    C4 Farmer Photo
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    D1 How_Do_You_Fund_Raise_Capital_For_Your_Farm
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    D2 Do_you_have_a_bank_account
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    D3_Bank_Account_Number
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    D4_Bank_Name
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    D5_BVN_Number
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    E1_Do_You_have_Multiple_Farms
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    E2_Do_you_Own_the_land_you_Farm
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    E3_What_is_the_source_of_your_Farm_Labour
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    E4_what_is_your_Farm_Source_of_Water
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    E5_Farm_Photo
                  </th>
                  {/* <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  E6_Geo_Mapping
                </th> */}
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    E7_Farm_size
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    E8_Farm_Shape
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    E9_agricultural_Intervention
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    F1_Farming_type
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    F2_Farming_mode_sub_items
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    F3_challenges_affecting_you_as_a_Farmer
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    F4_farm_records
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    F5_Farmers_Association
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    F6_What_factors_can_improve_your_yield
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    F7_Where_do_you_sell_you_produce
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    G1_What_is_Farmer_Disability_Status
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    G2_Field_agents_comments
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    SEX_AGE_Category
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Disability_Category
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Displacemment_Category
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Cluster_ID
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    status
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className=" pt-8">
                {enumerations.length &&
                  enumerations.map((farmer) => (
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 pt-8  hover:bg-gray-50 dark:hover:bg-gray-600">
                      {/* <td className="w-4 p-4">
                      <div className="flex items-center">
                        <input
                          id="checkbox-table-search-1"
                          type="checkbox"
                          className="w-4 h-4 text-primary bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          for="checkbox-table-search-1"
                          className="sr-only"
                        >
                          checkbox
                        </label>
                      </div>
                    </td> */}

                      {/* <th
                      scope="row"
                      className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {farmer.FirstName}
                    </th> */}
                      {/* <td className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap">
                      {farmer.LastName}
                    </td> */}

                      <TableData>{farmer.UID}</TableData>
                      <TableData>{farmer.A3_What_is_your_First_Name}</TableData>
                      <TableData>
                        {farmer.A4_What_is_your_Middle_Name}
                      </TableData>
                      <TableData>{farmer.A5_What_is_your_Last_Name}</TableData>
                      <TableData>{farmer.A6_Household_Gender}</TableData>
                      <TableData>{farmer.A7_National_ID_Status}</TableData>
                      <TableData>
                        {farmer.A8_What_is_your_Date_of_Birth}{" "}
                      </TableData>
                      <TableData>
                        {farmer.A9_What_is_your_Phone_Number}
                      </TableData>
                      <TableData>
                        {farmer.A10_What_is_your_Marital_Status}
                      </TableData>
                      <TableData>
                        {farmer.A11_What_is_your_Educational_level}
                      </TableData>
                      <TableData>{farmer.A12_Household_Size}</TableData>
                      <TableData>
                        {farmer.A13_What_is_your_status_in_your_household}
                      </TableData>

                      <TableData>
                        {farmer.B1_What_is_your_state_of_residence}
                      </TableData>
                      <TableData>
                        {farmer.B2_What_is_your_LGA_of_residence}
                      </TableData>
                      <TableData>
                        {farmer.B3_What_is_your_Electoral_Ward_of_Residence}
                      </TableData>
                      <TableData>
                        {farmer.B4_What_is_your_Community_of_Residence}
                      </TableData>
                      <TableData>
                        {farmer.B5_What_is_your_Residential_Address}
                      </TableData>
                      <TableData>
                        {farmer.B6_Why_do_you_live_in_this_community}
                      </TableData>
                      <TableData>
                        {farmer.B7_Farmers_House_coordinate}
                      </TableData>
                      <TableData>{farmer.B8_Nearest_Landmark}</TableData>

                      <TableData>
                        {farmer.C1_What_is_your_Means_of_identification}
                      </TableData>
                      <TableData>
                        {farmer.C2_Farmer_ID_Front_View_Upload}
                      </TableData>
                      <TableData>
                        {farmer.C3_Farmer_ID_Back_View_Upload}
                      </TableData>
                      <TableData>{farmer.C4_Farmer_Photo}</TableData>
                      <TableData>
                        {farmer.D1_How_Do_You_Fund_Raise_Capital_For_Your_Farm}
                      </TableData>
                      <TableData>
                        {farmer.D2_Do_you_have_a_bank_account}
                      </TableData>
                      <TableData>{farmer.D3_Bank_Account_Number}</TableData>
                      <TableData>{farmer.D4_Bank_Name}</TableData>
                      <TableData>{farmer.D5_BVN_Number}</TableData>
                      <TableData>
                        {farmer.E1_Do_You_have_Multiple_Farms}
                      </TableData>
                      <TableData>
                        {farmer.E2_Do_you_Own_the_land_you_Farm}
                      </TableData>
                      <TableData>
                        {farmer.E3_What_is_the_source_of_your_Farm_Labour}
                      </TableData>
                      <TableData>
                        {farmer.E4_what_is_your_Farm_Source_of_Water}
                      </TableData>
                      <TableData>{farmer.E5_Farm_Photo}</TableData>
                      {/* <TableData>{farmer.E6_Geo_Mapping}</TableData> */}
                      <TableData>{farmer.E7_Farm_size}</TableData>
                      <TableData>{farmer.E8_Farm_Shape}</TableData>
                      <TableData>
                        {
                          farmer.E9_Are_you_Benefiting_from_any_agricultural_Intervention
                        }
                      </TableData>
                      <TableData>
                        {farmer.F1_What_type_of_Farming_do_you_engage_in}
                      </TableData>
                      <TableData>{farmer.F2_Farming_mode_sub_items}</TableData>
                      <TableData>
                        {
                          farmer.F3_What_are_the_challenges_affecting_you_as_a_Farmer
                        }
                      </TableData>
                      <TableData>
                        {farmer.F4_Do_you_keep_your_farm_records}
                      </TableData>
                      <TableData>
                        {farmer.F5_Are_you_a_member_of_any_Farmers_Association}
                      </TableData>
                      <TableData>
                        {farmer.F6_What_factors_can_improve_your_yield}
                      </TableData>
                      <TableData>
                        {farmer.F7_Where_do_you_sell_you_produce}
                      </TableData>
                      <TableData>
                        {farmer.G1_What_is_Farmer_Disability_Status}
                      </TableData>
                      <TableData>{farmer.G2_Field_agents_comments}</TableData>
                      <TableData>{farmer.SEX_AGE_Category}</TableData>
                      <TableData>{farmer.Disability_Category}</TableData>
                      <TableData>{farmer.Displacemment_Category}</TableData>
                      <TableData>{farmer.Cluster_ID}</TableData>
                      <TableData>{farmer.Status}</TableData>

                      <td className="px-6 py-2">
                        <span className="flex items-center gap-2">
                          <Link to={`/farmer-details/${farmer.UID}`}>
                            <GrView />
                          </Link>
                          <Link to={`/edit-household-details/${farmer.UID}`}>
                            <FiEdit />
                          </Link>
                          <MdDeleteOutline fontSize={20} />
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
        <nav
          className="flex items-center justify-between pt-4"
          aria-label="Table navigation"
        >
          <div className="flex items-center">
            <div className="mt-4 ">
              <form>
                {" "}
                <label
                  // for="countries"
                  className="block mb-2 text-sm font-regular text-gray-900 dark:text-white"
                >
                  No of Data Per Page
                </label>
                <select
                  id="countries"
                  className=" border border-gray-200 text-gray-500 text-sm rounded-md font-light focus:ring-green-500 focus:border-green-400 block w-full p-2.5 "
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <option selected>{limit}</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                </select>
              </form>
            </div>
          </div>

          <div className="flex items-center gap-4">
            <div className=" gap-4 ">
              <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                Showing{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {start}-{end}
                </span>{" "}
                of{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {total}
                </span>
              </span>
            </div>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"inline-flex items-center -space-x-px"}
              pageClassName={
                "px-3 py-2 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              pageLinkClassName={""}
              previousClassName={
                "block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              previousLinkClassName={""}
              nextClassName={
                "block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              nextLinkClassName={""}
              breakClassName={
                "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              breakLinkClassName={
                "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              activeClassName={
                "z-10 px-3 py-2 leading-tight text-white border border-blue-400 bg-primary hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
              }
            />
          </div>
        </nav>
      </div>
    </div>
  );
}
