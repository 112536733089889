import { useEffect, useState } from "react";
import { baseURL } from "../constants/URL";

const useEnumeration = (id) => {
  //   const { id } = useParams();
  const [enumerationDetail, setEnumerationDetail] = useState(null);
  const BASE_URL = `${baseURL}/Enumeration`;

  const singleEnumeration = async (id) => {
    const result = await fetch(`${BASE_URL}/${id}`);
    result.json().then((json) => {
      setEnumerationDetail(json.data);
    });
  };

  useEffect(() => {
    id && singleEnumeration(id);
  }, [id]);

  return { enumerationDetail };
};

export default useEnumeration;
