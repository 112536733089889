import { useEffect, useState } from "react";
import { api, header } from "../../lib/queries/axiosInstance";


const useEnumerationDetails = (id) => {


  const [enumerationDetail, setEnumerationDetail] = useState(null);
  const [loading, setLoading] = useState(false)

  const singleEnumeration = async (id) => {
  

    try {
        setLoading(true)
        const res = await api.get(`Enumeration/${id}`, {
            headers: header(),
          })
          
        const data  = await res.data;
        console.log(data)
        setEnumerationDetail(data.data);
        setLoading(false);
    } catch (err) {
        setLoading(false)
    }

  };



  useEffect(() => {
    id && singleEnumeration(id);
  }, [id]);

  return { enumerationDetail };
};

export default useEnumerationDetails;
