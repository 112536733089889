import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import MapContainer from "../../components/Layouts/MapContainer";
import useEnumerationDetails from "../../hooks/enumerations/useEnumerationDetails";
import Modal from "react-modal";
import { FaRegTimesCircle } from "react-icons/fa";

export default function HouseHoldViewMore() {
  const [onShowFrontID, setOnShowFrontID] = useState(false);
  const [onShowBackID, setOnShowBackID] = useState(false);
  const [onShowFarmPhoto, setOnShowFarmPhoto] = useState(false);
  const [onShowLocation, setOnShowLocation] = useState(false);
  const { id } = useParams();
  const { enumerationDetail } = useEnumerationDetails(id);
  // const {
  //   A3_What_is_your_First_Name,
  //   A4_What_is_your_Middle_Name,
  //   A5_What_is_your_Last_Name,
  //   A6_Household_Gender,
  //   A7_National_ID_Status,
  //   A8_What_is_your_Date_of_Birth,
  //   A9_What_is_your_Phone_Number,
  //   A10_What_is_your_Marital_Status,
  //   A11_What_is_your_Educational_level,
  //   A12_Household_Size,
  //   A13_What_is_your_status_in_your_household,
  //   B1_What_is_your_state_of_residence,
  //   B2_What_is_your_LGA_of_residence,
  //   B3_What_is_your_Electoral_Ward_of_Residence,
  //   B4_What_is_your_Community_of_Residence,
  //   B5_What_is_your_Residential_Address,
  //   C4_Farmer_Photo,
  // } = enumerationDetail;

  const listTitle = "text-xs font-medium my-2 tracking-wider";
  const listItem = "text-xs font-light";

  return (
    <div style={{ width: "100%" }} className="p-4">
      <div className="mt-4 ">
        <div className="flex items-center mb-5">
          <Link
            className="flex items-center cursor-pointer mr-4"
            to={`/household`}
          >
            <AiOutlineLeft color={"black"} size={25} />
            <p className="text-primary">Back</p>
          </Link>
          <h1 className="text-lg font-semibold ">Household Details</h1>
        </div>
        {enumerationDetail && (
          <div className="w-full">
            <div>
              {/* First Section */}
              <div className="bg-white rounded-sm shadow-lg w-full px-10 pt-10 pb-4 flex items-start justify-between">
                {/* First Col */}
                <div className="w-[22%]">
                  {/* Image Col 1 */}
                  <div>
                    <img
                      src={enumerationDetail?.C4_Farmer_Photo}
                      className="h-[250px] w-[150px] rounded-sm"
                    />
                    {/* <img
                        src={enumerationDetail?.A2_Consent_Signature}
                        className="h-[100px] w-[150px] rounded-sm"
                      /> */}
                  </div>
                </div>
                {/* Seecond Col */}
                <div className="w-[78%]">
                  {/* First Row */}
                  <div className="w-full grid lg:grid-cols-3 gap-5">
                    {/* Col 1 */}
                    <ul className="h-full flex-col">
                      <li className={listTitle}>
                        A3_First Name:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.A3_What_is_your_First_Name}
                        </span>
                      </li>
                      <li className={listTitle}>
                        A4_Middle Name:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.A4_What_is_your_Middle_Name}
                        </span>
                      </li>
                      <li className={listTitle}>
                        A5_Last Name:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.A5_What_is_your_Last_Name}
                        </span>
                      </li>
                      <li className={listTitle}>
                        A6_Gender:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.A6_Household_Gender}
                        </span>
                      </li>
                      <li className={listTitle}>
                        A7_National ID:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.A7_National_ID_Status}
                        </span>
                      </li>
                    </ul>
                    {/* Col 2 */}
                    <ul className="h-full flex-col">
                      <li className={listTitle}>
                        A8_D.O.B:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.A8_What_is_your_Date_of_Birth}
                        </span>
                      </li>
                      <li className={listTitle}>
                        A9_Phone:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.A9_What_is_your_Phone_Number}
                        </span>
                      </li>
                      <li className={listTitle}>
                        A10_Marital Status:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.A10_What_is_your_Marital_Status}
                        </span>
                      </li>
                      <li className={listTitle}>
                        A11_Educational Level:{" "}
                        <span className={listItem}>
                          {
                            enumerationDetail?.A11_What_is_your_Educational_level
                          }
                        </span>
                      </li>
                      <li className={listTitle}>
                        A12_HouseHold Size:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.A12_Household_Size}
                        </span>
                      </li>
                      <li className={listTitle}>
                        A13_Household Status:{" "}
                        <span className={listItem}>
                          {
                            enumerationDetail?.A13_What_is_your_status_in_your_household
                          }
                        </span>
                      </li>
                    </ul>
                    {/* Col 3 */}
                    <ul className="h-full flex-col">
                      <li className={listTitle}>
                        B1_State of Residence:{" "}
                        <span className={listItem}>
                          {
                            enumerationDetail?.B1_What_is_your_state_of_residence
                          }
                        </span>
                      </li>
                      <li className={listTitle}>
                        B2_LGA of Residence:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.B2_What_is_your_LGA_of_residence}
                        </span>
                      </li>
                      <li className={listTitle}>
                        B3_Ward of Residence:{" "}
                        <span className={listItem}>
                          {
                            enumerationDetail?.B3_What_is_your_Electoral_Ward_of_Residence
                          }
                        </span>
                      </li>
                      <li className={listTitle}>
                        B4_Community of Residence:{" "}
                        <span className={listItem}>
                          {
                            enumerationDetail?.B4_What_is_your_Community_of_Residence
                          }
                        </span>
                      </li>
                      <li className={listTitle}>
                        B5_Residential Address:{" "}
                        <span className={listItem}>
                          {
                            enumerationDetail?.B5_What_is_your_Residential_Address
                          }
                        </span>
                      </li>
                    </ul>
                  </div>
                  {/* Second Row */}
                  <div className="w-full grid lg:grid-cols-3 gap-5 mt-10">
                    {/* Col 1 */}
                    <ul className="h-full flex-col">
                      <li className={listTitle}>
                        B6_ Why Community:{" "}
                        <span className={listItem}>
                          {
                            enumerationDetail?.B6_Why_do_you_live_in_this_community
                          }
                        </span>
                      </li>
                      <li className={listTitle}>
                        B7_ House Coordinate:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.B7_Farmers_House_coordinate}
                        </span>
                      </li>
                      <li className={listTitle}>
                        B7.1_ Coordinate Accuracy:{" "}
                        <span className={listItem}>
                          {
                            enumerationDetail?.B_7_1_Farmers_House_Coordinate_Accuracy
                          }
                        </span>
                      </li>
                      <li className={listTitle}>
                        B8_ Nearest Landmark:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.B8_Nearest_Landmark}
                        </span>
                      </li>
                    </ul>
                    {/* Col 2 */}
                    <ul className="h-full flex-col">
                      <li className={listTitle}>
                        C1_ Means of Identification:{" "}
                        <span className={listItem}>
                          {
                            enumerationDetail?.C1_What_is_your_Means_of_identification
                          }
                        </span>
                      </li>
                      <li className={listTitle}>
                        C2_ ID Front View:{" "}
                        <span
                          className={
                            "font-bold text-[11px] text-primary cursor-pointer"
                          }
                          onClick={() => setOnShowFrontID(true)}
                        >
                          {"Click to View"}
                        </span>
                      </li>
                      <li className={listTitle}>
                        C3_ ID Back View:{" "}
                        <span
                          className={
                            "font-bold text-[11px] text-primary cursor-pointer"
                          }
                          onClick={() => setOnShowBackID(true)}
                        >
                          {"Click to View"}
                        </span>
                      </li>
                    </ul>
                    {/* Col 3 */}
                    <ul className="h-full flex-col">
                      <li className={listTitle}>
                        D1_ Hou do you raise funds for farm:{" "}
                        <span className={listItem}>
                          {
                            enumerationDetail?.D1_How_Do_You_Fund_Raise_Capital_For_Your_Farm
                          }
                        </span>
                      </li>
                      <li className={listTitle}>
                        D2_ Do you have a bank Account:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.D2_Do_you_have_a_bank_account}
                        </span>
                      </li>
                      <li className={listTitle}>
                        D3_ Bank Account Number:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.D3_Bank_Account_Number}
                        </span>
                      </li>
                      <li className={listTitle}>
                        D4_ Bank Name:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.D4_Bank_Name}
                        </span>
                      </li>
                      <li className={listTitle}>
                        D5_ BVN Number:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.D5_BVN_Number}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Asset Section */}
              <div className="bg-white rounded-sm shadow-lg w-full px-10 pt-10 pb-4 mt-10">
                <h1 className="font-semibold text-lg mb-5">Asset Details</h1>
                {/* grid grid-cols-2 gap-5 */}
                <div className="w-full flex items-start justify-between">
                  {/* First Col */}
                  <div className="w-[35%]">
                    <ul className="h-full flex-col">
                      <li className={listTitle}>
                        E1_ Do you have multiple farm:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.E1_Do_You_have_Multiple_Farms}
                        </span>
                      </li>
                      <li className={listTitle}>
                        E2_ Do you own the land you farm:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.E2_Do_you_Own_the_land_you_Farm}
                        </span>
                      </li>
                      <li className={listTitle}>
                        E3_ Source of Farm Labour:{" "}
                        <span className={listItem}>
                          {
                            enumerationDetail?.E3_What_is_the_source_of_your_Farm_Labour
                          }
                        </span>
                      </li>
                      <li className={listTitle}>
                        E4_ Source of farm water:{" "}
                        <span className={listItem}>
                          {
                            enumerationDetail?.E4_what_is_your_Farm_Source_of_Water
                          }
                        </span>
                      </li>
                      <li className={listTitle}>
                        E5_ Farm Photo:{" "}
                        <span
                          className={
                            "font-bold text-[11px] text-primary cursor-pointer"
                          }
                          onClick={() => setOnShowFarmPhoto(true)}
                        >
                          {"Click to View"}
                        </span>
                      </li>

                      {/* <img
                        src={enumerationDetail.E5_Farm_Photo}
                        className="h-[280px] w-[220px] rounded-sm my-10"
                      /> */}
                      <li className={listTitle}>
                        F1_ Farming Type:{" "}
                        <span className={listItem}>
                          {
                            enumerationDetail?.F1_What_type_of_Farming_do_you_engage_in
                          }
                        </span>
                      </li>
                      <li className={listTitle}>
                        F2_ Farming Mode sub Items:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.F2_Farming_mode_sub_items}
                        </span>
                      </li>
                      <li className={listTitle}>
                        F3_ Challenges affection you as a farmer:{" "}
                        <span className={listItem}>
                          {
                            enumerationDetail?.F3_What_are_the_challenges_affecting_you_as_a_Farmer
                          }
                        </span>
                      </li>
                      <li className={listTitle}>
                        F4_ Do you keep your farm records:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.F4_Do_you_keep_your_farm_records}
                        </span>
                      </li>
                      <li className={listTitle}>
                        F5_ Member of any farmer's association:{" "}
                        <span className={listItem}>
                          {
                            enumerationDetail?.F5_Are_you_a_member_of_any_Farmers_Association
                          }
                        </span>
                      </li>
                      <li className={listTitle}>
                        F6_ Member of any farmer's association:{" "}
                        <span className={listItem}>
                          {
                            enumerationDetail?.F6_What_factors_can_improve_your_yield
                          }
                        </span>
                      </li>
                      <li className={listTitle}>
                        F7_ Where do you sell your produce:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.F7_Where_do_you_sell_you_produce}
                        </span>
                      </li>
                    </ul>
                  </div>
                  {/* Second Col */}
                  <div className="w-[60%]">
                    <ul className="h-full flex-col">
                      <li className={listTitle}>
                        E6_ Geo Mapping:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.E6_Geo_Mapping}
                        </span>
                      </li>
                      <li className={listTitle}>
                        E7_ Farm Size:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.E7_Farm_size}
                        </span>
                      </li>
                      <li className={listTitle}>
                        E8_ Farm Shape:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.E8_Farm_Shape}
                        </span>
                      </li>
                      <li className={listTitle}>
                        E9_ Agricultural Intervention:{" "}
                        <span className={listItem}>
                          {
                            enumerationDetail?.E9_Are_you_Benefiting_from_any_agricultural_Intervention
                          }
                        </span>
                      </li>
                      <li className={listTitle}>
                        G1_ Disability Status:{" "}
                        <span className={listItem}>
                          {
                            enumerationDetail?.G1_What_is_Farmer_Disability_Status
                          }
                        </span>
                      </li>
                      <li className={listTitle}>
                        G2_ Field agent comments:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.G2_Field_agents_comments}
                        </span>
                      </li>
                      {/* Map */}
                      <li className={listTitle}>
                        _House Location:{" "}
                        <span
                          className={
                            "font-bold text-[11px] text-primary cursor-pointer"
                          }
                          onClick={() => setOnShowLocation(true)}
                        >
                          {"Click to View"}
                        </span>
                      </li>
                      <li className={listTitle}>
                        _ Sex age category:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.SEX_AGE_Category}
                        </span>
                      </li>
                      <li className={listTitle}>
                        _ Disability_Category:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.Disability_Category}
                        </span>
                      </li>
                      <li className={listTitle}>
                        _ Displacement Category:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.Displacemment_Category}
                        </span>
                      </li>
                      <li className={listTitle}>
                        _ Cluster ID:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.Cluster_ID}
                        </span>
                      </li>
                      <li className={listTitle}>
                        _ Farm_Cordinat_lat:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.Farm_Cordinat_lat}
                        </span>
                      </li>
                      <li className={listTitle}>
                        _ Farm_Cordinat_lng:{" "}
                        <span className={listItem}>
                          {enumerationDetail?.Farm_Cordinat_lng}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* FrontID Card */}
            <div>
              <Modal
                isOpen={onShowFrontID}
                onRequestClose={() => setOnShowFrontID(false)}
              >
                <button onClick={() => setOnShowFrontID(false)}>
                  <FaRegTimesCircle className="text-red-600" size={30} />
                </button>
                <div className="w-full flex justify-center items-center">
                  <img
                    src={enumerationDetail?.C2_Farmer_ID_Front_View_Upload}
                    className="h-[90%]  w-[80%]"
                  />
                </div>
              </Modal>
            </div>
            {/* Back View Card ID */}
            <div>
              <Modal
                isOpen={onShowBackID}
                onRequestClose={() => setOnShowBackID(false)}
              >
                <button onClick={() => setOnShowBackID(false)}>
                  <FaRegTimesCircle className="text-red-600" size={30} />
                </button>
                <div className="w-full flex justify-center items-center">
                  <img
                    src={enumerationDetail?.C3_Farmer_ID_Back_View_Upload}
                    className="h-[90%]  w-[80%]"
                  />
                </div>
              </Modal>
            </div>
            {/* Farm Photo */}
            <div>
              <Modal
                isOpen={onShowFarmPhoto}
                onRequestClose={() => setOnShowFarmPhoto(false)}
              >
                <button onClick={() => setOnShowFarmPhoto(false)}>
                  <FaRegTimesCircle className="text-red-600" size={30} />
                </button>
                <div className="w-full flex justify-center items-center">
                  <img
                    src={enumerationDetail?.E5_Farm_Photo}
                    className="h-[90%]  w-[80%]"
                  />
                </div>
              </Modal>
            </div>
            {/* House Location */}
            <div>
              <Modal
                isOpen={onShowLocation}
                onRequestClose={() => setOnShowLocation(false)}
              >
                <button onClick={() => setOnShowLocation(false)}>
                  <FaRegTimesCircle className="text-red-600" size={30} />
                </button>
                <div className="w-[70%] h-[400px] mx-auto">
                  <div className="my-10">
                    <MapContainer
                      YOUR_LATITUDE={parseFloat(
                        enumerationDetail?.Farm_Cordinat_lat
                      )}
                      YOUR_LONGITUDE={parseFloat(
                        enumerationDetail?.Farm_Cordinat_lng
                      )}
                    />
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
