import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useFieldAgent from "../../../hooks/useFieldAgents";
import { baseURL } from "../../../constants/URL";
import faimage from "../../../assets/images/fa-avatar.jpeg"

// const agentData = () => {
//   return (
//     <div>
//       <span className="flex gap-2 mt-2 items-center">
//         <p>Email:</p>
//         <p className="font-extralight">{fieldAgentDetail.Email}</p>
//       </span>
//     </div>
//   );
// }

export default function FieldAgentDetails() {
  const [openModal, setOpenModal] = useState(false);
  const [approvedMsg, setApprovedMsg] = useState(null);

  const handleOnClose = () => setOpenModal(false);
  const { id } = useParams();
  const { fieldAgentDetail } = useFieldAgent(id);

  const APPROVE_AGENT_URL = `${baseURL}/FieldAgents/changeStatus/${id}`;
  const APPROVE_STATUS = 1;

  const approveAgent = async (id) => {
    // console.log('I SEE YOU BOSS');
    console.log(`${APPROVE_AGENT_URL}/${APPROVE_STATUS}`);
    try {
      const result = await fetch(`${APPROVE_AGENT_URL}/${APPROVE_STATUS}`);
      result.json().then((json) => {
        setApprovedMsg(json);
      });
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  useEffect(
    (id) => {
      id && approveAgent();
    },
    [id]
  );

  // console.log(approvedMsg);

  return (
    <div className="mt-4">
      <div className="text-xl pt-1">Field Agent Details</div>
      {fieldAgentDetail ? (
        <div className="grid lg:grid-cols-2 lg:grid-rows-1 ">
          <div className="bg-grey-100 rounded-lg p-6 ">
            <div className="bg-white rounded-lg p-6">
              {fieldAgentDetail.Status === "Pending" ? (
                <div className="flex justify-between">
                  {/* <div
                    className="h-28 w-28 rounded-full bg-sky-500 bg-cover bg-no-repeat bg-center"
                    style={{
                      backgroundImage:
                        'url("https://source.unsplash.com/80x80?face")',
                    }}
                  ></div> */}
                   <div
                    className="h-28 w-28 rounded-full bg-sky-500 bg-cover bg-no-repeat bg-center"
                  >
                    <img src={faimage} alt="Field Agent Avatar"></img>

                  </div>
                  <div className="p-6">
                    <button
                      data-modal-target="popup-modal"
                      data-modal-toggle="popup-modal"
                      className="bg-primary w-full  text-white py-3 px-10 rounded-lg focus:outline-none focus:shadow-outline"
                      type="button"
                      onClick={() => setOpenModal(true)}
                    >
                      Approve
                    </button>
                    {openModal && (
                      <Modal
                        onClose={handleOnClose}
                        agentApproval={approveAgent}
                        approvedMsg={approvedMsg}
                      />
                    )}
                  </div>
                </div>
              ) : (
                // <div
                //   className="h-28 w-28 rounded-full bg-sky-500 bg-cover bg-no-repeat bg-center"
                //   style={{
                //     backgroundImage:
                //       'url("https://source.unsplash.com/80x80?face")',
                //   }}
                // ></div>
                <div
                className="h-28 w-28 rounded-full bg-sky-500 bg-cover bg-no-repeat bg-center"
              >
                <img src={faimage} alt="Field Agent Avatar"></img>

              </div>
              )}
              <h2 className="text-2xl mt-2 font-regular">
                {fieldAgentDetail.FirstName} {fieldAgentDetail.LastName}
              </h2>
              <div className="gap-y-4 column-gap">
                <div className="mt-6">
                  <span className="flex gap-2 mt-2 items-center">
                    <p> Phone Number:</p>{" "}
                    <p className="font-extralight">{fieldAgentDetail.Phone}</p>
                  </span>
                </div>
                <div>
                  <span className="flex gap-2 mt-2 items-center">
                    <p>Email:</p>{" "}
                    <p className="font-extralight">{fieldAgentDetail.Email}</p>
                  </span>
                </div>

                <div>
                  <span className="flex gap-2 mt-2 items-center">
                    <p>State:</p>{" "}
                    <p className="font-extralight">
                      {fieldAgentDetail.StateAddress}
                    </p>
                  </span>
                </div>

                <div>
                  <span className="flex gap-2 mt-2 items-center">
                    <p>LGA:</p>{" "}
                    <p className="font-extralight">{fieldAgentDetail.Lga}</p>
                  </span>
                </div>
                <div>
                  <div>
                    <span className="flex gap-2 mt-2 items-center">
                      <p>State Address: </p>{" "}
                      <p className="font-extralight">
                        {fieldAgentDetail.StateAddress}
                      </p>
                    </span>
                  </div>

                  <span className="flex gap-2 mt-2 items-center">
                    <p>Date of Birth:</p>
                    <p className="font-extralight">
                      {fieldAgentDetail.DateOfBirth}
                    </p>
                  </span>
                </div>

                <div>
                  <span className="flex gap-2 mt-2 items-center">
                    <p>Guarantor Name: </p>{" "}
                    <p className="font-extralight">
                      {fieldAgentDetail.GuarantorName}
                    </p>
                  </span>
                </div>

                <div>
                  <span className="flex gap-2 mt-2 items-center">
                    <p>Relationship:</p>{" "}
                    <p className="font-extralight">
                      {fieldAgentDetail.Relationship}
                    </p>
                  </span>
                </div>

                <div>
                  <span className="flex gap-2 mt-2 items-center">
                    <p>Guarantor Phone:</p>{" "}
                    <p className="font-extralight">
                      {fieldAgentDetail.GuarantorPhone}
                    </p>
                  </span>
                </div>

                <div>
                  <span className="flex gap-2 mt-2 items-center">
                    <p>Guarantor Address:</p>{" "}
                    <p className="font-extralight">
                      {fieldAgentDetail.GuarantorAddress}
                    </p>
                  </span>
                </div>
              </div>

              <div>
                <span className="flex gap-2 mt-2 items-center">
                  <p>Date Of Registration:</p>{" "}
                  <p className="font-extralight">
                    {fieldAgentDetail.DateOfRegistration}
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>loading</p>
      )}
    </div>
  );
}

const Modal = ({ onClose, agentApproval, approvedMsg }) => {
  return (
    <div
      id="popup-modal"
      tabIndex="-1"
      className="fixed rounded-lg top-0 border-raidius left-0 right-0 z-50 bg-gray-600 bg-opacity-20 backdrop-blur-xs flex justify-center items-center p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full"
    >
      <div className="relative w-full h-full max-w-sm md:h-auto">
        <div className="relative bg-white rounded-lg  frounded-lg shadow dark:bg-gray-700">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-hide="popup-modal"
            onClick={onClose}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>

          <div className="p-6 text-center">
            <svg
              aria-hidden="true"
              className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            {approvedMsg?.code === 200 && (
              <div>
                <h3 className="mb-5 text-xl font-regular text-gray-700 dark:text-gray-400">
                  Agent Successfully Approved!
                </h3>
                <button
                  data-modal-hide="popup-modal"
                  type="button"
                  className="text-white bg-primary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-green-300  font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            )}
            {!approvedMsg && (
              <div>
                <div>
                  <h3 className="mb-5 text-xl font-regular text-gray-700 dark:text-gray-400">
                    Do you want to approve this Agent?
                  </h3>

                  <div className="flex justify-between">
                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                      onClick={onClose}
                    >
                      No, cancel
                    </button>
                    <button
                      data-modal-hide="popup-modal"
                      type="button"
                      className="text-white bg-primary hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                      onClick={agentApproval}
                    >
                      Confirm Approval
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
