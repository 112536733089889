import { useState, useCallback, useEffect } from "react";
import { api, header } from "../lib/queries/axiosInstance";
import Papa from "papaparse";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const useIndirectOutrechQuery = ({ state, lga }) => {
  const [limit, setLimit] = useState(100);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(start + limit);
  const [pageCount, setpageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [outreaches, setOutreaches] = useState([]);
  const [statusOptions, setStatusOptions] = useState("");
  const [advancedSearchOptions, setAdvancedSearchOptions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [searchResult, setSearchResult] = useState("");
  const [dateFilter, setDateFilter] = useState(`*${new Date().getFullYear()}*`);
  const [searchParams, setSearchParams] = useState({
    AgentID: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Gender: "",
    Community: "",
    MaritalStatus: "",
    AgeCategory: "",
    GenderAgeCategory: "",
    DisplacementStatus: "",
    DisablilityStatus: "",
    Education: "",
    Student: "",
    HouseholdStatus: "",
  });

  const fetchOutreaches = useCallback(async () => {
    const queryString = Object.entries(searchParams)
      .map(([key, value]) => (value ? `${key}=${value}` : ""))
      .filter(Boolean)
      .join("&");
    console.log(queryString);
    let params = "";
    params = `${dateFilter ? "CreatedAT=" + dateFilter : ""}${
      state ? "&State=" + state : ""
    }${lga ? "&LGA=" + lga : ""}${queryString ? "&" + queryString : ""}`;
    // console.log(`params`);
    try {
      setLoading(true);
      const res = await api.get(
        `indirectoutreach/search?${params}&start=${start}&limit=${limit}`,
        {
          headers: header(),
        }
      );
      const data = await res.data;
      console.log("res", res.data.data.content);
      if (data.code === 200) {
        const total = data.data.total;
        setTotal(total);
        setpageCount(Math.ceil(total / limit));
        setOutreaches(data.data.content);
      } else {
        setOutreaches([]);
        setTotal(0);
        setpageCount(0);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setOutreaches([]);
      setTotal(0);
      setpageCount(0);
      console.log(err);
    }
  }, [limit, start, dateFilter, state, lga]);
  async function onExport() {
    const tempLimit = 3000; // Number of items to fetch per request

    const pageCount = Math.ceil(total / tempLimit);
    const zip = new JSZip();
    try {
      for (let page = 0; page < pageCount; page++) {
        const tempStart = page * tempLimit;
        let params = `${dateFilter ? "CreatedAT=" + dateFilter : ""}${
          state ? "&State=" + state : ""
        }${lga ? "&LGA=" + lga : ""}&start=${tempStart}&limit=${tempLimit}`;
        setLoading(true);
        const res = await api.get(`indirectoutreach/search?${params}`, {
          headers: header(),
        });
        const data = await res.data; // Retrieved data from the backend
        const csvContent = Papa.unparse(data.data.content);
        zip.file(`outreaches_${page}.csv`, csvContent);
      }
      const currentTimestamp = new Date().toISOString().replace(/:/g, "-");
      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, `outreaches_${currentTimestamp}.zip`); // Download the ZIP file with timestamp
      setLoading(false); // Set loading state to false after finishing the export process
    } catch (err) {
      setLoading(false);
    }
  }
  useEffect(() => {
    setLoading(true);
    fetchOutreaches();
  }, [limit, start, dateFilter, state, lga]);
  const handleOnClose = () => setOpenModal(false);

  return {
    setOpenModal,
    openModal,
    handleOnClose,
    setLimit,
    outreaches,
    setSearchResult,
    searchResult,
    setOutreaches,
    fetchOutreaches,
    limit,
    loading,
    setLoading,
    start,
    setStart,
    end,
    setEnd,
    pageCount,
    setpageCount,
    total,
    setTotal,
    statusOptions,
    setStatusOptions,
    dateFilter,
    setDateFilter,
    onExport,
    advancedSearchOptions,
    setAdvancedSearchOptions,
    searchParams,
    setSearchParams,
  };
};

export default useIndirectOutrechQuery;
