import React from "react";

const SurveryContainer = ({ data }) => {
  const {
    Age,
    Sex,
    MaritalStatus,
    HouseHoldType,
    HeadOfHouseHold,
    Religion,
    Ethnicity,
    Indegigenity,
    DifficultySeeing,
    DifficultyHearing,
    DifficultyWalking,
    DifficultyRemembering,
    DifficultyWashingOver,
    DifficultyCommunication,
    HighestLevelOfEdu,
    PrimaryOccupation,
    SecondaryOccupation,
    MonthlyEarning,
    WhoPrepLandForCultivation,
    WhoDoesTheActualCultivation,
    WhoDoesWeeding,
    Harvesting,
    Processing,
    Storage,
    WhoSellCultivatedProduce,
    SweepingAndGenIndoorSan,
    FetchingWaterFirewood,
    Cooking,
    HouseChores,
    HouseRepairs,
    TakingCareOfSick,
    HomeVilante,
    HouseholdLeadership,
    ManagingHouseholdFinances,
    ChildBearing,
    ChildCare,
    ChildUpbringing,
    HoursOnDomOrRepRoles,
    CreditFinanceAgricultural,
    BusinessDevServices,
    VillageSavingsLoansAssociation,
    TrainingModernFarming,
    LandAgriculturalCommunity,
    LandDevelopmentOther,
    ExtensionAgents,
    Markets,
    AgriculturalInputs,
    PrimaryPurpose,
    LandAcquisition,
    LandOwnerGender,
    WomenOwnLand,
    WomenDecisionPower,
    BankAccount,
    NearestBankDistance,
    IncomeControl,
    FinancialSupport,
    MoneyLendingFacilities,
    MoneyLendingOptions,
    FarmerAssociationMember,
    LeadershipPosition,
    InfluentialPersonsCommunity,
    WhoDecideHouseholdIncomeUsed,
    WhoControlsDailyBudget,
    WhoDecidesHouseholdSavingsInvestments,
    WhoDecideWomenGirlsAllowedPaidJob,
    WhoDecidesDomesticWork,
    WhoDecideAccessWomenMenBoysGirlsBasicEducationInformation,
    WhoDecidesChildCareIssues,
    WhoDecideWhenWhereAccessHealthCare,
    WhoDecidesFamilySize,
    WhoDecidesChildMarriage,
    WhoDecidesLandsAgriculturePurpose,
    WhoDecidesCropsToProduce,
    LandPreparationHowMuchLandToCultivate,
    WhenWhatVarietySeedToPlant,
    WhoControlsProceedsFarmProduce,
    LandsForDevelopmentOtherThanAgriculturePurpose,
    CategoryIndividualsLeftOutDuringServiceDelivery,
    CategoryIndividualsMarginalizedDiscriminatedAgainst,
    CategoryIndividualsAbusedAssaulted,
    KindCommonAbuseAssaultExposed,
    ExperiencedFormAbuseAssault,
    KindAbuseAssaultExperienced,
    HavePWDsInCommunity,
    PerceptionOfDisabilityInCommunity,
    ChallengesFacedByPWDsInCommunity,
    HaveIDPsInCommunity,
    ChallengesFacedByIDPsInCommunity,
    WorryAboutFoodRunningOut,
    FoodNotLastNoMoneyToBuyMore,
    EatBalancedMeals,
    ReduceSizeOrSkipMeals,
    EatLessOrSkipMeals,
    ChildrenEatLessOrSkipMeals,
    EatLessOrSippedMeals,
    ChildrenLoseWeight,
    NotEatEntireDay,
    ChildNotEatEntireDay,
    BreastfeedDuration,
    GrownOwnFoodBeforeJuriya,
    PercentageFoodFromOwnLand,
    DistanceToNearestMarket,
    MarketsHaveDesiredFoods,
    MarketsHaveReasonablePrices,
    AbleToTradeWithFarmers,
    HealthIssues,
    DisabilityRequiresFeedingAssistance,
    MedicalProfessionalVisits,
  } = data || {};
  console.log(data);
  let menuContainer = "w-[40%]";
  let menuItem = "font-medium text-black text-sm my-2";
  let subMenuItem = "font-light text-gray-700 capitalize";
  return (
    <div className="bg-white px-10 py-10 w-full">
      <p className="font-medium text-lg text-primary">
        Household GESI & Nutrition Survery
      </p>
      {/* Lists */}
      <div className="w-full">
        {/* row one */}
        <div className="flex items-start justify-between mb-10">
          {/* Col One */}
          <div className={menuContainer}>
            <ul>
              <li className={menuItem}>
                Age: <span className={subMenuItem}>{Age}</span>
              </li>
              <li className={menuItem}>
                Sex: <span className={subMenuItem}>{Sex}</span>
              </li>
              <li className={menuItem}>
                MaritalStatus:{" "}
                <span className={subMenuItem}>{MaritalStatus}</span>
              </li>
              <li className={menuItem}>
                HouseHoldType:{" "}
                <span className={subMenuItem}>{HouseHoldType}</span>
              </li>
              <li className={menuItem}>
                HeadOfHouseHold:{" "}
                <span className={subMenuItem}>{HeadOfHouseHold}</span>
              </li>
              <li className={menuItem}>
                Religion: <span className={subMenuItem}>{Religion}</span>
              </li>
              <li className={menuItem}>
                Ethnicity: <span className={subMenuItem}>{Ethnicity}</span>
              </li>
              <li className={menuItem}>
                Indegigenity:{" "}
                <span className={subMenuItem}>{Indegigenity}</span>
              </li>
              <li className={menuItem}>
                DifficultySeeing:{" "}
                <span className={subMenuItem}>{DifficultySeeing}</span>
              </li>
              <li className={menuItem}>
                DifficultyHearing:{" "}
                <span className={subMenuItem}>{DifficultyHearing}</span>
              </li>
              <li className={menuItem}>
                DifficultyWalking:{" "}
                <span className={subMenuItem}>{DifficultyWalking}</span>
              </li>
              <li className={menuItem}>
                DifficultyRemembering:{" "}
                <span className={subMenuItem}>{DifficultyRemembering}</span>
              </li>
              <li className={menuItem}>
                DifficultyWashingOver:{" "}
                <span className={subMenuItem}>{DifficultyWashingOver}</span>
              </li>
              <li className={menuItem}>
                DifficultyCommunication:{" "}
                <span className={subMenuItem}>{DifficultyCommunication}</span>
              </li>
              <li className={menuItem}>
                HighestLevelOfEdu:{" "}
                <span className={subMenuItem}>{HighestLevelOfEdu}</span>
              </li>
              <li className={menuItem}>
                PrimaryOccupation:{" "}
                <span className={subMenuItem}>{PrimaryOccupation}</span>
              </li>
              <li className={menuItem}>
                SecondaryOccupation:{" "}
                <span className={subMenuItem}>{SecondaryOccupation}</span>
              </li>
              <li className={menuItem}>
                MonthlyEarning:{" "}
                <span className={subMenuItem}>{MonthlyEarning}</span>
              </li>
              <li className={menuItem}>
                WhoPrepLandForCultivation:{" "}
                <span className={subMenuItem}>{WhoPrepLandForCultivation}</span>
              </li>
              <li className={menuItem}>
                WhoDoesTheActualCultivation:{" "}
                <span className={subMenuItem}>
                  {WhoDoesTheActualCultivation}
                </span>
              </li>
              <li className={menuItem}>
                WhoDoesWeeding:{" "}
                <span className={subMenuItem}>{WhoDoesWeeding}</span>
              </li>
              <li className={menuItem}>
                Harvesting: <span className={subMenuItem}>{Harvesting}</span>
              </li>
              <li className={menuItem}>
                Processing: <span className={subMenuItem}>{Processing}</span>
              </li>
              <li className={menuItem}>
                Storage: <span className={subMenuItem}>{Storage}</span>
              </li>
              <li className={menuItem}>
                WhoSellCultivatedProduce:{" "}
                <span className={subMenuItem}>{WhoSellCultivatedProduce}</span>
              </li>
              <li className={menuItem}>
                SweepingAndGenIndoorSan:{" "}
                <span className={subMenuItem}>{SweepingAndGenIndoorSan}</span>
              </li>
              <li className={menuItem}>
                FetchingWaterFirewood:{" "}
                <span className={subMenuItem}>{FetchingWaterFirewood}</span>
              </li>
              <li className={menuItem}>
                Cooking: <span className={subMenuItem}>{Cooking}</span>
              </li>
            </ul>
          </div>
          {/* Col Two */}
          <div className={menuContainer}>
            <ul>
              <li className={menuItem}>
                HouseChores: <span className={subMenuItem}>{HouseChores}</span>
              </li>
              <li className={menuItem}>
                HouseRepairs:{" "}
                <span className={subMenuItem}>{HouseRepairs}</span>
              </li>
              <li className={menuItem}>
                TakingCareOfSick:{" "}
                <span className={subMenuItem}>{TakingCareOfSick}</span>
              </li>
              <li className={menuItem}>
                HomeVilante: <span className={subMenuItem}>{HomeVilante}</span>
              </li>
              <li className={menuItem}>
                HouseholdLeadership:{" "}
                <span className={subMenuItem}>{HouseholdLeadership}</span>
              </li>
              <li className={menuItem}>
                ManagingHouseholdFinances:{" "}
                <span className={subMenuItem}>{ManagingHouseholdFinances}</span>
              </li>
              <li className={menuItem}>
                ChildBearing:{" "}
                <span className={subMenuItem}>{ChildBearing}</span>
              </li>
              <li className={menuItem}>
                ChildUpbringing:{" "}
                <span className={subMenuItem}>{ChildUpbringing}</span>
              </li>
              <li className={menuItem}>
                HoursOnDomOrRepRoles:{" "}
                <span className={subMenuItem}>{HoursOnDomOrRepRoles}</span>
              </li>
              <li className={menuItem}>
                CreditFinanceAgricultural:{" "}
                <span className={subMenuItem}>{CreditFinanceAgricultural}</span>
              </li>
              <li className={menuItem}>
                BusinessDevServices:{" "}
                <span className={subMenuItem}>{BusinessDevServices}</span>
              </li>
              <li className={menuItem}>
                VillageSavingsLoansAssociation:{" "}
                <span className={subMenuItem}>
                  {VillageSavingsLoansAssociation}
                </span>
              </li>
              <li className={menuItem}>
                TrainingModernFarming:{" "}
                <span className={subMenuItem}>{TrainingModernFarming}</span>
              </li>
              <li className={menuItem}>
                LandAgriculturalCommunity:{" "}
                <span className={subMenuItem}>{LandAgriculturalCommunity}</span>
              </li>
              <li className={menuItem}>
                LandDevelopmentOther:{" "}
                <span className={subMenuItem}>{LandDevelopmentOther}</span>
              </li>
              <li className={menuItem}>
                ExtensionAgents:{" "}
                <span className={subMenuItem}>{ExtensionAgents}</span>
              </li>
              <li className={menuItem}>
                Markets: <span className={subMenuItem}>{Markets}</span>
              </li>
              <li className={menuItem}>
                AgriculturalInputs:{" "}
                <span className={subMenuItem}>{AgriculturalInputs}</span>
              </li>
              <li className={menuItem}>
                PrimaryPurpose:{" "}
                <span className={subMenuItem}>{PrimaryPurpose}</span>
              </li>
              <li className={menuItem}>
                LandAcquisition:{" "}
                <span className={subMenuItem}>{LandAcquisition}</span>
              </li>
              <li className={menuItem}>
                LandOwnerGender:{" "}
                <span className={subMenuItem}>{LandOwnerGender}</span>
              </li>
              <li className={menuItem}>
                WomenOwnLand:{" "}
                <span className={subMenuItem}>{WomenOwnLand}</span>
              </li>
              <li className={menuItem}>
                WomenDecisionPower:{" "}
                <span className={subMenuItem}>{WomenDecisionPower}</span>
              </li>
              <li className={menuItem}>
                BankAccount: <span className={subMenuItem}>{BankAccount}</span>
              </li>
              <li className={menuItem}>
                NearestBankDistance:{" "}
                <span className={subMenuItem}>{NearestBankDistance}</span>
              </li>
              <li className={menuItem}>
                IncomeControl:{" "}
                <span className={subMenuItem}>{IncomeControl}</span>
              </li>
            </ul>
          </div>
        </div>

        {/* ROw Two */}
        <div className="flex items-start justify-between">
          <div className={menuContainer}>
            <ul>
              <li className={menuItem}>
                FinancialSupport:{" "}
                <span className={subMenuItem}>{FinancialSupport}</span>
              </li>
              <li className={menuItem}>
                MoneyLendingFacilities:{" "}
                <span className={subMenuItem}>{MoneyLendingFacilities}</span>
              </li>
              <li className={menuItem}>
                MoneyLendingOptions:{" "}
                <span className={subMenuItem}>{MoneyLendingOptions}</span>
              </li>
              <li className={menuItem}>
                FarmerAssociationMember:{" "}
                <span className={subMenuItem}>{FarmerAssociationMember}</span>
              </li>
              <li className={menuItem}>
                LeadershipPosition:{" "}
                <span className={subMenuItem}>{LeadershipPosition}</span>
              </li>
              <li className={menuItem}>
                InfluentialPersonsCommunity:{" "}
                <span className={subMenuItem}>
                  {InfluentialPersonsCommunity}
                </span>
              </li>
              <li className={menuItem}>
                WhoDecideHouseholdIncomeUsed:{" "}
                <span className={subMenuItem}>
                  {WhoDecideHouseholdIncomeUsed}
                </span>
              </li>
              <li className={menuItem}>
                WhoControlsDailyBudget:{" "}
                <span className={subMenuItem}>{WhoControlsDailyBudget}</span>
              </li>
              <li className={menuItem}>
                WhoDecidesHouseholdSavingsInvestments:{" "}
                <span className={subMenuItem}>
                  {WhoDecidesHouseholdSavingsInvestments}
                </span>
              </li>
              <li className={menuItem}>
                WhoDecideWomenGirlsAllowedPaidJob:{" "}
                <span className={subMenuItem}>
                  {WhoDecideWomenGirlsAllowedPaidJob}
                </span>
              </li>
              <li className={menuItem}>
                WhoDecidesDomesticWork:{" "}
                <span className={subMenuItem}>{WhoDecidesDomesticWork}</span>
              </li>
              <li className={menuItem}>
                WhoDecideAccessWomenMenBoysGirls BasicEducationInformation:{" "}
                <span className={subMenuItem}>
                  {WhoDecideAccessWomenMenBoysGirlsBasicEducationInformation}
                </span>
              </li>
              <li className={menuItem}>
                WhoDecidesChildCareIssues:{" "}
                <span className={subMenuItem}>{WhoDecidesChildCareIssues}</span>
              </li>
              <li className={menuItem}>
                WhoDecideWhenWhereAccessHealthCare:{" "}
                <span className={subMenuItem}>
                  {WhoDecideWhenWhereAccessHealthCare}
                </span>
              </li>
              <li className={menuItem}>
                WhoDecidesFamilySize:{" "}
                <span className={subMenuItem}>{WhoDecidesFamilySize}</span>
              </li>
              <li className={menuItem}>
                WhoDecidesChildMarriage:{" "}
                <span className={subMenuItem}>{WhoDecidesChildMarriage}</span>
              </li>
              <li className={menuItem}>
                WhoDecidesLandsAgriculturePurpose:{" "}
                <span className={subMenuItem}>
                  {WhoDecidesLandsAgriculturePurpose}
                </span>
              </li>
              <li className={menuItem}>
                WhoDecidesCropsToProduce:{" "}
                <span className={subMenuItem}>{WhoDecidesCropsToProduce}</span>
              </li>
              <li className={menuItem}>
                LandPreparationHowMuchLandToCultivate:{" "}
                <span className={subMenuItem}>
                  {LandPreparationHowMuchLandToCultivate}
                </span>
              </li>
              <li className={menuItem}>
                WhenWhatVarietySeedToPlant:{" "}
                <span className={subMenuItem}>
                  {WhenWhatVarietySeedToPlant}
                </span>
              </li>
              <li className={menuItem}>
                WhoControlsProceedsFarmProduce:{" "}
                <span className={subMenuItem}>
                  {WhoControlsProceedsFarmProduce}
                </span>
              </li>
              <li className={menuItem}>
                LandsForDevelopmentOtherThanAgriculturePurpose:{" "}
                <span className={subMenuItem}>
                  {LandsForDevelopmentOtherThanAgriculturePurpose}
                </span>
              </li>
              <li className={menuItem}>
                CategoryIndividualsLeftOutDuringServiceDelivery:{" "}
                <span className={subMenuItem}>
                  {CategoryIndividualsLeftOutDuringServiceDelivery}
                </span>
              </li>
              <li className={menuItem}>
                CategoryIndividualsMarginalizedDiscriminatedAgainst:{" "}
                <span className={subMenuItem}>
                  {CategoryIndividualsMarginalizedDiscriminatedAgainst}
                </span>
              </li>
              <li className={menuItem}>
                CategoryIndividualsAbusedAssaulted:{" "}
                <span className={subMenuItem}>
                  {CategoryIndividualsAbusedAssaulted}
                </span>
              </li>
              <li className={menuItem}>
                KindCommonAbuseAssaultExposed:{" "}
                <span className={subMenuItem}>
                  {KindCommonAbuseAssaultExposed}
                </span>
              </li>
              <li className={menuItem}>
                ExperiencedFormAbuseAssault:{" "}
                <span className={subMenuItem}>
                  {ExperiencedFormAbuseAssault}
                </span>
              </li>
            </ul>
          </div>
          {/* Col 4 */}
          <div className={menuContainer}>
            <ul>
              <li className={menuItem}>
                KindAbuseAssaultExperienced:{" "}
                <span className={subMenuItem}>
                  {KindAbuseAssaultExperienced}
                </span>
              </li>
              <li className={menuItem}>
                HavePWDsInCommunity:{" "}
                <span className={subMenuItem}>{HavePWDsInCommunity}</span>
              </li>
              <li className={menuItem}>
                PerceptionOfDisabilityInCommunity:{" "}
                <span className={subMenuItem}>
                  {PerceptionOfDisabilityInCommunity}
                </span>
              </li>
              <li className={menuItem}>
                ChallengesFacedByPWDsInCommunity:{" "}
                <span className={subMenuItem}>
                  {ChallengesFacedByPWDsInCommunity}
                </span>
              </li>
              <li className={menuItem}>
                HaveIDPsInCommunity:{" "}
                <span className={subMenuItem}>{HaveIDPsInCommunity}</span>
              </li>
              <li className={menuItem}>
                ChallengesFacedByIDPsInCommunity:{" "}
                <span className={subMenuItem}>
                  {ChallengesFacedByIDPsInCommunity}
                </span>
              </li>
              <li className={menuItem}>
                WorryAboutFoodRunningOut:{" "}
                <span className={subMenuItem}>{WorryAboutFoodRunningOut}</span>
              </li>
              <li className={menuItem}>
                FoodNotLastNoMoneyToBuyMore:{" "}
                <span className={subMenuItem}>
                  {FoodNotLastNoMoneyToBuyMore}
                </span>
              </li>
              <li className={menuItem}>
                EatBalancedMeals:{" "}
                <span className={subMenuItem}>{EatBalancedMeals}</span>
              </li>
              <li className={menuItem}>
                ReduceSizeOrSkipMeals:{" "}
                <span className={subMenuItem}>{ReduceSizeOrSkipMeals}</span>
              </li>
              <li className={menuItem}>
                EatLessOrSkipMeals:{" "}
                <span className={subMenuItem}>{EatLessOrSkipMeals}</span>
              </li>
              <li className={menuItem}>
                ChildrenEatLessOrSkipMeals:{" "}
                <span className={subMenuItem}>
                  {ChildrenEatLessOrSkipMeals}
                </span>
              </li>
              <li className={menuItem}>
                EatLessOrSippedMeals:{" "}
                <span className={subMenuItem}>{EatLessOrSippedMeals}</span>
              </li>
              <li className={menuItem}>
                ChildrenLoseWeight:{" "}
                <span className={subMenuItem}>{ChildrenLoseWeight}</span>
              </li>
              <li className={menuItem}>
                NotEatEntireDay:{" "}
                <span className={subMenuItem}>{NotEatEntireDay}</span>
              </li>
              <li className={menuItem}>
                ChildNotEatEntireDay:{" "}
                <span className={subMenuItem}>{ChildNotEatEntireDay}</span>
              </li>
              <li className={menuItem}>
                BreastfeedDuration:{" "}
                <span className={subMenuItem}>{BreastfeedDuration}</span>
              </li>
              <li className={menuItem}>
                GrownOwnFoodBeforeJuriya:{" "}
                <span className={subMenuItem}>{GrownOwnFoodBeforeJuriya}</span>
              </li>
              <li className={menuItem}>
                PercentageFoodFromOwnLand:{" "}
                <span className={subMenuItem}>{PercentageFoodFromOwnLand}</span>
              </li>
              <li className={menuItem}>
                DistanceToNearestMarket:{" "}
                <span className={subMenuItem}>{DistanceToNearestMarket}</span>
              </li>
              <li className={menuItem}>
                MarketsHaveDesiredFoods:{" "}
                <span className={subMenuItem}>{MarketsHaveDesiredFoods}</span>
              </li>
              <li className={menuItem}>
                MarketsHaveReasonablePrices:{" "}
                <span className={subMenuItem}>
                  {MarketsHaveReasonablePrices}
                </span>
              </li>
              <li className={menuItem}>
                AbleToTradeWithFarmers:{" "}
                <span className={subMenuItem}>{AbleToTradeWithFarmers}</span>
              </li>
              <li className={menuItem}>
                HealthIssues:{" "}
                <span className={subMenuItem}>{HealthIssues}</span>
              </li>
              <li className={menuItem}>
                DisabilityRequiresFeedingAssistance:{" "}
                <span className={subMenuItem}>
                  {DisabilityRequiresFeedingAssistance}
                </span>
              </li>
              <li className={menuItem}>
                MedicalProfessionalVisits:{" "}
                <span className={subMenuItem}>{MedicalProfessionalVisits}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveryContainer;
