import React, { useState, useEffect } from "react"
import { api } from "../lib/queries/axiosInstance";



const useEngagement = () => {
    const [engagements, setEngagements] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);


    const fetchEngagements = () => {
    setLoading(true);
    
  api
    .get("/Engagements")
      .then((res) => {
      console.log(res.data.data.content)
      setEngagements(res.data.data.content);
        
    })
    .catch((err) => {
      console.log(err);
      setError(err.response);
    })
      .finally(() => setLoading(false));
    
    
    }
    
    useEffect(() => {
    fetchEngagements()
    }, [])
    
    return { engagements, loading, error };

    
};



export default useEngagement;
