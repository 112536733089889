import React from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { GOOGLE_MAPS_APIKEY } from "../../constants/ApiKeys";

const MapContainer = ({ YOUR_LATITUDE, YOUR_LONGITUDE }) => {
  const mapContainerStyle = {
    width: "100%",
    height: "350px",
  };

  const center = {
    lat: YOUR_LATITUDE,
    lng: YOUR_LONGITUDE,
  };

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={center}
      zoom={10}
      clickableIcons={false}
      apiKey={GOOGLE_MAPS_APIKEY}
    >
      <Marker position={center} />
    </GoogleMap>
  );
};

export default MapContainer;
