import React from 'react'
import EngagementStats from './EngagementStats';
import EngagementList from "./EngagementList";
import useEngagement from "../../../hooks/useEngagement";
// import {api} from '../../../lib/queries/axiosInstance'

export default function EngagementHome() {



// const useEngagement = () => {
//   const [engagementDetails, setengagementdetails] = useState(null);
//   const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(false);

//   setLoading(true);
//   api
//     .get("/Engagements")
//     .then((res) => {
//       //   console.log(res.data.data.content)
//       setengagementdetails(res.data.data.content);

//       return { engagementdetails, loading, error };
//     })
//     .catch((err) => {
//       console.log(err);
//       setError(err.response);
//     })
//     .finally(() => setLoading(false));
  
//   console.log(engagementDetails);
  
// };


  const { engagements } = useEngagement();
  console.log(engagements);


  return (
    <div>
      <div className="text-xl pt-1">Engagements</div>
      <EngagementStats />
      <EngagementList engagements={engagements} />
    </div>
  );
}
