import React from "react";
import { useEventContext } from "../../Context/EventContext";
import { toast } from "react-toastify";

const EventType = () => {
  const { eventForm, onChange, onActiveEventCat } = useEventContext();

  const onNext = () => {
    if (eventForm.EventType === "") {
      toast.error("All Fields Must be Fill");
    } else {
      onActiveEventCat();
    }
  };
  return (
    <div>
      <div className="p-2">
        <label className="block text-gray-700 mb-2 text-xs">
          Select Event Type
        </label>
        <select
          value={"Training Event"}
          id=""
          className="text-xs bg-white drop-shadow-lg border-none rounded w-full py-3 px-3 text-gray-700 leading-tight "
        >
          {/* <option value={""}>-- Select Event Type --</option> */}
          <option selected value={"Training Event"}>
            Training Event
          </option>
          {/* <option value={"Engagement Event"}>Engagement Event</option> */}
        </select>
      </div>
      <div className="p-2">
        <label className="block text-gray-700 mb-2 text-xs">
          Who is this training for?
        </label>
        <select
          value={eventForm.EventType}
          id="EventType"
          onChange={onChange}
          className="text-xs bg-white drop-shadow-lg border-none rounded w-full py-3 px-3 text-gray-700 leading-tight "
        >
          <option value={""}>-- Select --</option>
          <option value={"staff"}>Staff</option>
          <option value={"household"}>Household</option>
        </select>
      </div>
      <div className="flex w-full items-end justify-end">
        <div className="pt-6">
          <button
            className="bg-primary  text-white py-3 px-10 rounded-lg focus:outline-none focus:shadow-outline"
            type="button"
            onClick={onNext}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventType;
