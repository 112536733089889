import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { api, header } from "../../lib/queries/axiosInstance";
import { CustomMessage, Spinner } from "../../components/Generic";
import { NumberInputField, PrimaryBtn } from "../../components/Form";
import { useAuthContext } from "../../Context/AuthContext";

const UpdateSingleHouseholdInput = () => {
  const { id } = useParams();
  const [input, setInput] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    id: id,
    Acacia_albida: "",
    Acacia_senegal: "",
    Acacia_seyal: "",
    Prosopis_africana: "",
    Scerocarya_bierrea: "",
    Parkia_biglobosa: "",
    Baobab: "",
    Balanites_Aegyptiaca: "",
    Ziziphus_mauritiana: "",
    Teak: "",
    Moringa_oleifera: "",
    Moringa_stenopetala: "",
    Elais_Guinensis: "",
    Jetropa_curcas: "",
    Mahogany: "",
    Super_napia: "",
    Marigold_stem: "",
    Maize: "",
    Sorghum: "",
    Rice: "",
    Yam: "",
    Ginger: "",
    Soybean: "",
    Hibiscus: "",
    Sesame: "",
    Bambara_Nut: "",
    Groundnut: "",
    Sweet_Potato: "",
    Watermelon: "",
    Pumpkin: "",
    Napia: "",
    Cowpea: "",
    Chilli: "",
    Marigold_kg: "",
    Compost: "",
    Biofertilizer_Biopesticide: "",
    Pruning_tools: "",
  });
  const { setIsMessage, isMessage, setMessageContent, setMessageType } =
    useAuthContext();
  const navigate = useNavigate();
  let colContainer = "w-[20%]";
  let rowContainer = "w-full flex justify-between items-center";
  const {
    Acacia_albida,
    Acacia_senegal,
    Acacia_seyal,
    Prosopis_africana,
    Scerocarya_bierrea,
    Parkia_biglobosa,
    Baobab,
    Balanites_Aegyptiaca,
    Ziziphus_mauritiana,
    Teak,
    Moringa_oleifera,
    Moringa_stenopetala,
    Elais_Guinensis,
    Jetropa_curcas,
    Mahogany,
    Super_napia,
    Marigold_stem,
    Maize,
    Sorghum,
    Rice,
    Yam,
    Ginger,
    Soybean,
    Hibiscus,
    Sesame,
    Bambara_Nut,
    Groundnut,
    Sweet_Potato,
    Watermelon,
    Pumpkin,
    Napia,
    Cowpea,
    Chilli,
    Marigold_kg,
    Compost,
    Biofertilizer_Biopesticide,
    Pruning_tools,
  } = formData;
  const getInputDetails = async () => {
    setLoading(true);
    const res = await api.get(`hh_input_distribution/${id}`, {
      headers: header(),
    });
    const data = await res.data;
    if (data.code === 200) {
      setInput(data.data);
      setFormData({
        ...formData,
        Acacia_albida: data?.data?.Acacia_albida,
        Acacia_senegal: data?.data?.Acacia_senegal,
        Acacia_seyal: data?.data?.Acacia_seyal,
        Prosopis_africana: data?.data?.Prosopis_africana,
        Scerocarya_bierrea: data?.data?.Scerocarya_bierrea,
        Parkia_biglobosa: data?.data?.Parkia_biglobosa,
        Baobab: data?.data?.Baobab,
        Balanites_Aegyptiaca: data?.data?.Balanites_Aegyptiaca,
        Ziziphus_mauritiana: data?.data?.Ziziphus_mauritiana,
        Teak: data?.data?.Teak,
        Moringa_oleifera: data?.data?.Moringa_oleifera,
        Moringa_stenopetala: data?.data?.Moringa_stenopetala,
        Elais_Guinensis: data?.data?.Elais_Guinensis,
        Jetropa_curcas: data?.data?.Jetropa_curcas,
        Mahogany: data?.data?.Mahogany,
        Super_napia: data?.data?.Super_napia,
        Marigold_stem: data?.data?.Marigold_stem,
        Maize: data?.data?.Maize,
        Sorghum: data?.data?.Sorghum,
        Rice: data?.data?.Rice,
        Yam: data?.data?.Yam,
        Ginger: data?.data?.Ginger,
        Soybean: data?.data?.Soybean,
        Hibiscus: data?.data?.Hibiscus,
        Sesame: data?.data?.Sesame,
        Bambara_Nut: data?.data?.Bambara_Nut,
        Groundnut: data?.data?.Groundnut,
        Sweet_Potato: data?.data?.Sweet_Potato,
        Watermelon: data?.data?.Watermelon,
        Pumpkin: data?.data?.Pumpkin,
        Napia: data?.data?.Napia,
        Cowpea: data?.data?.Cowpea,
        Chilli: data?.data?.Chilli,
        Marigold_kg: data?.data?.Marigold_kg,
        Compost: data?.data?.Compost,
        Biofertilizer_Biopesticide: data?.data?.Biofertilizer_Biopesticide,
        Pruning_tools: data?.data?.Pruning_tools,
      });
    }
    setLoading(false);
  };
  useEffect(() => {
    getInputDetails();
  }, []);
  const onHandleChange = (nameID, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [nameID]: value,
    }));
    // console.log(nameID, value);
  };
  async function onSubmit() {
    const numberRegex = /^\d+$/; // Regular expression to match only numbers
    // if (
    //   !numberRegex.test(Acacia_albida) ||
    //   !numberRegex.test(Acacia_senegal) ||
    //   !numberRegex.test(Acacia_seyal) ||
    //   !numberRegex.test(Prosopis_africana) ||
    //   !numberRegex.test(Scerocarya_bierrea) ||
    //   !numberRegex.test(Parkia_biglobosa) ||
    //   !numberRegex.test(Baobab) ||
    //   !numberRegex.test(Balanites_Aegyptiaca) ||
    //   !numberRegex.test(Ziziphus_mauritiana) ||
    //   !numberRegex.test(Teak) ||
    //   !numberRegex.test(Moringa_oleifera) ||
    //   !numberRegex.test(Moringa_stenopetala) ||
    //   !numberRegex.test(Elais_Guinensis) ||
    //   !numberRegex.test(Jetropa_curcas) ||
    //   !numberRegex.test(Mahogany) ||
    //   !numberRegex.test(Super_napia) ||
    //   !numberRegex.test(Marigold_stem) ||
    //   !numberRegex.test(Maize) ||
    //   !numberRegex.test(Sorghum) ||
    //   !numberRegex.test(Rice) ||
    //   !numberRegex.test(Yam) ||
    //   !numberRegex.test(Ginger) ||
    //   !numberRegex.test(Soybean) ||
    //   !numberRegex.test(Hibiscus) ||
    //   !numberRegex.test(Sesame) ||
    //   !numberRegex.test(Bambara_Nut) ||
    //   !numberRegex.test(Groundnut) ||
    //   !numberRegex.test(Sweet_Potato) ||
    //   !numberRegex.test(Watermelon) ||
    //   !numberRegex.test(Pumpkin) ||
    //   !numberRegex.test(Napia) ||
    //   !numberRegex.test(Cowpea) ||
    //   !numberRegex.test(Chilli) ||
    //   !numberRegex.test(Marigold_kg) ||
    //   !numberRegex.test(Compost) ||
    //   !numberRegex.test(Biofertilizer_Biopesticide) ||
    //   !numberRegex.test(Pruning_tools)
    // ) {
    //   setMessageContent("All Quantity Must be Numbers Only");
    //   setIsMessage(true);
    //   setMessageType("error");
    // } else {
    setLoading(true);
    api
      .put(`hh_input_distribution`, formData, {
        headers: header(),
      })
      .then((res) => {
        setLoading(false);
        if (res.data.code === 200) {
          setMessageContent(
            "Household Input Distribution Updated Successfully"
          );
          setIsMessage(true);
          setMessageType("success");
          navigate(`/input-details/${input?.UID}`);
        } else {
          setMessageContent(res.data.message);
          setIsMessage(true);
          setMessageType("error");
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        if (err.response.data.message === undefined) {
          setMessageContent(err.message);
          setIsMessage(true);
          setMessageType("error");
        } else {
          setMessageContent(err.response.data.message);
          setIsMessage(true);
          setMessageType("error");
        }
      });
    // }
  }
  if (loading) {
    return <Spinner />;
  }
  if (isMessage) {
    return <CustomMessage />;
  }
  return (
    <>
      {input && (
        <div>
          {/* Header */}
          <div className="flex justify-between items-center w-full">
            <h3 className="text-lg font-medium">
              Edit Input Distribution Details
            </h3>
            <div className=" flex">
              <Link
                className="flex items-start justify-center bg-white w-full my-5 mx-4 text-primary py-3 px-5 rounded-md border border-primary text-center text-xs"
                to={`/input-details/${input?.UID}`}
              >
                {"Cancel"}
              </Link>
              <div>
                <PrimaryBtn text={"Update"} OnBtnClick={onSubmit} />
              </div>
            </div>
          </div>
          <div>
            {/* Row one */}
            <div className={rowContainer}>
              <div className={colContainer}>
                <NumberInputField
                  title={"Acacia_albida"}
                  placeholder={"Enter Quantity"}
                  value={Acacia_albida}
                  nameID={"Acacia_albida"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Acacia_senegal"}
                  placeholder={"Enter Quantity"}
                  value={Acacia_senegal}
                  nameID={"Acacia_senegal"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Prosopis_africana"}
                  placeholder={"Enter Quantity"}
                  value={Prosopis_africana}
                  nameID={"Prosopis_africana"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Pruning_tools"}
                  placeholder={"Enter Quantity"}
                  value={Pruning_tools}
                  nameID={"Pruning_tools"}
                  onHandleChange={onHandleChange}
                />
              </div>
            </div>
            {/* Row Two */}
            <div className={rowContainer}>
              <div className={colContainer}>
                <NumberInputField
                  title={"Scerocarya_bierrea"}
                  placeholder={"Enter Quantity"}
                  value={Scerocarya_bierrea}
                  nameID={"Scerocarya_bierrea"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Parkia_biglobosa"}
                  placeholder={"Enter Quantity"}
                  value={Parkia_biglobosa}
                  nameID={"Parkia_biglobosa"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Baobab"}
                  placeholder={"Enter Quantity"}
                  value={Baobab}
                  nameID={"Baobab"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Balanites_Aegyptiaca"}
                  placeholder={"Enter Quantity"}
                  value={Balanites_Aegyptiaca}
                  nameID={"Balanites_Aegyptiaca"}
                  onHandleChange={onHandleChange}
                />
              </div>
            </div>
            {/* Row Three */}
            <div className={rowContainer}>
              <div className={colContainer}>
                <NumberInputField
                  title={"Ziziphus_mauritiana"}
                  placeholder={"Enter Quantity"}
                  value={Ziziphus_mauritiana}
                  nameID={"Ziziphus_mauritiana"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Teak"}
                  placeholder={"Enter Quantity"}
                  value={Teak}
                  nameID={"Teak"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Moringa_oleifera"}
                  placeholder={"Enter Quantity"}
                  value={Moringa_oleifera}
                  nameID={"Moringa_oleifera"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Moringa_stenopetala"}
                  placeholder={"Enter Quantity"}
                  value={Moringa_stenopetala}
                  nameID={"Moringa_stenopetala"}
                  onHandleChange={onHandleChange}
                />
              </div>
            </div>
            {/* Row Four */}
            <div className={rowContainer}>
              <div className={colContainer}>
                <NumberInputField
                  title={"Elais_Guinensis"}
                  placeholder={"Enter Quantity"}
                  value={Elais_Guinensis}
                  nameID={"Elais_Guinensis"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Jetropa_curcas"}
                  placeholder={"Enter Quantity"}
                  value={Jetropa_curcas}
                  nameID={"Jetropa_curcas"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Mahogany"}
                  placeholder={"Enter Quantity"}
                  value={Mahogany}
                  nameID={"Mahogany"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Super_napia"}
                  placeholder={"Enter Quantity"}
                  value={Super_napia}
                  nameID={"Super_napia"}
                  onHandleChange={onHandleChange}
                />
              </div>
            </div>
            {/* Row Five */}
            <div className={rowContainer}>
              <div className={colContainer}>
                <NumberInputField
                  title={"Marigold_stem"}
                  placeholder={"Enter Quantity"}
                  value={Marigold_stem}
                  nameID={"Marigold_stem"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Maize"}
                  placeholder={"Enter Quantity"}
                  value={Maize}
                  nameID={"Maize"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Sorghum"}
                  placeholder={"Enter Quantity"}
                  value={Sorghum}
                  nameID={"Sorghum"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Rice"}
                  placeholder={"Enter Quantity"}
                  value={Rice}
                  nameID={"Rice"}
                  onHandleChange={onHandleChange}
                />
              </div>
            </div>
            {/* Row Six */}
            <div className={rowContainer}>
              <div className={colContainer}>
                <NumberInputField
                  title={"Yam"}
                  placeholder={"Enter Quantity"}
                  value={Yam}
                  nameID={"Yam"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Ginger"}
                  placeholder={"Enter Quantity"}
                  value={Ginger}
                  nameID={"Ginger"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Soybean"}
                  placeholder={"Enter Quantity"}
                  value={Soybean}
                  nameID={"Soybean"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Hibiscus"}
                  placeholder={"Enter Quantity"}
                  value={Hibiscus}
                  nameID={"Hibiscus"}
                  onHandleChange={onHandleChange}
                />
              </div>
            </div>
            {/* Row Seven */}
            <div className={rowContainer}>
              <div className={colContainer}>
                <NumberInputField
                  title={"Sesame"}
                  placeholder={"Enter Quantity"}
                  value={Sesame}
                  nameID={"Sesame"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Bambara_Nut"}
                  placeholder={"Enter Quantity"}
                  value={Bambara_Nut}
                  nameID={"Bambara_Nut"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Groundnut"}
                  placeholder={"Enter Quantity"}
                  value={Groundnut}
                  nameID={"Groundnut"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Sweet_Potato"}
                  placeholder={"Enter Quantity"}
                  value={Sweet_Potato}
                  nameID={"Sweet_Potato"}
                  onHandleChange={onHandleChange}
                />
              </div>
            </div>
            {/* ROW EIHGT */}
            <div className={rowContainer}>
              <div className={colContainer}>
                <NumberInputField
                  title={"Watermelon"}
                  placeholder={"Enter Quantity"}
                  value={Watermelon}
                  nameID={"Watermelon"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Pumpkin"}
                  placeholder={"Enter Quantity"}
                  value={Pumpkin}
                  nameID={"Pumpkin"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Napia"}
                  placeholder={"Enter Quantity"}
                  value={Napia}
                  nameID={"Napia"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Cowpea"}
                  placeholder={"Enter Quantity"}
                  value={Cowpea}
                  nameID={"Cowpea"}
                  onHandleChange={onHandleChange}
                />
              </div>
            </div>
            {/* Row Nine */}
            <div className={rowContainer}>
              <div className={colContainer}>
                <NumberInputField
                  title={"Chilli"}
                  placeholder={"Enter Quantity"}
                  value={Chilli}
                  nameID={"Chilli"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Marigold_kg"}
                  placeholder={"Enter Quantity"}
                  value={Marigold_kg}
                  nameID={"Marigold_kg"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Compost"}
                  placeholder={"Enter Quantity"}
                  value={Compost}
                  nameID={"Compost"}
                  onHandleChange={onHandleChange}
                />
              </div>
              <div className={colContainer}>
                <NumberInputField
                  title={"Biofertilizer_Biopesticide"}
                  placeholder={"Enter Quantity"}
                  value={Biofertilizer_Biopesticide}
                  nameID={"Biofertilizer_Biopesticide"}
                  onHandleChange={onHandleChange}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateSingleHouseholdInput;
