import React from "react";
import styles from "./farmer.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { GrView } from "react-icons/gr";
import { FiEdit, FiSettings } from "react-icons/fi";
import { TbFilterOff } from "react-icons/tb";
import { HiOutlineSearch } from "react-icons/hi";
import { TiExportOutline } from "react-icons/ti";
import PulseLoader from "react-spinners/PulseLoader";
// import { useEnumerationProvider } from "../../../Context/EnumerationContext";
import ReactPaginate from "react-paginate";
import { useAuthContext } from "../../../src/Context/AuthContext";
import useEnumerationsQuery from "../../hooks/enumerations/useEnumerationsQuery";
import { getStatus } from "../../lib/helpers";
import { BsTrash } from "react-icons/bs";
import { getColumnData } from "../../lib/services/advancedSearchSelect";
import { states, tempState } from "../../lib/constants/states";
import Papa from "papaparse";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { api, header } from "../../lib/queries/axiosInstance";
import { trimObj } from "../../lib/utils/utilityHandler";
import { FaRegTimesCircle } from "react-icons/fa";

const TableData = ({ children }) => <td className="px-6 py-2">{children}</td>;

export default function Household() {
  const navigate = useNavigate();
  const { authUser, authDashboard, params } = useAuthContext();
  console.log(authDashboard);
  const [enumerationStatus, setEnumerationStatus] = useState(null);
  const [NINStatus, setNINStatus] = useState(null);
  const [householdGender, setHouseholdGender] = useState(null);
  const [farmSize, setFarmSize] = useState(null);
  const [state, setState] = useState("");
  const [filteredLga, setFilteredLga] = useState([""]);
  const [selectedLga, setSelectedLga] = useState();
  const [selectedState, setselectedState] = useState();
  const [isReadyToPrint, setIsReadyToPrint] = useState(false);
  const [objParams, setObjParams] = useState({});
  const [onShowSearch, setOnShowSearch] = useState(false);

  // const [searchResult, setSearchResult] = useState("");

  const [selectedDate, setSelectedDate] = useState("");
  const [updatedDate, setUpdatedDate] = useState("");
  useEffect(() => {
    if (params === "validated") {
      setFarmSize("*.*");
      setEnumerationStatus("Completed");
    } else if (params === "unvalidated") {
      setFarmSize("unvalidated");
      setEnumerationStatus("Completed");
    } else {
      setFarmSize("");
    }
  }, []);
  const formatDate = (dateStr) => {
    if (dateStr.startsWith("0")) {
      return dateStr.split("")[1];
    }
    return dateStr;
  };
  function handleDateChange(event) {
    setLimit(100);
    setStart(0);
    setSelectedDate(event.target.value);
    // console.log(event.type);

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    if (event.target.value) {
      let dateArray = event.target.value?.split("-");
      let newdateDateFormat = `${monthNames[dateArray[1] - 1]} ${formatDate(
        dateArray[2]
      )}, ${dateArray[0]}`;

      setUpdatedDate(`*${newdateDateFormat}`);
    } else if (event.type === "change") {
      setUpdatedDate("");
    }
  }

  const {
    // setDateSearch,
    // dateSearch,
    openModal,
    setOpenModal,
    handleOnClose,
    enumerations,
    setLimit,
    setEnumerations,
    limit,
    loading,
    setLoading,
    start,
    setStart,
    end,
    setEnd,
    pageCount,
    setpageCount,
    total,
    setTotal,
    searchResult,
    setSearchResult,
    statusOptions,
    setStatusOptions,
    searchEnumerations,
    fetchEnumerations,
    advancedSearchOptions,
    setAdvancedSearchOptions,
  } = useEnumerationsQuery({
    status: enumerationStatus,
    NIN: NINStatus,
    gender: householdGender,
    dateSelected: updatedDate,
    state: selectedState,
    lga: selectedLga,
    farmSize: farmSize,
  });

  const handleSearch = () => {
    fetchEnumerations();
  };

  const handlePageClick = async (data) => {
    let startCounter = data.selected + 1;
    let startr = startCounter * limit - limit;
    let endr = startr + limit;
    setEnd(endr);
    setStart(startr);
  };

  const handleChangeState = (itemValue) => {
    const filteredCity = states.find((item) => item.state === itemValue);
    const newFilteredCity = filteredCity === undefined ? "" : filteredCity.lgas;

    setFilteredLga(newFilteredCity);
    return newFilteredCity;
  };

  useEffect(() => {
    handleChangeState(selectedState);
  }, [selectedState]);

  const handleEnumerationStatus = (e) => {
    setLimit(100);
    setStart(0);
    setEnumerationStatus(e.target.value);
  };

  const handleGender = (e) => {
    setLimit(100);
    setStart(0);
    setHouseholdGender(e.target.value);
  };
  const handleFarmSize = (e) => {
    setLimit(100);
    setStart(0);
    setFarmSize(e.target.value);
  };

  const handleNINStatus = (e) => {
    setLimit(100);
    setStart(0);
    setNINStatus(e.target.value);
  };

  const handleState = (e) => {
    setLimit(100);
    setStart(0);
    setselectedState(e.target.value);
  };

  const exportToCSV = (data, filename) => {
    const csv = Papa.unparse(data);
    const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const csvURL = URL.createObjectURL(csvData);
    const tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", filename);
    tempLink.click();
  };
  async function onExportToCsv() {
    setLimit(5000);
    setIsReadyToPrint(true);
  }

  const buildParams = () => {
    const params = {};
    for (let option of advancedSearchOptions) {
      params[option.column] = option.value;
    }

    setObjParams(params);
    return params;
  };
  async function onExport() {
    const tempLimit = 5000; // Number of items to fetch per request

    const pageCount = Math.ceil(total / tempLimit);
    const zip = new JSZip();
    try {
      for (let page = 0; page < pageCount; page++) {
        const tempStart = page * tempLimit;

        let params = {
          Status: enumerationStatus,
          A7_National_ID_Status: NINStatus,
          A6_Household_Gender: householdGender,
          search: searchResult,
          UpdatedAT: selectedDate,
          B1_What_is_your_state_of_residence: selectedState,
          B2_What_is_your_LGA_of_residence: selectedLga,
          E7_Farm_size:
            farmSize === "unvalidated"
              ? " "
              : farmSize === "*.*"
              ? farmSize
              : null,
          start: tempStart,
          limit: tempLimit,
        };

        setLoading(true);
        const _params = buildParams();
        const __params = {
          ..._params,
          ...params,
        };

        const np = trimObj(__params);
        const res = await api.get(`Enumeration/filter`, {
          headers: header(),
          params: np,
        });

        const data = await res.data; // Retrieved data from the backend
        const csvContent = Papa.unparse(data.data.content);
        zip.file(`Enumeration_${page}.csv`, csvContent);
        // const total = data.data.total;
        // setTotal(total);
        // setpageCount(Math.ceil(total / limit));
        // setEnumerations(data.data.content);
        // // Generate CSV file
        // const csv = Papa.unparse(data.data.content);
        // const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        // const csvURL = URL.createObjectURL(csvData);
        // const tempLink = document.createElement("a");
        // tempLink.href = csvURL;
        // tempLink.setAttribute("download", `data_${page}.csv`);
        // tempLink.click();
      }
      const currentTimestamp = new Date().toISOString().replace(/:/g, "-");
      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, `Enumerations_${currentTimestamp}.zip`); // Download the ZIP file with timestamp
      setLoading(false); // Set loading state to false after finishing the export process
    } catch (err) {
      setLoading(false);
      setEnumerations([]);
    }
  }
  // useEffect(()=>{
  // },[householdGender])

  return (
    <div>
      <div className="flex justify-between items-start text-xl pt-1">
        Beneficiaries{authUser && authUser.TargetRole} {`(${total})`}
        {/* Seacrh */}
        <div>
          <div className="flex justify-end items-end mb-2">
            {onShowSearch ? (
              <div className="ml-2">
                <button
                  className="bg-white w-[40px]  text-red-600 border-red-600 text-center text-sm flex py-2 px-3 rounded-lg focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={() => setOnShowSearch(false)}
                >
                  <FaRegTimesCircle fontSize={20} className="text-red-600" />
                </button>
              </div>
            ) : (
              <div className="ml-2">
                <button
                  className="bg-primary w-[40px]  text-white text-center text-sm flex py-2 px-3 rounded-lg focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={() => setOnShowSearch(true)}
                >
                  <HiOutlineSearch fontSize={20} className="text-white" />
                </button>
              </div>
            )}
          </div>
          {onShowSearch && (
            <div className="flex align-baseline">
              <div className="relative">
                <HiOutlineSearch
                  fontSize={20}
                  className="text-gray-400 absolute top-[50%] left-3 -translate-y-1/2"
                />
                <input
                  type="text"
                  name="searchResult"
                  value={searchResult}
                  onChange={(e) => setSearchResult(e.target.value)}
                  placeholder="Search..."
                  className="text-sm focus:ring-green-500 focus:border-green-400   active:outline-none border border-gray-300 w-[16rem] h-10 pl-11 pr-4 rounded-sm"
                />
              </div>

              <div className="ml-2">
                <button
                  className="bg-primary w-full  text-white text-sm flex py-2.5 px-8 rounded-sm focus:outline-none focus:shadow-outline"
                  type="button"
                  onClick={handleSearch}
                >
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <>
                      <HiOutlineSearch
                        fontSize={20}
                        className="text-white mr-2"
                      />
                      <p>Search</p>
                    </>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="bg-white mt-4 p-6">
        <div className="flex items-start py-2 justify-between">
          <div className={" flex gap-3 items-center"}>
            <div>
              <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                Enumeration <br /> Status
              </label>

              <select
                onChange={handleEnumerationStatus}
                value={enumerationStatus}
                className=" border border-gray-200 text-[10px] font-medium px-1 py-2 text-gray-500 rounded-md  focus:ring-green-500 focus:border-green-500 block w-full "
              >
                <option selected value={""}>
                  --
                </option>
                <option value="Pending">Pending</option>
                <option value="Completed">Completed</option>
              </select>
            </div>

            <div>
              <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                NIN <br />
                Status
              </label>

              <select
                onChange={handleNINStatus}
                value={NINStatus}
                className=" border border-gray-200 text-[10px] font-medium px-1 py-2 text-gray-500 rounded-md  focus:ring-green-500 focus:border-green-500 block w-full "
              >
                <option deselected>--</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div>
              <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                Household <br /> Gender
              </label>

              <select
                onChange={handleGender}
                value={householdGender}
                className=" border border-gray-200 text-[10px] font-medium px-1 py-2 text-gray-500 rounded-md  focus:ring-green-500 focus:border-green-500 block w-full "
              >
                <option selected value="all">
                  --
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>

            <div>
              <label
                htmlFor="date"
                className="block mb-2 text-xs font-regular text-gray-900 dark:text-white "
              >
                Select <br /> a date:
              </label>{" "}
              <input
                className="border border-gray-200 text-xs py-3 rounded-md text-gray-600  "
                type="date"
                id="date"
                name="date"
                value={selectedDate}
                onChange={handleDateChange}
              />{" "}
            </div>

            {authUser && authUser.Role === "Analyst" && (
              <div>
                <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                  State
                </label>
                <br />
                <select
                  onChange={handleState}
                  value={selectedState}
                  className="border border-gray-200 text-[10px] font-medium px-1 py-2 text-gray-500 rounded-md  focus:ring-green-500 focus:border-green-500 block w-full "
                >
                  <option selected value="">
                    --
                  </option>
                  {tempState.map((opts) => (
                    <option value={opts}>{opts}</option>
                  ))}
                </select>
              </div>
            )}

            {authUser && authUser.Role === "Analyst" && (
              <div>
                <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                  LGA
                </label>
                <br />
                <select
                  onChange={(e) => setSelectedLga(e.target.value)}
                  value={selectedLga}
                  className=" border border-gray-200 text-[10px] font-medium px-1 py-2 text-gray-500 rounded-md  focus:ring-green-500 focus:border-green-500 block w-full "
                >
                  <option selected value="">
                    LGA
                  </option>
                  {filteredLga.length &&
                    filteredLga.map((opts) => (
                      <option value={opts}>{opts}</option>
                    ))}
                </select>
              </div>
            )}

            {/* Has Farm Size */}
            <div>
              <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                Farm Size
              </label>

              <select
                onChange={handleFarmSize}
                value={farmSize}
                className=" border border-gray-200 text-[10px] font-medium px-1 py-2 text-gray-500 rounded-md  focus:ring-green-500 focus:border-green-500 block w-full "
              >
                <option>--</option>
                <option value="*.*">Validated</option>
                <option value="unvalidated">Unvalidated</option>
              </select>
            </div>
          </div>
          {/* Advance Search and Clear */}
          <div className="flex">
            {/* Clear */}
            <div
              className="flex items-center cursor-pointer"
              onClick={() => window.location.reload()}
            >
              <TbFilterOff className="text-xs text-red-500 mr-1" />
              <p className="text-xs text-red-500">Clear</p>
            </div>
            {/* Advance Search */}
            <div className="py-6 pl-4">
              <button
                data-modal-target="popup-modal"
                data-modal-toggle="popup-modal"
                className="bg-white w-full text-[10px] text-primary py-[8px] px-2 flex justify-center items-center rounded-sm border border-primary focus:outline-none focus:shadow-outline"
                type="button"
                onClick={() => setOpenModal(true)}
              >
                <FiSettings className="text-primary mr-2" />
                Advanced Search
              </button>
              <button
                data-modal-target="popup-modal"
                data-modal-toggle="popup-modal"
                className="text-white w-full text-[10px] bg-primary py-[8px] px-2 flex justify-center items-center rounded-sm border border-primary focus:outline-none focus:shadow-outline"
                type="button"
                onClick={() => onExport()}
              >
                Export to CSV
                <TiExportOutline className="text-white ml-2" />
              </button>
              {openModal && (
                <Modal
                  // fetchEnumerations={handleSearch}
                  onClose={handleOnClose}
                  advancedSearchOptions={advancedSearchOptions}
                  setAdvancedSearchOptions={setAdvancedSearchOptions}
                  fetchEnumerations={fetchEnumerations}
                />
              )}
            </div>
          </div>
          {/* 
          <div>
              <label
                className="block mb-2 text-xs font-regular text-gray-900 dark:text-white "
              >
                Marital Status
              </label>
              <select
                onChange={(e) => setMaritalStatus(e.target.value)}
                value={householdGender}
                className=" border border-gray-200 text-[10px] font-medium px-1 py-2 text-gray-500 rounded-md  focus:ring-green-500 focus:border-green-500 block w-full " >
                <option selected value="all">
                  --All Gender--
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
          
          </div> */}
        </div>

        <div
          className={` ${styles.tablewrp} relative scroll-div overflow-scroll mt-4 shadow-md  w-100% sm:rounded-lg`}
        >
          {loading ? (
            <div className=" m-12  justify-items-center justify-center">
              {" "}
              <PulseLoader
                className=" m-12 justify-center"
                color={"#46A576"}
                loading={loading}
                // cssOverride={override}
                size={14}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <div>
              <table
                className={` w-full  text-sm text-left text-gray-500 dark:text-gray-400`}
              >
                <thead className="text-xs sticky top-0 right-0 left-0 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    {/* <th scope="col" className="p-4">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label for="checkbox-all-search" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </th> */}

                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      A3 Farmers ID
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      A3 First Name
                    </th>
                    <th scope="col" className="px-6 whitespace-nowrap  py-3">
                      A4 Middle Name
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      A5 Last Name
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Agent First Name
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Agent Last Name
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      A6 Gender
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      A7 Have NIN
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      A8 D.O.B
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      A9 Phone Number
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      A10 Marital Status
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      A11 Eductaion Level
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      A12 Household Size
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      A13 House Status
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      B1 State
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      B2 LGA
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      B3 Electoral Ward
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      B4 Community
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      B5 Residential Address
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      B6 Why Live in this community
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      B7 Farmer House cordinate
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      B8 Nearest Landmark
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      C1 Means of Identification
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      C2 ID Front View
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      C3 ID Front View
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      C4 Farmer Photo
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      D1 How_Do_You_Fund_Raise_Capital_For_Your_Farm
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      D2 Do_you_have_a_bank_account
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      D3_Bank_Account_Number
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      D4_Bank_Name
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      D5_BVN_Number
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      E1_Do_You_have_Multiple_Farms
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      E2_Do_you_Own_the_land_you_Farm
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      E3_What_is_the_source_of_your_Farm_Labour
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      E4_what_is_your_Farm_Source_of_Water
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      E5_Farm_Photo
                    </th>
                    {/* <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  E6_Geo_Mapping
                </th> */}
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      E7_Farm_size
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      E8_Farm_Shape
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      E9_agricultural_Intervention
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      F1_Farming_type
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      F2_Farming_mode_sub_items
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      F3_challenges_affecting_you_as_a_Farmer
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      F4_farm_records
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      F5_Farmers_Association
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      F6_What_factors_can_improve_your_yield
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      F7_Where_do_you_sell_you_produce
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      G1_What_is_Farmer_Disability_Status
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      G2_Field_agents_comments
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      SEX_AGE_Category
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Disability_Category
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Displacemment_Category
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Cluster_ID
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Updated Date
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      status
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className=" pt-8">
                  {enumerations.length &&
                    enumerations.map((farmer) => (
                      <tr
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 pt-8  hover:bg-gray-50 dark:hover:bg-gray-600"
                        key={farmer.UID}
                        onClick={() =>
                          navigate(`/householddetail/${farmer.UID}`)
                        }
                      >
                        <TableData>
                          <Link
                            className="border-none"
                            // to={`/householddetail/${farmer.UID}`}
                          >
                            {farmer.UID}
                          </Link>
                        </TableData>
                        <TableData>
                          {" "}
                          <Link
                          //  to={`/householddetail/${farmer.UID}`}
                          >
                            {farmer.A3_What_is_your_First_Name}
                          </Link>
                        </TableData>
                        <TableData>
                          <Link
                          //  to={`/householddetail/${farmer.UID}`}
                          >
                            {farmer.A4_What_is_your_Middle_Name}
                          </Link>
                        </TableData>
                        <TableData>
                          <Link
                          // to={`/householddetail/${farmer.UID}`}
                          >
                            {farmer.A5_What_is_your_Last_Name}
                          </Link>{" "}
                        </TableData>
                        <TableData>{farmer.Agent_First_Name}</TableData>
                        <TableData>{farmer.Agent_Second_Name}</TableData>
                        <TableData>{farmer.A6_Household_Gender}</TableData>
                        <TableData>{farmer.A7_National_ID_Status}</TableData>
                        <TableData>
                          {farmer.A8_What_is_your_Date_of_Birth}{" "}
                        </TableData>
                        <TableData>
                          {farmer.A9_What_is_your_Phone_Number}
                        </TableData>
                        <TableData>
                          {farmer.A10_What_is_your_Marital_Status}
                        </TableData>
                        <TableData>
                          {farmer.A11_What_is_your_Educational_level}
                        </TableData>
                        <TableData>{farmer.A12_Household_Size}</TableData>
                        <TableData>
                          {farmer.A13_What_is_your_status_in_your_household}
                        </TableData>
                        <TableData>
                          {farmer.B1_What_is_your_state_of_residence}
                        </TableData>
                        <TableData>
                          {farmer.B2_What_is_your_LGA_of_residence}
                        </TableData>
                        <TableData>
                          {farmer.B3_What_is_your_Electoral_Ward_of_Residence}
                        </TableData>
                        <TableData>
                          {farmer.B4_What_is_your_Community_of_Residence}
                        </TableData>
                        <TableData>
                          {farmer.B5_What_is_your_Residential_Address}
                        </TableData>
                        <TableData>
                          {farmer.B6_Why_do_you_live_in_this_community}
                        </TableData>
                        <TableData>
                          {farmer.B7_Farmers_House_coordinate}
                        </TableData>
                        <TableData>{farmer.B8_Nearest_Landmark}</TableData>

                        <TableData>
                          {farmer.C1_What_is_your_Means_of_identification}
                        </TableData>
                        <TableData>
                          {farmer.C2_Farmer_ID_Front_View_Upload}
                        </TableData>
                        <TableData>
                          {farmer.C3_Farmer_ID_Back_View_Upload}
                        </TableData>
                        <TableData>{farmer.C4_Farmer_Photo}</TableData>
                        <TableData>
                          {
                            farmer.D1_How_Do_You_Fund_Raise_Capital_For_Your_Farm
                          }
                        </TableData>
                        <TableData>
                          {farmer.D2_Do_you_have_a_bank_account}
                        </TableData>
                        <TableData>{farmer.D3_Bank_Account_Number}</TableData>
                        <TableData>{farmer.D4_Bank_Name}</TableData>
                        <TableData>{farmer.D5_BVN_Number}</TableData>
                        <TableData>
                          {farmer.E1_Do_You_have_Multiple_Farms}
                        </TableData>
                        <TableData>
                          {farmer.E2_Do_you_Own_the_land_you_Farm}
                        </TableData>
                        <TableData>
                          {farmer.E3_What_is_the_source_of_your_Farm_Labour}
                        </TableData>
                        <TableData>
                          {farmer.E4_what_is_your_Farm_Source_of_Water}
                        </TableData>
                        <TableData>{farmer.E5_Farm_Photo}</TableData>
                        {/* <TableData>{farmer.E6_Geo_Mapping}</TableData> */}
                        <TableData>{farmer.E7_Farm_size}</TableData>
                        <TableData>{farmer.E8_Farm_Shape}</TableData>
                        <TableData>
                          {
                            farmer.E9_Are_you_Benefiting_from_any_agricultural_Intervention
                          }
                        </TableData>
                        <TableData>
                          {farmer.F1_What_type_of_Farming_do_you_engage_in}
                        </TableData>
                        <TableData>
                          {farmer.F2_Farming_mode_sub_items}
                        </TableData>
                        <TableData>
                          {
                            farmer.F3_What_are_the_challenges_affecting_you_as_a_Farmer
                          }
                        </TableData>
                        <TableData>
                          {farmer.F4_Do_you_keep_your_farm_records}
                        </TableData>
                        <TableData>
                          {
                            farmer.F5_Are_you_a_member_of_any_Farmers_Association
                          }
                        </TableData>
                        <TableData>
                          {farmer.F6_What_factors_can_improve_your_yield}
                        </TableData>
                        <TableData>
                          {farmer.F7_Where_do_you_sell_you_produce}
                        </TableData>
                        <TableData>
                          {farmer.G1_What_is_Farmer_Disability_Status}
                        </TableData>
                        <TableData>{farmer.G2_Field_agents_comments}</TableData>
                        <TableData>{farmer.SEX_AGE_Category}</TableData>
                        <TableData>{farmer.Disability_Category}</TableData>
                        <TableData>{farmer.Displacemment_Category}</TableData>
                        <TableData>{farmer.Cluster_ID}</TableData>
                        <TableData>{farmer.UpdatedAT}</TableData>
                        <TableData>{getStatus(farmer.Status)}</TableData>

                        <td className="px-6 py-2">
                          <span className="flex items-center gap-2">
                            <Link to={`/householddetail/${farmer.UID}`}>
                              <GrView />
                            </Link>
                            {/* <FiEdit /> <MdDeleteOutline fontSize={20} /> */}
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {isReadyToPrint && (
                <div
                  className="h-full w-full absolute top-0 left-0 flex justify-center items-center"
                  style={{ backgroundColor: "rgba(0,0,0,0.5" }}
                >
                  <div className="flex gap-5">
                    <button
                      className="bg-primary w-full text-sm  text-white py-[8px] px-10  flex justify-center items-center rounded-sm border border-primary focus:outline-none focus:shadow-outline"
                      type="button"
                      onClick={() => onExport()}
                    >
                      Export
                    </button>
                    <button
                      className="bg-white w-full text-sm  text-red-600 py-[8px] px-10 flex justify-center items-center rounded-sm border border-red-600 focus:outline-none focus:shadow-outline"
                      type="button"
                      onClick={() => {
                        setLimit(100);
                        setIsReadyToPrint(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <nav
          className="flex items-center justify-between pt-4"
          aria-label="Table navigation"
        >
          <div className="flex items-center">
            <div className="mt-4 ">
              <form>
                {" "}
                <label
                  // for="countries"
                  className="block mb-2 text-sm font-regular text-gray-900 dark:text-white"
                >
                  No of Data Per Page
                </label>
                <select
                  id="countries"
                  className=" border border-gray-200 text-gray-500 text-sm rounded-md font-light focus:ring-green-500 focus:border-green-400 block w-full p-2.5 "
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <option selected>{limit}</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                </select>
              </form>
            </div>
          </div>

          <div className="flex items-center gap-4">
            <div className=" gap-4 ">
              {/* <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                Showing{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {start}-{end}
                </span>{" "}
                of{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {total}
                </span>
              </span> */}
            </div>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"inline-flex items-center -space-x-px"}
              pageClassName={
                "px-3 py-2 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              pageLinkClassName={""}
              previousClassName={
                "block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              previousLinkClassName={""}
              nextClassName={
                "block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              nextLinkClassName={""}
              breakClassName={
                "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              breakLinkClassName={
                "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              activeClassName={
                "z-10 px-3 py-2 leading-tight text-white border border-blue-400 bg-primary hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
              }
            />
          </div>
        </nav>
      </div>
    </div>
  );
}

const Modal = ({
  onClose,
  setAdvancedSearchOptions,
  advancedSearchOptions,
  fetchEnumerations,
}) => {
  // const [val, setVal] = useState([""]);

  const [searchColumnOptions, setSearchColumnOptions] = useState([]);
  const handleNewInput = () => {
    setAdvancedSearchOptions([
      ...advancedSearchOptions,
      {
        column: "",
        value: "",
      },
    ]);
  };

  // useEffect(() => {
  //   if(!advancedSearchOptions.length){
  //   handleNewInput();
  // }
  // }, []);

  // const handleChange = (onChangeValue, i) => {
  //   const inputData = [...val];
  //   inputData[i] = onChangeValue.target.value;
  //   setVal(inputData);
  // };

  const handleDelete = (i) => {
    const deleteVal = [...advancedSearchOptions];
    deleteVal.splice(i, 1);
    setAdvancedSearchOptions(deleteVal);
  };

  const handleSelectFormChange = (e, index) => {
    const newValue = [...advancedSearchOptions];
    advancedSearchOptions[index].column = e.target.value;
    setAdvancedSearchOptions(newValue);
  };

  const handleInputFormChange = (e, index) => {
    const newValue = [...advancedSearchOptions];
    advancedSearchOptions[index].value = e.target.value;
    setAdvancedSearchOptions(newValue);
  };
  useEffect(() => {
    setSearchColumnOptions(getColumnData());
  }, []);

  const advSearch = () => {
    onClose();
    fetchEnumerations();
  };

  console.log(advancedSearchOptions);

  return (
    <div
      id="popup-modal"
      tabIndex="-1"
      className="fixed rounded-lg  z-50 bg-gray-600 bg-opacity-20 backdrop-blur-xs right-5 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full w-full"
    >
      <div className=" w-full flex flex-col flex-1  min-h-screen bg-white max-w-sm absolute top-0 right-0 ">
        <div className="relative flex flex-col flex-1 bg-white shadow dark:bg-gray-700">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-900 bg-transparent hover:bg-green-900 hover:text-gray-100  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-hide="popup-modal"
            onClick={onClose}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>

          <div className="p-6 ">
            <div className="flex justify-between items-center text-xl pt-1">
              Advanced Search
            </div>
            <p className="font-light text-sm">Select column to search from</p>
          </div>

          <div className="p-6">
            {advancedSearchOptions.map((data, i) => {
              return (
                <div className="mt-6 border p-2 rounded-md">
                  <div className=" mt-2">
                    <div className=" ">
                      <button
                        className="top-0 text-red text-sm right-0 p-2"
                        onClick={() => handleDelete(i)}
                      >
                        {" "}
                        <span className="flex items-center">
                          <BsTrash fontSize={14} color="red">
                            {" "}
                          </BsTrash>{" "}
                          Delete
                        </span>{" "}
                      </button>
                    </div>
                    {/* <label className="block text-gray-700 mb-2 text-sm">
                Select Engagement Type
              </label> */}
                    <select
                      value={data.column}
                      onChange={(e) => {
                        handleSelectFormChange(e, i);
                      }}
                      className="text-sm bg-gray-50 appearance-none border-gray-300 rounded w-full py-3 px-3  text-gray-500  leading-tight focus:outline-none focus:shadow-outline"
                    >
                      {searchColumnOptions.map((opts, i) => (
                        <option>{opts}</option>
                      ))}

                      {/* <option disabled selected>
                        --Select Search Field--
                      </option>
                      <option value="Lagos">Farmers_One</option>
                      <option value="Lagos">Farmers_Two</option>
                      <option value="Lagos">Farmers_Three</option> */}
                    </select>
                  </div>

                  <div className="mt-2">
                    <input
                      value={data.value}
                      onChange={(e) => handleInputFormChange(e, i)}
                      className="text-sm bg-gray-50 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                </div>
              );
            })}

            <button
              onClick={() => handleNewInput()}
              data-modal-target="popup-modal"
              data-modal-toggle="popup-modal"
              className="bg-green-50 w-full text-base text-primary border py-3 px-10 border-primary rounded-md border-dashed mt-4 focus:outline-none focus:shadow-outline"
              type="button"
            >
              Add Search Column
            </button>
          </div>
        </div>
        <div className="flex bg-white flex-col m-6">
          <button
            data-modal-target="popup-modal"
            onClick={advSearch}
            data-modal-toggle="popup-modal"
            className="bg-primary  w-full text-base text-white py-3 px-10 rounded-lg focus:outline-none focus:shadow-outline"
            type="button"
          >
            Submit Search
          </button>
        </div>
      </div>
    </div>
  );
};
