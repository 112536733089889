import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { TbFilterOff } from "react-icons/tb";
import { TiExportOutline } from "react-icons/ti";
import { FiSettings } from "react-icons/fi";
import PulseLoader from "react-spinners/PulseLoader";
import ReactPaginate from "react-paginate";
import { useAuthContext } from "../../../src/Context/AuthContext";
import { BsTrash } from "react-icons/bs";
import { states } from "../../lib/constants/states";
import { getDateFromCreated } from "../../lib/constants/functions";
import useIndirectOutrechQuery from "../../hooks/useIndirectOutreach";

const TableData = ({ children }) => <td className="px-6 py-2">{children}</td>;

export default function IndirectListings() {
  const navigate = useNavigate();
  const { authUser } = useAuthContext();
  const [filteredLga, setFilteredLga] = useState([""]);
  const [selectedLga, setSelectedLga] = useState();
  const [selectedState, setselectedState] = useState();
  const [selectedDate, setSelectedDate] = useState("");
  const {
    setOpenModal,
    openModal,
    handleOnClose,
    setLimit,
    outreaches,
    fetchOutreaches,
    limit,
    loading,
    setStart,
    setEnd,
    pageCount,
    total,
    searchParams,
    setSearchParams,
    setDateFilter,
    onExport,
    advancedSearchOptions,
    setAdvancedSearchOptions,
  } = useIndirectOutrechQuery({
    state: selectedState,
    lga: selectedLga,
  });
  const tempState = ["Adamawa", "Kaduna", "Nasarawa", "FCT"];
  const handlePageClick = async (data) => {
    let startCounter = data.selected + 1;
    let startr = startCounter * limit - limit;
    let endr = startr + limit;
    setEnd(endr);
    setStart(startr);
  };

  const handleChangeState = (itemValue) => {
    const filteredCity = states.find((item) => item.state === itemValue);
    const newFilteredCity = filteredCity === undefined ? "" : filteredCity.lgas;

    setFilteredLga(newFilteredCity);
    return newFilteredCity;
  };

  useEffect(() => {
    handleChangeState(selectedState);
  }, [selectedState]);
  function handleDateChange(event) {
    console.log(event.target.value);
    setLimit(100);
    setStart(0);
    setSelectedDate(event.target.value);
    if (event.target.value) {
      let dateArray = event.target.value?.split("-");
      let newdateDateFormat = `*${dateArray[0]}-*${dateArray[1]}-*${dateArray[2]}*`;
      setDateFilter(newdateDateFormat);
    }
  }
  const handleState = (e) => {
    setLimit(100);
    setStart(0);
    setselectedState(e.target.value);
  };
  return (
    <div>
      <div className="flex justify-between items-start text-xl pt-1">
        {/* Household Input Distributions{authUser && authUser.TargetRole}{" "} */}
        Indirect Outreaches {`(${total})`}
      </div>

      <div className="bg-white mt-4 p-6">
        <div className="flex items-center py-2 justify-between">
          <div className={" flex gap-3 items-center"}>
            {/* Date */}
            <div>
              <label
                htmlFor="date"
                className="block mb-2 text-xs font-regular text-gray-900 dark:text-white "
              >
                Date:
              </label>{" "}
              <input
                className="border border-gray-200 text-xs py-3 rounded-md text-gray-600  "
                type="date"
                id="date"
                name="date"
                value={selectedDate}
                onChange={handleDateChange}
              />{" "}
            </div>
            {/* State */}
            {authUser && authUser.Role === "Analyst" && (
              <div>
                <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                  State
                </label>
                <select
                  onChange={handleState}
                  value={selectedState}
                  className="border border-gray-200 text-[10px] font-medium px-1 py-2 text-gray-500 rounded-md  focus:ring-green-500 focus:border-green-500 block w-full "
                >
                  <option selected value="">
                    --
                  </option>
                  {tempState.map((opts) => (
                    <option value={opts}>{opts}</option>
                  ))}
                </select>
              </div>
            )}
            {/* LGA */}
            {authUser && authUser.Role === "Analyst" && (
              <div>
                <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                  LGA
                </label>
                <select
                  onChange={(e) => setSelectedLga(e.target.value)}
                  value={selectedLga}
                  className=" border border-gray-200 text-[10px] font-medium px-1 py-2 text-gray-500 rounded-md  focus:ring-green-500 focus:border-green-500 block w-full "
                >
                  <option selected value="">
                    LGA
                  </option>
                  {filteredLga.length &&
                    filteredLga.map((opts) => (
                      <option value={opts}>{opts}</option>
                    ))}
                </select>
              </div>
            )}
          </div>
          {/* Export and CLear */}
          <div className="flex items-center">
            <div
              className="flex items-center cursor-pointer mr-5"
              onClick={() => window.location.reload()}
            >
              <TbFilterOff className="text-xs text-red-500 mr-1" />
              <p className="text-xs text-red-500">Clear</p>
            </div>
            <div>
              <button
                data-modal-target="popup-modal"
                data-modal-toggle="popup-modal"
                className="bg-white w-[120px] text-[10px] text-primary py-[8px] px-2 flex justify-center items-center rounded-sm border border-primary focus:outline-none focus:shadow-outline"
                type="button"
                onClick={() => setOpenModal(true)}
              >
                <FiSettings className="text-primary mr-2" />
                Advanced Search
              </button>
              <button
                data-modal-target="popup-modal"
                data-modal-toggle="popup-modal"
                className="text-white w-[120px] text-[10px] bg-primary py-[8px] px-2 flex justify-center items-center rounded-sm border border-primary focus:outline-none focus:shadow-outline"
                type="button"
                onClick={() => onExport()}
              >
                Export to CSV
                <TiExportOutline className="text-white ml-2" />
              </button>
            </div>
            {/* Advance Search */}
            <div className="py-6 pl-4">
              {openModal && (
                <Modal
                  onClose={handleOnClose}
                  advancedSearchOptions={advancedSearchOptions}
                  setAdvancedSearchOptions={setAdvancedSearchOptions}
                  fetchOutreaches={fetchOutreaches}
                  searchParams={searchParams}
                  setSearchParams={setSearchParams}
                />
              )}
            </div>
          </div>
        </div>

        <div
          className={`max-h-[70vh] relative scroll-div overflow-scroll mt-4 shadow-md  w-100% sm:rounded-lg`}
        >
          {loading ? (
            <div className=" m-12  justify-items-center justify-center">
              {" "}
              <PulseLoader
                className=" m-12 justify-center"
                color={"#46A576"}
                loading={loading}
                // cssOverride={override}
                size={14}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <div>
              <table
                className={` w-full  text-sm text-left text-gray-500 dark:text-gray-400`}
              >
                <thead className="text-xs sticky top-0 right-0 left-0 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      FirstName
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      MiddleName
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      LastName
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Gender
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      MaritalStatus
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      HouseholdStatus
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      PhoneNo
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      AltPhone
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Birthdate
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      State
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      LGA
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Ward
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Community
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Address
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      LocationType
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Education
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Student
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      CurrentEducationLevel
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      StudentType
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      EmploymentStatus
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      EmploymentType
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      EmploymentSector
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      WorkType
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      WorkTypeOthers
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      EngagementPeriodUnit
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      EngagementPeriod
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      WorkStatus
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      WorkSource
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      WorkSourceDiscription
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      See
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Hear
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Walk
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Remember
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      SelfCare
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      Communicate
                    </th>
                    {/* <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WhyLiveHere
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Picture
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Coordinate
                  </th> */}
                    {/* <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Comment
                  </th> */}
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      DisablilityStatus
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      DisplacementStatus
                    </th>
                    <th scope="col" className="px-6 py-3 whitespace-nowrap">
                      CreatedAT
                    </th>
                  </tr>
                </thead>
                <tbody className=" pt-8">
                  {outreaches?.length &&
                    outreaches?.map((outreach, index) => (
                      <tr
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 pt-8  hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer"
                        key={index}
                      >
                        <TableData>{outreach?.FirstName}</TableData>
                        <TableData>{outreach?.MiddleName}</TableData>
                        <TableData>{outreach?.LastName}</TableData>
                        <TableData>{outreach?.Gender}</TableData>
                        <TableData>{outreach?.MaritalStatus}</TableData>
                        <TableData>{outreach?.HouseholdStatus}</TableData>
                        <TableData>{outreach?.PhoneNo}</TableData>
                        <TableData>{outreach?.AltPhone}</TableData>
                        <TableData>
                          {getDateFromCreated(outreach?.Birthdate)}
                        </TableData>
                        <TableData>{outreach?.State}</TableData>
                        <TableData>{outreach?.LGA}</TableData>
                        <TableData>{outreach?.Ward}</TableData>
                        <TableData>{outreach?.Community}</TableData>
                        <TableData>{outreach?.Address}</TableData>
                        <TableData>{outreach?.LocationType}</TableData>
                        <TableData>{outreach?.Education}</TableData>
                        <TableData>{outreach?.Student}</TableData>
                        <TableData>{outreach?.CurrentEducationLevel}</TableData>
                        <TableData>{outreach?.StudentType}</TableData>
                        <TableData>{outreach?.EmploymentStatus}</TableData>
                        <TableData>{outreach?.EmploymentType}</TableData>
                        <TableData>{outreach?.EmploymentSector}</TableData>
                        <TableData>{outreach?.WorkType}</TableData>
                        <TableData>{outreach?.WorkTypeOthers}</TableData>
                        <TableData>{outreach?.EngagementPeriodUnit}</TableData>
                        <TableData>{outreach?.EngagementPeriod}</TableData>
                        <TableData>{outreach?.WorkStatus}</TableData>
                        <TableData>{outreach?.WorkSource}</TableData>
                        <TableData>{outreach?.WorkSourceDiscription}</TableData>
                        <TableData>{outreach?.See}</TableData>
                        <TableData>{outreach?.Hear}</TableData>
                        <TableData>{outreach?.Walk}</TableData>
                        <TableData>{outreach?.Remember}</TableData>
                        <TableData>{outreach?.SelfCare}</TableData>
                        <TableData>{outreach?.Communicate}</TableData>
                        {/* <TableData>{outreach?.WhyLiveHere}</TableData> */}
                        {/* <TableData>{outreach?.Picture}</TableData>
                      <TableData>{outreach?.Coordinate}</TableData> */}
                        {/* <TableData>{outreach?.Comment}</TableData> */}
                        <TableData>{outreach?.DisablilityStatus}</TableData>
                        <TableData>{outreach?.DisplacementStatus}</TableData>
                        <TableData>
                          {getDateFromCreated(outreach?.CreatedAT)}
                        </TableData>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <nav
          className="flex items-center justify-between pt-4"
          aria-label="Table navigation"
        >
          <div className="flex items-center">
            <div className="mt-4 ">
              <form>
                {" "}
                <label
                  // for="countries"
                  className="block mb-2 text-sm font-regular text-gray-900 dark:text-white"
                >
                  No of Data Per Page
                </label>
                <select
                  id="countries"
                  className=" border border-gray-200 text-gray-500 text-sm rounded-md font-light focus:ring-green-500 focus:border-green-400 block w-full p-2.5 "
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <option selected>{limit}</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                </select>
              </form>
            </div>
          </div>

          <div className="flex items-center gap-4">
            <div className=" gap-4 ">
              {/* <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                Showing{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {start}-{end}
                </span>{" "}
                of{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {total}
                </span>
              </span> */}
            </div>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"inline-flex items-center -space-x-px"}
              pageClassName={
                "px-3 py-2 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              pageLinkClassName={""}
              previousClassName={
                "block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              previousLinkClassName={""}
              nextClassName={
                "block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              nextLinkClassName={""}
              breakClassName={
                "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              breakLinkClassName={
                "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              activeClassName={
                "z-10 px-3 py-2 leading-tight text-white border border-blue-400 bg-primary hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
              }
            />
          </div>
        </nav>
      </div>
    </div>
  );
}

const Modal = ({
  onClose,
  setAdvancedSearchOptions,
  advancedSearchOptions,
  fetchOutreaches,
  searchParams,
  setSearchParams,
}) => {
  const searchColumnOptions = [
    "AgentID",
    "FirstName",
    "MiddleName",
    "LastName",
    "Gender",
    "Community",
    "MaritalStatus",
    "AgeCategory",
    "GenderAgeCategory",
    "DisplacementStatus",
    "DisablilityStatus",
    "Education",
    "Student",
    "HouseholdStatus",
  ];
  let selectClass =
    "text-sm bg-gray-50 appearance-none border-gray-300 rounded w-full py-3 px-3  text-gray-500  leading-tight focus:outline-none focus:shadow-outline";
  const handleNewInput = () => {
    setAdvancedSearchOptions([
      ...advancedSearchOptions,
      {
        column: "",
        value: "",
      },
    ]);
  };
  const marital_data = [
    "Single",
    "Married",
    "Divorced",
    "Separated",
    "Widowed",
  ];

  const household_status = [
    "Head of Household",
    "Spouse to Head of Household",
    "Child to Household Head",
    "Relative to Household Head or Spouse",
    "Other member of household",
  ];
  const edu_data = [
    "No formal education",
    "Primary School",
    "Secondary School",
    "Post Secondary School (1 - 3 Year Program, ND, NCE)",
    "Post Secondary School (HND)",
    "Post Secondary School (University/Bachelor’s Degree)",
    "Post Secondary School (M.Sc., P.HD)",
  ];
  const student_data = ["Yes", "No"];
  const displacement_data = ["IDP", "Non-IDP"];
  const disability_data = ["PWD", "Non-PWD"];
  const age_data = ["Adult", "Youth"];
  const sexage_data = [
    "Adult Male",
    "Youth Male",
    "Adult Female",
    "Youth Female",
  ];
  const handleDelete = (i) => {
    const deleteVal = [...advancedSearchOptions];
    deleteVal.splice(i, 1);
    setAdvancedSearchOptions(deleteVal);
  };

  const handleSelectFormChange = (e, index) => {
    const newValue = [...advancedSearchOptions];
    advancedSearchOptions[index].column = e.target.value;
    setAdvancedSearchOptions(newValue);
  };
  const handleSelectValue = (e, index, nameID) => {
    const newValue = [...advancedSearchOptions];
    advancedSearchOptions[index].value = e.target.value;
    setAdvancedSearchOptions(newValue);
    setSearchParams((prevState) => ({
      ...prevState,
      [nameID]: e.target.value,
    }));
  };

  const handleInputFormChange = (e, index, nameID) => {
    const newValue = [...advancedSearchOptions];
    advancedSearchOptions[index].value = e.target.value;
    setAdvancedSearchOptions(newValue);
    setSearchParams((prevState) => ({
      ...prevState,
      [nameID]: e.target.value,
    }));
  };

  const advSearch = () => {
    fetchOutreaches();
  };

  return (
    <div
      id="popup-modal"
      tabIndex="-1"
      className="fixed rounded-lg  z-50 bg-gray-600 bg-opacity-20 backdrop-blur-xs right-5 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] md:h-full w-full"
    >
      <div className=" w-full flex flex-col flex-1  min-h-screen bg-white max-w-sm absolute top-0 right-0 ">
        <div className="relative flex flex-col flex-1 bg-white shadow dark:bg-gray-700">
          <button
            type="button"
            className="absolute top-3 right-2.5 text-gray-900 bg-transparent hover:bg-green-900 hover:text-gray-100  rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
            data-modal-hide="popup-modal"
            onClick={onClose}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>

          <div className="p-6 ">
            <div className="flex justify-between items-center text-xl pt-1">
              Advanced Search
            </div>
            <p className="font-light text-sm">Select column to search from</p>
          </div>

          <div className="p-6">
            {advancedSearchOptions.map((data, i) => {
              return (
                <div className="mt-6 border p-2 rounded-md" key={i}>
                  <div className=" mt-2">
                    <div className=" ">
                      <button
                        className="top-0 text-red text-sm right-0 p-2"
                        onClick={() => handleDelete(i)}
                      >
                        {" "}
                        <span className="flex items-center">
                          <BsTrash fontSize={14} color="red">
                            {" "}
                          </BsTrash>{" "}
                          Delete
                        </span>{" "}
                      </button>
                    </div>
                    <select
                      value={data.column}
                      onChange={(e) => {
                        handleSelectFormChange(e, i);
                      }}
                      className="text-sm bg-gray-50 appearance-none border-gray-300 rounded w-full py-3 px-3  text-gray-500  leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option value="">--</option>
                      {searchColumnOptions.map((opts, i) => (
                        <option>{opts}</option>
                      ))}
                    </select>
                  </div>
                  {data.column !== "" && (
                    <div className="mt-2">
                      {data.column === "Gender" ? (
                        <select
                          onChange={(e) => {
                            handleSelectValue(e, i, "Gender");
                          }}
                          className="text-sm bg-gray-50 appearance-none border-gray-300 rounded w-full py-3 px-3  text-gray-500  leading-tight focus:outline-none focus:shadow-outline"
                        >
                          <option value="">--</option>
                          <option value="male">male</option>
                          <option value="female">female</option>
                        </select>
                      ) : data.column === "MaritalStatus" ? (
                        <select
                          onChange={(e) => {
                            handleSelectValue(e, i, "MaritalStatus");
                          }}
                          className={selectClass}
                        >
                          <option value="">--</option>
                          {marital_data.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      ) : data.column === "HouseholdStatus" ? (
                        <select
                          onChange={(e) => {
                            handleSelectValue(e, i, "MaritalStatus");
                          }}
                          className={selectClass}
                        >
                          <option value="">--</option>
                          {household_status.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      ) : data.column === "Education" ? (
                        <select
                          onChange={(e) => {
                            handleSelectValue(e, i, "Education");
                          }}
                          className={selectClass}
                        >
                          <option value="">--</option>
                          {edu_data.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      ) : data.column === "Student" ? (
                        <select
                          onChange={(e) => {
                            handleSelectValue(e, i, "Student");
                          }}
                          className={selectClass}
                        >
                          <option value="">--</option>
                          {student_data.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      ) : data.column === "DisablilityStatus" ? (
                        <select
                          onChange={(e) => {
                            handleSelectValue(e, i, "DisablilityStatus");
                          }}
                          className={selectClass}
                        >
                          <option value="">--</option>
                          {disability_data.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      ) : data.column === "DisplacementStatus" ? (
                        <select
                          onChange={(e) => {
                            handleSelectValue(e, i, "DisplacementStatus");
                          }}
                          className={selectClass}
                        >
                          <option value="">--</option>
                          {displacement_data.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      ) : data.column === "AgeCategory" ? (
                        <select
                          onChange={(e) => {
                            handleSelectValue(e, i, "AgeCategory");
                          }}
                          className={selectClass}
                        >
                          <option value="">--</option>
                          {age_data.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      ) : data.column === "GenderAgeCategory" ? (
                        <select
                          onChange={(e) => {
                            handleSelectValue(e, i, "GenderAgeCategory");
                          }}
                          className={selectClass}
                        >
                          <option value="">--</option>
                          {sexage_data.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <input
                          value={data.value}
                          onChange={(e) =>
                            handleInputFormChange(e, i, data.column)
                          }
                          className="text-sm bg-gray-50 appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                      )}
                    </div>
                  )}
                </div>
              );
            })}

            <button
              onClick={() => handleNewInput()}
              data-modal-target="popup-modal"
              data-modal-toggle="popup-modal"
              className="bg-green-50 w-full text-base text-primary border py-3 px-10 border-primary rounded-md border-dashed mt-4 focus:outline-none focus:shadow-outline"
              type="button"
            >
              Add Search Column
            </button>
          </div>
        </div>
        <div className="flex bg-white flex-col m-6">
          <button
            data-modal-target="popup-modal"
            onClick={advSearch}
            data-modal-toggle="popup-modal"
            className="bg-primary  w-full text-base text-white py-3 px-10 rounded-lg focus:outline-none focus:shadow-outline"
            type="button"
          >
            Submit Search
          </button>
        </div>
      </div>
    </div>
  );
};
