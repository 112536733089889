import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import MoreQuestion from "./MoreQuestion";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useEnumerationProvider } from "../../../Context/EnumerationContext";
import useEnumeration from "../../../hooks/useEnumeration";
import { baseURL } from "../../../constants/URL";

export default function FarmerDetails() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "farmer data",
    onAfterPrint: () => alert("print success"),
  });

  const { id } = useParams();
  const { enumerationDetail } = useEnumeration(id);

  const [agentName, setAgentName] = useState("");

  const SINGLE_FIELD_AGENT_BASE_URL = `${baseURL}/FieldAgents`;

  const assignedAgent = async (id) => {
    const result = await fetch(`${SINGLE_FIELD_AGENT_BASE_URL}/${id}`);
    result.json().then((json) => {
      setAgentName(json.data);
    });
  };

  useEffect(() => {
    enumerationDetail?.FieldAgentID &&
      assignedAgent(enumerationDetail.FieldAgentID);
  }, [enumerationDetail?.FieldAgentID]);

  // console.log(agentName);

  return (
    <div
      ref={componentRef}
      style={{ width: "100%", height: window.innerHeight }}
      className="p-4"
    >
      <div className="flex items-center justify-between">
        <div className="text-2xl mt-2 pt-1 font-medium">Farmer Details</div>
        <button
          className="bg-primary py-3 px-6 rounded text-white"
          onClick={handlePrint}
        >
          print details to pdf
        </button>
      </div>
      {enumerationDetail && (
        <div className="grid lg:grid-cols-2 gap-3 mt-4 lg:grid-rows-1 ">
          <div className="bg-grey-100 rounded ">
            <div className="bg-white rounded p-6">
              <div
                className="h-28 w-28 rounded-full bg-sky-500 bg-cover bg-no-repeat bg-center"
                style={{
                  backgroundImage: `url(${enumerationDetail.C4_Farmer_Photo})`,
                }}
              >
                {" "}
              </div>
              <h2 className="text-2xl mt-2 font-regular">
                {`${enumerationDetail.A3_What_is_your_First_Name} ${enumerationDetail.A4_What_is_your_Middle_Name} ${enumerationDetail.A5_What_is_your_Last_Name}`}
              </h2>
              <div className="gap-y-4 column-gap">
                <div className="mt-6">
                  <span className="flex gap-2 mt-2 items-center">
                    <p> Phone Number:</p>{" "}
                    {enumerationDetail.A9_What_is_your_Phone_Number}
                    <p className="font-extralight"></p>
                  </span>
                </div>

                <div>
                  <span className="flex gap-2 mt-2 items-center">
                    <p>Gender:</p>
                    <p className="font-extralight">
                      {enumerationDetail.A6_Household_Gender}
                    </p>
                  </span>
                </div>
                <div>
                  <span className="flex gap-2 mt-2 items-center">
                    <p>Have NIN:</p>
                    <p className="font-extralight">
                      {enumerationDetail.D2_Do_you_have_a_bank_account}
                    </p>
                  </span>
                </div>

                <div>
                  <span className="flex gap-2 mt-2 items-center">
                    <p>D.O.B:</p>
                    <p className="font-extralight">
                      {enumerationDetail.A8_What_is_your_Date_of_Birth}
                    </p>
                  </span>
                </div>

                <div>
                  <span className="flex gap-2 mt-2 items-center">
                    <p>Marital Status:</p>
                    <p className="font-extralight">
                      {enumerationDetail.MaritalStatus}
                    </p>
                  </span>
                </div>

                <div>
                  <span className="flex gap-2 mt-2 items-center">
                    <p>House Hold Size</p>
                    <p className="font-extralight">
                      {enumerationDetail.HouseHoldSize}
                    </p>
                  </span>
                </div>

                <div>
                  <span className="flex gap-2 mt-2 items-center">
                    <p>State: </p>
                    <p className="font-extralight">
                      {enumerationDetail.B1_What_is_your_state_of_residence}
                    </p>
                  </span>
                </div>

                <div>
                  <span className="flex gap-2 mt-2 items-center">
                    <p>L.G.A: </p>
                    <p className="font-extralight">
                      {enumerationDetail.B2_What_is_your_LGA_of_residence}
                    </p>
                  </span>
                </div>

                <div>
                  <span className="flex gap-2 mt-2 items-center">
                    <p>Ward:</p>
                    <p className="font-extralight">
                      {
                        enumerationDetail.B3_What_is_your_Electoral_Ward_of_Residence
                      }
                    </p>
                  </span>
                </div>

                <div>
                  <span className="flex gap-2 mt-2 items-center">
                    <p>Community:</p>
                    <p className="font-extralight">
                      {enumerationDetail.B4_What_is_your_Community_of_Residence}
                    </p>
                  </span>
                </div>

                <div>
                  <span className="flex gap-2 mt-2 items-center">
                    <p>House Hold Status</p>
                    <p className="font-extralight">
                      {
                        enumerationDetail.A13_What_is_your_status_in_your_household
                      }
                    </p>
                  </span>
                </div>

                <div>
                  <span className="flex gap-2 mt-2 items-center">
                    <p>Education Level</p>
                    <p className="font-extralight">
                      {enumerationDetail.A11_What_is_your_Educational_level}
                    </p>
                  </span>
                </div>

                <div>
                  <span className="flex gap-2 mt-2 items-center">
                    <p>ID Type</p>
                    <p className="font-extralight">
                      {enumerationDetail.A7_National_ID_Status}
                    </p>
                  </span>
                </div>

                <div>
                  <span className="flex gap-2 mt-2 items-center">
                    <p>Num Of Farm</p>
                    <p className="font-extralight">
                      {/* {enumerationDetail.NumOfFarm} */}
                    </p>
                  </span>
                </div>
              </div>
            </div>

            <div className="bg-white flex-1 rounded p-6 mt-6">
              <div className="flex align-center justify-between text-md">
                <p>Assigned Field Agents</p>
                <Link className="text-primary" to="#">
                  Assign a Field Agent
                </Link>
              </div>
              <div className="mt-8">
                <div className="flex items-center mt-2 gap-2">
                  <div
                    className="h-6 w-6 rounded-full bg-sky-500 bg-cover bg-no-repeat bg-center"
                    style={{
                      backgroundImage:
                        'url("https://source.unsplash.com/80x80?face")',
                    }}
                  ></div>

                  <p>
                    {`${enumerationDetail.Agent_First_Name} ${enumerationDetail.Agent_Second_Name}`}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <MoreQuestion enumerationDetail={enumerationDetail} />
        </div>
      )}
    </div>
  );
}
