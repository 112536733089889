import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { api, header } from "../../lib/queries/axiosInstance";
import { useEffect } from "react";
import FarmLandMap from "./FarmLandMap";

const FarmLandsPage = () => {
  const [limit, setLimit] = useState(100);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(start + limit);
  const [pageCount, setpageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [demoPlots, setDemoPlots] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const getDemoPlots = async () => {
      const res = await api.get(`demoplot/0/150`, {
        headers: header(),
      });

      const data = await res.data;
      console.log(data);
      if (data?.data?.content?.length > 0) {
        const total = data.data.total;
        setTotal(total);
        setpageCount(Math.ceil(total / limit));
        setDemoPlots(data.data.content);
      }
      setLoading(false);
    };

    getDemoPlots();
  }, []);
  console.log(demoPlots);
  return (
    <div>{demoPlots?.length > 0 && <FarmLandMap landData={demoPlots} />}</div>
  );
};

export default FarmLandsPage;
