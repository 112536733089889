import React, { useEffect } from "react";
import { useAuthContext } from "../../Context/AuthContext";
import { BsCheck2Circle } from "react-icons/bs";
import { BiErrorCircle } from "react-icons/bi";

const CustomMessage = () => {
  const {
    isMessage,
    setIsMessage,
    messageType,
    setMessageType,
    messageContent,
    setMessageContent,
  } = useAuthContext();
  useEffect(() => {
    setTimeout(() => {
      setMessageContent("");
      setMessageType("");
      setIsMessage(false);
    }, 1500);
  }, []);
  return (
    <div className="customMessageContainer">
      <div className="bg-white rounded-md shadow-lg p-8">
        <div className="flex justify-center items-center">
          {messageType === "success" ? (
            <BsCheck2Circle className="text-primary" size={50} />
          ) : (
            <BiErrorCircle className={"text-red-600"} size={50} />
          )}
        </div>
        <h2
          className={`${
            messageType === "success" ? "text-primary" : "text-red-600"
          } text-center my-3`}
        >
          {messageContent}
        </h2>
      </div>
    </div>
  );
};

export default CustomMessage;
