import React from "react";
import { GoogleMap, Marker, Polygon, Polyline } from "@react-google-maps/api";

const DemoPlotMap = ({ YOUR_LATITUDE, YOUR_LONGITUDE, GeoMapping }) => {
  const mapContainerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = {
    lat: GeoMapping[0].latitude,
    lng: GeoMapping[0].longitude,
  };

  const lineCoordinates = GeoMapping?.map((marker) => ({
    lat: marker.latitude,
    lng: marker.longitude,
  }));
  const markerIcon = {
    url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png", // URL of the green marker icon
    scaledSize: new window.google.maps.Size(40, 40), // Size of the marker icon
  };
  const polygonCoordinates = [...lineCoordinates, lineCoordinates[0]]; // Add the first marker at the end to close the polygon

  return (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={center}
      zoom={17} // Initial zoom level
    >
      {GeoMapping?.map((marker) => (
        <Marker
          key={marker.longitude}
          position={{ lat: marker.latitude, lng: marker.longitude }}
          icon={markerIcon} // Use the custom marker icon
        />
      ))}
      {/* <Polygon
        coordinates={GeoMapping}
        strokeColor="#0000FF"
        strokeOpacity={0.8}
        strokeWeight={2}
        tappable={true}
        fillColor="#0000FF"
        fillOpacity={0.35}
      /> */}
      <Polyline
        path={lineCoordinates}
        options={{
          strokeColor: "#0000FF", // Line color
          strokeOpacity: 1.0, // Line opacity
          strokeWeight: 2, // Line thickness
          fillColor: "green", // Fill color
          fillOpacity: 0.5, // Fill opacity
        }}
      />
      <Polygon
        paths={polygonCoordinates}
        options={{
          fillColor: "#46A578", // Fill color
          fillOpacity: 0.5, // Fill opacity
          strokeColor: "#46A578", // Line color
          strokeOpacity: 1.0, // Line opacity
          strokeWeight: 2, // Line thickness
        }}
      />
    </GoogleMap>
  );
};

export default DemoPlotMap;
