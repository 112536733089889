import React from "react";

export default function MoreQuestion({ enumerationDetail }) {
  return (
    <div className="p-4 bg-white">
      <div className="bg-white p-3 border ">
        <h2 className="mb-1 text-md font-regular text-gray-900 ">
          {" "}
          How do you raise fund/capital for your farm?
        </h2>
        <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside ">
          <li>{enumerationDetail?.FarmFundSource}</li>
        </ul>
      </div>

      <div className="bg-white p-3 border ">
        <h2 className="mb-1 text-md font-regular text-gray-900 ">
          {" "}
          How do you sell your farm products?
        </h2>
        <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside ">
          <li>{enumerationDetail.HowProductSell}</li>
        </ul>
      </div>

      <div className="bg-white p-3 border ">
        <h2 className="mb-1 text-md font-regular text-gray-900 ">
          {" "}
          What factor can improve your yield?
        </h2>
        <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside ">
          <li>{enumerationDetail.FactorImproveYield}</li>
        </ul>
      </div>

      <div className="bg-white p-3 border ">
        <h2 className="mb-1 text-md font-regular text-gray-900 ">
          {" "}
          How do you keep record of your farm sales?
        </h2>
        <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside ">
          <li>{enumerationDetail.SalesRecord}</li>
        </ul>
      </div>

      <div className="bg-white p-3 border ">
        <h2 className="mb-1 text-md font-regular text-gray-900 ">
          {" "}
          What Challenges are affecting you as a farmer?
        </h2>
        <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside ">
          <li>{enumerationDetail.Challenges}</li>
        </ul>
      </div>

      <div className="bg-white p-3 border ">
        <h2 className="mb-1 text-md font-regular text-gray-900 ">
          {" "}
          What type of farming do you engage in?
        </h2>
        <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside ">
          <li>{enumerationDetail.FarmingMode}</li>
        </ul>
      </div>

      <div className="bg-white p-3 border ">
        <h2 className="mb-1 text-md font-regular text-gray-900 ">
          {" "}
          Are you benefiting from any agricultural interventions?
        </h2>
        <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside ">
          <li>{enumerationDetail.IsIntervention}</li>
        </ul>
      </div>

      <div className="bg-white p-3 border ">
        <h2 className="mb-1 text-md font-regular text-gray-900 ">
          {" "}
          What is the source of your farm water?
        </h2>
        <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside ">
          <li>{enumerationDetail.FarmWaterSource}</li>
        </ul>
      </div>

      <div className="bg-white p-3 border ">
        <h2 className="mb-1 text-md font-regular text-gray-900 ">
          {" "}
          What is the source of your farm Labour?
        </h2>
        <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside ">
          <li>{enumerationDetail.FarmLabourSource}</li>
        </ul>
      </div>
    </div>
  );
}
