const columnData = [
  "Attendee_ID",
  "Engagement_ID",
  "UID",
  "Agent_First_Name",
  "Agent_Second_Name",
  "Agent_Email_Address",
  "Agent_Phone_Number",
  "Agent_Gender",
  "Agent_ID",
  "A1_Consent_Information",
  "A2_Consent_Signature",
  "A3_What_is_your_First_Name",
  "A4_What_is_your_Middle_Name",
  "A5_What_is_your_Last_Name",
  "A6_Household_Gender",
  "A7_National_ID_Status",
  "A8_What_is_your_Date_of_Birth",
  "A9_What_is_your_Phone_Number",
  "A10_What_is_your_Marital_Status",
  "A11_What_is_your_Educational_level",
  "A12_Household_Size",
  "A13_What_is_your_status_in_your_household",
  "B1_What_is_your_state_of_residence",
  "B2_What_is_your_LGA_of_residence",
  "B3_What_is_your_Electoral_Ward_of_Residence",
  "B4_What_is_your_Community_of_Residence",
  "B6_Why_do_you_live_in_this_community",
  "B7_Farmers_House_coordinate",
  "B_7_1_Farmers_House_Coordinate_Accuracy",
  "B8_Nearest_Landmark",
  "C1_What_is_your_Means_of_identification",
  "C2_Farmer_ID_Front_View_Upload",
  "C3_Farmer_ID_Back_View_Upload",
  "C4_Farmer_Photo",
  "D1_How_Do_You_Fund_Raise_Capital_For_Your_Farm",
  "D2_Do_you_have_a_bank_account",
  "D3_Bank_Account_Number",
  "D4_Bank_Name",
  "D5_BVN_Number",
  "E1_Do_You_have_Multiple_Farms",
  "E2_Do_you_Own_the_land_you_Farm",
  "E3_What_is_the_source_of_your_Farm_Labour",
  "E4_what_is_your_Farm_Source_of_Water",
  "E5_Farm_Photo",
  "E6_Geo_Mapping",
  "E7_Farm_size",
  "E8_Farm_Shape",
  "E9_Are_you_Benefiting_from_any_agricultural_Intervention",
  "F1_What_type_of_Farming_do_you_engage_in",
  "F2_Farming_mode_sub_items",
  "F3_What_are_the_challenges_affecting_you_as_a_Farmer",
  "F4_Do_you_keep_your_farm_records",
  "F5_Are_you_a_member_of_any_Farmers_Association",
  "F6_What_factors_can_improve_your_yield",
  "F7_Where_do_you_sell_you_produce",
  "G1_What_is_Farmer_Disability_Status",
  "G2_Field_agents_comments",
  "SEX_AGE_Category",
  "Disability_Category",
  "Displacemment_Category",
  "Cluster_ID",
  "Farm_Cordinat_lat",
  "Farm_Cordinat_lng",
  "Status",
  "CreatedAT",
  "UpdatedAT",
];

export const getColumnData = () => {
  return columnData;
};
