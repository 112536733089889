import React, { useEffect, useState } from "react";
import useEnumerationDetails from "../../hooks/enumerations/useEnumerationDetails";
import { Link, useNavigate, useParams } from "react-router-dom";
import { InputField, PrimaryBtn } from "../../components/Form";
import { states, tempState } from "../../constants/states";
import { wardsList } from "../../constants/wards";
import { bankName } from "../../constants/bank";
import { async } from "q";
import { api, header } from "../../lib/queries/axiosInstance";
import { toast } from "react-toastify";
import { CustomMessage, Spinner } from "../../components/Generic";
import { useAuthContext } from "../../Context/AuthContext";

const EditHouseDetails = () => {
  const { id } = useParams();
  const { enumerationDetail } = useEnumerationDetails(id);
  const [filteredLga, setFilteredLga] = useState([]);
  const [filteredWard, setFilteredWard] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { setIsMessage, isMessage, setMessageContent, setMessageType } =
    useAuthContext();
  const [formData, setFormData] = useState({
    Engagement_ID: "",
    UID: "",
    Agent_First_Name: "",
    Agent_Second_Name: "",
    Agent_Email_Address: "",
    Agent_Phone_Number: "",
    Agent_Gender: "",
    Agent_ID: "",
    A1_Consent_Information: "",
    A2_Consent_Signature: "",
    A3_What_is_your_First_Name: "",
    A4_What_is_your_Middle_Name: "",
    A5_What_is_your_Last_Name: "",
    A6_Household_Gender: "",
    A7_National_ID_Status: "",
    A8_What_is_your_Date_of_Birth: "",
    A9_What_is_your_Phone_Number: "",
    A10_What_is_your_Marital_Status: "",
    A11_What_is_your_Educational_level: "",
    A12_Household_Size: "",
    A13_What_is_your_status_in_your_household: "",
    B1_What_is_your_state_of_residence: "",
    B2_What_is_your_LGA_of_residence: "",
    B3_What_is_your_Electoral_Ward_of_Residence: "",
    B4_What_is_your_Community_of_Residence: "",
    B5_What_is_your_Residential_Address: "",
    B6_Why_do_you_live_in_this_community: "",
    B_7_1_Farmers_House_Coordinate_Accuracy: "",
    B8_Nearest_Landmark: "",
    C1_What_is_your_Means_of_identification: "",
    D1_How_Do_You_Fund_Raise_Capital_For_Your_Farm: "",
    D2_Do_you_have_a_bank_account: "",
    D3_Bank_Account_Number: "",
    D4_Bank_Name: "",
    D5_BVN_Number: "",
    E1_Do_You_have_Multiple_Farms: "",
    E2_Do_you_Own_the_land_you_Farm: "",
    E3_What_is_the_source_of_your_Farm_Labour: "",
    E4_what_is_your_Farm_Source_of_Water: "",
    E9_Are_you_Benefiting_from_any_agricultural_Intervention: "",
    F4_Do_you_keep_your_farm_records: "",
    F5_Are_you_a_member_of_any_Farmers_Association: "",
    F6_What_factors_can_improve_your_yield: "",
    F7_Where_do_you_sell_you_produce: "",
    G1_What_is_Farmer_Disability_Status: "",
    G2_Field_agents_comments: "",
    SEX_AGE_Category: "",
    Disability_Category: "",
    Displacemment_Category: "",
    Cluster_ID: "",
    Farm_Cordinat_lat: "",
    Farm_Cordinat_lng: "",
    Status: "",
  });
  useEffect(() => {
    setFormData({
      ...formData,
      Engagement_ID: enumerationDetail?.Engagement_ID,
      UID: enumerationDetail?.UID,
      Agent_First_Name: enumerationDetail?.Agent_First_Name,
      Agent_Second_Name: enumerationDetail?.Agent_Second_Name,
      Agent_Email_Address: enumerationDetail?.Agent_Email_Address,
      Agent_Phone_Number: enumerationDetail?.Agent_Phone_Number,
      Agent_Gender: enumerationDetail?.Agent_Gender,
      Agent_ID: enumerationDetail?.Agent_ID,
      A1_Consent_Information: enumerationDetail?.A1_Consent_Information,
      A2_Consent_Signature: enumerationDetail?.A2_Consent_Signature,
      A3_What_is_your_First_Name: enumerationDetail?.A3_What_is_your_First_Name,
      A4_What_is_your_Middle_Name:
        enumerationDetail?.A4_What_is_your_Middle_Name,
      A5_What_is_your_Last_Name: enumerationDetail?.A5_What_is_your_Last_Name,
      A6_Household_Gender: enumerationDetail?.A6_Household_Gender,
      A7_National_ID_Status: enumerationDetail?.A7_National_ID_Status,
      A8_What_is_your_Date_of_Birth:
        enumerationDetail?.A8_What_is_your_Date_of_Birth,
      A9_What_is_your_Phone_Number:
        enumerationDetail?.A9_What_is_your_Phone_Number,
      A10_What_is_your_Marital_Status:
        enumerationDetail?.A10_What_is_your_Marital_Status,
      A11_What_is_your_Educational_level:
        enumerationDetail?.A11_What_is_your_Educational_level,
      A12_Household_Size: enumerationDetail?.A12_Household_Size,
      A13_What_is_your_status_in_your_household:
        enumerationDetail?.A13_What_is_your_status_in_your_household,
      B1_What_is_your_state_of_residence:
        enumerationDetail?.B1_What_is_your_state_of_residence,
      B2_What_is_your_LGA_of_residence:
        enumerationDetail?.B2_What_is_your_LGA_of_residence,
      B3_What_is_your_Electoral_Ward_of_Residence:
        enumerationDetail?.B3_What_is_your_Electoral_Ward_of_Residence,
      B4_What_is_your_Community_of_Residence:
        enumerationDetail?.B4_What_is_your_Community_of_Residence,
      B5_What_is_your_Residential_Address:
        enumerationDetail?.B5_What_is_your_Residential_Address,
      B6_Why_do_you_live_in_this_community:
        enumerationDetail?.B6_Why_do_you_live_in_this_community,
      B_7_1_Farmers_House_Coordinate_Accuracy:
        enumerationDetail?.B_7_1_Farmers_House_Coordinate_Accuracy,
      B8_Nearest_Landmark: enumerationDetail?.B8_Nearest_Landmark,
      C1_What_is_your_Means_of_identification:
        enumerationDetail?.C1_What_is_your_Means_of_identification,
      D1_How_Do_You_Fund_Raise_Capital_For_Your_Farm:
        enumerationDetail?.D1_How_Do_You_Fund_Raise_Capital_For_Your_Farm,
      D2_Do_you_have_a_bank_account:
        enumerationDetail?.D2_Do_you_have_a_bank_account,
      D3_Bank_Account_Number: enumerationDetail?.D3_Bank_Account_Number,
      D4_Bank_Name: enumerationDetail?.D4_Bank_Name,
      D5_BVN_Number: enumerationDetail?.D5_BVN_Number,
      E1_Do_You_have_Multiple_Farms:
        enumerationDetail?.E1_Do_You_have_Multiple_Farms,
      E2_Do_you_Own_the_land_you_Farm:
        enumerationDetail?.E2_Do_you_Own_the_land_you_Farm,
      E3_What_is_the_source_of_your_Farm_Labour:
        enumerationDetail?.E3_What_is_the_source_of_your_Farm_Labour,
      E4_what_is_your_Farm_Source_of_Water:
        enumerationDetail?.E4_what_is_your_Farm_Source_of_Water,
      E9_Are_you_Benefiting_from_any_agricultural_Intervention:
        enumerationDetail?.E9_Are_you_Benefiting_from_any_agricultural_Intervention,
      F4_Do_you_keep_your_farm_records:
        enumerationDetail?.F4_Do_you_keep_your_farm_records,
      F5_Are_you_a_member_of_any_Farmers_Association:
        enumerationDetail?.F5_Are_you_a_member_of_any_Farmers_Association,
      F6_What_factors_can_improve_your_yield:
        enumerationDetail?.F6_What_factors_can_improve_your_yield,
      F7_Where_do_you_sell_you_produce:
        enumerationDetail?.F7_Where_do_you_sell_you_produce,
      G1_What_is_Farmer_Disability_Status:
        enumerationDetail?.G1_What_is_Farmer_Disability_Status,
      G2_Field_agents_comments: enumerationDetail?.G2_Field_agents_comments,
      SEX_AGE_Category: enumerationDetail?.SEX_AGE_Category,
      Disability_Category: enumerationDetail?.Disability_Category,
      Displacemment_Category: enumerationDetail?.Displacemment_Category,
      Cluster_ID: enumerationDetail?.Cluster_ID,
      Farm_Cordinat_lat: enumerationDetail?.Farm_Cordinat_lat,
      Farm_Cordinat_lng: enumerationDetail?.Farm_Cordinat_lng,
      Status: enumerationDetail?.Status,
    });
    if (enumerationDetail) {
      const filteredCity = states.find(
        (item) => item.state === B1_What_is_your_state_of_residence
      );
      const newFilteredCity =
        filteredCity === undefined ? [] : filteredCity.lgas;
      setFilteredLga(newFilteredCity);
      const tempFilter = wardsList.find(
        (item) => item.lga === B2_What_is_your_LGA_of_residence?.toUpperCase()
      );
      const newFiltered = tempFilter === undefined ? [] : tempFilter.wards;
      setFilteredWard(newFiltered);
    }
  }, [enumerationDetail]);
  const {
    Engagement_ID,
    UID,
    Agent_First_Name,
    Agent_Second_Name,
    Agent_Email_Address,
    Agent_Phone_Number,
    Agent_Gender,
    Agent_ID,
    A1_Consent_Information,
    A2_Consent_Signature,
    A3_What_is_your_First_Name,
    A4_What_is_your_Middle_Name,
    A5_What_is_your_Last_Name,
    A6_Household_Gender,
    A7_National_ID_Status,
    A8_What_is_your_Date_of_Birth,
    A9_What_is_your_Phone_Number,
    A10_What_is_your_Marital_Status,
    A11_What_is_your_Educational_level,
    A12_Household_Size,
    A13_What_is_your_status_in_your_household,
    B1_What_is_your_state_of_residence,
    B2_What_is_your_LGA_of_residence,
    B3_What_is_your_Electoral_Ward_of_Residence,
    B4_What_is_your_Community_of_Residence,
    B5_What_is_your_Residential_Address,
    B6_Why_do_you_live_in_this_community,
    B_7_1_Farmers_House_Coordinate_Accuracy,
    B8_Nearest_Landmark,
    C1_What_is_your_Means_of_identification,
    D1_How_Do_You_Fund_Raise_Capital_For_Your_Farm,
    D2_Do_you_have_a_bank_account,
    D3_Bank_Account_Number,
    D4_Bank_Name,
    D5_BVN_Number,
    E1_Do_You_have_Multiple_Farms,
    E2_Do_you_Own_the_land_you_Farm,
    E3_What_is_the_source_of_your_Farm_Labour,
    E4_what_is_your_Farm_Source_of_Water,
    E9_Are_you_Benefiting_from_any_agricultural_Intervention,
    F4_Do_you_keep_your_farm_records,
    F5_Are_you_a_member_of_any_Farmers_Association,
    F6_What_factors_can_improve_your_yield,
    F7_Where_do_you_sell_you_produce,
    G1_What_is_Farmer_Disability_Status,
    G2_Field_agents_comments,
    SEX_AGE_Category,
    Disability_Category,
    Displacemment_Category,
    Cluster_ID,
    Farm_Cordinat_lat,
    Farm_Cordinat_lng,
    Status,
  } = formData;
  const edu_data = ["none", "primary", "secondary", "tertiary"];
  const marital_data = [
    "single",
    "married",
    "divorced",
    "separated",
    "widow/widower",
  ];
  const household_data = [
    "Household head",
    "Spouse to household head",
    "Child to head of household",
    "Relative to household head or spouse",
    "Other member of household",
  ];
  const household_size = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
  ];
  const why_comm_data = [
    "I am an indigene",
    "I relocated here due to violence or armed conflict in my previous community of residence",
    "I relocated here due to natural disaster in my previous community of residence",
    "I relocated here due to human right violation in my previous community of residence",
    "I relocated here due to economic, social, family, and other reasons",
  ];
  const id_means_data = [
    "National ID Card",
    "Voter's Card",
    "Int'l Passport",
    "Others",
  ];
  const capital_data = [
    "bank_loan",
    "cooperative",
    "grants",
    "personal_savings",
    "others",
  ];
  const labourData = [
    "paid",
    "family",
    "Paid Family Members Self",
    "Paid Self",
    "none",
  ];
  const waterData = [
    "borehole",
    "river",
    "spring",
    "underground",
    "well",
    "other",
    "Rain",
  ];
  const handleChangeState = (e) => {
    const filteredCity = states.find((item) => item.state === e.target.value);
    const newFilteredCity = filteredCity === undefined ? [] : filteredCity.lgas;
    setFilteredLga(newFilteredCity);
    setFormData((prevState) => ({
      ...prevState,
      B1_What_is_your_state_of_residence: e.target.value,
    }));
  };
  const handleChangeLGA = (value) => {
    const tempFilter = wardsList.find(
      (item) => item.lga === value?.toUpperCase()
    );
    const newFiltered = tempFilter === undefined ? [] : tempFilter.wards;
    setFilteredWard(newFiltered);
    setFormData((prevState) => ({
      ...prevState,
      B2_What_is_your_LGA_of_residence: value,
    }));
  };
  const onHandleChange = (nameID, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [nameID]: value,
    }));
    // console.log(nameID, value);
  };
  const onSelectChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  let selectClass =
    "block text-xs text-gray-600 py-3 px-4 rounded-sm w-full shadow-xs border border-gray-300 ";
  let colContainer = "w-[28%]";
  async function onSubmit() {
    const textRegex = /^[A-Za-z]+$/;
    const numberRegex = /^\d+$/; // Regular expression to match only numbers
    if (
      A3_What_is_your_First_Name === "" ||
      A5_What_is_your_Last_Name === "" ||
      A6_Household_Gender === "" ||
      A7_National_ID_Status === "" ||
      A8_What_is_your_Date_of_Birth === "" ||
      A9_What_is_your_Phone_Number === "" ||
      A10_What_is_your_Marital_Status === "" ||
      A11_What_is_your_Educational_level === "" ||
      A12_Household_Size === "" ||
      A13_What_is_your_status_in_your_household === "" ||
      B1_What_is_your_state_of_residence === "" ||
      B2_What_is_your_LGA_of_residence === "" ||
      B3_What_is_your_Electoral_Ward_of_Residence === "" ||
      B4_What_is_your_Community_of_Residence === "" ||
      B5_What_is_your_Residential_Address === "" ||
      B6_Why_do_you_live_in_this_community === "" ||
      B_7_1_Farmers_House_Coordinate_Accuracy === "" ||
      B8_Nearest_Landmark === "" ||
      C1_What_is_your_Means_of_identification === "" ||
      D1_How_Do_You_Fund_Raise_Capital_For_Your_Farm === "" ||
      D2_Do_you_have_a_bank_account === "" ||
      D3_Bank_Account_Number === "" ||
      D4_Bank_Name === "" ||
      D5_BVN_Number === "" ||
      E1_Do_You_have_Multiple_Farms === "" ||
      E2_Do_you_Own_the_land_you_Farm === "" ||
      E3_What_is_the_source_of_your_Farm_Labour === "" ||
      E4_what_is_your_Farm_Source_of_Water === "" ||
      E9_Are_you_Benefiting_from_any_agricultural_Intervention === ""
    ) {
      setMessageContent("All Fields Fill");
      setIsMessage(true);
      setMessageType("error");
    }
    // else if (
    //   !textRegex.test(A3_What_is_your_First_Name) ||
    //   !textRegex.test(A5_What_is_your_Last_Name)
    // ) {
    //   setMessageContent("Names Must be Text Only");
    //   setIsMessage(true);
    //   setMessageType("error");
    // }
    else if (
      !numberRegex.test(D3_Bank_Account_Number) ||
      !numberRegex.test(D5_BVN_Number) ||
      !numberRegex.test(A9_What_is_your_Phone_Number)
    ) {
      setMessageContent(
        "Account Number, BVN and Phone Number will be Numbers only"
      );
      setIsMessage(true);
      setMessageType("error");
    } else if (D3_Bank_Account_Number.length !== 10) {
      setMessageContent("Invalid Account Number");
      setIsMessage(true);
      setMessageType("error");
    } else if (D5_BVN_Number.length !== 11) {
      setMessageContent("Invalid Bank Verification Number");
      setIsMessage(true);
      setMessageType("error");
    } else if (A9_What_is_your_Phone_Number.length !== 11) {
      setMessageContent("Invalid Phone Number");
      setIsMessage(true);
      setMessageType("error");
    } else {
      setLoading(true);
      api
        .put(`/Enumeration/updatebyadmin/${UID}`, formData, {
          headers: header(),
        })
        .then((res) => {
          setLoading(false);
          if (res.data.code === 200) {
            setMessageContent("Household Profile Updated Successfully");
            setIsMessage(true);
            setMessageType("success");
            navigate(`/householddetail/${UID}`);
          } else {
            setMessageContent(res.data.message);
            setIsMessage(true);
            setMessageType("error");
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          if (err.response.data.message === undefined) {
            setMessageContent(err.message);
            setIsMessage(true);
            setMessageType("error");
          } else {
            setMessageContent(err.response.data.message);
            setIsMessage(true);
            setMessageType("error");
          }
        });
    }
  }
  if (loading) {
    return <Spinner />;
  }
  if (isMessage) {
    return <CustomMessage />;
  }
  return (
    <div>
      {/* Header */}
      <div className="flex justify-between items-center w-full">
        <h3>Edit Household Details</h3>
        <div className=" flex">
          <Link
            className="flex items-start justify-center bg-white w-full my-5 mx-4 text-primary py-3 px-5 rounded-md border border-primary text-center text-xs"
            to={`/householddetail/${id}`}
          >
            {"Cancel"}
          </Link>
          <div>
            <PrimaryBtn text={"Save"} OnBtnClick={onSubmit} />
          </div>
        </div>
      </div>
      {/* Edit COntent */}
      {enumerationDetail && (
        <div className="w-full mb-10">
          <form>
            {/* Section A (all As Questions) */}
            <div className="px-10 py-4">
              <h2 className="text-lg font-medium mb-5">A3-A13</h2>
              <div className="w-full flex justify-between items-start">
                {/* Col 1 */}
                <div className={colContainer}>
                  <div>
                    <InputField
                      title={"A3_What_is_your_First_Name"}
                      placeholder={"Enter First Name"}
                      value={A3_What_is_your_First_Name}
                      nameID={"A3_What_is_your_First_Name"}
                      onHandleChange={onHandleChange}
                    />
                  </div>
                  <div>
                    <InputField
                      title={"A4_What_is_your_Middle_Name"}
                      placeholder={"Enter Middle Name"}
                      value={A4_What_is_your_Middle_Name}
                      nameID={"A4_What_is_your_Middle_Name"}
                      onHandleChange={onHandleChange}
                    />
                  </div>
                  <div>
                    <InputField
                      title={"A5_What_is_your_Last_Name"}
                      placeholder={"Enter Last Name"}
                      value={A5_What_is_your_Last_Name}
                      nameID={"A5_What_is_your_Last_Name"}
                      onHandleChange={onHandleChange}
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                      A6_Household_Gender
                    </label>

                    <select
                      name={"A6_Household_Gender"}
                      onChange={(e) => onSelectChange(e)}
                      value={A6_Household_Gender}
                      className={selectClass}
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                </div>
                {/* Col 2 */}
                <div className={colContainer}>
                  <div>
                    <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                      A7_National_ID_Status
                    </label>

                    <select
                      name={"A7_National_ID_Status"}
                      onChange={(e) => onSelectChange(e)}
                      value={A7_National_ID_Status}
                      className={selectClass}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  <div>
                    <label
                      htmlFor="date"
                      className="block mb-2 text-xs font-regular text-gray-900 dark:text-white "
                    >
                      A8_What_is_your_Date_of_Birth
                    </label>
                    <input
                      className="border w-full border-gray-200 text-xs py-3 rounded-md text-gray-600"
                      type="date"
                      id="A8_What_is_your_Date_of_Birth"
                      name="A8_What_is_your_Date_of_Birth"
                      value={A8_What_is_your_Date_of_Birth}
                      onChange={handleDateChange}
                    />
                  </div>
                  <div>
                    <InputField
                      title={"A9_What_is_your_Phone_Number"}
                      placeholder={"Enter Last Name"}
                      value={A9_What_is_your_Phone_Number}
                      nameID={"A9_What_is_your_Phone_Number"}
                      onHandleChange={onHandleChange}
                      maxLength={"11"}
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                      A10_What_is_your_Marital_Status
                    </label>

                    <select
                      name={"A10_What_is_your_Marital_Status"}
                      onChange={(e) => onSelectChange(e)}
                      value={A10_What_is_your_Marital_Status}
                      className={selectClass}
                    >
                      {marital_data.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* Col 3 */}
                <div className={colContainer}>
                  <div>
                    <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                      A11_What_is_your_Educational_level
                    </label>

                    <select
                      name={"A11_What_is_your_Educational_level"}
                      onChange={(e) => onSelectChange(e)}
                      value={A11_What_is_your_Educational_level}
                      className={selectClass}
                    >
                      {edu_data.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                      A12_Household_Size
                    </label>

                    <select
                      name={"A12_Household_Size"}
                      onChange={(e) => onSelectChange(e)}
                      value={A12_Household_Size}
                      className={selectClass}
                    >
                      {household_size.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                      A13_What_is_your_status_in_your_household
                    </label>

                    <select
                      name={"A13_What_is_your_status_in_your_household"}
                      onChange={(e) => onSelectChange(e)}
                      value={A13_What_is_your_status_in_your_household}
                      className={selectClass}
                    >
                      {household_data.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {/* Section B (all B's Questions) */}
            <div className="px-10 py-4">
              <h2 className="text-lg font-medium mb-5">B13-B8</h2>
              <div className="w-full flex justify-between items-start">
                {/* Col 1 */}
                <div className={colContainer}>
                  <div>
                    <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                      B1_What_is_your_state_of_residence
                    </label>

                    <select
                      name={"B1_What_is_your_state_of_residence"}
                      onChange={(e) => handleChangeState(e)}
                      value={B1_What_is_your_state_of_residence}
                      className={selectClass}
                    >
                      {tempState.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                      B2_What_is_your_LGA_of_residence
                    </label>

                    <select
                      name={"B2_What_is_your_LGA_of_residence"}
                      onChange={(e) => handleChangeLGA(e.target.value)}
                      value={B2_What_is_your_LGA_of_residence}
                      className={selectClass}
                    >
                      <option selected value={B2_What_is_your_LGA_of_residence}>
                        {B2_What_is_your_LGA_of_residence}
                      </option>
                      {filteredLga.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                      B3_What_is_your_Electoral_Ward_of_Residence
                    </label>

                    <select
                      name={"B3_What_is_your_Electoral_Ward_of_Residence"}
                      onChange={(e) => onSelectChange(e)}
                      value={B3_What_is_your_Electoral_Ward_of_Residence}
                      className={selectClass}
                    >
                      <option
                        selected
                        value={B3_What_is_your_Electoral_Ward_of_Residence}
                      >
                        {B3_What_is_your_Electoral_Ward_of_Residence}
                      </option>
                      {filteredWard.map((item, index) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* Col 2 */}
                <div className={colContainer}>
                  <div>
                    <InputField
                      title={"B4_What_is_your_Community_of_Residence"}
                      placeholder={"B4_What_is_your_Community_of_Residence"}
                      value={B4_What_is_your_Community_of_Residence}
                      nameID={"B4_What_is_your_Community_of_Residence"}
                      onHandleChange={onHandleChange}
                    />
                  </div>
                  <div>
                    <InputField
                      title={"B5_What_is_your_Residential_Address"}
                      placeholder={"B5_What_is_your_Residential_Address"}
                      value={B5_What_is_your_Residential_Address}
                      nameID={"B5_What_is_your_Residential_Address"}
                      onHandleChange={onHandleChange}
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                      B6_Why_do_you_live_in_this_community
                    </label>

                    <select
                      name={"B6_Why_do_you_live_in_this_community"}
                      onChange={(e) => onSelectChange(e)}
                      value={B6_Why_do_you_live_in_this_community}
                      className={selectClass}
                    >
                      {why_comm_data.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* Col 3 */}
                <div className={colContainer}>
                  <div>
                    <InputField
                      title={"B8_Nearest_Landmark"}
                      placeholder={"B8_Nearest_Landmark"}
                      value={B8_Nearest_Landmark}
                      nameID={"B8_Nearest_Landmark"}
                      onHandleChange={onHandleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Section C, D, E (all C's, D's, E's Questions) */}
            <div className="px-10 py-4">
              <div className="w-full flex justify-between items-start">
                {/* Col 1 */}
                <div className={colContainer}>
                  <h2 className="text-lg font-medium mb-5">C1</h2>
                  <div>
                    <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                      C1_What_is_your_Means_of_identification
                    </label>

                    <select
                      name={"C1_What_is_your_Means_of_identification"}
                      onChange={(e) => onSelectChange(e)}
                      value={C1_What_is_your_Means_of_identification}
                      className={selectClass}
                    >
                      {id_means_data.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {/* Col 2 */}
                <div className={colContainer}>
                  <h2 className="text-lg font-medium mb-5">D1-D5</h2>
                  <div>
                    <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                      D1_How_Do_You_Fund_Raise _Capital_For_Your_Farm
                    </label>

                    <select
                      name={"D1_How_Do_You_Fund_Raise_Capital_For_Your_Farm"}
                      onChange={(e) => onSelectChange(e)}
                      value={D1_How_Do_You_Fund_Raise_Capital_For_Your_Farm}
                      className={selectClass}
                    >
                      {capital_data.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                      D2_Do_you_have_a_bank_account
                    </label>

                    <select
                      name={"D2_Do_you_have_a_bank_account"}
                      onChange={(e) => onSelectChange(e)}
                      value={D2_Do_you_have_a_bank_account}
                      className={selectClass}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  <div>
                    <InputField
                      title={"D3_Bank_Account_Number"}
                      placeholder={"D3_Bank_Account_Number"}
                      value={D3_Bank_Account_Number}
                      nameID={"D3_Bank_Account_Number"}
                      onHandleChange={onHandleChange}
                      maxLength={"10"}
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                      D4_Bank_Name
                    </label>

                    <select
                      name={"D4_Bank_Name"}
                      onChange={(e) => onSelectChange(e)}
                      value={D4_Bank_Name}
                      className={selectClass}
                    >
                      {bankName.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <InputField
                      title={"D5_BVN_Number"}
                      placeholder={"D5_BVN_Number"}
                      value={D5_BVN_Number}
                      nameID={"D5_BVN_Number"}
                      onHandleChange={onHandleChange}
                      maxLength={"11"}
                    />
                  </div>
                </div>
                {/* Col 3 */}
                <div className={colContainer}>
                  <h2 className="text-lg font-medium mb-5">E1-E9</h2>
                  <div>
                    <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                      E1_Do_You_have_Multiple_Farms
                    </label>

                    <select
                      name={"E1_Do_You_have_Multiple_Farms"}
                      onChange={(e) => onSelectChange(e)}
                      value={E1_Do_You_have_Multiple_Farms}
                      className={selectClass}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  <div>
                    <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                      E2_Do_you_Own_the_land_you_Farm
                    </label>

                    <select
                      name={"E2_Do_you_Own_the_land_you_Farm"}
                      onChange={(e) => onSelectChange(e)}
                      value={E2_Do_you_Own_the_land_you_Farm}
                      className={selectClass}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  <div>
                    <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                      E3_What_is_the_source_of_your_Farm_Labour
                    </label>

                    <select
                      name={"E3_What_is_the_source_of_your_Farm_Labour"}
                      onChange={(e) => onSelectChange(e)}
                      value={E3_What_is_the_source_of_your_Farm_Labour}
                      className={selectClass}
                    >
                      {labourData.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                      E4_what_is_your_Farm_Source_of_Water
                    </label>

                    <select
                      name={"E4_what_is_your_Farm_Source_of_Water"}
                      onChange={(e) => onSelectChange(e)}
                      value={E4_what_is_your_Farm_Source_of_Water}
                      className={selectClass}
                    >
                      {waterData.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label className="block mb-2 text-xs font-regular text-gray-900 dark:text-white ">
                      E9_agricultural_Intervention
                    </label>

                    <select
                      name={
                        "E9_Are_you_Benefiting_from_any_agricultural_Intervention"
                      }
                      onChange={(e) => onSelectChange(e)}
                      value={
                        E9_Are_you_Benefiting_from_any_agricultural_Intervention
                      }
                      className={selectClass}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default EditHouseDetails;
