import React, { useEffect, useState } from "react";
import { HiOutlineSearch } from "react-icons/hi";
import { api, header } from "../../lib/queries/axiosInstance";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { CustomLink } from "../../components/Generic";

const StaffListings = () => {
  const [limit, setLimit] = useState(100);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(start + limit);
  const [pageCount, setpageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [staff, setStaff] = useState([]);

  useEffect(() => {
    setLoading(true);
    // const getStaff = async () => {
    //   const res = await api.get(`householdsurvey/1/${limit}`, {
    //     headers: header(),
    //   });
    const getStaff = async () => {
      const res = await api.get(`stafftable`, {
        headers: header(),
      });

      const data = await res.data;
      console.log(data);

      const total = data.data.total;
      setTotal(total);
      setpageCount(Math.ceil(total / limit));
      setStaff(data.data.content);
      setLoading(false);
    };

    getStaff();
  }, [limit]);
  return (
    <div>
      <div className="w-full flex justify-between items-center">
        <div className="text-2xl pt-1">Staff</div>
        <div>
          <CustomLink
            path={"/add-new-staff"}
            text={"Add Staff"}
            width={"w-[100px]"}
          />
        </div>
      </div>
      <div className="mt-6 bg-white px-4 pt-3 pb-4 rounded-sm border border-gray-200 flex-1">
        <div className="flex items-end py-2 justify-between">
          {/* <div>
            <label
              // for="countries"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Sort by Status
            </label>
            <select
              id="countries"
              className=" border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            >
              <option selected>All Status </option>
              <option value="US">Pending Request</option>
              <option value="CA">Approved Agents</option>
              <option value="FR">Rejected Agents</option>
            </select>
          </div> */}
          {/* <strong className="text-gray-700 font-medium">All Field Agents</strong> */}
          {/* <div className="relative">
            <HiOutlineSearch
              fontSize={20}
              className="text-gray-400 absolute top-1/2 left-3 -translate-y-1/2"
            />
            <input
              type="text"
              placeholder="Search..."
              className="text-sm focus:outline-none active:outline-none border border-gray-300 w-[24rem] h-10 pl-11 pr-4 rounded-sm"
            />
          </div> */}
        </div>
        <div className="border-x border-gray-200 rounded-sm mt-3">
          <table className="w-full text-gray-700">
            <thead>
              <tr>
                <th>Staff ID</th>
                <th>First Name</th>
                <th>Middle Name</th>
                <th>Last Name</th>
                {/* <th>Email</th> */}
                <th>Phone Number</th>
                <th>Gender</th>
                <th>State</th>
                <th>LGA</th>
                <th>Ward</th>
                <th>Designation</th>
                {/* <th>Status</th> */}
                {/* <th>Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {staff &&
                staff.map((staff) => (
                  <tr key={staff.id}>
                    <td>{staff.id}</td>
                    <td>
                      <div className="flex items-center gap-2">
                        {" "}
                        <div
                          className="h-6 w-6 rounded-full bg-sky-500 bg-cover bg-no-repeat bg-center"
                          style={{
                            backgroundImage: `url(${staff.StaffPhoto})`,
                          }}
                        ></div>
                        {staff.FirstName}
                      </div>
                    </td>
                    <td>{staff.MiddleName}</td>
                    <td>{staff.LastName}</td>
                    {/* <td>{staff.Email}</td> */}
                    <td>{staff.PhoneNumber}</td>
                    <td>{staff.Gender}</td>
                    <td>{staff.StateOfResidence}</td>
                    <td>{staff.LGA}</td>
                    <td>{staff.Ward}</td>
                    <td>{staff.Designation}</td>
                    {/* <td>
                      <span className="flex items-center gap-2">
                        <Link to={`/#`}>
                          <GrView />
                        </Link>
                        <FiEdit /> <MdDeleteOutline fontSize={20} />
                      </span>
                    </td> */}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default StaffListings;
