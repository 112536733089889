import React, { useState, useEffect } from "react";
import { api } from "../../lib/queries/axiosInstance";
import { FcBullish } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
// import setAuthToken from "../../lib/utils/setAuthToken";
import { useAuthContext } from "../../Context/AuthContext";
import PulseLoader from "react-spinners/PulseLoader";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { logo } from "../../constants/imports";

const Login = () => {
  const [email, setFormEmail] = useState("");
  const [password, setFormPassword] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // const [token, setToken] = useState("");

  const { fetchUserDetails } = useAuthContext();

  const navigate = useNavigate();

  useEffect(() => {
    setError(false);
  }, [email, password]);

  const handleSubmit = async () => {
    setLoading(true);
    api
      .post("/Admin/login", {
        email,
        password,
      })
      .then(async (res) => {
        // console.log(res.data)
        localStorage.setItem("token", res.data.data);
        // localStorage.setItem("dashboardData", res.data.dashboard);

        // setAuthToken(localStorage.getItem("token"))
        // setDashboardData(res.data)
        await fetchUserDetails();
        navigate("/");
        return res;
      })
      .catch((err) => {
        console.log(err);
        setError(err.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <div className="min-h-screen bg-gray-100 flex flex-col justify-center sm:py-12">
        <div className="p-10 xs:p-0 mx-auto md:w-full md:max-w-md">
          <div className="font-bold text-center text-2xl mb-5     flex items-center gap-2 px-1 py-3">
            <img src={logo} className="h-[50px] w-[50px]" />
            <span className="text-neutral-900 text-xl">
              kobokolet Back Office
            </span>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            <div className="bg-white shadow w-full rounded-lg divide-y divide-gray-200">
              <div className="px-5 py-7">
                <h1 className="font-bold text-2xl mb-5">Login </h1>

                {error && (
                  <div
                    className="flex bg-red-100 rounded-lg p-4 mb-4 text-sm text-red-700"
                    role="alert"
                  >
                    <svg
                      className="w-5 h-5 inline mr-3"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <div>
                      <span className="font-medium">{error}</span>.
                    </div>
                  </div>
                )}

                <label className="font-regular text-sm text-gray-600 pb-1 block">
                  E-mail
                </label>
                <input
                  type="text"
                  name="email"
                  value={email}
                  //   onChange={(e) => onChange(e)}
                  onChange={(e) => setFormEmail(e.target.value)}
                  className="border rounded-md px-3 py-3 mt-1 mb-5 text-sm w-full"
                />
                <div className="relative items-center">
                  <label className="font-regular text-sm text-gray-600 pb-1 block">
                    Password
                  </label>
                  <div className="flex justify-between items-center  border rounded-md  mb-6 text-sm w-full">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={password}
                      //   onChange={(e) => onChange(e)}
                      onChange={(e) => setFormPassword(e.target.value)}
                      className="border-none rounded-md px-3 py-3  text-sm w-full"
                    />
                    <div
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute inline-block right-5"
                    >
                      {showPassword ? (
                        <AiOutlineEye size={22}></AiOutlineEye>
                      ) : (
                        <AiOutlineEyeInvisible
                          size={22}
                        ></AiOutlineEyeInvisible>
                      )}
                    </div>
                  </div>
                </div>
                {/* 
<div className="relative items-center"> 
   			<input type={showPassword ? 'text' : 'password' } x-model="password" name="password" className="w-full px-4 mb-5 rounded border py-2"/>
   			<button className="absolute inline-block bottom-7 right-5">
     
          {showPassword ? 
          <AiOutlineEyeInvisible></AiOutlineEyeInvisible>
          :
 
      <AiOutlineEye></AiOutlineEye>
          }
	   	
	       

	        </button>
	       </div> */}

                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="transition duration-200 bg-primary hover:bg-green-600 focus:bg-green-700 focus:shadow-sm focus:ring-4 focus:ring-green-500 focus:ring-opacity-50 text-white w-full py-4 rounded-lg text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block"
                >
                  {loading ? (
                    <PulseLoader
                      className="justify-center"
                      color={"#ffffff"}
                      loading={loading}
                      // cssOverride={override}
                      size={12}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  ) : (
                    <div>
                      <span className="inline-block mr-2">Login</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="w-4 h-4 inline-block"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </svg>
                    </div>
                  )}
                </button>
              </div>

              <div className="py-5">
                <div className="grid grid-cols-2 gap-1">
                  <div className="text-center sm:text-left whitespace-nowrap">
                    <button className="transition duration-200 mx-5 px-5 py-4 cursor-pointer font-normal text-sm rounded-lg text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-200 focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 ring-inset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="w-4 h-4 inline-block align-text-top"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
                        />
                      </svg>
                      <span className="inline-block ml-1">Forgot Password</span>
                    </button>
                  </div>
                  <div className="text-center sm:text-right  whitespace-nowrap">
                    <button className="transition duration-200 mx-5 px-5 py-4 cursor-pointer font-normal text-sm rounded-lg text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-200 focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 ring-inset">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="w-4 h-4 inline-block align-text-bottom	"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
                        />
                      </svg>
                      <span className="inline-block ml-1">Help</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
