import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
// import { useEnumerationProvider } from "../../../Context/EnumerationContext";
import useEnumerationDetails from "../../../src/hooks/enumerations/useEnumerationDetails";
import { FiEdit } from "react-icons/fi";
import { AiOutlineLeft } from "react-icons/ai";
import { SurveryContainer } from "../../components/HouseHold";
import { api, header } from "../../lib/queries/axiosInstance";
import { CustomMessage } from "../../components/Generic";
import { useAuthContext } from "../../Context/AuthContext";
import { getDateFromCreated } from "../../lib/constants/functions";

export default function HouseholdDetails() {
  const [survey, setSurvey] = useState(null);
  const [onRAAdoption, setOnRAAdoption] = useState(false);
  const [onAssets, setOnAssets] = useState(false);
  const [onRemarks, setOnRemarks] = useState(false);
  const [onFieldAgent, setOnFieldAgent] = useState(false);
  const [onGroups, setOnGroups] = useState(false);
  const [onInput, setOnInput] = useState(false);
  const [onSurvey, setOnSurvey] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState([]);
  const { id } = useParams();
  const { enumerationDetail } = useEnumerationDetails(id);

  const { isMessage } = useAuthContext();
  const navigate = useNavigate();
  const listTitle = "text-xs font-medium my-4 tracking-wider list-none";
  const listItem = "text-xs font-light ";
  const row = "w-full flex justify-between";
  const col = "w-[30%]";
  const headerCat = "text-green-500 text-md font-medium mt-3";
  const inputTitle = "text-sm font-light text-black py-1";
  const inputContent = "text-sm py-1 font-semibold text-black";
  const getSurvey = async () => {
    setLoading(true);
    const res = await api.get(`householdsurvey/${id}`, {
      headers: header(),
    });
    const data = await res.data;
    if (data.code === 200) {
      setSurvey(data.data);
    }
    setLoading(false);
  };
  const getInput = async () => {
    setLoading(true);
    const res = await api.get(`hh_input_distribution/byUID/${id}`, {
      headers: header(),
    });
    const data = await res.data;
    if (data.code === 200) {
      setInputs(data.data.content);
    }
    setLoading(false);
  };
  async function onMount() {
    await getInput();
    await getSurvey();
  }
  useEffect(() => {
    onMount();
  }, []);
  console.log(survey);
  function onActiveRAAdoption() {
    setOnRAAdoption(true);
    setOnAssets(false);
    setOnRemarks(false);
    setOnFieldAgent(false);
    setOnGroups(false);
    setOnInput(false);
    setOnSurvey(false);
  }
  function onActiveAssets() {
    setOnRAAdoption(false);
    setOnAssets(true);
    setOnRemarks(false);
    setOnFieldAgent(false);
    setOnGroups(false);
    setOnInput(false);
    setOnSurvey(false);
  }
  function onActiveRemark() {
    setOnRAAdoption(false);
    setOnAssets(false);
    setOnRemarks(true);
    setOnFieldAgent(false);
    setOnGroups(false);
    setOnInput(false);
    setOnSurvey(false);
  }
  function onActiveFieldAgent() {
    setOnRAAdoption(false);
    setOnAssets(false);
    setOnRemarks(false);
    setOnFieldAgent(true);
    setOnGroups(false);
    setOnInput(false);
    setOnSurvey(false);
  }
  function onActiveGroup() {
    setOnRAAdoption(false);
    setOnAssets(false);
    setOnRemarks(false);
    setOnFieldAgent(false);
    setOnGroups(true);
    setOnInput(false);
    setOnSurvey(false);
  }
  function onActiveInputDistribution() {
    setOnRAAdoption(false);
    setOnAssets(false);
    setOnRemarks(false);
    setOnFieldAgent(false);
    setOnGroups(false);
    setOnInput(true);
    setOnSurvey(false);
  }
  function onActiveSurvey() {
    setOnRAAdoption(false);
    setOnAssets(false);
    setOnRemarks(false);
    setOnFieldAgent(false);
    setOnGroups(false);
    setOnInput(false);
    setOnSurvey(true);
  }

  // const data = [

  // ];
  if (isMessage) {
    return <CustomMessage />;
  }

  return (
    <div style={{ width: "100%" }} className="p-4">
      <div className="mt-4">
        <div className="flex items-center mb-5">
          <Link
            className="flex items-center cursor-pointer mr-4"
            onClick={() => navigate(-1)}
          >
            <AiOutlineLeft color={"black"} size={25} />
            <p className="text-primary">Back</p>
          </Link>
          <h1 className="text-lg font-semibold ">Household Details</h1>
        </div>
        {enumerationDetail && (
          <div className="w-full">
            {/* First Section (The Profile Summary) */}
            <div className="bg-white rounded-sm shadow-lg w-full px-10  flex items-start justify-between">
              {/* First Col */}
              <div className="w-[35%]  h-full flex justify-between items-center py-10 pr-5">
                <img
                  src={enumerationDetail?.C4_Farmer_Photo}
                  className="h-[100px] w-[100px] rounded-full"
                />
                <div>
                  <h1 className="text-sm font-semibold">{`${enumerationDetail?.A3_What_is_your_First_Name} ${enumerationDetail?.A5_What_is_your_Last_Name}`}</h1>
                  <li className={listTitle}>
                    UID:{" "}
                    <span className={listItem}>{enumerationDetail?.UID}</span>
                  </li>
                  <p
                    className={`text-xs py-2 px-3 rounded-xl text-center${
                      enumerationDetail?.Status === "Completed"
                        ? " bg-green-200 text-primary"
                        : " bg-red-200 text-red-600"
                    }`}
                  >
                    {enumerationDetail?.Status}
                  </p>
                </div>
              </div>
              {/* Second Col */}
              <div className="w-[25%] h-full border-l border-r border-r-gray-400 border-l-gray-400 px-8">
                <ul className="h-full">
                  <li className={listTitle}>
                    Gender:{" "}
                    <span className={listItem}>
                      {enumerationDetail?.A6_Household_Gender}
                    </span>
                  </li>
                  <li className={listTitle}>
                    Phone:{" "}
                    <span className={listItem}>
                      {enumerationDetail?.A9_What_is_your_Phone_Number}
                    </span>
                  </li>
                  <li className={listTitle}>
                    Sex Age Category:{" "}
                    <span className={listItem}>
                      {enumerationDetail?.SEX_AGE_Category}
                    </span>
                  </li>
                  <li className={listTitle}>
                    National ID:{" "}
                    <span className={listItem}>
                      {enumerationDetail?.A7_National_ID_Status}
                    </span>
                  </li>
                </ul>
              </div>
              {/* Third Col */}
              <div className="w-[40%]  h-full flex items-center justify-between px-5">
                {/* Col 1 */}
                <ul className="h-full flex-col w-[40%]">
                  <li className={listTitle}>
                    State:{" "}
                    <span className={listItem}>
                      {enumerationDetail?.B1_What_is_your_state_of_residence}
                    </span>
                  </li>
                  <li className={listTitle}>
                    LGA:{" "}
                    <span className={listItem}>
                      {enumerationDetail?.B2_What_is_your_LGA_of_residence}
                    </span>
                  </li>
                  <li className={listTitle}>
                    Community:{" "}
                    <span className={listItem}>
                      {
                        enumerationDetail?.B4_What_is_your_Community_of_Residence
                      }
                    </span>
                  </li>
                  <li className={listTitle}>
                    Ward:{" "}
                    <span className={listItem}>
                      {
                        enumerationDetail?.B3_What_is_your_Electoral_Ward_of_Residence
                      }
                    </span>
                  </li>
                </ul>
                {/* Col 2 */}
                <div className="w-[40%]">
                  <Link
                    className="flex items-start justify-center bg-white w-full my-5 mx-4 text-primary py-3 px-5 rounded-md border border-primary text-center text-xs"
                    to={`/edit-household-details/${enumerationDetail?.UID}`}
                  >
                    <FiEdit size={20} className="text-primary mr-3" />
                    {"Update"}
                  </Link>
                  <Link
                    className=" block text-xs bg-primary w-full my-5 mx-4 text-white py-3 px-5 rounded-md text-center"
                    to={`/household-view-more/${enumerationDetail?.UID}`}
                  >
                    {"View Profile"}
                  </Link>
                </div>
              </div>
            </div>
            {/* Menu Tabs */}
            <div className="w-full flex justify-between items-end bg-white  my-8 rounded-md">
              <RenderTabItem
                title={"RA Adoption"}
                active={onRAAdoption}
                onClick={onActiveRAAdoption}
              />
              <RenderTabItem
                title={"Assets"}
                active={onAssets}
                onClick={onActiveAssets}
              />
              {/* <RenderTabItem
                title={"Remarks"}
                active={onRemarks}
                onClick={onActiveRemark}
              /> */}
              {/* <RenderTabItem
                title={"Field Agent"}
                active={onFieldAgent}
                onClick={onActiveFieldAgent}
              />
              <RenderTabItem
                title={"Groups"}
                active={onGroups}
                onClick={onActiveGroup}
              /> */}
              <RenderTabItem
                title={"Input Distribution"}
                active={onInput}
                onClick={onActiveInputDistribution}
              />
              <RenderTabItem
                title={"GESI"}
                active={onSurvey}
                onClick={onActiveSurvey}
              />
            </div>
            {/* Tabs Content */}
            {onSurvey && (
              <>
                {survey === null ? (
                  <div>
                    <h1>No GESI Survey for this HouseHold</h1>
                  </div>
                ) : (
                  <SurveryContainer data={survey} />
                )}
              </>
            )}
            {onInput && (
              <div className="bg-white py-8">
                <h1 className="pb-8 pl-8 text-black text-xl font-semibold">
                  Input Distribution
                </h1>
                {inputs.length > 0 ? (
                  <div>
                    {inputs.map((input, index) => (
                      <div key={index} className="">
                        {/* Header */}
                        <div className="w-full flex justify-between items-center py-4 px-8 bg-green-50">
                          {/* Date */}
                          <div className="w-[15%]">
                            <p className="text-sm font-semibold text-green-500">
                              Date:{" "}
                              <span className="text-sm font-light text-black">
                                {getDateFromCreated(input.CreatedAT)}
                              </span>
                            </p>
                            <p className="text-sm font-semibold text-green-500">
                              Venue:{" "}
                              <span className="text-sm font-light text-black">
                                {input.DistributionVenue}
                              </span>
                            </p>
                          </div>
                          {/* Coment */}
                          <div className="w-[30%] bg-white p-4">
                            <p className="text-sm font-semibold text-green-500">
                              Field Agent Comment
                            </p>
                            <p className="text-sm font-light text-black">
                              {input.AgentComment}
                            </p>
                          </div>
                          {/* SIgn */}
                          <div className="w-[30%]">
                            <img
                              src={input.Signature}
                              className="h-[80px] w-full"
                            />
                          </div>
                        </div>
                        {/* Body */}
                        <div className="p-8">
                          {/* First Row */}
                          <div className={row}>
                            {/* Col 1 */}
                            <div className={col}>
                              <p className={headerCat}>
                                Nitrogen Fixing seedling
                              </p>
                              <p className={inputTitle}>
                                Acacia albida (Apple-ring Acacia) -{" "}
                                <span className={inputContent}>
                                  {input.Acacia_albida}
                                </span>
                              </p>
                              <p className={inputTitle}>
                                Acacia senegal (Gum Arabic) -{" "}
                                <span className={inputContent}>
                                  {input.Acacia_senegal}
                                </span>
                              </p>
                              <p className={inputTitle}>
                                Prosopis africana -{" "}
                                <span className={inputContent}>
                                  {input.Acacia_seyal}
                                </span>
                              </p>
                            </div>
                            {/* Col 2 */}
                            <div className={col}>
                              <p className={headerCat}>
                                Soil conditioner/Forage seedling
                              </p>
                              <p className={inputTitle}>
                                Parkia biglobosa (Locust Bean) -{" "}
                                <span className={inputContent}>
                                  {input.Parkia_biglobosa}
                                </span>
                              </p>

                              <p className={inputTitle}>
                                Baobab (Adansonia digitata) -{" "}
                                <span className={inputContent}>
                                  {input.Baobab}
                                </span>
                              </p>

                              <p className={inputTitle}>
                                Balanites Aegyptiaca (Desert Date/Aduwa) -{" "}
                                <span className={inputContent}>
                                  {input.Balanites_Aegyptiaca}
                                </span>
                              </p>
                            </div>
                            {/* Col 3*/}
                            <div className={col}>
                              <p className={headerCat}>
                                Bio-Fence/Medicinal/Fruit seedling
                              </p>

                              <p className={inputTitle}>
                                Moringa stenopetala -{" "}
                                <span className={inputContent}>
                                  {input.dash}
                                </span>
                              </p>

                              <p className={inputTitle}>
                                Moringa oleifera -{" "}
                                <span className={inputContent}>
                                  {input.Moringa_oleifera}
                                </span>
                              </p>

                              <p className={inputTitle}>
                                Elais Guinensis (Tenera) -{" "}
                                <span className={inputContent}>
                                  {input.Elais_Guinensis}
                                </span>
                              </p>

                              <p className={inputTitle}>
                                Mahogany -{" "}
                                <span className={inputContent}>
                                  {input.Mahogany}
                                </span>
                              </p>
                            </div>
                          </div>
                          {/* Second Row */}
                          <div className={row}>
                            {/* Col 1 */}
                            <div className={col}>
                              <p className={headerCat}>Cash Crops Seeds</p>
                              <p className={inputTitle}>
                                Ginger -{" "}
                                <span className={inputContent}>
                                  {input.Ginger}
                                </span>
                              </p>

                              <p className={inputTitle}>
                                Soybean -{" "}
                                <span className={inputContent}>
                                  {input.Soybean}
                                </span>
                              </p>

                              <p className={inputTitle}>
                                Hibiscus -{" "}
                                <span className={inputContent}>
                                  {input.Hibiscus}
                                </span>
                              </p>

                              <p className={inputTitle}>
                                Sesame -{" "}
                                <span className={inputContent}>
                                  {input.Sesame}
                                </span>
                              </p>

                              <p className={inputTitle}>
                                Bambara Nut -{" "}
                                <span className={inputContent}>
                                  {input.Bambara_Nut}
                                </span>
                              </p>

                              <p className={inputTitle}>
                                Groundnut -{" "}
                                <span className={inputContent}>
                                  {input.Groundnut}
                                </span>
                              </p>

                              <p className={inputTitle}>
                                Cowpea -{" "}
                                <span className={inputContent}>
                                  {input.Cowpea}
                                </span>
                              </p>

                              <p className={inputTitle}>
                                Chilli -{" "}
                                <span className={inputContent}>
                                  {input.Chilli}
                                </span>
                              </p>
                            </div>
                            {/* Col 2 */}
                            <div className={col}>
                              {/* Sub col - 1 */}
                              <div>
                                <p className={headerCat}>Cover crops seeds</p>
                                <p className={inputTitle}>
                                  Pumpkin -{" "}
                                  <span className={inputContent}>
                                    {input.Pumpkin}
                                  </span>
                                </p>

                                <p className={inputTitle}>
                                  Watermelon -{" "}
                                  <span className={inputContent}>
                                    {input.Watermelon}
                                  </span>
                                </p>

                                <p className={inputTitle}>
                                  Sweet Potato -{" "}
                                  <span className={inputContent}>
                                    {input.Sweet_Potato}
                                  </span>
                                </p>
                              </div>
                              {/* Sub Col - 2 */}
                              <div>
                                <p className={headerCat}>Compost</p>
                                <p className={inputTitle}>
                                  Compost -{" "}
                                  <span className={inputContent}>
                                    {input.Compost}
                                  </span>
                                </p>
                              </div>
                            </div>
                            {/* COl 3 */}
                            <div className={col}>
                              {/* Sub col - 1 */}
                              <div>
                                <p className={headerCat}>
                                  Pest Control seedling
                                </p>
                                <p className={inputTitle}>
                                  Super napia -{" "}
                                  <span className={inputContent}>
                                    {input.Super_napia}
                                  </span>
                                </p>

                                <p className={inputTitle}>
                                  Marigold -{" "}
                                  <span className={inputContent}>
                                    {input.Marigold_stem}
                                  </span>
                                </p>
                              </div>
                              {/* Sub Col - 2 */}
                              <div>
                                <p className={headerCat}>
                                  Biofertilizer/Biopesticide
                                </p>
                                <p className={inputTitle}>
                                  Biofertilizer/Biopesticide -{" "}
                                  <span className={inputContent}>
                                    {input.Biofertilizer_Biopesticide}
                                  </span>
                                </p>
                              </div>
                              {/* Sub Col - 3 */}
                              <div>
                                <p className={headerCat}>Pruning tools </p>
                                <p className={inputTitle}>
                                  Pruning tools -{" "}
                                  <span className={inputContent}>
                                    {input.Pruning_tools}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <h2>No Input Distribution for this household</h2>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

function RenderTabItem({ title, active, onClick }) {
  return (
    <div
      onClick={onClick}
      className={`${
        active
          ? "border-b-4 border-b-primary font-medium text-black"
          : "text-gray-700"
      } text-sm px-6 pb-2 pt-4 cursor-pointer hover:text-white hover:bg-primary`}
    >
      <p>{title}</p>
    </div>
  );
}
