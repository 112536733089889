import React from "react";
import styles from "./farmer.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { HiOutlineSearch } from "react-icons/hi";
import PulseLoader from "react-spinners/PulseLoader";
// import { useEnumerationProvider } from "../../../Context/EnumerationContext";
import ReactPaginate from "react-paginate";
import { useAuthContext } from "../../../src/Context/AuthContext";
import useEnumerationsQuery from "../../hooks/enumerations/useEnumerationsQuery";
import { getStatus } from "../../lib/helpers";
import { BsTrash } from "react-icons/bs";
import { getColumnData } from "../../lib/services/advancedSearchSelect";
import { states, tempState } from "../../lib/constants/states";
import { wardsList } from "../../lib/constants/wards";
import useAttendees from "../../hooks/useAttendees";

const TableData = ({ children }) => <td className="px-6 py-2">{children}</td>;

export default function Attendees() {
  const navigate = useNavigate();
  const { authUser } = useAuthContext();
  console.log(authUser);
  const [enumerationStatus, setEnumerationStatus] = useState(null);
  const [NINStatus, setNINStatus] = useState(null);
  const [householdGender, setHouseholdGender] = useState(null);
  const [state, setState] = useState("");
  const [filteredLga, setFilteredLga] = useState([""]);
  const [selectedLga, setSelectedLga] = useState();
  const [selectedState, setselectedState] = useState();

  // const [searchResult, setSearchResult] = useState("");

  const [selectedDate, setSelectedDate] = useState("");
  const [updatedDate, setUpdatedDate] = useState("");

  const formatDate = (dateStr) => {
    if (dateStr.startsWith("0")) {
      return dateStr.split("")[1];
    }
    return dateStr;
  };
  function handleDateChange(event) {
    setLimit(100);
    setStart(0);
    setSelectedDate(event.target.value);
    // console.log(event.type);

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    if (event.target.value) {
      let dateArray = event.target.value?.split("-");
      let newdateDateFormat = `${monthNames[dateArray[1] - 1]} ${formatDate(
        dateArray[2]
      )}, ${dateArray[0]}`;

      setUpdatedDate(`*${newdateDateFormat}`);
    } else if (event.type === "change") {
      setUpdatedDate("");
    }
  }
  const { attendeeData, setStart, setEnd, limit, loading, setLimit } =
    useAttendees();
  console.log(attendeeData);

  const {
    // setDateSearch,
    // dateSearch,
    openModal,
    setOpenModal,
    handleOnClose,
    enumerations,
    // setLimit,
    setEnumerations,
    // limit,
    // loading,
    setLoading,
    start,
    // setStart,
    end,
    // setEnd,
    pageCount,
    setpageCount,
    total,
    setTotal,
    searchResult,
    setSearchResult,
    statusOptions,
    setStatusOptions,
    searchEnumerations,
    fetchEnumerations,
    advancedSearchOptions,
    setAdvancedSearchOptions,
  } = useEnumerationsQuery({
    status: enumerationStatus,
    NIN: NINStatus,
    gender: householdGender,
    dateSelected: updatedDate,
    state: selectedState,
    lga: selectedLga,
  });

  const handlePageClick = async (data) => {
    let startCounter = data.selected + 1;
    let startr = startCounter * limit - limit;
    let endr = startr + limit;
    setEnd(endr);
    setStart(startr);
  };
  return (
    <div>
      <div className="flex justify-between items-center text-xl pt-1">
        Attendees
        {/* in {authUser && authUser.TargetRole} {`(${total})`} */}
      </div>

      <div className="bg-white mt-4 p-6">
        <div
          className={` ${styles.tablewrp} relative scroll-div overflow-scroll mt-4 shadow-md  w-100% sm:rounded-lg`}
        >
          {loading ? (
            <div className=" m-12  justify-items-center justify-center">
              {" "}
              <PulseLoader
                className=" m-12 justify-center"
                color={"#46A576"}
                loading={loading}
                // cssOverride={override}
                size={14}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <table
              className={` w-full  text-sm text-left text-gray-500 dark:text-gray-400`}
            >
              <thead className="text-xs sticky top-0 right-0 left-0 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Attendee ID
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    First Name
                  </th>
                  <th scope="col" className="px-6 whitespace-nowrap  py-3">
                    Middle Name
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Last Name
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Gender
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Have NIN
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Phone Number
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Cluster_ID
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Updated Date
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className=" pt-8">
                {attendeeData.length &&
                  attendeeData.map((farmer) => (
                    <tr
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 pt-8  hover:bg-gray-50 dark:hover:bg-gray-600"
                      key={farmer.Attendee_ID}
                      // onClick={() => navigate(`/householddetail/${farmer.UID}`)}
                    >
                      <TableData>
                        <Link
                          className="border-none"
                          // to={`/householddetail/${farmer.UID}`}
                        >
                          {farmer.Attendee_ID}
                        </Link>
                      </TableData>
                      <TableData>
                        {" "}
                        <Link
                        //  to={`/householddetail/${farmer.UID}`}
                        >
                          {farmer.FirstName}
                        </Link>
                      </TableData>
                      <TableData>
                        <Link
                        //  to={`/householddetail/${farmer.UID}`}
                        >
                          {farmer.MiddleName}
                        </Link>
                      </TableData>
                      <TableData>
                        <Link
                        // to={`/householddetail/${farmer.UID}`}
                        >
                          {farmer.LastName}
                        </Link>
                      </TableData>
                      <TableData>{farmer.Gender}</TableData>
                      <TableData>{farmer.HaveNIN}</TableData>
                      <TableData>{farmer.PhoneNumber}</TableData>
                      <TableData>{farmer.Cluster_ID}</TableData>
                      <TableData>{farmer.UpdatedAT}</TableData>
                      <TableData>{getStatus(farmer.Status)}</TableData>
                      <td className="px-6 py-2">
                        <span className="flex items-center gap-2">
                          <Link to={`/householddetail/${farmer.Attendee_ID}`}>
                            <GrView />
                          </Link>
                          {/* <FiEdit /> <MdDeleteOutline fontSize={20} /> */}
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
        <nav
          className="flex items-center justify-between pt-4"
          aria-label="Table navigation"
        >
          <div className="flex items-center">
            <div className="mt-4 ">
              <form>
                {" "}
                <label
                  // for="countries"
                  className="block mb-2 text-sm font-regular text-gray-900 dark:text-white"
                >
                  No of Data Per Page
                </label>
                <select
                  id="countries"
                  className=" border border-gray-200 text-gray-500 text-sm rounded-md font-light focus:ring-green-500 focus:border-green-400 block w-full p-2.5 "
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <option selected>{limit}</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                </select>
              </form>
            </div>
          </div>

          <div className="flex items-center gap-4">
            <div className=" gap-4 ">
              {/* <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                Showing{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {start}-{end}
                </span>{" "}
                of{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {total}
                </span>
              </span> */}
            </div>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"inline-flex items-center -space-x-px"}
              pageClassName={
                "px-3 py-2 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              pageLinkClassName={""}
              previousClassName={
                "block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              previousLinkClassName={""}
              nextClassName={
                "block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              nextLinkClassName={""}
              breakClassName={
                "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              breakLinkClassName={
                "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              activeClassName={
                "z-10 px-3 py-2 leading-tight text-white border border-blue-400 bg-primary hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
              }
            />
          </div>
        </nav>
      </div>
    </div>
  );
}
