import { useEffect, useState } from "react";
import { api, header } from "../lib/queries/axiosInstance";

const useAttendees = () => {
  const [attendeeData, setAttendeeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(100);
  const [total, setTotal] = useState(0);

  const [end, setEnd] = useState(start + limit);
  const [pageCount, setpageCount] = useState(0);

  //   useEffect(() => {
  //     const fetchAllAttendees = async () => {
  //       setLoading(true);
  //       const result = await fetch(URL);
  //       result.json().then((json) => {
  //         setAttendeeData(json.data.content);
  //       setLoading(false);

  //       });
  //     };
  //     fetchAllAttendees();
  //   }, []);

  const fetchAllAttendees = async () => {
    try {
      setLoading(true);
      const res = await api.get(`Attendees/${start}/${limit}`, {
        headers: header(),
      });

      const data = await res.data;
      console.log(data);
      const total = data.data.content.total;
      setTotal(total);
      setpageCount(Math.ceil(total / limit));
      setAttendeeData(data.data.content);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllAttendees();
  }, []);
  useEffect(() => {
    setLoading(true);
    fetchAllAttendees();
    console.log(limit);
  }, [start, limit, end]);

  return {
    attendeeData,
    loading,
    limit,
    start,
    setEnd,
    setStart,
    pageCount,
    setLimit,
    total,
  };
};

export default useAttendees;
