import React from "react";
import styles from "./survey.module.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { HiOutlineSearch } from "react-icons/hi";
import { api, header } from "../../lib/queries/axiosInstance";
import PulseLoader from "react-spinners/PulseLoader";
import ReactPaginate from "react-paginate";
import Papa from "papaparse";
import { TiExportOutline } from "react-icons/ti";

const TableData = ({ children }) => (
  <td className="px-6 py-2 text-black">{children}</td>
);
export default function SurveyListings() {
  const [limit, setLimit] = useState(100);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(start + limit);
  const [pageCount, setpageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [surveys, setSurveys] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const getSurveys = async () => {
      const res = await api.get(`householdsurvey/0/${limit}`, {
        headers: header(),
      });
      // const getSurveys = async () => {
      //   const res = await api.get(`householdsurvey`, {
      //     headers: header(),
      //   });

      const data = await res.data;
      console.log(data);
      if (data?.data?.content?.length > 0) {
        const total = data.data.total;
        setTotal(total);
        setpageCount(Math.ceil(total / limit));
        setSurveys(data.data.content);
      }
      setLoading(false);
    };

    getSurveys();
  }, [limit]);

  const fetchSurveys = async (start) => {
    setLoading(true);
    const res = await api.get(`householdsurvey/${start}/${limit}`, {
      headers: header(),
    });

    const data = await res.data;
    setLoading(false);
    const resData = data.data.content;
    // console.log(data.data);
    return resData;
  };

  const handlePageClick = async (data) => {
    let startCounter = data.selected + 1;
    let startr = startCounter * limit - limit;
    let endr = startr + limit;
    const survs = await fetchSurveys(startr);
    setEnd(endr);
    setStart(startr);
    setSurveys(survs);
  };

  const exportToCSV = () => {
    console.log("Export Clicked");
    const currentTimestamp = new Date().toISOString().replace(/:/g, "-");
    // const csv = Papa.unparse(surveys);
    // const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    // const csvURL = URL.createObjectURL(csvData);
    // const tempLink = document.createElement("a");
    // tempLink.href = csvURL;
    // tempLink.setAttribute(
    //   "download",
    //   `Generated-Surveys_${currentTimestamp}.csv`
    // );
    // tempLink.click();
    // // Generate CSV file
    const csv = Papa.unparse(surveys);
    const modifiedCsv = csv.replace(/â€“/g, "-");
    const csvData = new Blob([modifiedCsv], {
      type: "text/csv;charset=utf-8;",
    });
    const csvURL = URL.createObjectURL(csvData);
    const tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute(
      "download",
      `Generated-Surveys_${currentTimestamp}.csv`
    );
    tempLink.click();
  };

  return (
    <div>
      <div className="text-xl pt-1">GESI Records({total})</div>
      <div className="bg-white mt-4 p-6">
        <div className="flex items-start py-2  justify-between">
          <div></div>
          {/* Export */}
          <div>
            <button
              data-modal-target="popup-modal"
              data-modal-toggle="popup-modal"
              className="text-white w-full text-xs bg-primary py-[8px] px-2 flex justify-center items-center rounded-sm border border-primary focus:outline-none focus:shadow-outline"
              type="button"
              onClick={() => exportToCSV()}
            >
              Export to CSV
              <TiExportOutline className="text-white ml-2" />
            </button>
          </div>
        </div>
        <div
          className={` ${styles.tablewrp} relative scroll-div overflow-scroll mt-4 shadow-md  w-100% sm:rounded-lg`}
        >
          {loading ? (
            <div className=" m-12  justify-items-center justify-center">
              {" "}
              <PulseLoader
                className=" m-12 justify-center"
                color={"#46A576"}
                loading={loading}
                // cssOverride={override}
                size={14}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <table
              className={` w-full  text-sm text-left text-gray-500 dark:text-gray-400`}
            >
              <thead className="text-xs sticky top-0 right-0 left-0 text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    UID
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Agent FirstName
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Agent LastName
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Agent Email
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Agent Phone Number
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    State
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Age
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Sex
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    MaritalStatus
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    HouseHoldType
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    HeadOfHouseHold
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Religion
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Ethnicity
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Indegigenity
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    DifficultySeeing
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    DifficultyHearing
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    DifficultyWalking
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    DifficultyRemembering
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    DifficultyWashingOver
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    DifficultyCommunication
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    HighestLevelOfEdu
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    PrimaryOccupation
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    SecondaryOccupation
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    MonthlyEarning
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WhoPrepLandForCultivation
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WhoDoesTheActualCultivation
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WhoDoesWeeding
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Harvesting
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Processing
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Storage
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WhoSellCultivatedProduce
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    SweepingAndGenIndoorSan
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    FetchingWaterFirewood
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Cooking
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    HouseChores
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    TakingCareOfSick
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    HomeVilante
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    HouseholdLeadership
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    ManagingHouseholdFinances
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    ChildBearing
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    ChildCare
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    ChildUpbringing
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    HoursOnDomOrRepRoles
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    CreditFinanceAgricultural
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    BusinessDevServices
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    VillageSavingsLoansAssociation
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    TrainingModernFarming
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    LandAgriculturalCommunity
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    LandDevelopmentOther
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    ExtensionAgents
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Markets
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    AgriculturalInputs
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    PrimaryPurpose
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    LandAcquisition
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    LandOwnerGender
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WomenOwnLand
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WomenDecisionPower
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    BankAccount
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    NearestBankDistance
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    IncomeControl
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    FinancialSupport
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    MoneyLendingFacilities
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    MoneyLendingOptions
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    FarmerAssociationMember
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    LeadershipPosition
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    InfluentialPersonsCommunity
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WhoDecideHouseholdIncomeUsed
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WhoControlsDailyBudget
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WhoDecidesHouseholdSavingsInvestments
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WhoDecideWomenGirlsAllowedPaidJob
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WhoDecidesDomesticWork
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WhoDecideAccessWomenMenBoysGirlsBasicEducationInformation
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WhoDecidesChildCareIssues
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WhoDecideWhenWhereAccessHealthCare
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WhoDecidesFamilySize
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WhoDecidesChildMarriage
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WhoDecidesLandsAgriculturePurpose
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WhoDecidesCropsToProduce
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    LandPreparationHowMuchLandToCultivate
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WhenWhatVarietySeedToPlant
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WhoControlsProceedsFarmProduce
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    LandsForDevelopmentOtherThanAgriculturePurpose
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    CategoryIndividualsLeftOutDuringServiceDelivery
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    CategoryIndividualsMarginalizedDiscriminatedAgainst
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    CategoryIndividualsAbusedAssaulted
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    KindCommonAbuseAssaultExposed
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    ExperiencedFormAbuseAssault
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    KindAbuseAssaultExperienced
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    HavePWDsInCommunity
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    PerceptionOfDisabilityInCommunity
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    ChallengesFacedByPWDsInCommunity
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    HaveIDPsInCommunity
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    ChallengesFacedByIDPsInCommunity
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    WorryAboutFoodRunningOut
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    FoodNotLastNoMoneyToBuyMore
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    EatBalancedMeals
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    ReduceSizeOrSkipMeals
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    EatLessOrSkipMeals
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    ChildrenEatLessOrSkipMeals
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    EatLessOrSippedMeals
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    ChildrenLoseWeight
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    NotEatEntireDay
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    ChildNotEatEntireDay
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    BreastfeedDuration
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    GrownOwnFoodBeforeJuriya
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    PercentageFoodFromOwnLand
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    DistanceToNearestMarket
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    MarketsHaveDesiredFoods
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    MarketsHaveReasonablePrices
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    AbleToTradeWithFarmers
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    HealthIssues
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    DisabilityRequiresFeedingAssistance
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    MedicalProfessionalVisits
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Status
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    CreatedAT
                  </th>
                </tr>
              </thead>
              <tbody className=" pt-8">
                {surveys.length &&
                  surveys.map((farmer) => (
                    <tr
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 pt-8  hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer"
                      onClick={() => navigate(`/householddetail/${farmer.UID}`)}
                    >
                      <TableData>{farmer?.UID}</TableData>
                      <TableData>{farmer?.Agent_First_Name}</TableData>
                      <TableData>{farmer?.Agent_Last_Name}</TableData>
                      <TableData>{farmer?.Agent_Email}</TableData>
                      <TableData>0{farmer?.Agent_Phone}</TableData>
                      <TableData>{farmer?.State}</TableData>
                      <TableData>{farmer?.Age}</TableData>
                      <TableData>{farmer?.Sex}</TableData>
                      <TableData>{farmer?.MaritalStatus}</TableData>
                      <TableData>{farmer?.HouseHoldType}</TableData>
                      <TableData>{farmer?.HeadOfHouseHold}</TableData>
                      <TableData>{farmer?.Religion}</TableData>
                      <TableData>{farmer?.Ethnicity}</TableData>
                      <TableData>{farmer?.Indegigenity}</TableData>
                      <TableData>{farmer?.DifficultySeeing}</TableData>
                      <TableData>{farmer?.DifficultyHearing}</TableData>
                      <TableData>{farmer?.DifficultyWalking}</TableData>
                      <TableData>{farmer?.DifficultyRemembering}</TableData>
                      <TableData>{farmer?.DifficultyWashingOver}</TableData>
                      <TableData>{farmer?.DifficultyCommunication}</TableData>
                      <TableData>{farmer?.HighestLevelOfEdu}</TableData>
                      <TableData>{farmer?.PrimaryOccupation}</TableData>
                      <TableData>{farmer?.SecondaryOccupation}</TableData>
                      <TableData>{farmer?.MonthlyEarning}</TableData>
                      <TableData>{farmer?.WhoPrepLandForCultivation}</TableData>
                      <TableData>
                        {farmer?.WhoDoesTheActualCultivation}
                      </TableData>
                      <TableData>{farmer?.WhoDoesWeeding}</TableData>
                      <TableData>{farmer?.Harvesting}</TableData>
                      <TableData>{farmer?.Processing}</TableData>
                      <TableData>{farmer?.Storage}</TableData>
                      <TableData>{farmer?.WhoSellCultivatedProduce}</TableData>
                      <TableData>{farmer?.SweepingAndGenIndoorSan}</TableData>
                      <TableData>{farmer?.FetchingWaterFirewood}</TableData>
                      <TableData>{farmer?.Cooking}</TableData>
                      <TableData>{farmer?.HouseChores}</TableData>
                      <TableData>{farmer?.TakingCareOfSick}</TableData>
                      <TableData>{farmer?.HomeVilante}</TableData>
                      <TableData>{farmer?.HouseholdLeadership}</TableData>
                      <TableData>{farmer?.ManagingHouseholdFinances}</TableData>
                      <TableData>{farmer?.ChildBearing}</TableData>
                      <TableData>{farmer?.ChildCare}</TableData>
                      <TableData>{farmer?.ChildUpbringing}</TableData>
                      <TableData>{farmer?.HoursOnDomOrRepRoles}</TableData>
                      <TableData>{farmer?.CreditFinanceAgricultural}</TableData>
                      <TableData>{farmer?.BusinessDevServices}</TableData>
                      <TableData>
                        {farmer?.VillageSavingsLoansAssociation}
                      </TableData>
                      <TableData>{farmer?.TrainingModernFarming}</TableData>
                      <TableData>{farmer?.LandAgriculturalCommunity}</TableData>
                      <TableData>{farmer?.LandDevelopmentOther}</TableData>
                      <TableData>{farmer?.ExtensionAgents}</TableData>
                      <TableData>{farmer?.Markets}</TableData>
                      <TableData>{farmer?.AgriculturalInputs}</TableData>
                      <TableData>{farmer?.PrimaryPurpose}</TableData>
                      <TableData>{farmer?.LandAcquisition}</TableData>
                      <TableData>{farmer?.LandOwnerGender}</TableData>
                      <TableData>{farmer?.WomenOwnLand}</TableData>
                      <TableData>{farmer?.WomenDecisionPower}</TableData>
                      <TableData>{farmer?.BankAccount}</TableData>
                      <TableData>{farmer?.NearestBankDistance}</TableData>
                      <TableData>{farmer?.IncomeControl}</TableData>
                      <TableData>{farmer?.FinancialSupport}</TableData>
                      <TableData>{farmer?.MoneyLendingFacilities}</TableData>
                      <TableData>{farmer?.MoneyLendingOptions}</TableData>
                      <TableData>{farmer?.FarmerAssociationMember}</TableData>
                      <TableData>{farmer?.LeadershipPosition}</TableData>
                      <TableData>
                        {farmer?.InfluentialPersonsCommunity}
                      </TableData>
                      <TableData>
                        {farmer?.WhoDecideHouseholdIncomeUsed}
                      </TableData>
                      <TableData>{farmer?.WhoControlsDailyBudget}</TableData>
                      <TableData>
                        {farmer?.WhoDecidesHouseholdSavingsInvestments}
                      </TableData>
                      <TableData>
                        {farmer?.WhoDecideWomenGirlsAllowedPaidJob}
                      </TableData>
                      <TableData>{farmer?.WhoDecidesDomesticWork}</TableData>
                      <TableData>
                        {
                          farmer?.WhoDecideAccessWomenMenBoysGirlsBasicEducationInformation
                        }
                      </TableData>
                      <TableData>{farmer?.WhoDecidesChildCareIssues}</TableData>
                      <TableData>
                        {farmer?.WhoDecideWhenWhereAccessHealthCare}
                      </TableData>
                      <TableData>{farmer?.WhoDecidesFamilySize}</TableData>
                      <TableData>{farmer?.WhoDecidesChildMarriage}</TableData>
                      <TableData>
                        {farmer?.WhoDecidesLandsAgriculturePurpose}
                      </TableData>
                      <TableData>{farmer?.WhoDecidesCropsToProduce}</TableData>
                      <TableData>
                        {farmer?.LandPreparationHowMuchLandToCultivate}
                      </TableData>
                      <TableData>
                        {farmer?.WhenWhatVarietySeedToPlant}
                      </TableData>
                      <TableData>
                        {farmer?.WhoControlsProceedsFarmProduce}
                      </TableData>
                      <TableData>
                        {farmer?.LandsForDevelopmentOtherThanAgriculturePurpose}
                      </TableData>
                      <TableData>
                        {
                          farmer?.CategoryIndividualsLeftOutDuringServiceDelivery
                        }
                      </TableData>
                      <TableData>
                        {
                          farmer?.CategoryIndividualsMarginalizedDiscriminatedAgainst
                        }
                      </TableData>
                      <TableData>
                        {farmer?.CategoryIndividualsAbusedAssaulted}
                      </TableData>
                      <TableData>
                        {farmer?.KindCommonAbuseAssaultExposed}
                      </TableData>
                      <TableData>
                        {farmer?.ExperiencedFormAbuseAssault}
                      </TableData>
                      <TableData>
                        {farmer?.KindAbuseAssaultExperienced}
                      </TableData>
                      <TableData>{farmer?.HavePWDsInCommunity}</TableData>
                      <TableData>
                        {farmer?.PerceptionOfDisabilityInCommunity}
                      </TableData>
                      <TableData>
                        {farmer?.ChallengesFacedByPWDsInCommunity}
                      </TableData>
                      <TableData>{farmer?.HaveIDPsInCommunity}</TableData>
                      <TableData>
                        {farmer?.ChallengesFacedByIDPsInCommunity}
                      </TableData>
                      <TableData>{farmer?.WorryAboutFoodRunningOut}</TableData>

                      <TableData>
                        {farmer?.FoodNotLastNoMoneyToBuyMore}
                      </TableData>

                      <TableData>{farmer?.EatBalancedMeals}</TableData>

                      <TableData>{farmer?.ReduceSizeOrSkipMeals}</TableData>

                      <TableData>{farmer?.EatLessOrSkipMeals}</TableData>

                      <TableData>
                        {farmer?.ChildrenEatLessOrSkipMeals}
                      </TableData>

                      <TableData>{farmer?.EatLessOrSippedMeals}</TableData>

                      <TableData>{farmer?.ChildrenLoseWeight}</TableData>

                      <TableData>{farmer?.NotEatEntireDay}</TableData>

                      <TableData>{farmer?.ChildNotEatEntireDay}</TableData>

                      <TableData>{farmer?.BreastfeedDuration}</TableData>

                      <TableData>{farmer?.GrownOwnFoodBeforeJuriya}</TableData>

                      <TableData>{farmer?.PercentageFoodFromOwnLand}</TableData>

                      <TableData>{farmer?.DistanceToNearestMarket}</TableData>

                      <TableData>{farmer?.MarketsHaveDesiredFoods}</TableData>

                      <TableData>
                        {farmer?.MarketsHaveReasonablePrices}
                      </TableData>

                      <TableData>{farmer?.AbleToTradeWithFarmers}</TableData>

                      <TableData>{farmer?.HealthIssues}</TableData>

                      <TableData>
                        {farmer?.DisabilityRequiresFeedingAssistance}
                      </TableData>

                      <TableData>{farmer?.MedicalProfessionalVisits}</TableData>

                      <TableData>{farmer?.Status}</TableData>

                      <TableData>{farmer?.CreatedAT}</TableData>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
        <nav
          className="flex items-center justify-between pt-4"
          aria-label="Table navigation"
        >
          <div className="flex items-center">
            <div className="mt-4 ">
              <form>
                {" "}
                <label
                  // for="countries"
                  className="block mb-2 text-sm font-regular text-gray-900 dark:text-white"
                >
                  No of Data Per Page
                </label>
                <select
                  id="countries"
                  className=" border border-gray-200 text-gray-500 text-sm rounded-md font-light focus:ring-green-500 focus:border-green-400 block w-full p-2.5 "
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <option selected>{limit}</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                </select>
              </form>
            </div>
          </div>

          <div className="flex items-center gap-4">
            <div className=" gap-4 ">
              <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                Showing{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {start}-{end}
                </span>{" "}
                of{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {total}
                </span>
              </span>
            </div>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"inline-flex items-center -space-x-px"}
              pageClassName={
                "px-3 py-2 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              pageLinkClassName={""}
              previousClassName={
                "block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              previousLinkClassName={""}
              nextClassName={
                "block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              nextLinkClassName={""}
              breakClassName={
                "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              breakLinkClassName={
                "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              activeClassName={
                "z-10 px-3 py-2 leading-tight text-white border border-blue-400 bg-primary hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
              }
            />
          </div>
        </nav>
      </div>
    </div>
  );
}
