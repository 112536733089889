import {
  HiOutlineViewGrid,
  HiOutlineUsers,
  HiOutlineQuestionMarkCircle,
  HiUserAdd,
} from "react-icons/hi";
import { HiOutlineDocumentText, HiOutlineDocumentCheck } from "react-icons/hi2";
import { MdOutlineCircle } from "react-icons/md";
import { RiUserStarLine } from "react-icons/ri";
import { FcSurvey } from "react-icons/fc";
import { FaUsers } from "react-icons/fa";
import { BsCalendar2Event } from "react-icons/bs";
import { GrDashboard, GrUserWorker } from "react-icons/gr";
import { SiPivotaltracker } from "react-icons/si";
import { BiBrain, BiLandscape, BiPyramid, BiRadioCircle } from "react-icons/bi";
import { AiOutlineGold, AiOutlineProfile } from "react-icons/ai";

export const DASHBOARD_SIDEBAR_LINKS = [
  {
    key: "dashboard",
    label: "Dashboard",
    path: "/",
    icon: <HiOutlineViewGrid />,
    dropdown: false,
  },

  {
    key: "users",
    label: "Users",
    path: "#",
    icon: <FaUsers />,
    dropdown: [
      {
        key: "managestaff",
        label: "Staff",
        path: "/staff",
        icon: <BiRadioCircle />,
      },
      {
        key: "household",
        label: "Household",
        path: "/household",
        icon: <BiRadioCircle />,
      },
      {
        key: "fieldagents",
        label: "Field Agents",
        path: "/fieldagents",
        icon: <BiRadioCircle />,
      },
      {
        key: "adhoc-agents",
        label: "Adhoc Agents",
        path: "/adhoc-agents",
        icon: <BiRadioCircle />,
      },
      {
        key: "outreach",
        label: "Outreach",
        path: "/outreach",
        icon: <BiRadioCircle />,
      },
    ],
  },
  {
    key: "activities",
    label: "Activities",
    path: "#",
    icon: <SiPivotaltracker />,
    dropdown: [
      {
        key: "household-inputs",
        label: "Household Input Distribution",
        path: "/household-inputs",
        icon: <BiRadioCircle />,
      },
      {
        key: "survey",
        label: "GESI Records",
        path: "/household-survey",
        icon: <BiRadioCircle />,
      },
      {
        key: "ra-adoption",
        label: "RA Adoption",
        path: "/ra-adoption",
        icon: <BiRadioCircle />,
      },
      // {
      //   key: "trainings",
      //   label: "Trainings",
      //   path: "/demoplots-training",
      //   icon: <BiRadioCircle />,
      // },
      // {
      //   key: "attendance",
      //   label: "Attendance",
      //   path: "/demoplots-attendance",
      //   icon: <BiRadioCircle />,
      // },
    ],
  },
  {
    key: "DemoPlot",
    label: "Demo Plots",
    path: "#",
    icon: <BiLandscape />,

    dropdown: [
      {
        key: "demo-plots",
        label: "Enumerations",
        path: "/demo-plots",
        icon: <BiRadioCircle />,
      },
      {
        key: "demo-plots-input",
        label: "Input Distribution",
        path: "/demo-plots/input-distribution",
        icon: <BiRadioCircle />,
      },
    ],
  },
  {
    key: "events",
    label: "Events",
    path: "#",
    icon: <BsCalendar2Event />,

    dropdown: [
      {
        key: "allengagements",
        label: "Engagement Events",
        path: "/allengagements",
        icon: <BiRadioCircle />,
      },
      {
        key: "all-events",
        label: "Training Events",
        path: "/all-events",
        icon: <BiRadioCircle />,
      },
    ],
  },
  {
    key: "Farmlands",
    label: "Farmlands",
    path: "/farmlands",
    icon: <AiOutlineGold />,
    dropdown: false,
  },
];
export const DASHBOARD_SIDEBAR_CODINATORS_LINKS = [
  {
    key: "dashboard",
    label: "Dashboard",
    path: "/",
    icon: <HiOutlineViewGrid />,
    dropdown: false,
  },

  {
    key: "users",
    label: "Users",
    path: "#",
    icon: <FaUsers />,
    dropdown: [
      {
        key: "managestaff",
        label: "Staff",
        path: "/staff",
        icon: <BiRadioCircle />,
      },
      {
        key: "household",
        label: "Household",
        path: "/household",
        icon: <BiRadioCircle />,
      },
      {
        key: "fieldagents",
        label: "Field Agents",
        path: "/fieldagents",
        icon: <BiRadioCircle />,
      },
      {
        key: "adhoc-agents",
        label: "Adhoc Agents",
        path: "/adhoc-agents",
        icon: <BiRadioCircle />,
      },
      {
        key: "outreach",
        label: "Outreach",
        path: "/outreach",
        icon: <BiRadioCircle />,
      },
    ],
  },
  {
    key: "activities",
    label: "Activities",
    path: "#",
    icon: <SiPivotaltracker />,
    dropdown: [
      {
        key: "household-inputs",
        label: "Household Input Distribution",
        path: "/household-inputs",
        icon: <BiRadioCircle />,
      },
      {
        key: "survey",
        label: "GESI Records",
        path: "/household-survey",
        icon: <BiRadioCircle />,
      },
      {
        key: "ra-adoption",
        label: "RA Adoption",
        path: "/ra-adoption",
        icon: <BiRadioCircle />,
      },
    ],
  },
  {
    key: "DemoPlot",
    label: "Demo Plots",
    path: "#",
    icon: <BiLandscape />,

    dropdown: [
      {
        key: "attendance",
        label: "Attendance",
        path: "/demoplots-attendance",
        icon: <BiRadioCircle />,
      },
      {
        key: "trainings",
        label: "Trainings",
        path: "/demoplots-training",
        icon: <BiRadioCircle />,
      },
    ],
  },
  {
    key: "events",
    label: "Events",
    path: "#",
    icon: <BsCalendar2Event />,

    dropdown: [
      {
        key: "allengagements",
        label: "Engagement Events",
        path: "/allengagements",
        icon: <BiRadioCircle />,
      },
      {
        key: "all-events",
        label: "Training Events",
        path: "/all-events",
        icon: <BiRadioCircle />,
      },
    ],
  },
  {
    key: "Farmlands",
    label: "Farmlands",
    path: "/farmlands",
    icon: <AiOutlineGold />,
    dropdown: false,
  },
];
export const DASHBOARD_SIDEBAR_BOTTOM_LINKS = [
  {
    key: "support",
    label: "Help & Support",
    path: "/support",
    icon: <HiOutlineQuestionMarkCircle />,
  },
];
