import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import { HiOutlineSearch } from "react-icons/hi";
import { api, header } from "../../lib/queries/axiosInstance";
import PulseLoader from "react-spinners/PulseLoader";
import ReactPaginate from "react-paginate";
import Papa from "papaparse";
import { TiExportOutline } from "react-icons/ti";
import { CustomLink } from "../../components/Generic";

const TableData = ({ children }) => (
  <td className="px-6 py-2 text-black text-xs">{children}</td>
);
export default function EventListings() {
  const [limit, setLimit] = useState(100);
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(start + limit);
  const [pageCount, setpageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [events, setEvents] = useState([]);
  const [searchResult, setSearchResult] = useState("");
  const navigate = useNavigate();

  const fetchSearch = async () => {
    setLoading(true);

    const searchedData = await api.get(`Enumeration/search/${searchResult}`, {
      headers: header(),
    });
    setEvents(searchedData.data.data);
    setLoading(false);
  };

  const handleSearch = () => {
    fetchSearch();
  };

  useEffect(() => {
    setLoading(true);
    // const getEvents = async () => {
    //   const res = await api.get(`householdsurvey/1/${limit}`, {
    //     headers: header(),
    //   });
    const getEvents = async () => {
      const res = await api.get(`eventtable`, {
        headers: header(),
      });

      const data = await res.data;
      console.log(data);
      if (data?.data?.content?.length > 0) {
        const total = data.data.total;
        setTotal(total);
        setpageCount(Math.ceil(total / limit));
        setEvents(data.data.content);
      }
      setLoading(false);
    };
    getEvents();
  }, [limit]);

  const fetchevents = async (start) => {
    setLoading(true);
    const res = await api.get(`eventtable/${start}/${limit}`, {
      headers: header(),
    });

    const data = await res.data;
    setLoading(false);
    const resData = data.data.content;
    // console.log(data.data);
    return resData;
  };

  const handlePageClick = async (data) => {
    // let startCounter = data.selected + 1;
    // let startr = startCounter * limit - limit;
    // let endr = startr + limit;
    // const survs = await fetchevents(startr);
    // setEnd(endr);
    // setStart(startr);
    // setEvents(survs);
  };

  const exportToCSV = () => {
    console.log("Export Clicked");
    const currentTimestamp = new Date().toISOString().replace(/:/g, "-");
    // const csv = Papa.unparse(events);
    // const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    // const csvURL = URL.createObjectURL(csvData);
    // const tempLink = document.createElement("a");
    // tempLink.href = csvURL;
    // tempLink.setAttribute(
    //   "download",
    //   `Generated-events_${currentTimestamp}.csv`
    // );
    // tempLink.click();
    // // Generate CSV file
    const csv = Papa.unparse(events);
    const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const csvURL = URL.createObjectURL(csvData);
    const tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute(
      "download",
      `Generated-events_${currentTimestamp}.csv`
    );
    tempLink.click();
  };

  return (
    <div>
      <div className="w-full flex justify-between items-center">
        <div className="text-xl pt-1">All Events ({total})</div>
        <CustomLink
          path={"/create-event"}
          text={"Create Event"}
          width={"w-[150px]"}
        />
      </div>
      <div className="bg-white mt-4 p-6">
        <div className="flex items-start py-2  justify-between">
          <div>
            <label className="block mb-2 text-sm font-regular text-gray-900 dark:text-white ">
              Event Category
            </label>

            <select
              // onChange={handleNINStatus}
              // value={NINStatus}
              className=" border border-gray-200 text-sm font-medium px-3 py-2 text-black rounded-md  focus:ring-green-500 focus:border-green-500 block w-full "
            >
              <option selected> -- All Category -- </option>
              <option value="Yes">Pending</option>
              <option value="No">Upcoming</option>
              <option value="No">Completed</option>
            </select>
          </div>
          <div></div>
        </div>
        <div
          className={`max-h-[65vh] relative scroll-div overflow-scroll mt-4 shadow-md  w-100% sm:rounded-lg`}
        >
          {loading ? (
            <div className=" m-12  justify-items-center justify-center">
              {" "}
              <PulseLoader
                className=" m-12 justify-center"
                color={"#46A576"}
                loading={loading}
                // cssOverride={override}
                size={14}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <table
              className={` w-full  text-[10px] text-left text-black dark:text-gray-400`}
            >
              <thead className="text-[10px] sticky top-0 right-0 left-0 text-black capitalize bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th
                    scope="col"
                    className=" text-xs px-6 py-3 whitespace-nowrap"
                  >
                    Event UID
                  </th>
                  <th
                    scope="col"
                    className=" text-xs px-6 py-3 whitespace-nowrap"
                  >
                    Event Type
                  </th>
                  <th
                    scope="col"
                    className=" text-xs px-6 py-3 whitespace-nowrap"
                  >
                    Event Title
                  </th>
                  <th
                    scope="col"
                    className=" text-xs px-6 py-3 whitespace-nowrap"
                  >
                    Event Sub Title
                  </th>
                  <th
                    scope="col"
                    className=" text-xs px-6 py-3 whitespace-nowrap"
                  >
                    Event Date & Time
                  </th>
                  <th
                    scope="col"
                    className=" text-xs px-6 py-3 whitespace-nowrap"
                  >
                    State
                  </th>
                  <th
                    scope="col"
                    className=" text-xs px-6 py-3 whitespace-nowrap"
                  >
                    LGA
                  </th>
                  <th
                    scope="col"
                    className=" text-xs px-6 py-3 whitespace-nowrap"
                  >
                    Event Community
                  </th>
                  <th
                    scope="col"
                    className=" text-xs px-6 py-3 whitespace-nowrap"
                  >
                    Created At
                  </th>
                  {/* <th scope="col" className=" text-xs px-6 py-3 whitespace-nowrap">
                    Assigned Agent
                  </th> */}
                </tr>
              </thead>
              <tbody className=" pt-8">
                {events.length &&
                  events.map((event, index) => (
                    <tr
                      key={index}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 pt-8  hover:bg-gray-50 dark:hover:bg-gray-600 cursor-pointer"
                      onClick={() =>
                        navigate(`/event-details/${event.EventUID}`)
                      }
                    >
                      <TableData>{event.EventUID}</TableData>
                      <TableData>{"Training Event"}</TableData>
                      <TableData>{event.EventCategory}</TableData>
                      <TableData>{`${
                        event.EventSubCategory ? event.EventSubCategory : "-"
                      }`}</TableData>
                      <TableData>{`${event.EventDate}, ${event.EventTime}`}</TableData>
                      <TableData>{event.State}</TableData>
                      <TableData>{event.LGA}</TableData>
                      <TableData>{event.EventCommunity}</TableData>
                      <TableData>{event.CreatedAT}</TableData>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>
        <nav
          className="flex items-center justify-between pt-4"
          aria-label="Table navigation"
        >
          <div className="flex items-center">
            <div className="mt-4 ">
              <form>
                {" "}
                <label
                  // for="countries"
                  className="block mb-2 text-sm font-regular text-gray-900 dark:text-white"
                >
                  No of Data Per Page
                </label>
                <select
                  id="countries"
                  className=" border border-gray-200 text-gray-500 text-sm rounded-md font-light focus:ring-green-500 focus:border-green-400 block w-full p-2.5 "
                  onChange={(e) => setLimit(e.target.value)}
                >
                  <option selected>{limit}</option>
                  <option value="200">200</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                </select>
              </form>
            </div>
          </div>

          <div className="flex items-center gap-4">
            <div className=" gap-4 ">
              <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
                Showing{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {start}-{end}
                </span>{" "}
                of{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {total}
                </span>
              </span>
            </div>
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"inline-flex items-center -space-x-px"}
              pageClassName={
                "px-3 py-2 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              pageLinkClassName={""}
              previousClassName={
                "block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              previousLinkClassName={""}
              nextClassName={
                "block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              nextLinkClassName={""}
              breakClassName={
                "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              breakLinkClassName={
                "px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              }
              activeClassName={
                "z-10 px-3 py-2 leading-tight text-white border border-blue-400 bg-primary hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
              }
            />
          </div>
        </nav>
      </div>
    </div>
  );
}
